import React from "react";
import { Alert } from "@akj-dev/design-system";
import { Box, Button } from "@material-ui/core";

interface AlertWithButtonsProps {
  message: string;
  onConfirm: Function;
  onCancel: Function;
  showButtons: boolean;
  hideCancel: boolean;
}

function AlertWithButtons({
  message,
  onConfirm,
  onCancel,
  showButtons,
  hideCancel,
}: AlertWithButtonsProps) {
  const splitMessage = message
    .split("\n")
    .map((line) => <div key={line}>{line}</div>);
  const newText = (
    <Box>
      {splitMessage}
      {showButtons && (
        <Box display="flex" pt={1}>
          <Box pr={1}>
            <Button
              color="primary"
              size="medium"
              onClick={() => onConfirm()}
              variant="text"
            >
              Yes, use it
            </Button>
          </Box>
          {!hideCancel && (
            <Button
              color="primary"
              size="medium"
              onClick={() => onCancel()}
              variant="text"
            >
              Dismiss
            </Button>
          )}
        </Box>
      )}
    </Box>
  );

  return <Alert message={newText} type="info" />;
}

export default AlertWithButtons;
