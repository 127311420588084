import React, { ReactNode } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Alert } from "@akj-dev/design-system";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomFade: {
      width: "100%",
      height: "16px",
      margin: "0 -16px 0 -16px",
      padding: "0 16px 0 16px",
      backgroundImage: "linear-gradient(rgba(255,255,255,1), rgba(255,0,0,0))",
    },
  })
);

interface Props {
  children: ReactNode;
  menu?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string;
  loading?: boolean;
  error?: string;
  stickyHeader?: boolean;
}

function Main({
  children,
  menu,
  title,
  subtitle,
  loading = false,
  error = "",
  stickyHeader = false,
}: Props) {
  const classes = useStyles();

  const getContent = () => {
    if (loading) {
      return (
        <Box margin="auto">
          <CircularProgress />
        </Box>
      );
    }

    return children;
  };

  if (error) {
    return (
      <Box paddingTop={2}>
        <Alert message={error} type="error" />
      </Box>
    );
  }

  return (
    <Box data-cy="main-layout" display="flex" flexDirection="column" pb={1}>
      {stickyHeader ? (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          width="100%"
          position="sticky"
          top="0"
          zIndex={1000}
        >
          <Box display="flex" p={1} ml={-1} mr={-1} bgcolor="#ffffff">
            <Box display="flex" flexGrow={1} alignItems="baseline">
              <Typography data-cy="main-title" variant="h2">
                {title}
              </Typography>
              {subtitle && (
                <Box ml={0.5}>
                  <Typography
                    data-cy="main-subtitle"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                    variant="subtitle1"
                  />
                </Box>
              )}
            </Box>
            <Box>{menu}</Box>
          </Box>

          <div className={classes.bottomFade} />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          paddingTop={1}
        >
          <Box display="flex" flexGrow={1} alignItems="baseline">
            <Typography data-cy="main-title" variant="h2">
              {title}
            </Typography>
            {subtitle && (
              <Box ml={0.5}>
                <Typography
                  data-cy="main-subtitle"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  variant="subtitle1"
                />
              </Box>
            )}
          </Box>
          <Box>{menu}</Box>
        </Box>
      )}
      <Box
        data-cy="main-content"
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        pt={stickyHeader ? 0 : 1}
      >
        {getContent()}
      </Box>
    </Box>
  );
}

export default Main;
