import React, { useEffect, useState } from "react";
import { useQueryParams, StringParam } from "use-query-params";
import {
  Typography,
  makeStyles,
  Button,
  Box,
  Grid,
  Chip,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import EditIcon from "@material-ui/icons/Edit";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Form from "../../components/Form";
import Dialog from "../../../../components/Dialog";
import AttachmentComponent from "../../../../components/Attachment";
import CommentVersion from "./CommentVersion";
import { Attachment, Comment, CommentFields } from "../../../../types";
import {
  CommentCreateMutationType,
  CommentUpdateMutationType,
  CommentVersion as CommentVersionType,
} from "../../types";
import { formattedDateTime } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { hasPermission } from "../../../../helpers/functions";
import { useCommentHistory, useCommentTypes } from "../../../../hooks/comments";
import { useRemove } from "../../../../hooks/comments/attachments";

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  commentWrap: {
    float: "right",
    width: ({ commentWidth }) => commentWidth,
    padding: theme.spacing(1),
    boxShadow: "inset 0 0 0 white, 0 4px 8px -8px gray",
    backgroundColor: "white",
    transition: "all 1s ease-out",
    "&.highlighted": {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "0 5px 5px 0",
      boxShadow: `inset 0 0 8px ${theme.palette.secondary.main}`,
    },
  },
  commentReply: {
    borderLeft: `solid ${theme.palette.primary.main} 2px`,
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
  },
  message: {
    "& img": {
      maxWidth: "100%",
    },
    display: "inline-block",
    overflowWrap: "anywhere",
    "& p": {
      margin: 0,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(0.5),
  },
  formWrap: {
    padding: theme.spacing(1, 0, 0.5, 0),
  },
  edited: {
    color: theme.palette.grey[300],
    marginLeft: theme.spacing(0.5),
  },
}));

interface StyleProps {
  commentWidth: string;
}

interface Props {
  comment: Comment;
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
  showReplies: boolean;
  setShowReplies: React.Dispatch<React.SetStateAction<boolean>>;
  replying: boolean;
  setReplying: React.Dispatch<React.SetStateAction<number>>;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<number>>;
  hideZeroLevelCommentForm: () => void;
  create: CommentCreateMutationType;
  update: CommentUpdateMutationType;
  loaded: boolean;
}

function Component({
  comment,
  fields,
  setFields,
  clearFields,
  showReplies,
  setShowReplies,
  replying,
  setReplying,
  editing,
  setEditing,
  hideZeroLevelCommentForm,
  create,
  update,
  loaded,
}: Props) {
  const depth = comment?.depth || 0;
  const [{ comment_id }] = useQueryParams({
    comment_id: StringParam,
  });
  const [isHighlighted, setIsHighlighted] = useState(
    comment_id === comment.id.toString() ?? false
  );
  const commentWidth = `${100 - (depth < 12 ? depth : 12) * 5}%`;
  const styleProps: StyleProps = { commentWidth };
  const classes = useStyles(styleProps);
  const commentTypes = useCommentTypes();
  const [showCommentHistory, setShowCommentHistory] = useState(false);
  const versions = useCommentHistory(
    comment.id,
    {},
    { enabled: showCommentHistory }
  );
  const remove = useRemove(comment.id);

  useEffect(() => {
    if (loaded && !!comment_id && isHighlighted) {
      document
        .getElementById(comment_id)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => setIsHighlighted(false), 3000);
    }
  }, [loaded, isHighlighted, comment_id]);

  const handleEdit = () => {
    hideZeroLevelCommentForm();
    setReplying(-1);
    setEditing(comment.id);
    clearFields();
    setFields({ message: comment.message, type_id: comment.type.id });
  };

  const handleReply = () => {
    hideZeroLevelCommentForm();
    setEditing(-1);
    setReplying(comment.id);
    if (!showReplies) setShowReplies(true);
    clearFields();
  };

  const handleEditCancel = () => {
    setEditing(-1);
    clearFields();
  };

  const handleReplyCancel = () => {
    setReplying(-1);
    clearFields();
  };

  return (
    <Grid
      data-cy="comment"
      container
      className={
        comment.depth === 0
          ? [classes.commentWrap, isHighlighted && "highlighted"].join(" ")
          : [
              classes.commentWrap,
              classes.commentReply,
              isHighlighted && "highlighted",
            ].join(" ")
      }
      id={comment.id.toString()}
    >
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon className={classes.icon} />
          <Typography variant="caption">
            {formattedDateTime(comment.created_at, "dd/MM/yyyy HH:mm")}
          </Typography>
        </Box>
        <Typography variant="h4" component="div">
          {comment.creator?.full_name}
          {hasPermission(PermissionCodes.commentsViewInternal) && (
            <Chip
              label={
                <Typography variant="caption" color="textSecondary">
                  {comment.type?.name}
                </Typography>
              }
              size="small"
              variant="outlined"
              style={{ margin: 10 }}
            />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          {!!comment.can_be_edited && (
            <Box pr={1}>
              <Button
                data-cy={`comments-edit-button-${comment.id}`}
                color="primary"
                onClick={handleEdit}
                startIcon={<EditIcon />}
                size="small"
                variant="outlined"
                disabled={editing || replying}
              >
                {editing ? "Editing" : "Edit"}
              </Button>
            </Box>
          )}
          <Button
            data-cy={`comments-reply-button-${comment.id}`}
            color="primary"
            onClick={handleReply}
            startIcon={<CommentIcon />}
            size="small"
            variant="outlined"
            disabled={editing || replying}
          >
            {replying ? "Replying" : "Reply"}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          data-cy="comment-message"
          className={classes.message}
          variant="body2"
          dangerouslySetInnerHTML={{ __html: comment.message }}
          component="span"
        />
        {comment.was_edited && (
          <Button
            data-cy="comments-edited-button"
            variant="text"
            className={classes.edited}
            onClick={() => setShowCommentHistory(true)}
          >
            <Typography variant="caption">(edited)</Typography>
          </Button>
        )}
      </Grid>
      {comment.attachments?.length > 0 && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap">
            {comment.attachments?.map((attachment: Attachment) => (
              <AttachmentComponent
                attachment={attachment}
                canBeDeleted={
                  !attachment.is_deleted && comment.can_remove_attachments
                }
                key={attachment.id}
                onRemove={(attachment) => remove.mutate(attachment)}
              />
            ))}
          </Box>
        </Grid>
      )}
      {comment.depth === 0 && comment.descendants.length > 0 && (
        <>
          <Grid item xs={12}>
            <Box mt={1}>
              <Button
                data-cy="comments-show-replies-button"
                color="primary"
                onClick={() => setShowReplies(!showReplies)}
                size="small"
                variant="text"
              >
                {showReplies ? "Hide Replies" : "Show Replies"}
              </Button>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {editing && !replying && (
          <Grid item xs={12} className={classes.formWrap}>
            <Form
              fields={fields}
              submitButtonLabel={update.isLoading ? "Saving" : "Save"}
              submitDisabled={
                fields.message === comment.message && !fields["files[]"].length
              }
              mutation={update}
              setFields={setFields}
              onSubmit={(fields: CommentFields) => {
                update.mutate({
                  ...fields,
                  id: comment.id,
                });
              }}
              onCancel={handleEditCancel}
            />
          </Grid>
        )}
        {replying && !editing && (
          <Grid item xs={12} className={classes.formWrap}>
            <Form
              fields={fields}
              submitButtonLabel={
                create.isLoading ? "Adding Comment" : "Add comment"
              }
              mutation={create}
              setFields={setFields}
              onSubmit={(fields: CommentFields) =>
                create.mutate({
                  ...fields,
                  parent_id: comment.id,
                  type_id:
                    commentTypes.data?.data?.length === 1
                      ? commentTypes.data?.data?.[0].id
                      : comment.type.id,
                })
              }
              onCancel={handleReplyCancel}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        title="Previous Comment Versions"
        confirmText="Close"
        open={showCommentHistory}
        setOpen={() => setShowCommentHistory(true)}
        onConfirm={() => setShowCommentHistory(false)}
        onCancel={() => setShowCommentHistory(false)}
        hideCancelButton
      >
        {versions.data?.data?.map((version: CommentVersionType) => (
          <CommentVersion key={version.id} comment={version} />
        ))}
      </Dialog>
    </Grid>
  );
}

export default Component;
