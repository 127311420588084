import { useQuery } from "react-query";
import * as api from "../../../api/tickets/views/columns";

export function useAutocomplete(query = "", options = {}) {
  return useQuery(
    ["autocomplete", "tickets", "views-columns", query],
    () => api.autocomplete({ query }),
    options
  );
}
