import { Query, TimerFields, TimerType } from "../types";
import * as api from "./";

export function paginate(
  id: number,
  timerType: TimerType,
  query: Partial<Query> = { limit: 10, page: 1 }
) {
  let entity;
  if (timerType === TimerType.ticket) entity = "tickets";
  if (timerType === TimerType.task) entity = "tasks";
  return api.get(`${entity}/${id}/timers`, query);
}

export function createTimer(
  id: number,
  timerType: TimerType,
  fields: Partial<TimerFields>
) {
  let entity;
  if (timerType === TimerType.ticket) entity = "tickets";
  if (timerType === TimerType.task) entity = "tasks";
  return api.post(`${entity}/${id}/timers/manual`, fields);
}

export function update(id: number, fields: Partial<TimerFields>) {
  return api.put(`timers/${id}`, fields);
}

export function remove(id: number) {
  return api.remove(`timers/${id}`);
}
