import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
} from "react-query";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MultilineAlert from "./MultilineAlert";
import GridGroup from "./GridGroup";
import ContactSection from "./ContactSection";
import Autocomplete from "../../../components/Autocomplete";
import Dialog from "../../../components/Dialog";
import RichTextEditor from "../../../components/RichTextEditor";
import {
  Category,
  Customer,
  LooseObject,
  Priority,
  System,
  TicketFields,
  Type,
} from "../../../types";
import { PermissionCodes } from "../../../helpers/constants";
import { hasPermission } from "../../../helpers/functions";
import { useDebounce } from "../../../helpers/hooks";
import {
  useCategories,
  usePriorities,
  useSystems,
  useTypes,
} from "../../../hooks/tickets/autocomplete";
import { TicketData } from "../types";
import { useCustomers } from "../../../hooks/autocomplete";
import { useContacts } from "../../../hooks/customers/autocomplete";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(0.5),
    marginLeft: "auto",
  },
  selectedType: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

interface Props {
  children?: React.ReactNode;
  fields: Partial<TicketFields>;
  id?: number;
  ticket?:
    | QueryObserverRefetchErrorResult<TicketData, Error>
    | QueryObserverSuccessResult<TicketData, Error>;
  onSubmit: (fields: any) => void;
  setFields: (fields: any) => void;
  loading: boolean;
  validation: LooseObject;
  isConvertingFromEvent?: boolean;
}

function Component({
  children,
  fields,
  id,
  ticket,
  onSubmit,
  setFields,
  loading,
  validation = { errors: {} },
  isConvertingFromEvent = false,
}: Props) {
  const classes = useStyles();

  const [formValidation, setValidation] = useState(validation);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [customersFilled, setCustomersFilled] = useState(false);
  const [categoryFilled, setCategoryFilled] = useState(false);
  const [priorityFilled, setPriorityFilled] = useState(false);
  const [systemFilled, setSystemFilled] = useState(false);
  const [shortDescriptionFilled, setShortDescriptionFilled] = useState(false);
  const [descriptionFilled, setDescriptionFilled] = useState(false);

  const [categoryInput, setCategoryInput] = useState("");
  const debouncedCategoryInput = useDebounce(categoryInput);
  const categories = useCategories(
    { query: debouncedCategoryInput, type_id: fields.type_id },
    {
      enabled: !ticket?.isLoading ?? true,
      onSuccess: () =>
        setFields({ category_id: ticket?.data?.data?.category.id ?? "" }),
    }
  );

  const [contactInput, setContactInput] = useState("");
  const debouncedContactInput = useDebounce(contactInput);
  const debouncedFirstNameInput = useDebounce(
    fields["contact.first_name"] ?? ""
  );
  const debouncedLastNameInput = useDebounce(fields["contact.last_name"] ?? "");
  const debouncedEmailInput = useDebounce(fields["contact.email"] ?? "");
  const debouncedPhoneInput = useDebounce(fields["contact.phone"] ?? "");
  const contacts = useContacts(
    debouncedContactInput,
    fields.customer_id,
    {
      first_name: debouncedFirstNameInput,
      last_name: debouncedLastNameInput,
      email: debouncedEmailInput,
      phone: debouncedPhoneInput,
    },
    {
      enabled: ticket?.isFetched ?? !!fields.customer_id,
    }
  );

  const [contactFilled, setContactFilled] = useState(false);

  const [customersInput, setCustomersInput] = useState("");
  const debouncedCustomersInput = useDebounce(customersInput);
  const [totalCustomers, setTotalCustomers] = useState(-1);
  const customers = useCustomers(
    { query: debouncedCustomersInput },
    {
      enabled: ticket?.isFetched ?? true,
      onSuccess: () =>
        setFields({ customer_id: ticket?.data?.data?.customer?.id ?? "" }),
    }
  );

  const priorities = usePriorities(
    {},
    {
      enabled: ticket?.isFetched ?? true,
      onSuccess: () =>
        setFields({ priority_id: ticket?.data?.data?.priority?.id ?? "" }),
    }
  );
  const canEditSeverity = hasPermission(PermissionCodes.ticketsUpdatePriority);

  const [systemInput, setSystemInput] = useState("");
  const debouncedSystemInput = useDebounce(systemInput);
  const systems = useSystems(
    { query: debouncedSystemInput, customer_id: fields.customer_id },
    {
      enabled: ticket?.isFetched ?? true,
      onSuccess: () =>
        setFields({ system_id: ticket?.data?.data?.system.id ?? "" }),
    }
  );

  const types = useTypes(
    {},
    {
      enabled: ticket?.isFetched ?? true,
      onSuccess: () =>
        setFields({ type_id: ticket?.data?.data?.type.id ?? "" }),
    }
  );

  useEffect(() => {
    setValidation(validation);
  }, [validation]);

  useEffect(() => {
    if (totalCustomers < 0 && customers.data?.data) {
      setTotalCustomers(customers.data?.data?.length);

      if (customers.data?.data?.length === 1) {
        setTimeout(
          () => setFields({ customer_id: customers.data?.data?.[0]?.id }),
          300
        );
      }
    }
  }, [totalCustomers, customers, setFields]);

  const isLoadingAnything =
    categories.isLoading ||
    contacts.isLoading ||
    customers.isLoading ||
    priorities.isLoading ||
    systems.isLoading ||
    types.isLoading;

  if (ticket?.data?.data && !validation) {
    if (categories.data?.data && !fields.category_id && !categoryFilled) {
      setFields({
        category_id: ticket?.data?.data?.category?.id ?? "",
      });
      setCategoryFilled(true);
    }
    if (customers.data?.data && !fields.customer_id && !customersFilled) {
      setFields({
        customer_id: ticket?.data?.data?.customer?.id ?? "",
      });
      setCustomersFilled(true);
    }
    if (
      priorities.data?.data &&
      !fields.priority_id &&
      !priorityFilled &&
      canEditSeverity
    ) {
      setFields({
        priority_id: ticket?.data?.data?.priority?.id ?? "",
      });
      setPriorityFilled(true);
    }
    if (systems.data?.data && !fields.system_id && !systemFilled) {
      setFields({
        system_id: ticket?.data?.data?.system?.id ?? "",
      });
      setSystemFilled(true);
    }
    if (types.data?.data && !fields.type_id) {
      setFields({
        type_id: ticket?.data?.data?.type?.id ?? "",
      });
    }
    if (
      contacts.data?.data &&
      !fields["contact.id"] &&
      fields.customer_id === ticket?.data?.data.customer.id &&
      !contactFilled
    ) {
      setFields({
        "contact.id": ticket?.data?.data?.contact?.id ?? "",
        "contact.email": ticket?.data?.data?.contact?.email ?? "",
        "contact.first_name": ticket?.data?.data?.contact?.first_name ?? "",
        "contact.last_name": ticket?.data?.data?.contact?.last_name ?? "",
        "contact.phone": ticket?.data?.data?.contact?.phone ?? "",
      });
      setContactFilled(true);
    }
    if (!fields.description && !descriptionFilled) {
      setTimeout(() => {
        setFields({
          description: ticket?.data?.data?.description ?? "",
        });
        setDescriptionFilled(true);
      }, 300);
    }
    if (!fields.short_description && !shortDescriptionFilled) {
      setFields({
        short_description: ticket?.data?.data?.short_description ?? "",
      });
      setShortDescriptionFilled(true);
    }
  }

  const resetValidation = (fields: string[]) => {
    const updatedErrors = { ...formValidation?.errors };
    for (const field of fields) {
      updatedErrors[field] = null;
    }
    setValidation({
      ...formValidation,
      errors: { ...updatedErrors },
    });
  };

  const handleChangeDescription = (description: string) => {
    resetValidation(["description"]);
    setFields({
      description,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value &&
      formValidation?.errors[event.target.name]?.length
    ) {
      resetValidation([event.target.name]);
    }
    setFields({
      [event.target.name]: event.target.value,
    });
  };

  const hasErrors = (field: string) => {
    if (
      formValidation?.errors[field] &&
      formValidation?.errors[field]?.length
    ) {
      return true;
    }

    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {formValidation?.errors[field]?.map((error: string) => (
            <span key={error}>{error}</span>
          ))}
        </>
      );
    }

    return <></>;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    onSubmit(fields);
  };

  const ToggleButtonWithTooltip = ({
    tooltipText,
    disabled,
    ...rest
  }: {
    tooltipText: string;
    disabled: boolean;
    [x: string]: any;
  }) => {
    return (
      <Tooltip title={tooltipText} className={classes.fullWidth}>
        {disabled ? (
          <div className={classes.fullWidth}>
            <ToggleButton
              disabled={disabled}
              className={classes.fullWidth}
              style={{ borderRadius: "5px 0 0 5px" }}
              {...rest}
            />
          </div>
        ) : (
          <ToggleButton className={classes.fullWidth} {...rest} />
        )}
      </Tooltip>
    );
  };

  return (
    <form
      data-cy="tickets-form"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Card variant="outlined">
        <CardContent>
          <GridGroup>
            <Grid item xs={12} md={6}>
              {types?.isLoading && (
                <Typography variant="caption">
                  <CircularProgress size={12} /> Loading ticket types
                </Typography>
              )}
              <ToggleButtonGroup
                data-cy="ticket-type-toggle-button-group"
                exclusive
                onChange={(event, value: number) => {
                  if (value && value !== fields.type_id) {
                    if (fields.priority_id || fields.category_id) {
                      setIsDialogOpen(true);
                    }
                    if (fields.priority_id === 4) {
                      setFields({ priority_id: 3 });
                    }
                    setCategoryInput("");
                    setFields({ type_id: value, category_id: "" });
                  }
                }}
                size="small"
                className={classes.fullWidth}
              >
                {(types.data?.data || []).map((type: Type) => (
                  <ToggleButtonWithTooltip
                    data-cy={`ticket-type-toggle-button-${type.id}`}
                    key={type.id}
                    tooltipText={type.description}
                    value={type.id}
                    disabled={
                      type.id !== fields.type_id ? isConvertingFromEvent : false
                    }
                    className={
                      type.id === fields.type_id
                        ? classes.selectedType
                        : classes.fullWidth
                    }
                  >
                    {type.name}
                  </ToggleButtonWithTooltip>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </GridGroup>
          <GridGroup>
            <Grid item xs={12} md={6}>
              <Autocomplete
                data-cy="ticket-form-company-autocomplete"
                label="Select Company"
                name="customer_id"
                placeholder="Type at least 3 characters to search a company"
                loading={customers.isLoading}
                options={
                  customers.data?.data?.filter(
                    (customer: Customer) => customer.name !== "NOT-SET"
                  ) || []
                }
                valueFrom="id"
                textFrom="name"
                value={
                  totalCustomers === 1
                    ? customers.data?.data?.[0]
                    : customers.data?.data?.find((customer: Customer) => {
                        return customer.id === fields.customer_id;
                      }) || null
                }
                error={hasErrors("customer_id")}
                helperText={displayErrors("customer_id")}
                onSearch={(query) => setCustomersInput(query)}
                onSelect={(customer: Customer) =>
                  setFields({ customer_id: customer ? customer.id : null })
                }
                disabled={totalCustomers === 1}
                onBlur={() => setCustomersInput("")}
                required
              />
            </Grid>
            {fields.customer_id && (
              <Grid item xs={12} md={6}>
                <Autocomplete
                  data-cy="ticket-form-system-autocomplete"
                  label="Select System"
                  name="system_id"
                  placeholder="Type to search"
                  loading={systems?.isLoading}
                  options={systems.data?.data || []}
                  valueFrom="id"
                  textFrom="name"
                  value={
                    systems.data?.data.find(
                      (system: System) => system.id === fields.system_id
                    ) || null
                  }
                  error={hasErrors("system_id")}
                  helperText={displayErrors("system_id")}
                  onSelect={(system: System) => {
                    resetValidation(["system_id"]);
                    setFields({ system_id: system ? system.id : null });
                  }}
                  onSearch={(query) => setSystemInput(query)}
                  onBlur={() => setSystemInput("")}
                  required
                />
              </Grid>
            )}
          </GridGroup>

          <ContactSection
            fields={fields}
            setFields={setFields}
            hasErrors={hasErrors}
            displayErrors={displayErrors}
            handleChange={handleChange}
            resetValidation={resetValidation}
            contacts={contacts}
            setContactInput={setContactInput}
          />
          <GridGroup>
            <Grid item xs={12}>
              <TextField
                data-cy="ticket-form-short-description-field"
                label="Short Description"
                name="short_description"
                error={hasErrors("short_description")}
                helperText={displayErrors("short_description")}
                inputProps={{
                  maxLength: 100,
                }}
                value={fields.short_description}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                data-cy="ticket-form-description-field"
                onChange={handleChangeDescription}
                placeholder="Description *"
                value={fields.description}
              />

              {hasErrors("description") && (
                <Typography variant="caption" color="error">
                  {displayErrors("description")}
                </Typography>
              )}
            </Grid>
          </GridGroup>
          <GridGroup>
            <Grid item xs={12} md={6}>
              <Autocomplete
                data-cy="ticket-form-category-autocomplete"
                label="Select Category"
                name="category_id"
                key={fields.type_id}
                error={hasErrors("category_id")}
                helperText={displayErrors("category_id")}
                loading={categories.isLoading}
                options={categories.data?.data || []}
                placeholder="Type to search"
                valueFrom="id"
                textFrom="name"
                value={
                  (categories.data?.data || []).find(
                    (category: Category) => category.id === fields.category_id
                  ) || null
                }
                onSelect={(category: Category) => {
                  resetValidation(["category_id"]);
                  setFields({ category_id: category ? category.id : null });
                }}
                onSearch={(query) => setCategoryInput(query)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {(!id || canEditSeverity) && (
                <TextField
                  data-cy="ticket-form-severity-field"
                  label="Severity"
                  name="priority_id"
                  error={hasErrors("priority_id")}
                  helperText={displayErrors("priority_id")}
                  onChange={handleChange}
                  size="small"
                  value={
                    (priorities.data?.data || []).find(
                      (priority: Priority) => priority.id === fields.priority_id
                    )?.id ?? ""
                  }
                  variant="outlined"
                  select
                  fullWidth
                >
                  {(priorities.data?.data || []).map((priority: Priority) => {
                    if (fields.type_id === 1 && priority.id === 4) {
                      return null;
                    }
                    return (
                      <MenuItem key={priority.id} value={priority.id}>
                        {priority.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              {fields.priority_id && (
                <MultilineAlert
                  message={
                    (
                      (priorities.data?.data || []).find(
                        (priority: Priority) =>
                          priority.id === fields.priority_id
                      ) || ({} as Priority)
                    ).description ?? ""
                  }
                />
              )}
            </Grid>
          </GridGroup>

          {children}
        </CardContent>
        <CardActions>
          <div className={classes.fullWidth}>
            <Box display="flex" mb={1} ml={0.5}>
              <Box>
                <Button
                  data-cy="tickets-form-submit-button"
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  startIcon={
                    loading && (
                      <CircularProgress style={{ color: "white" }} size={10} />
                    )
                  }
                  disabled={isLoadingAnything}
                >
                  {id ? "Update Ticket" : "Open New Ticket"}
                </Button>
              </Box>
              <Box flexGrow={1} ml={1}>
                <Button
                  data-cy="tickets-form-cancel-button"
                  color="primary"
                  component={Link}
                  size="medium"
                  to={id ? `/support/tickets/${id}` : "/support/tickets"}
                  variant="text"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </CardActions>
      </Card>
      <Dialog
        title="Changing type may impact ticket category and severity."
        confirmText="OK"
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        onConfirm={() => setIsDialogOpen(false)}
        hideCancelButton
      >
        <div>
          Before confirming these ticket changes, please ensure that you check
          that the category and the severity of the ticket are as you expect.
        </div>
      </Dialog>
    </form>
  );
}

export default Component;
