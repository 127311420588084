import React from "react";
import queryString from "query-string";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";

import Donut from "./Donut";
import { DashboardCardItem, Query } from "../../types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $actions": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  content: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    display: "flex",
    justifyContent: "left",
  },
  actions: {
    transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  link: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    textDecoration: "none",
    width: "100%",
  },
}));

interface Props {
  data: DashboardCardItem;
  query: Query;
  title: string;
}

function Component({ data, query, title }: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const filter = {
    ...query,
    ...data.query,
  };

  return (
    <Card data-cy="dashboard-card" raised className={classes.root}>
      <CardHeader
        action={
          title && (
            <Chip
              label={data.name}
              size="small"
              style={{
                backgroundColor: data.color,
                color: theme.palette.primary.contrastText,
                marginTop: theme.spacing(0.5),
              }}
            />
          )
        }
        className={classes.header}
        title={title ?? data.name}
      />
      <CardContent className={classes.content}>
        <Donut filter={filter} data={data} />
      </CardContent>
      <CardActions className={classes.actions}>
        <Link
          data-cy={`dashboard-view-all-button-${title}-${data.name}`}
          className={classes.link}
          to={"/support/tickets?" + queryString.stringify(filter)}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <div>View all</div>
            <ChevronRightIcon fontSize="small" />
          </Box>
        </Link>
      </CardActions>
    </Card>
  );
}

export default Component;
