import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, Grid, makeStyles } from "@material-ui/core";
import Assigned from "../Assigned";
import Attachments from "../Attachments";
import Customer from "../Customer";
import Contact from "../Contact";
import Message from "../Message";
import Tags from "../Tags";
import InfoItem from "../../../../components/InfoItem";
import { Ticket } from "../../../../types";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { Typography } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  description: {
    wordBreak: "break-word",

    "& img": {
      maxWidth: "95%",
    },
  },

  divider: {
    margin: theme.spacing(1, 0),
  },
}));

interface Props {
  ticket: Ticket;
}

const Details: React.FC<Props> = ({ ticket }) => {
  const classes = useStyles();

  const canViewAssignedTeam = hasPermission(
    PermissionCodes.ticketsViewAssignedTeam
  );
  const canViewAssignedUser = hasPermission(
    PermissionCodes.ticketsViewAssignedUser
  );
  const canViewTags = hasPermission(PermissionCodes.ticketsTagsViewAny);
  const canCreateTags = hasPermission(PermissionCodes.ticketsTagsCreate);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InfoItem
              title="Category"
              value={ticket.category?.name || "N/A"}
              hasBottomMargin
            />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Box>
                {hasPermission(PermissionCodes.ticketsUpdate) && (
                  <Button
                    data-cy="update-ticket-button"
                    variant="text"
                    color="primary"
                    size="small"
                    component={Link}
                    to={`/support/tickets/${ticket.id}/edit`}
                  >
                    Update Ticket Details
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.description}
            variant="h4"
            paragraph
            style={{ marginTop: "6px", fontWeight: 200 }}
            dangerouslySetInnerHTML={{ __html: ticket.description_as_html }}
          />
          <Attachments ticket={ticket} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Customer ticket={ticket} />

        <Divider className={classes.divider} />

        <Contact ticket={ticket} />

        {(canViewAssignedTeam || canViewAssignedUser) && (
          <>
            <Divider className={classes.divider} />
            <Assigned ticket={ticket} />
          </>
        )}

        {ticket.messages.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <Message ticket={ticket} />
          </>
        )}

        {(canViewTags || canCreateTags) && (
          <>
            <Divider className={classes.divider} />
            <Tags ticket={ticket} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Details;
