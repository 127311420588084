import { get } from "./";
import { AutocompleteParams } from "../types";

export function breachReasons({ query = "", limit = 100 }) {
  return get("sla-calendars/contracts/breach-reasons/autocomplete", {
    query,
    limit,
  });
}

export function closureReasons({ query = "", limit = 100 }) {
  return get("tickets/closure-reasons/autocomplete", {
    query,
    limit,
  });
}

export function contacts(params: AutocompleteParams = {}) {
  return get(`contacts/autocomplete`, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function customerContacts({
  customer_id,
  query = "",
  params = {},
  limit = 100,
}: {
  customer_id: number | null | undefined;
  [key: string]: any;
}) {
  return get(`customers/${customer_id}/contacts/autocomplete`, {
    query,
    ...params,
    limit,
  });
}

export function customers(params: AutocompleteParams = {}) {
  return get("customers/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function escalators(params: AutocompleteParams = {}) {
  return get(`tickets/escalators/autocomplete`, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function stages(params: AutocompleteParams) {
  return get("tickets/stages/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function ticketEscalators(id: number, params: AutocompleteParams) {
  return get(`tickets/${id}/escalators/autocomplete`, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function ticketSubscribers(id: number, params: AutocompleteParams) {
  return get(`tickets/${id}/subscribers/autocomplete`, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function teams(params: AutocompleteParams = {}) {
  return get("teams/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function tickets(params: AutocompleteParams = {}) {
  return get("tickets/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function users(params: AutocompleteParams = {}) {
  return get("users/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function roles(params: AutocompleteParams = {}) {
  return get("roles/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}
