import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../api/timers";
import {
  LooseObject,
  Query,
  QueryData,
  Timer,
  TimerData,
  TimerFields,
  TimerType,
} from "../types";

export function useCreate(
  id: number,
  timerType: TimerType,
  options: LooseObject = {}
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: TimerData, variables: any) => {
    queryClient.invalidateQueries(["timers", "table", timerType, id]);

    if (timerType === TimerType.ticket)
      queryClient.invalidateQueries(["tickets", id, "details"]);

    if (timerType === TimerType.task)
      queryClient.invalidateQueries(["tasks", id, "details"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation<TimerData, Error, Partial<TimerFields>>(
    (fields) => api.createTimer(id, timerType, fields),
    {
      ...options,
      onSuccess,
    }
  );
}

export function usePaginate(
  id: number,
  timerType: TimerType,
  query: Partial<Query>,
  options = {}
) {
  return useQuery<QueryData<Timer>, Error>(
    ["timers", "table", timerType, id, query],
    () => api.paginate(id, timerType, query),
    options
  );
}

export function useRemove(
  id: number,
  timerType: TimerType,
  options: LooseObject = {}
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["timers", "table", timerType, id]);

    if (timerType === TimerType.ticket)
      queryClient.invalidateQueries(["tickets", id, "details"]);

    if (timerType === TimerType.task)
      queryClient.invalidateQueries(["tasks", id, "details"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((timerId) => api.remove(timerId), {
    ...options,
    onSuccess,
  });
}

export function useUpdate(
  id: number,
  timerType: TimerType,
  options: LooseObject = {}
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["timers", "table", timerType, id]);

    if (timerType === TimerType.ticket)
      queryClient.invalidateQueries(["tickets", id, "details"]);

    if (timerType === TimerType.task)
      queryClient.invalidateQueries(["tasks", id, "details"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation<
    TimerData,
    Error,
    { id: number; fields: Partial<TimerFields> }
  >(({ id, fields }) => api.update(id, fields), {
    ...options,
    onSuccess,
  });
}
