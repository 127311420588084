import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Query, ViewType } from "../../types";
import AppliedFilter from "./AppliedFilter";
import {
  useCategories,
  usePriorities,
  useStages,
  useStatuses,
  useSystems,
  useTags,
  useTypes,
  useSubscribers,
  useEscalators,
} from "../../../../hooks/tickets/autocomplete";
import { PermissionCodes } from "../../../../helpers/constants";
import {
  formatArrayParamWithData,
  formatArrayParamWithoutData,
  formatNonArrayParamWithoutData,
  hasPermission,
} from "../../../../helpers/functions";
import {
  useContacts,
  useCustomers,
  useTeams,
  useUsers,
} from "../../../../hooks/autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersWrap: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignContent: "center",
      width: "100%",
      minHeight: theme.spacing(2),
      gap: "6px",
      padding: "3px",
      margin: theme.spacing(0.5),
      border: "1px solid",
      borderColor: theme?.palette?.grey?.[100],
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      listStyleType: "none",
    },
    chip: {
      maxWidth: "100%",
    },
  })
);

interface Props {
  view?: ViewType;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ view = ViewType.table, query, setQuery }: Props) {
  const classes = useStyles();
  const systems = useSystems(
    { ids: query.system_ids },
    { enabled: (query.system_ids?.length ?? 0) > 0 }
  );
  const customers = useCustomers(
    { ids: query.customer_ids },
    { enabled: (query.customer_ids?.length ?? 0) > 0 }
  );
  const categories = useCategories(
    { ids: query.category_ids },
    { enabled: (query.category_ids?.length ?? 0) > 0 }
  );
  const statuses = useStatuses(
    { ids: query.status_ids },
    { enabled: (query.status_ids?.length ?? 0) > 0 }
  );
  const stages = useStages(
    { ids: query.stage_ids },
    { enabled: (query.stage_ids?.length ?? 0) > 0 }
  );
  const types = useTypes(
    { ids: query.type_ids },
    { enabled: (query.type_ids?.length ?? 0) > 0 }
  );
  const priorities = usePriorities(
    { ids: query.priority_ids },
    { enabled: (query.priority_ids?.length ?? 0) > 0 }
  );
  const teams = useTeams(
    { ids: query.team_ids },
    { enabled: (query.team_ids?.length ?? 0) > 0 }
  );
  const users = useUsers(
    { ids: query.user_ids },
    { enabled: (query.user_ids?.length ?? 0) > 0 }
  );
  const escalators = useEscalators(
    { references: query.escalated_by_ids },
    { enabled: (query.escalated_by_ids?.length ?? 0) > 0 }
  );
  const openers = useUsers(
    { ids: query.opened_by_ids },
    { enabled: (query.opened_by_ids?.length ?? 0) > 0 }
  );
  const tags = useTags(
    { ids: query.tag_ids },
    { enabled: (query.tag_ids?.length ?? 0) > 0 }
  );
  const contacts = useContacts(
    { ids: query.contact_ids },
    { enabled: (query.contact_ids?.length ?? 0) > 0 }
  );
  const subscribers = useSubscribers(
    { references: query.subscriber_ids },
    { enabled: (query.subscriber_ids?.length ?? 0) > 0 }
  );

  return (
    <ul data-cy="applied-filters-section" className={classes.filtersWrap}>
      {query?.identifiers?.length && (
        <AppliedFilter
          chipKey="identifiers"
          setQuery={setQuery}
          query={query}
          formatter={() =>
            formatArrayParamWithoutData(
              "ID",
              "identifiers",
              query?.identifiers || []
            )
          }
        />
      )}
      {query?.is_open !== undefined && (
        <AppliedFilter
          chipKey="is_open"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Ticket State",
              "is_open",
              query?.is_open
            )
          }
        />
      )}
      {hasPermission(PermissionCodes.ticketsEscalatedView) &&
        query?.is_escalated !== undefined && (
          <AppliedFilter
            chipKey="is_escalated"
            query={query}
            setQuery={setQuery}
            formatter={() =>
              formatNonArrayParamWithoutData(
                "Escalated Only",
                "is_escalated",
                query?.is_escalated || []
              )
            }
          />
        )}
      {query?.customer_ids?.length && (
        <AppliedFilter
          chipKey="customer_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Company Name",
              "customer_ids",
              "name",
              customers.data?.data || []
            )
          }
        />
      )}
      {query?.system_ids?.length && (
        <AppliedFilter
          chipKey="system_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "System",
              "system_ids",
              "name",
              systems.data?.data || []
            )
          }
        />
      )}
      {query?.short_descriptions?.length && (
        <AppliedFilter
          chipKey="short_descriptions"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithoutData(
              "Short Description",
              "short_descriptions",
              query?.short_descriptions || []
            )
          }
        />
      )}
      {query?.category_ids?.length && (
        <AppliedFilter
          chipKey="category_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Category",
              "category_ids",
              "name",
              categories.data?.data || []
            )
          }
        />
      )}
      {query?.priority_ids?.length && (
        <AppliedFilter
          chipKey="priority_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Severity",
              "priority_ids",
              "name",
              priorities.data?.data || []
            )
          }
        />
      )}
      {query?.tag_ids?.length && (
        <AppliedFilter
          chipKey="tag_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Tag",
              "tag_ids",
              "name",
              tags.data?.data || []
            )
          }
        />
      )}
      {query?.escalated_by_ids?.length && (
        <AppliedFilter
          chipKey="escalated_by_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Escalated by",
              "escalated_by_ids",
              "full_name",
              escalators.data?.data || [],
              "reference"
            )
          }
        />
      )}
      {view !== ViewType.kanban && query?.status_ids?.length && (
        <AppliedFilter
          chipKey="status_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Status",
              "status_ids",
              "name",
              statuses.data?.data || []
            )
          }
        />
      )}
      {hasPermission(PermissionCodes.ticketsViewAnyStage) &&
        view !== ViewType.kanban &&
        query?.stage_ids?.length && (
          <AppliedFilter
            chipKey="stage_ids"
            query={query}
            setQuery={setQuery}
            formatter={() =>
              formatArrayParamWithData(
                "Substage",
                "stage_ids",
                "name",
                stages.data?.data || []
              )
            }
          />
        )}
      {query?.team_ids?.length && (
        <AppliedFilter
          chipKey="team_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Team",
              "team_ids",
              "name",
              teams.data?.data || []
            )
          }
        />
      )}
      {query?.type_ids?.length && (
        <AppliedFilter
          chipKey="type_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Type",
              "type_ids",
              "name",
              types.data?.data || []
            )
          }
        />
      )}
      {query?.user_ids?.length && (
        <AppliedFilter
          chipKey="user_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "User",
              "user_ids",
              "full_name",
              users.data?.data || []
            )
          }
        />
      )}
      {query?.contact_ids?.length && (
        <AppliedFilter
          chipKey="contact_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Contact",
              "contact_ids",
              "full_name",
              contacts.data?.data || []
            )
          }
        />
      )}
      {query?.subscriber_ids?.length && (
        <AppliedFilter
          chipKey="subscriber_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Subscriber",
              "subscriber_ids",
              "full_name",
              subscribers.data?.data || [],
              "reference"
            )
          }
        />
      )}
      {query?.opened_after !== undefined && (
        <AppliedFilter
          chipKey="opened_after"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Opened From",
              "opened_after",
              query?.opened_after || []
            )
          }
        />
      )}
      {query?.opened_before !== undefined && (
        <AppliedFilter
          chipKey="opened_before"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Opened To",
              "opened_before",
              query?.opened_before || []
            )
          }
        />
      )}
      {query?.opened_by_ids?.length && (
        <AppliedFilter
          chipKey="opened_by_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Opened By",
              "opened_by_ids",
              "full_name",
              openers.data?.data || []
            )
          }
        />
      )}
      {query?.is_opened_by_system !== undefined && (
        <AppliedFilter
          chipKey="is_opened_by_system"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Opened By System Only",
              "is_opened_by_system",
              query?.is_opened_by_system || []
            )
          }
        />
      )}
      {query?.resolved_after !== undefined && (
        <AppliedFilter
          chipKey="resolved_after"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Resolved From",
              "resolved_after",
              query?.resolved_after || []
            )
          }
        />
      )}
      {query?.resolved_before !== undefined && (
        <AppliedFilter
          chipKey="resolved_before"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Resolved To",
              "resolved_before",
              query?.resolved_before || []
            )
          }
        />
      )}
      {hasPermission(PermissionCodes.slaCalendarViewAny) &&
        query?.is_in_SLA !== undefined && (
          <AppliedFilter
            chipKey="is_in_SLA"
            query={query}
            setQuery={setQuery}
            formatter={() =>
              formatNonArrayParamWithoutData(
                "Target Resolution",
                "is_in_SLA",
                query?.is_in_SLA
              )
            }
          />
        )}
      {hasPermission(PermissionCodes.slaCalendarViewAny) &&
        query?.is_in_response_SLA !== undefined && (
          <AppliedFilter
            chipKey="is_in_response_SLA"
            query={query}
            setQuery={setQuery}
            formatter={() =>
              formatNonArrayParamWithoutData(
                "Target Response",
                "is_in_response_SLA",
                query?.is_in_response_SLA
              )
            }
          />
        )}
      {query?.is_watched_by_auth_user !== undefined && (
        <AppliedFilter
          chipKey="is_watched_by_auth_user"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatNonArrayParamWithoutData(
              "Watched Only",
              "is_watched_by_auth_user",
              query?.is_watched_by_auth_user
            )
          }
        />
      )}
    </ul>
  );
}

export default Component;
