import React from "react";
import { Grid, Button } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { dateOrNull } from "../../../../helpers/functions";

export interface Query {
  dateFrom?: string | null;
  dateTo?: string | null;
  limit: number;
  page: number;
}

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
  isLoading: boolean;
};

export default function Component({
  query,
  setQuery,
  resetQuery,
  isLoading,
}: Props) {
  return (
    <Grid container spacing={1}>
      <Button color="primary" onClick={resetQuery} size="small" variant="text">
        RESET
      </Button>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DatePicker
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date from"
              margin="none"
              onChange={(date) =>
                setQuery({ dateFrom: format(date, "yyy-MM-dd") })
              }
              size="small"
              value={dateOrNull(query.dateFrom ?? "")}
              variant="inline"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date to"
              margin="none"
              onChange={(date) =>
                setQuery({ dateTo: format(date, "yyy-MM-dd") })
              }
              size="small"
              value={dateOrNull(query.dateTo ?? "")}
              variant="inline"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
