import React, { useRef, useState } from "react";
import { Box, Button } from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";

import { useOnClickOutside } from "../helpers/hooks";

type Props = {
  children: React.ReactElement;
  flexGrow?: number;
  input: React.ReactElement;
  onEdit?: () => void;
  open?: boolean;
  style?: Object;
  disabled?: boolean;
  [x: string]: any;
};

const Component = ({
  children,
  flexGrow,
  input,
  onEdit,
  open = false,
  style,
  disabled,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [editing, setEdit] = useState(open);

  useOnClickOutside(ref, () => setEdit(false));

  return (
    <Box
      display={children.props.children ? "flex" : "block"}
      alignItems="center"
    >
      {editing ? (
        <Box flexGrow={1}>
          <div ref={ref}>
            {React.cloneElement(input, {
              onClose: () => setEdit(false),
            })}
          </div>
        </Box>
      ) : (
        <Box flexGrow={flexGrow || 1} style={style}>
          {children}
        </Box>
      )}
      {!editing && (
        <Box>
          <Button
            color="primary"
            onClick={() => {
              setEdit(true);
              if (onEdit) {
                onEdit();
              }
            }}
            size="small"
            startIcon={<EditIcon />}
            variant="text"
            disabled={disabled}
          >
            Edit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Component;
