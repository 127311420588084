import { useQuery } from "react-query";
import * as api from "../../api/dashboard";
import { DashboardCardItems, Query } from "../../screens/Tickets/types";

export function useSearch(query: Query, options = {}) {
  return useQuery<DashboardCardItems>(
    ["tickets", "dashboard", query],
    () => api.dashboard(query),
    options
  );
}
