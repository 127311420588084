import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles } from "@material-ui/core/styles";
import { Attachment } from "../types";
import { useDownload } from "../hooks/attachments";
import Dialog from "./Dialog";

const useStyles = makeStyles((theme) => ({
  attachment: {
    textAlign: "left",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "0px 0px 6px 6px",
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: 150,
  },
  attachmentImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  alternateImage: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  downloadAttachmentButtonText: {
    paddingLeft: "3px",
    width: "100%",
  },
  button: {
    width: "100%",
  },
  icon: {
    marginLeft: 2,
  },
}));

type Props = {
  attachment: Attachment;
  canBeDeleted?: boolean;
  onRemove: (attachment: Attachment) => void;
};

const Component = ({ attachment, canBeDeleted, onRemove }: Props) => {
  const classes = useStyles();
  const [showRemove, toggleRemove] = useState(false);

  const download = useDownload();

  if (attachment.is_deleted) {
    return <></>;
  }

  return (
    <Box
      data-cy={`attachment-${attachment.id}`}
      key={attachment.id}
      className={classes.attachment}
    >
      {canBeDeleted && (
        <Box>
          <IconButton
            data-cy={`delete-attachment-${attachment.id}-button`}
            onClick={() => toggleRemove(!showRemove)}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>

          <Dialog
            title="Are you sure you want to delete this attachment?"
            cancelText="No"
            open={showRemove}
            setOpen={toggleRemove}
            onConfirm={() => {
              toggleRemove(false);
              onRemove(attachment);
            }}
          />
        </Box>
      )}

      {attachment.preview_url ? (
        <Box height={100}>
          <img
            src={attachment.preview_url}
            className={classes.attachmentImage}
            alt=""
          />
        </Box>
      ) : (
        <Box className={classes.alternateImage}>
          <Typography variant="h3">
            {
              attachment.name
                .split(".")
                .pop()
                ?.toUpperCase() /** file extension */
            }
          </Typography>
        </Box>
      )}
      <Box pl={0}>
        <Button
          data-cy={`attachment-${attachment.id}-download-button`}
          onClick={() => download.mutate(attachment)}
          startIcon={<CloudDownloadIcon className={classes.icon} />}
          variant="text"
          className={classes.button}
        >
          <Typography
            data-cy={`attachment-${attachment.id}-name`}
            noWrap
            variant="caption"
            align="left"
            className={classes.downloadAttachmentButtonText}
          >
            {attachment.name}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Component;
