import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickPopover from "../../../../components/ClickPopover";

const useStyles = makeStyles((theme) => ({
  applyButton: {
    marginRight: theme.spacing(0.5),
  },
  wrap: {
    padding: "24px",
  },
  grid: {
    display: "inline-block",
  },
}));

interface Props {
  filterName: string;
  applyFilter: Function;
  resetValue?: () => void;
  children: React.ReactNode;
}

function Component({ filterName, applyFilter, resetValue, children }: Props) {
  const classes = useStyles();

  return (
    <ClickPopover
      button={{
        size: "small",
        endIcon: <ExpandMoreIcon />,
        variant: "text",
        style: {
          justifyContent: "left",
          padding: "3px 0",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      trigger={filterName}
      closeCallback={() => {
        if (resetValue) resetValue();
      }}
    >
      {(open: boolean, handleClose: () => void) => (
        <div className={classes.wrap}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`filter by ${filterName}`.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => applyFilter(handleClose)}
                  color="primary"
                  size="medium"
                  className={classes.applyButton}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  color="primary"
                  size="medium"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </ClickPopover>
  );
}

export default Component;
