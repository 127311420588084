import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { getAddressLine } from "../helpers/getAddressLine";
import { UPRN, WLR3Address } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 300,
    overflowY: "scroll",
    marginTop: theme.spacing(1),
  },
}));

interface FilteredListProps<TType> {
  items: TType[];
  isUprn?: boolean;
  handleItemSelected: (item: TType) => void;
  renderRow: (item: TType, index: number, onClick: () => void) => JSX.Element;
}

export function FilteredList<TType extends WLR3Address | UPRN>({
  items,
  isUprn,
  renderRow,
  handleItemSelected,
}: FilteredListProps<TType>) {
  const classes = useStyles();
  const [filterText, setFilterText] = React.useState("");

  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <SearchIcon />
        </Grid>
        <Grid item xs>
          <TextField
            label="Filter"
            value={filterText}
            onChange={setFilter}
            fullWidth
          />
        </Grid>
      </Grid>
      <List component="nav" className={classes.root}>
        {items
          .filter((item) => {
            const address = isUprn
              ? (item as UPRN).address
              : getAddressLine(item as WLR3Address);
            return (
              !filterText ||
              address.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
            );
          })
          .map((item, i: number) =>
            renderRow(item, i, () => {
              handleItemSelected(item);
              setFilterText("");
            })
          )}
      </List>
    </div>
  );
}

FilteredList.propTypes = {
  items: PropTypes.array.isRequired,
  isUprn: PropTypes.bool,
  renderRow: PropTypes.func.isRequired, // signature: function(object: item, int: index, func: onClick)
  handleItemSelected: PropTypes.func.isRequired, // signature: function(object: item)
};
