import React, { ReactNode } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface MainProps {
  children: ReactNode;
  title: string;
}
export const Main = ({ children, title }: MainProps) => (
  <Box
    height="calc(100vh - 182px)"
    display="flex"
    flexDirection="column"
    paddingTop={1}
  >
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      marginBottom={2}
    >
      <Box>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      </Box>
    </Box>
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {children}
    </Box>
  </Box>
);

Main.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Main;
