import { Query } from "../screens/Users/types";
import { get, post, put } from "./";

export function getPermissions() {
  return get("user/permissions");
}

export function loggedIn() {
  return get("user");
}

export function users(query: Partial<Query>) {
  return get("users", query);
}

export function create(params: any) {
  return post("users", params);
}

export function update(id: number, params: any) {
  return put(`users/${id}`, params);
}

export function show(id: number) {
  return get(`users/${id}`);
}
