import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Query, QueryItem } from "../../types";
import FilterPopup from "./FilterPopup";
import { NumberFilterValue } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      display: "inline-block",
      whiteSpace: "nowrap",
    },
    button: {
      minWidth: "90px",
    },
  })
);

export interface SelectorOption {
  label: string;
  value: number;
}

interface Props {
  filterName: string;
  filterKey: string;
  options: Array<SelectorOption>;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  allLabel?: string;
}

function Component({
  filterName,
  filterKey,
  options,
  query,
  setQuery,
  allLabel,
}: Props) {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<NumberFilterValue>(
    query?.[filterKey as QueryItem] as NumberFilterValue
  );

  const filterValue = query?.[filterKey as QueryItem] as NumberFilterValue;

  useEffect(() => {
    setSelectedValue(filterValue);
  }, [filterValue]);

  const applyFilter = (handleClose: Function) => {
    setQuery({
      [filterKey]: selectedValue,
    });
    handleClose();
  };

  const resetValue = () =>
    setSelectedValue(query?.[filterKey as QueryItem] as number | null);

  return (
    <FilterPopup
      filterName={filterName}
      applyFilter={applyFilter}
      resetValue={resetValue}
    >
      <ButtonGroup className={classes.buttonGroup}>
        <Button
          color="primary"
          variant={
            selectedValue === null || selectedValue === undefined
              ? "contained"
              : "outlined"
          }
          onClick={() => setSelectedValue(null)}
          className={classes.button}
        >
          {allLabel || "All"}
        </Button>
        {options.map((option: SelectorOption) => (
          <Button
            key={`${option.label}-${option.value}`}
            color="primary"
            variant={
              selectedValue?.toString() === option.value?.toString()
                ? "contained"
                : "outlined"
            }
            onClick={() => setSelectedValue(option.value)}
            className={classes.button}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </FilterPopup>
  );
}

export default Component;
