import React from "react";
import { TextField, StandardTextFieldProps } from "@material-ui/core";
import { Controller, UseControllerProps } from "react-hook-form";
import { getLabel } from "./utils/getLabel";

/**
 * TODO: Some way to retain type safety with provider defaultValues and name
 *  attr here? We get this using Controller directly but it increases
 *  boilerplate a lot.
 */
interface RHFTextFieldProps extends Pick<UseControllerProps, "name" | "rules"> {
  label: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  InputProps?: StandardTextFieldProps["InputProps"];
  disabled?: boolean;
}

/**
 * reach-hook-form text field
 * Use inside a <FormProvider>
 */
export const RHFTextField = ({
  name,
  label,
  type,
  InputProps,
  rules,
  disabled,
}: RHFTextFieldProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <TextField
            fullWidth
            label={getLabel(label, rules)}
            type={type}
            {...field}
            helperText={
              fieldState.error && (fieldState.error.message || "Required")
            }
            error={!!fieldState.error}
            InputProps={InputProps}
            InputLabelProps={
              type === "date"
                ? {
                    shrink: true,
                  }
                : {}
            }
            disabled={disabled || formState.isSubmitting}
          />
        );
      }}
    />
  );
};
