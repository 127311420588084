import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import Form from "../components/Form";
import Loading from "../../../layout/Loading";
import Main from "../../../layout/Main";
import { useParams } from "react-router-dom";
import { useSingleUser, useUpdate } from "../../../hooks/users";

const Switch = ({
  children,
  error,
  embedded,
  loading,
}: {
  children: React.ReactNode;
  error: string;
  embedded: boolean;
  loading: boolean;
}) => {
  if (embedded) {
    return (
      <Loading error={error} loading={loading}>
        {children}
      </Loading>
    );
  }

  return (
    <Main error={error} title="Edit User" loading={loading}>
      <Paper elevation={4} style={{ width: "100%" }}>
        {children}
      </Paper>
    </Main>
  );
};

interface Props {
  embedded?: boolean;
  owner?: string;
}

export default function Update({ embedded = false, owner = "" }: Props) {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching: userFetching } = useSingleUser(parseInt(id));

  const { mutate: updateUser, isLoading: userUpdating } = useUpdate(
    parseInt(id)
  );

  const [fields, setFields] = useState(data?.data);

  useEffect(() => {
    if (data) {
      setFields(data.data);
    }
  }, [userFetching, data]);

  const errorMessage: string | null =
    error === null
      ? ""
      : error instanceof Error
      ? error.message
      : "Unknown error";

  return (
    <Switch error={errorMessage} embedded={embedded} loading={userFetching}>
      <Form
        loading={userUpdating}
        onSubmit={updateUser}
        fields={fields}
        setFields={setFields}
      />
    </Switch>
  );
}
