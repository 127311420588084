import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
  Chip,
} from "@material-ui/core";
import _ from "lodash";
import { amber } from "@material-ui/core/colors";

import { Close, Done, Pause, Timer } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MouseOverPopover from "../../../../components/MouseOverPopover";
import { functions } from "../../../../helpers";
import { QueryData, Subscription, Tag, Ticket } from "../../../../types";
import { SortDirection } from "../../../../types";
import { Query } from "../../types";

import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import CustomViews from "./CustomViews";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

type Props = {
  loading: boolean;
  paginator?: QueryData<Ticket>;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
};

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  in_sla: {
    color: theme.palette.success.main,
    float: "left",
    paddingRight: "3px",
  },
  out_of_sla: {
    color: theme.palette.error.main,
    float: "left",
    paddingRight: "3px",
  },
  paused_sla: {
    color: theme.palette.info.main,
    float: "left",
    paddingRight: "3px",
  },
  success: {
    color: theme.palette.success.main,
  },
  table: {
    "& th": {
      whiteSpace: "nowrap",
    },
    "& td": {
      whiteSpace: "nowrap",
    },
  },
}));

export default function Component({
  loading,
  paginator,
  query,
  setQuery,
}: Props) {
  const classes = useStyles();
  // Check if "sort" is equal with the given "columnName"
  // and return the value of "direction" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction || "none" : "none";

  const columns: MUIDataTableColumn[] =
    paginator?.meta?.columns
      ?.filter((column) => !!column.label)
      .map((column) => {
        let current: MUIDataTableColumn = {
          name: column.name,
          label: column.label,
          options: {
            filter: false,
            sortDirection: getSortDirection(column.sort_by),
          },
        };

        if (column.name === "identifier") {
          current.options = {
            ...current.options,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const row = paginator?.data[tableMeta.rowIndex];

              return (
                <Box ml={-1}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    component={Link}
                    to={`/support/tickets/${row?.id}`}
                  >
                    {value}
                  </Button>
                </Box>
              );
            },
          };
        } else if (column.name === "parent_or_child") {
          current.options = {
            ...current.options,
            customBodyRender: (value: string) =>
              value ? (
                <Chip
                  label={value.charAt(0).toUpperCase() + value.slice(1)}
                  size="small"
                  variant="outlined"
                />
              ) : (
                ""
              ),
          };
        } else if (column.name === "is_watched_by_auth_user") {
          current.options = {
            ...current.options,
            customBodyRender: (value: string) => (
              <Box display="flex" justifyContent="center">
                {value ? (
                  <VisibilityIcon
                    className={classes.success}
                    fontSize="inherit"
                  />
                ) : (
                  <VisibilityOffIcon
                    className={classes.error}
                    fontSize="inherit"
                  />
                )}
              </Box>
            ),
          };
        } else if (column.name === "subscriptions.subscriber.full_name") {
          current.options = {
            ...current.options,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const row = paginator?.data[tableMeta.rowIndex];
              const names = row.subscriptions
                ?.map(
                  (subscription: Subscription) =>
                    subscription.subscriber.full_name
                )
                .join(", ");

              return (
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  <Typography noWrap title={names} variant="body2">
                    {names}
                  </Typography>
                </div>
              );
            },
          };
        } else if (column.name === "tags.name") {
          current.options = {
            ...current.options,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const row = paginator?.data[tableMeta.rowIndex];
              const tags = row.tags?.map((tag: Tag) => tag.name).join(", ");

              return (
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                >
                  <Typography noWrap title={tags} variant="body2">
                    {tags}
                  </Typography>
                </div>
              );
            },
          };
        } else if (
          column.name.indexOf("_date") > 0 ||
          column.name.indexOf("_at") > 0
        ) {
          current.options = {
            ...current.options,
            customBodyRender: (value: string) => {
              return functions.formattedDateTime(value, "dd/MM/yyyy HH:mm");
            },
          };
        } else if (column.name.indexOf("is_") >= 0) {
          current.options = {
            ...current.options,
            customBodyRender: (value: string) => {
              return value ? "Yes" : "No";
            },
          };
        } else if (column.name.indexOf("is_") >= 0) {
          current.options = {
            ...current.options,
            customBodyRender: (value: string) => {
              return value ? "Yes" : "No";
            },
          };
        } else if (
          [
            "active_resolution_commitment.sla_calendar_contract.sla_calendar.name",
          ].find((name) => name === column.name)
        ) {
          current.options = {
            ...current.options,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const row = paginator?.data[tableMeta.rowIndex];

              return (
                <>
                  {row && row.active_resolution_commitment && (
                    <Typography
                      className={
                        row.active_resolution_commitment?.is_paused
                          ? classes.paused_sla
                          : row.active_resolution_commitment?.is_breached
                          ? classes.out_of_sla
                          : classes.in_sla
                      }
                    >
                      {!row.active_resolution_commitment?.is_paused &&
                        !row.active_resolution_commitment?.is_breached &&
                        ((row.active_resolution_commitment?.percentage ?? 0) >
                        55 ? (
                          <Timer
                            fontSize="inherit"
                            style={{ color: amber[400] }}
                          />
                        ) : (
                          <Done fontSize="inherit" />
                        ))}
                      {!row.active_resolution_commitment?.is_paused &&
                        row.active_resolution_commitment?.is_breached && (
                          <Close fontSize="inherit" />
                        )}
                      {row.active_resolution_commitment?.is_paused && (
                        <Pause fontSize="inherit" />
                      )}
                    </Typography>
                  )}
                  <MouseOverPopover
                    text={
                      row.active_resolution_commitment?.sla_calendar_contract
                        ?.sla_calendar?.name
                    }
                  >
                    <div style={{ maxWidth: 400 }}>
                      {
                        row.active_resolution_commitment?.sla_calendar_contract
                          ?.sla_calendar?.description
                      }
                    </div>
                  </MouseOverPopover>
                </>
              );
            },
            display: hasPermission(PermissionCodes.slaCalendarView)
              ? "true"
              : "false",
          };
        } else {
          current.options = {
            ...current.options,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const row = paginator?.data[tableMeta.rowIndex];
              return (
                <span
                  dangerouslySetInnerHTML={{ __html: _.get(row, column.name) }}
                />
              );
            },
          };
        }

        return current;
      }) ?? [];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,

    customToolbar: () => <CustomViews query={query} setQuery={setQuery} />,

    // responsive: "standard",
    serverSide: true,

    rowsPerPage: query.limit,
    page: (query.page || 1) - 1,
    count: paginator?.meta?.total,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      const sort = paginator?.meta?.columns?.find(
        (column) => column.name === changedColumn
      )?.sort_by;
      setQuery({
        page: 1,
        sort,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      className: classes.table,
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="tickets-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Tickets</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={paginator?.data || []}
      columns={columns}
      options={options}
    />
  );
}
