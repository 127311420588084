import React, { useEffect, useState } from "react";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { Location } from "history";
import { Alert } from "@akj-dev/design-system";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { Attachment, Ticket } from "../../../../types";
import FileUpload from "../../../../components/FileUpload";
import DialogComponent from "../../../../components/Dialog";
import AttachmentComponent from "../../../../components/Attachment";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useUpload } from "../../../../hooks/tickets";
import { useRemove } from "../../../../hooks/tickets/attachments";

interface Props {
  ticket: Ticket;
  disableAdding?: boolean;
}

function Component({
  ticket,
  disableAdding = false,
  history,
}: Props & RouteComponentProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [files, setFiles] = useState<Array<File>>([]);

  const remove = useRemove(ticket.id);

  const upload = useUpload(ticket.id, {
    onSuccess: () => {
      setFiles([]);
      if (isDialogOpen) setIsLeaving(true);
      setIsDialogOpen(false);
    },
  });

  useEffect(() => {
    if (isLeaving) {
      history.push((nextLocation as Location).pathname);
    }
  }, [isLeaving, history, nextLocation]);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (files?.length && !isLeaving) {
      setIsDialogOpen(true);
      setNextLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    upload.mutate({ id: ticket.id, params: { "files[]": files } });
  };

  const handleCancel = () => {
    setIsLeaving(true);
    setIsDialogOpen(false);
  };

  return (
    <div data-cy="attachments-section">
      {ticket.attachments?.length > 0 && (
        <Typography paragraph variant="caption">
          Attachments
        </Typography>
      )}

      <Box display="flex" flexWrap="wrap">
        {ticket.attachments?.map((attachment: Attachment) => (
          <AttachmentComponent
            attachment={attachment}
            canBeDeleted={
              !attachment.is_deleted && ticket.can_remove_attachments
            }
            key={attachment.id}
            onRemove={(attachment) => remove.mutate(attachment)}
          />
        ))}
      </Box>
      {hasPermission(PermissionCodes.ticketsAttachmentsCreate) && (
        <>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box paddingTop="24px" paddingBottom="24px">
              <FileUpload
                variant="outlined"
                multiple
                name="files[]"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  files: Array<File>
                ) => setFiles(files)}
                value={files}
                setFiles={(newFiles: File[]) => setFiles(newFiles)}
                resetError={upload.reset}
                uploading={upload.isLoading}
                disableAdding={disableAdding}
              />
              {upload.isError && (
                <Alert
                  type="error"
                  message={
                    upload.error instanceof Error
                      ? upload.error.message
                      : "Unknown Error"
                  }
                />
              )}
              {ticket.id && files?.length > 0 && (
                <Box paddingTop="24px" paddingBottom="24px">
                  <Button
                    data-cy="attachment-upload-button"
                    type="submit"
                    startIcon={
                      upload.isLoading ? (
                        <CircularProgress size={10} />
                      ) : (
                        <PublishIcon />
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={upload.isLoading}
                  >
                    {upload.isLoading ? "Uploading Files" : "Confirm Upload"}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
          <Prompt
            when={!!files?.length}
            //@ts-ignore
            message={handleBlockedNavigation}
          />
          <DialogComponent
            title="Your files have not been uploaded."
            confirmText="Upload Files"
            cancelText="Leave without Uploading"
            open={isDialogOpen}
            setOpen={setIsDialogOpen}
            onConfirm={handleSubmit}
            onCancel={handleCancel}
          >
            <Box mb="20px">
              Do you wish to upload them or leave without uploading?
            </Box>
          </DialogComponent>
        </>
      )}
    </div>
  );
}

export default withRouter(Component);
