import React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 600,
      fontSize: "0.875rem",
      paddingTop: 0,
      paddingBottom: 0,
    },
    arrowButton: {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  })
);

interface Props {
  label: string;
  handleClick?: Function;
  handleArrowClick?: Function;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  children: React.ReactNode;
}

export default function Component({
  label,
  handleClick,
  handleArrowClick,
  disabled,
  startIcon,
  children,
}: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    if (handleArrowClick) handleArrowClick();
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          startIcon={startIcon}
          onClick={() => handleClick && handleClick()}
          className={classes.button}
          disabled={disabled}
        >
          {label}
        </Button>
        <Button
          data-cy="split-button-arrow"
          size="small"
          color="primary"
          variant="outlined"
          onClick={handleToggle}
          className={classes.arrowButton}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        placement="top-start"
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ paddingRight: 27 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
