import React from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Grid, Paper, Divider, Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckIcon from "@material-ui/icons/Check";
import Main from "../../../layout/Main";
import Details from "./components/Details";
import Info from "./components/Info";
import { LooseObject } from "../../../types";
import { useEventDetail } from "../../../hooks/events";

export default function Component() {
  const { id } = useParams<LooseObject>();
  const event = useEventDetail(parseInt(id));

  return (
    <Main
      error={event?.isError ? event?.error.message : ""}
      title={`Event ${id ?? ""}`}
      loading={event?.isLoading || !id}
      stickyHeader
    >
      {event && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            pb={1}
          >
            <Button
              data-cy="events-back-button"
              startIcon={<ChevronLeftIcon />}
              variant="text"
              color="primary"
              component={Link}
              to="/support/events"
            >
              Back to all Events
            </Button>
            <Button
              data-cy="events-edit-button"
              startIcon={<CheckIcon />}
              variant="outlined"
              color="primary"
              disabled // todo
            >
              Edit Event
            </Button>
          </Box>
          <Paper elevation={4} style={{ width: "100%" }}>
            <Box m={1.5}>
              {event.data?.data && <Info event={event.data?.data} />}
              <Box mt={1.5} mb={1.5}>
                <Divider />
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={8}>
                  {event.data?.data && <Details event={event.data?.data} />}
                </Grid>
                <Grid item xs={4} />
              </Grid>
            </Box>
          </Paper>
        </>
      )}
    </Main>
  );
}
