import React, { ReactNode } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(1.5, 1, 1, 1),
    },
    dialogContent: {
      maxHeight: "300px",
      overflowY: "scroll",
      marginRight: "-15px",
    },
    buttons: {
      margin: theme.spacing(0.5),
    },
    cancelButton: {
      padding: "7px 18px",
      marginRight: theme.spacing(0.5),
    },
  })
);

type Props = {
  title: string;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: Function;
  onCancel?: Function;
  hideCancelButton?: boolean;
  children?: ReactNode;
  loading?: boolean;
};

const Component = ({
  title,
  confirmText,
  cancelText,
  open,
  setOpen,
  onConfirm,
  onCancel,
  hideCancelButton,
  children,
  loading,
}: Props) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) onCancel();
    else setOpen(false);
  };

  return (
    <Dialog
      data-cy="dialog"
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <div className={classes.dialog}>
        <DialogTitle data-cy="dialog-title" id="confirm-dialog">
          <Typography variant="h3" paragraph>
            {title}
          </Typography>
        </DialogTitle>
        {children && (
          <DialogContent
            data-cy="dialog-content"
            className={classes.dialogContent}
          >
            {children}
          </DialogContent>
        )}
        <DialogActions className={classes.buttons}>
          {!hideCancelButton && (
            <Button
              data-cy="dialog-cancel-button"
              variant="outlined"
              onClick={handleCancel}
              className={classes.cancelButton}
              disabled={loading}
            >
              {cancelText || "Cancel"}
            </Button>
          )}
          <Button
            data-cy="dialog-submit-button"
            variant="contained"
            onClick={() => onConfirm()}
            id="confirmButton"
            color="primary"
            type="submit"
            startIcon={loading && <CircularProgress size={10} />}
            disabled={loading}
          >
            {confirmText || "Yes"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Component;
