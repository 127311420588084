import React from "react";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import { Grid } from "@material-ui/core";
import Main from "../../layout/Main";
import Table from "./components/Table";
import { constants } from "../../helpers";

function Reports() {
  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, constants.limit.reports),
    page: withDefault(NumberParam, 1),
  });

  return (
    <Main error="" title="Reports">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table query={query} setQuery={setQuery} />
        </Grid>
      </Grid>
    </Main>
  );
}

export default Reports;
