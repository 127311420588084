import React from "react";
import Loading from "../../../layout/Loading";
import Table from "./components/Table";
import { Query } from "../types";
import { usePaginate } from "../../../hooks/messages";
// import { Link } from "react-router-dom";
// import { Button } from "@material-ui/core";
// import { formattedDateTime } from "../../../helpers/functions";
// import { DataTable } from "@akj-dev/design-system";

interface Props {
  query: Query;
  setQuery: (query: Partial<Query>) => void;
}

export default function Component({ query, setQuery }: Props) {
  const paginator = usePaginate(query);

  // const columns = [
  //   {
  //     name: "id",
  //     label: "ID",
  //     sortable: true
  //   },
  //   {
  //     name: "subject",
  //     label: "Subject",
  //     sortable: true
  //   },
  //   {
  //     name: "from",
  //     label: "From",
  //     sortable: true
  //   },
  //   {
  //     name: "created_at",
  //     label: "Date",
  //     sortable: true
  //   }
  // ];
  //
  // return (
  //   <DataTable
  //     columns={columns}
  //     count={paginator.meta.total}
  //     data={paginator.data.map((message: Message) => [
  //       <Button
  //         variant="text"
  //         color="primary"
  //         size="small"
  //         component={Link}
  //         to={`/support/mailbox/${message.id}`}
  //       >
  //         #{message.id}
  //       </Button>,
  //       message.subject,
  //       message.from,
  //       formattedDateTime(message.created_at)
  //     ])}
  //     error={paginator.error}
  //     // headerClass={classes.tableHeader}
  //     loading={paginator.loading}
  //     onChangePage={(event, page) => setQuery({ page: page + 1 })}
  //     onChangeRowsPerPage={(event) => setQuery({ limit: event.target.value })}
  //     onChangeSort={state =>
  //       setQuery({
  //         sort: columns[state.columnIndex]?.name,
  //         direction: state.dir.toLocaleLowerCase()
  //       })
  //     }
  //     page={query.page - 1}
  //     rowsPerPage={query.limit}
  //     title="Mailbox"
  //   />
  // );

  return (
    <Loading
      error={paginator.isError ? "Can't load messages" : undefined}
      loading={paginator.isLoading}
    >
      <Table
        loading={paginator.isLoading}
        paginator={paginator?.data}
        query={query}
        setQuery={setQuery}
      />
    </Loading>
  );
}
