import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Filter, FetchResourcesHook } from "../types";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, MenuItem } from "@material-ui/core";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
  fetchResourcesHook: FetchResourcesHook;
}

const SKIP: number = 20;

/**
 * Autocomplete style text filter. Adapted from IdentifierFilter
 */
export default function AutoTextFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: Props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const {
    data: filterResponse,
    fetchNextPage: fetchNextFilterPage,
    isLoading,
    hasNextPage,
  } = fetchResourcesHook(item?.resources, search, {
    getNextPageParam: (lastPage: any) => {
      const limit = lastPage?.pageSize * lastPage?.page + 1;
      return lastPage?.page < limit / lastPage?.pageSize &&
        Boolean(lastPage?.list?.length)
        ? lastPage?.pageSize + lastPage?.page * SKIP
        : undefined;
    },
  });

  const [filter, setFilter] = useState<any>();
  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList: any = [];

      filterResponse.pages?.forEach((page: any) => {
        page?.list?.forEach((value: any) => {
          if (
            typeof value === "string" &&
            (search === "" ||
              value.toLowerCase().includes(search.toLowerCase()))
          )
            filterList.push(value);
        });
      });

      setFilter(filterList);
    }
  }, [filterResponse, search]);

  const handleMultiSelect = (listItem: any) => {
    if (
      !selection.some((current: any) => current === listItem) &&
      options?.MAX_COUNT &&
      selection.length < options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else if (
      !selection.some((current: any) => current === listItem) &&
      !options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else {
      let selectionAfter = selection;
      selectionAfter = selectionAfter.filter(
        (current: any) => current !== listItem
      );
      setSelection([...selectionAfter]);
    }
  };

  const isSelected = (listItem: any) => {
    if (selection.find((current: any) => current === listItem)) {
      return true;
    }
    return false;
  };

  const onScroll = (e: any) => {
    const buffer = 2;
    const scrolled = e.target.scrollHeight - e.target.scrollTop;
    const total = e.target.clientHeight + buffer;
    const hitBottom = scrolled <= total;
    if (hitBottom && hasNextPage) {
      fetchNextFilterPage();
    }
  };

  const formatValue = (item: any, value: any) => {
    return (
      <MenuItem
        key={value}
        button
        onClick={() => handleMultiSelect(value)}
        selected={isSelected(value)}
        className={classes.filterListValue}
      >
        {item.valueDisplayFunc
          ? item.valueDisplayFunc(value)
          : value === "false"
          ? "Unassigned"
          : value}
      </MenuItem>
    );
  };
  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <TextField
          variant="outlined"
          size="small"
          value={search}
          className={classes.filterSearch}
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
        />
        <div
          key={item?.name}
          id="scrollableDiv"
          className={classes.filterBody}
          onScroll={onScroll}
        >
          {!Boolean(filter?.length) && !isLoading ? (
            <div className={classes.loader}>Nothing was found</div>
          ) : (
            filter?.map((value: any) => {
              return formatValue(item, value);
            })
          )}
          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBody: {
      maxHeight: 200,
      overflowY: "auto",
      paddingInline: theme.spacing(2),
    },
    filterSearch: {
      paddingInline: theme.spacing(2),
    },
    filterListValue: {
      textTransform: "capitalize",
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      flex: 1,
    },
  })
);
