import React, { useState } from "react";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import Autocomplete from "../../../components/Autocomplete";
import { useTicketsList } from "../../../hooks/tickets/autocomplete";
import { Ticket } from "../../../types";
import { useDebounce } from "../../../helpers/hooks";

interface Props {
  disabled?: boolean;
  onSelect: (ticket: Ticket | null) => void;
  except?: number;
}

export default function Component({ disabled, onSelect, except }: Props) {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [ticketInput, setTicketInput] = useState("");
  const debouncedTicketInput = useDebounce(ticketInput);
  const parentTickets = useTicketsList(
    debouncedTicketInput,
    { can_be_parent: 1, except },
    "parent",
    { enabled: fetchTickets }
  );

  return (
    <>
      <Typography data-cy="assign-parent-title" paragraph variant="body2">
        <LinkIcon style={{ position: "relative", top: "7px" }} /> Assign to a
        Parent Ticket
      </Typography>

      <Autocomplete
        options={parentTickets.data?.data || []}
        renderOption={(ticket: Ticket) => (
          <FormControlLabel
            control={<Radio />}
            label={`${ticket.identifier} ${ticket.short_description ?? ""}`}
          />
        )}
        valueFrom="id"
        textFrom={(ticket: Ticket) =>
          `${ticket.identifier} ${ticket.short_description ?? ""}`
        }
        placeholder="Search Parent Ticket"
        onSearch={(query) => setTicketInput(query)}
        onSelect={(ticket: Ticket) => onSelect(ticket)}
        onOpen={() => setFetchTickets(true)}
        onBlur={() => setTicketInput("")}
        loading={parentTickets?.isLoading}
        disabled={disabled}
        shrink
      />
    </>
  );
}
