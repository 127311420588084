import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Breadcrumb } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  })
);

type Props = {
  data: Breadcrumb[];
};

function Component({ data }: Props) {
  const classes = useStyles();

  if (data.length <= 1) {
    return null;
  }

  return (
    <Breadcrumbs data-cy="breadcrumbs">
      {data.map((br, index) =>
        index + 1 === data.length ? (
          <span key={br.id}>
            <Typography variant="subtitle2">{br.title}</Typography>
          </span>
        ) : (
          <Link key={br.id} to={br.link} className={classes.link}>
            <Typography variant="subtitle2">{br.title}</Typography>
          </Link>
        )
      )}
    </Breadcrumbs>
  );
}

export default Component;
