import { remove, get, multiPartPost, post, put } from "./";
import { Query } from "../screens/Tickets/types";
import { AutocompleteParams, QueryData, Ticket } from "../types";

export function addAssociation(id: number, ids: Array<number>) {
  return post(`tickets/${id}/children`, {
    children: ids,
  });
}

export function addTag(id: number, name: string) {
  return post(`tickets/${id}/tags/add`, { name });
}

export function auditTags({ query = "", limit = 100 }) {
  return get("tickets/audits/tags", {
    query,
    limit,
  });
}

export function associations(
  id: number,
  query: Partial<Query>
): Promise<QueryData<Ticket>> {
  return get(`tickets/${id}/associations`, query);
}

export function categories(params: AutocompleteParams = {}) {
  return get("tickets/categories/autocomplete", {
    ...params,
    limit: params.limit ?? 50,
  });
}

export function deescalate(id: number) {
  return post(`tickets/${id}/deescalate`);
}

export function escalate(id: number, reference?: string) {
  return post(`tickets/${id}/escalate`, { reference });
}

export function escalators(id: number, query: string, { limit = 100 }) {
  return get(`tickets/${id}/escalators/autocomplete`, {
    query,
    limit,
  });
}

export function exportXLSX(query: any) {
  return get(`tickets/export`, query);
}

export function filters(params = {}) {
  return get("tickets/filters", params);
}

export function paginate(query: Partial<Query>): Promise<QueryData<Ticket>> {
  return get("tickets", query);
}

export function paginateKanban(status_id: number, query: Partial<Query>) {
  return get("tickets/kanban", { status_id, ...query });
}

export function priorities(params: AutocompleteParams = {}) {
  return get("tickets/priorities", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function removeAssociation(id: number, ids: Array<number>) {
  return remove(`tickets/${id}/children`, {
    children: ids,
  });
}

export function removeTag(tag_id: number) {
  return remove(`tickets/tags/${tag_id}/remove`);
}

export function show(id: number) {
  return get(`tickets/${id}`);
}

export function start(ticket_commitment_id: number, params?: any) {
  return post(
    `tickets/commitments/${ticket_commitment_id}/pauses/start`,
    params || {}
  );
}

export function stop(ticket_commitment_id: number, params?: any) {
  return post(
    `tickets/commitments/${ticket_commitment_id}/pauses/stop`,
    params || {}
  );
}

export function store(params: any) {
  if (params["files[]"]?.length) {
    return multiPartPost("tickets", params);
  }

  return post("tickets", params);
}

export function subscribe(id: number, params?: any) {
  return post(`tickets/${id}/subscribe`, params);
}

export function subscribers(params: AutocompleteParams = {}) {
  return get(`tickets/subscribers/autocomplete`, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function statuses(params: AutocompleteParams = {}) {
  return get("tickets/statuses", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function subscriptions(id: number) {
  return get(`tickets/${id}/subscriptions`);
}

export function systems(params: AutocompleteParams = {}) {
  return get("tickets/systems/autocomplete", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function tags(params: AutocompleteParams = {}) {
  let url = `tickets/tags/autocomplete`;

  if (params.ticket_id) {
    url = `tickets/${params.ticket_id}/tags/autocomplete`;
  }

  return get(url, {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function types(params: AutocompleteParams = {}) {
  return get("tickets/types", {
    ...params,
    limit: params.limit ?? 100,
  });
}

export function unwatch(id: number, params?: any) {
  return post(`tickets/${id}/unwatch`, params);
}

export function unsubscribe(id: number, params?: any) {
  if (params.subscription_id) {
    return post(`tickets/${id}/unsubscribe/${params.subscription_id}`);
  }

  return post(`tickets/${id}/unsubscribe`);
}

export function update(id: number, params: any) {
  if (params["files[]"]?.length) {
    return multiPartPost(`tickets/${id}`, { ...params, _method: "PUT" });
  }

  return put(`tickets/${id}`, params);
}

export function upload(id: number, params: any) {
  return multiPartPost(`tickets/${id}/attachments`, params);
}

export function visits(id: number) {
  return get(`tickets/${id}/visits`);
}

export function watch(id: number, params?: any) {
  return post(`tickets/${id}/watch`, params);
}
