import React from "react";

// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ImageToBase64 from "../helpers/ImageToBase64";
import "./richEditorStyle.css";

interface Props {
  onChange: (value: string) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
}

function RichTextEditor({ onChange, placeholder, rows = 3, value }: Props) {
  return (
    <CKEditor
      config={{
        placeholder: placeholder,
        // plugins: [ Highlight ],
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "link",
          "uploadImage",
          "|",
          "blockQuote",
          "insertTable",
          "|",
        ],
      }}
      data={value}
      editor={ClassicEditor}
      onChange={(event: any, editor: any) => onChange(editor.getData())}
      onReady={(editor: any) => {
        if (editor) {
          // Change editor upload adapter
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader: any
          ) => {
            return new ImageToBase64(loader);
          };

          // Change editor height
          editor.editing.view.change((writer: any) => {
            writer.setStyle(
              "min-height",
              `${rows * 40}px`,
              editor.editing.view.document.getRoot()
            );
          });
        }
      }}
    />
  );
}

export default RichTextEditor;
