import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { TextField } from "@material-ui/core";

type Props = {
  onDebouncedChange: (value?: string) => void;
  value: number | string | null;
  wait: number;
  [x: string]: any;
};

const Component = ({ value, onDebouncedChange, wait, ...props }: Props) => {
  const [val, setVal] = useState<number | string | null>("");

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleDebouncedChange = useMemo(
    () =>
      debounce((input: string) => {
        onDebouncedChange(input || undefined);
      }, wait),
    [wait, onDebouncedChange]
  );

  return (
    <TextField
      {...props}
      onChange={(event) => {
        setVal(event.target.value);
        handleDebouncedChange(event.target.value);
      }}
      value={val}
      inputProps={{
        autoComplete: "new-password",
        form: {
          autocomplete: "off",
        },
      }}
    />
  );
};

export default Component;
