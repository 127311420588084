import React from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { RHFTextField } from "../../../atoms/form-fields/RHFTextField";
import { RHFSelectField } from "../../../atoms/form-fields/RHFSelectField";
import { IndexedCardInfo } from "../../../atoms/indexed-card-info";

interface BillOptionsProps {
  cardIndex: string;
}

export const BillOptions = ({ cardIndex }: BillOptionsProps) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Bill Options">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RHFSelectField label="Bill Type" name="ebilling_enabled">
            <MenuItem value="0">Paper</MenuItem>
            <MenuItem value="1">Online</MenuItem>
          </RHFSelectField>
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="E-Billing Email Address"
            name="ebilling_email"
            type="email"
          />
        </Grid>
        <Grid item sm={6}>
          <RHFSelectField label="Large Print Bill?" name="large_print_bill">
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </RHFSelectField>
        </Grid>
        <Grid item sm={6}>
          <RHFSelectField
            label="Cost Centre CSV?"
            name="create_cost_centre_csv"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </RHFSelectField>
        </Grid>
      </Grid>
    </IndexedCardInfo>
  );
};
