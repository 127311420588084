import { useMutation } from "react-query";
import * as api from "../../api/comments/attachments";
import { Attachment, LooseObject } from "../../types";

export function useRemove(commentId: number, options: LooseObject = {}) {
  const onSuccess = (data: any, attachment: Attachment) => {
    attachment.is_deleted = true;

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, attachment);
    }
  };

  return useMutation(
    (attachment: Attachment) => api.remove(commentId, attachment.id),
    {
      ...options,
      onSuccess,
    }
  );
}
