import React, { useEffect, useState } from "react";
import { capitalize } from "lodash";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PauseIcon from "@material-ui/icons/Pause";
import {
  formattedDateTime,
  secondsToHumanReadable,
} from "../../../../../../helpers/functions";
import { Commitment } from "../../../../../../types";
import { useTicketDetail } from "../../../../../../hooks/tickets";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  breached: {
    color: theme.palette.error.main,
  },
  done: {
    color: theme.palette.success.main,
  },
  paused: {
    color: theme.palette.grey[300],
  },
}));

interface Props {
  commitment: Commitment;
  shouldCountUp: boolean;
  shouldCountDown: boolean;
}

interface TimerProps {
  name: string;
  time: string;
}

const Timer = ({ name, time }: TimerProps) => {
  return (
    <>
      <Grid item xs={3}>
        <Typography variant="caption">{name}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h6">
          {formattedDateTime(time, "dd/MM/yyyy HH:mm")}
        </Typography>
      </Grid>
    </>
  );
};

export default function Component({
  commitment,
  shouldCountUp,
  shouldCountDown,
}: Props) {
  const [counter, setCounter] = useState<number>(
    commitment?.seconds_running || 0
  );
  const { refetch, isFetching } = useTicketDetail(commitment.ticket_id);
  const classes = useStyles();

  useEffect(() => {
    setCounter(commitment?.seconds_running);
  }, [commitment]);

  useEffect(() => {
    if (counter === 0) {
      refetch();
    }
  }, [counter, commitment.ticket_id, refetch]);

  useEffect(() => {
    let interval: number | null = null;
    if (shouldCountUp && !interval) {
      interval = window.setInterval(
        () => setCounter((prevCounter) => prevCounter + 1),
        1000
      );
    }

    if (shouldCountDown && !interval) {
      interval = window.setInterval(
        () => setCounter((prevCounter) => prevCounter - 1),
        1000
      );
    }

    if (!shouldCountUp && !shouldCountDown && interval) {
      window.clearInterval(interval);
    }

    return function cleanup() {
      if (interval) window.clearInterval(interval);
    };
  }, [shouldCountUp, shouldCountDown]);

  const getStatus = (): string => {
    if (!shouldCountUp && !shouldCountDown) return commitment.status;
    return `${secondsToHumanReadable(counter)} ${
      shouldCountUp ? "over the estimated time" : "remaining"
    }`;
  };

  const getIcon = () => {
    if (commitment.is_paused) return <PauseIcon fontSize="inherit" />;
    if (commitment.is_breached) return <CloseIcon fontSize="inherit" />;
    return <DoneIcon fontSize="inherit" />;
  };

  const getClassName = () => {
    if (commitment.is_paused) return classes.paused;
    if (commitment.is_breached) return classes.breached;
    return classes.done;
  };

  return (
    <>
      <Typography paragraph={true} variant="caption">
        {commitment.name}
      </Typography>

      <Box mb={0.5} height="30px" boxSizing="content-box">
        {!isFetching ? (
          <Typography
            className={getClassName()}
            paragraph={true}
            variant="h6"
            style={{ lineHeight: "30px" }}
          >
            {getIcon()} {getStatus()}
          </Typography>
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>

      <Grid container>
        <Timer
          name={"Target " + capitalize(commitment.type)}
          time={commitment.expected_at}
        />

        {commitment.resolved_at && (
          <Timer
            name={"Actual " + capitalize(commitment.type)}
            time={commitment.resolved_at}
          />
        )}

        {commitment.alert_at && (
          <Timer name="Alert Time" time={commitment.alert_at} />
        )}

        {commitment.breach_reason && (
          <>
            <Grid item xs={3}>
              <Typography variant="caption">Breach Reason</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">
                {commitment.breach_reason.name}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
