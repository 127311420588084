import React from "react";
import { Tooltip } from "@material-ui/core/";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    circle: {
      height: 36,
      width: 36,
      borderRadius: "50%",
      backgroundColor: theme.palette.grey[200],
      border: "2px solid white",
      marginLeft: "-10px",
      zIndex: ({ zIndex }) => zIndex,
    },
  })
);

type Props = {
  tooltipText: string;
  zIndex: number;
  children: React.ReactElement<any, any>;
};

interface StyleProps {
  zIndex: number;
}

const Component = ({ tooltipText, zIndex, children }: Props) => {
  const styleProps: StyleProps = { zIndex };
  const classes = useStyles(styleProps);

  return (
    <div className={classes.circle}>
      <Tooltip title={tooltipText} placement="top">
        {children}
      </Tooltip>
    </div>
  );
};

export default Component;
