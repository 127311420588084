import React from "react";
// @ts-ignore
import { Draggable } from "react-drag-and-drop";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Column as ColumnType } from "../../../../types";

interface Props {
  column: ColumnType;
  disabled?: boolean;
  updateColumn: (column: ColumnType, fields: Partial<ColumnType>) => void;
}

export default function Column({ column, disabled, updateColumn }: Props) {
  return (
    <div>
      <Draggable
        data={column.id}
        onDragEnd={() => updateColumn(column, { order: 0 })}
        type="column"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={column.checked ?? false}
                disabled={disabled}
                onChange={(event) =>
                  updateColumn(column, {
                    checked: event.target.checked,
                  })
                }
                name="column_visibility"
                color="primary"
                size="small"
              />
            }
            label={<Typography variant="subtitle2">{column.label}</Typography>}
          />
          {/*<DragHandleIcon*/}
          {/*  style={{ cursor: 'move' }}*/}
          {/*/>*/}
        </Box>
      </Draggable>
      <Divider />
    </div>
  );
}
