import React from "react";

import { Grid } from "@material-ui/core";

import { Ticket } from "../../../../types";
import ContactItem from "../components/ContactItem";

interface Props {
  ticket: Ticket;
}

const Component: React.FC<Props> = ({ ticket }) => {
  return (
    <Grid container spacing={1}>
      <ContactItem title="First Name" value={ticket?.contact?.first_name} />
      <ContactItem title="Last Name" value={ticket?.contact?.last_name} />
      <ContactItem title="Number" value={ticket?.contact?.phone} />
      <ContactItem title="Email" value={ticket?.contact?.email} />
    </Grid>
  );
};

export default Component;
