import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AvailableFilters from "./components/AvailableFilters";
import AppliedFilters from "./components/AppliedFilters";
import { Filter } from "../../../types";
import { Query } from "../types";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
  },
  buttonMargin: {
    marginRight: theme.spacing(1),
  },
  showHideButton: {
    width: theme.spacing(5),
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.1s linear",
    "&.open": {
      transform: "rotate(180deg)",
    },
  },
  contentBox: {
    maxHeight: "0px",
    transition: "max-height 0.2s ease-out",
    "&.shown": {
      maxHeight: "600px",
    },
  },
}));

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  resetQuery: (filter?: Filter | undefined) => void;
}

function Component({ query, setQuery, resetQuery }: Props) {
  const classes = useStyles();
  const [filtersShown, setFiltersShown] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const handleResetFilters = () => {
    setShouldReset(true);
  };

  useEffect(() => {
    if (shouldReset) {
      resetQuery();
      setShouldReset(false);
    }
  }, [shouldReset, resetQuery]);

  const toggleFilterVisibility = () =>
    setFiltersShown((prevValue) => !prevValue);

  return (
    <Card raised>
      <CardHeader
        title={
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                data-cy="users-filters-reset-button"
                color="primary"
                variant="outlined"
                size="small"
                className={classes.buttonMargin}
                onClick={handleResetFilters}
              >
                Reset
              </Button>
              <Button
                data-cy="users-filters-show-button"
                variant="text"
                size="small"
                onClick={toggleFilterVisibility}
                endIcon={
                  <ExpandMoreIcon
                    className={`${classes.expandIcon} ${
                      filtersShown ? "open" : ""
                    }`}
                  />
                }
                className={classes.showHideButton}
              >
                {filtersShown ? "Hide" : "Show"}
              </Button>
            </Box>
          </Box>
        }
        className={classes.header}
      />
      <Box className={`${classes.contentBox} ${filtersShown ? "shown" : ""}`}>
        <CardContent>
          <AvailableFilters query={query} setQuery={setQuery} />
        </CardContent>
        <CardActions>
          <AppliedFilters
            query={query}
            setQuery={(query) => {
              setQuery(query);
            }}
          />
        </CardActions>
      </Box>
    </Card>
  );
}

export default Component;
