import React, { useState } from "react";
import { format } from "date-fns";
import { Button, Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "../../../components/Autocomplete";
import FreeSearch from "../../../components/FreeSearch";
import { dateOrNull } from "../../../helpers/functions";
import { useDebounce } from "../../../helpers/hooks";
import { useUsers } from "../../../hooks/autocomplete";
import { usePaginate, useStatuses } from "../../../hooks/tasks";
import { Status, User } from "../../../types";
import { Query } from "../types";

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
  view: string;
};

function Component({ query, resetQuery, setQuery, view }: Props) {
  const { isLoading } = usePaginate("", query);

  const [statusInput, setStatusInput] = useState("");
  const debouncedStatusInput = useDebounce(statusInput, 600);
  const statuses = useStatuses(debouncedStatusInput);

  const status =
    statuses.data?.data?.find(
      (status: Status) => status.id === query.status_id
    ) || null;

  const [userQuery, setUserQuery] = useState("");
  const users = useUsers({ query: userQuery });

  return (
    <Grid container spacing={1}>
      <Button
        data-cy="tasks-filters-reset-button"
        color="primary"
        onClick={resetQuery}
        size="small"
        variant="text"
      >
        RESET
      </Button>

      <Grid item xs={12}>
        <FreeSearch
          data-cy="subject-field"
          disabled={isLoading}
          fullWidth
          label="Subject"
          id="filter-name"
          variant="outlined"
          value={query.name ?? ""}
          size="small"
          onDebouncedChange={(value) => setQuery({ name: value })}
          wait={500}
        />
      </Grid>
      {view !== "kanban" && (
        <>
          <Grid item xs={12}>
            <Autocomplete
              data-cy="status-autocomplete"
              label="Status"
              placeholder="Type to search"
              loading={statuses.isLoading}
              disabled={isLoading}
              options={statuses.data?.data || []}
              value={status}
              valueFrom="id"
              textFrom="name"
              onSelect={(status: Status) =>
                setQuery({ status_id: status ? status.id : null })
              }
              onSearch={(query) => setStatusInput(query)}
              shrink
            />
          </Grid>

          {status && status.is_open === false && (
            <Grid item xs={12}>
              <DatePicker
                data-cy="date-completed-picker"
                autoOk
                disabled={isLoading}
                disableToolbar
                format="dd/MM/yyy"
                fullWidth
                inputVariant="outlined"
                label="Date Completed"
                margin="none"
                onChange={(date) =>
                  setQuery({
                    done_at: format(date, "yyy-MM-dd"),
                  })
                }
                size="small"
                value={dateOrNull(query.done_at ?? "")}
                variant="inline"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <Autocomplete
          data-cy="users-autocomplete"
          label="Assigned To"
          placeholder="Type to search"
          loading={users?.isLoading}
          disabled={isLoading}
          options={users.data?.data || []}
          value={
            users.data?.data.find((user: User) => user.id === query.user_id) ||
            null
          }
          valueFrom="id"
          textFrom="full_name"
          onSelect={(user: User) =>
            setQuery({ user_id: user ? user.id : null })
          }
          onSearch={(query) => setUserQuery(query)}
          shrink
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DatePicker
              data-cy="created-after-date-picker"
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date Created from"
              margin="none"
              onChange={(date) =>
                setQuery({
                  created_after: format(date, "yyy-MM-dd"),
                })
              }
              size="small"
              value={dateOrNull(query.created_after ?? "")}
              variant="inline"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              data-cy="created-to-date-picker"
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date Created to"
              margin="none"
              onChange={(date) =>
                setQuery({
                  created_before: format(date, "yyy-MM-dd"),
                })
              }
              size="small"
              value={dateOrNull(query.created_before ?? "")}
              variant="inline"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DatePicker
              data-cy="due-date-from-picker"
              disabled={isLoading}
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyy"
              margin="none"
              id="filter-due_after"
              label="Due Date from"
              value={dateOrNull(query.due_after ?? "")}
              onChange={(date) =>
                setQuery({
                  due_after: format(date, "yyy-MM-dd"),
                })
              }
              autoOk
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              data-cy="due-date-to-picker"
              disabled={isLoading}
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyy"
              margin="none"
              id="filter-due_before"
              label="Due Date to"
              value={dateOrNull(query.due_before ?? "")}
              onChange={(date) =>
                setQuery({ due_before: format(date, "yyy-MM-dd") })
              }
              autoOk
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Component;
