import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import GridItem from "./components/GridItem";
import { Category } from "../types";

type Props = {
  categories: Array<Category>;
  loading: boolean;
  handleCategoryClick: (id: number) => void;
};

function Component({ categories, loading, handleCategoryClick }: Props) {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Box
      data-cy="knowledge-base-grid"
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(260px, 1fr))"
      gridGap={16}
      mt={2}
    >
      {categories.map((category: Category) => (
        <GridItem
          key={category.id}
          category={category}
          onClick={handleCategoryClick}
        />
      ))}
    </Box>
  );
}

export default Component;
