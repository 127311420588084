import { useQuery, useQueryClient, useMutation } from "react-query";
import * as api from "../api/users";
import { Query, QueryData, QueryNonArrayData, User } from "../types";

export function usePaginate(query: Partial<Query>) {
  return useQuery<QueryData<User>, Error>(["users", query], () =>
    api.users(query)
  );
}

export function useSingleUser(id: number, options = {}) {
  return useQuery<QueryNonArrayData<User>, Error>(
    ["user", id],
    () => api.show(id),
    {
      ...options,
    }
  );
}

export function useUpdate(userId: number, options = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.removeQueries(["user", userId]);
  };

  return useMutation(({ id, params }) => api.update(id, params), {
    ...options,
    onSuccess,
  });
}

export function useUser() {
  const user = localStorage.getItem("affinity-support.user")
    ? JSON.parse(localStorage.getItem("affinity-support.user") as string)
    : null;

  useQuery<QueryNonArrayData<User>, Error>(
    ["user", "current"],
    () => api.loggedIn(),
    {
      enabled: !user,
      onSuccess: (data) =>
        localStorage.setItem(
          "affinity-support.user",
          JSON.stringify(data.data)
        ),
    }
  );

  return {
    isLoading: !user,
    isError: user && !user.permissions.length,
    ...user,
  };
}
