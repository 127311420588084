import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import Loading from "../../../layout/Loading";
import Table from "./components/Table";
import { usePaginate } from "../../../hooks/tickets";
import { Query } from "../types";

type Props = {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
};

function Component({ query, setQuery }: Props) {
  const paginator = usePaginate(query);
  const queryClient = useQueryClient();

  useEffect(() => {
    return function cleanup() {
      queryClient.invalidateQueries(["tickets", "table"]);
    };
  }, [queryClient]);

  useEffect(() => {
    queryClient.setQueryData(["tickets", "query"], query);
  }, [query, queryClient]);

  return (
    <Loading error={paginator.isError ? "Can't load tickets" : undefined}>
      <Table
        loading={
          paginator.isLoading || paginator.isFetching || paginator.isRefetching
        }
        paginator={paginator?.data}
        query={query}
        setQuery={setQuery}
      />
    </Loading>
  );
}

export default Component;
