import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Comment from "./Comment";
import { Comment as CommentType, CommentFields } from "../../../../types";
import {
  CommentCreateMutationType,
  CommentUpdateMutationType,
} from "../../types";

interface Props {
  comments: CommentType[];
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
  hideZeroLevelCommentForm: () => void;
  replying: number;
  setReplying: React.Dispatch<React.SetStateAction<number>>;
  editing: number;
  setEditing: React.Dispatch<React.SetStateAction<number>>;
  create: CommentCreateMutationType;
  update: CommentUpdateMutationType;
  loaded: boolean;
}

const CommentSection = (props: Props): JSX.Element => {
  const {
    comments,
    fields,
    setFields,
    clearFields,
    hideZeroLevelCommentForm,
    replying,
    setReplying,
    editing,
    setEditing,
    create,
    update,
    loaded,
  } = props;

  const [expandedComments, setExpandedComments] = useState<number[]>(
    !!parseInt(window.location.hash?.slice(1)) ? comments.map((c) => c.id) : []
  );

  return (
    <>
      {comments.map((comment: CommentType) => {
        const showReplies =
          expandedComments.findIndex((id) => id === comment.id) >= 0;

        return (
          <React.Fragment key={comment.id}>
            <TableRow>
              <TableCell style={{ borderBottom: "none", padding: 0 }}>
                <Comment
                  comment={comment}
                  fields={fields}
                  setFields={setFields}
                  clearFields={clearFields}
                  showReplies={showReplies}
                  setShowReplies={() => {
                    const commentIndex = expandedComments.findIndex(
                      (id: number) => id === comment.id
                    );
                    if (commentIndex >= 0)
                      setExpandedComments((ids) =>
                        ids.filter((id) => id !== comment.id)
                      );
                    else setExpandedComments((ids) => [...ids, comment.id]);
                  }}
                  replying={replying === comment.id}
                  setReplying={setReplying}
                  editing={editing === comment.id}
                  setEditing={setEditing}
                  hideZeroLevelCommentForm={hideZeroLevelCommentForm}
                  create={create}
                  update={update}
                  loaded={loaded}
                />
              </TableCell>
            </TableRow>
            {(showReplies || comment.depth > 0) &&
              comment.descendants.length > 0 && (
                <CommentSection {...props} comments={comment.descendants} />
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CommentSection;
