import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { Category } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    card: {
      "&:hover": {
        boxShadow: theme.shadows[12],
      },
    },
    button: {
      width: "100%",
      display: "block",
    },
  })
);

type Props = {
  category: Category;
  onClick: (id: number) => void;
};

function Component({ category, onClick }: Props) {
  const classes = useStyles();
  let { url } = useRouteMatch();

  return (
    <Link
      data-cy={`knowledge-base-grid-item-${category.id}`}
      className={classes.link}
      to={`${url}/${category.id}`}
    >
      <Button onClick={() => onClick(category.id)} className={classes.button}>
        <Card className={classes.card}>
          <CardContent>
            <FolderOpenIcon />
            <Typography variant="h4">{category.name}</Typography>
          </CardContent>
        </Card>
      </Button>
    </Link>
  );
}

export default Component;
