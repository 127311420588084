import React from "react";

import { Box, Typography } from "@material-ui/core/";
import ReplyIcon from "@material-ui/icons/Reply";

import { Message } from "../../../../types";
import { formattedDate, formattedTime } from "../../../../helpers/functions";

interface Props {
  message: Message;
  reply?: boolean;
}

export default function Component({ message, reply = false }: Props) {
  return (
    <Box data-cy="mailbox-message-info" display="flex">
      {reply && (
        <Box flexGrow={1}>
          <Typography display="block" variant="h4">
            <ReplyIcon fontSize="inherit" /> Replied
          </Typography>
        </Box>
      )}
      {!reply && (
        <Box flexGrow={1}>
          <Typography display="block" variant="h4">
            #{message.id}
          </Typography>
          <Typography display="block" variant="caption">
            {message.from}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography display="block" variant="h4">
          {formattedDate(message.created_at, "dd MMM ''yy")}
        </Typography>
        <Typography display="block" variant="caption">
          {formattedTime(message.created_at)}
        </Typography>
      </Box>
    </Box>
  );
}
