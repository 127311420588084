import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { ValidationError } from "../api";
import * as api from "../api/comments";
import {
  CommentsData,
  CommentHistoryData,
  CommentTypesData,
  Query,
} from "../screens/Comments/types";
import { Comment, CommentFields, LooseObject } from "../types";

export function useCommentHistory(
  id: number,
  params: LooseObject = {},
  options: LooseObject = {}
) {
  return useQuery<CommentHistoryData, Error>(
    ["comments", id, "versions"],
    () => api.commentHistory(id, params),
    options
  );
}

export function useCommentTypes(query: string = "", options: LooseObject = {}) {
  return useQuery<CommentTypesData, Error>(
    ["comments", "types", query],
    () => api.types({ query }),
    options
  );
}

export function useComments(
  owner: string,
  query: Partial<Query>,
  options: LooseObject = {}
) {
  return useInfiniteQuery<CommentsData, Error | ValidationError>(
    [`${owner}comments`, query.type_id ?? 0, query],
    ({ pageParam = 1 }) => api.comments(owner, { ...query, page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta?.current_page < lastPage.meta?.last_page)
          return lastPage.meta?.current_page + 1;
        return false;
      },
      ...options,
    }
  );
}

export function useCreate(owner: string, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries([`${owner}comments`]);
    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation<Comment, Error, CommentFields>(
    (fields) => api.create(owner, fields),
    {
      ...options,
      onSuccess,
    }
  );
}

export function useUpdate(owner: string, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries([`${owner}comments`]);
    queryClient.invalidateQueries(["comments", data.data.id, "versions"]);
    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation<Comment, Error, CommentFields>(
    ({ id, ...rest }) => api.update(id, rest),
    {
      ...options,
      onSuccess,
    }
  );
}
