import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    marginBottom: theme.spacing(1.5),
  },
}));

interface GridGroupProps {
  children: React.ReactNode;
}

function GridGroup({ children }: GridGroupProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.group}>
      <Grid container spacing={1}>
        {children}
      </Grid>
    </Grid>
  );
}

export default GridGroup;
