import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  Chip,
  useTheme,
} from "@material-ui/core";
import { LooseObject, Role, Team, User, Customer } from "../../../types";
import { useHistory } from "react-router-dom";
import Autocomplete from "../../../components/Autocomplete";
import { useQueryClient } from "react-query";
import { useCustomers, useRoles, useTeams } from "../../../hooks/autocomplete";

const Switch = ({
  actions,
  embedded,
  fields,
}: {
  actions: React.ReactNode;
  embedded: boolean;
  fields: React.ReactNode;
}) => {
  if (!embedded) {
    return (
      <Card variant="outlined">
        <CardContent>{fields}</CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    );
  }

  return (
    <>
      {fields}
      {actions}
    </>
  );
};

interface Props {
  embedded?: boolean;
  loading: boolean;
  onSubmit: (fields: LooseObject) => void;
  fields?: User;
  setFields: Dispatch<SetStateAction<any>>;
}

function Component({
  embedded = false,
  loading,
  onSubmit,
  fields,
  setFields,
}: Props) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [roleQuery, setRoleQuery] = useState("");
  const [teamQuery, setTeamQuery] = useState("");
  const [customerQuery, setCustomerQuery] = useState("");

  const roles = useRoles({ query: roleQuery });
  const teams = useTeams({ query: teamQuery });
  const customers = useCustomers({ query: customerQuery });

  const [roleOptions, setRoleOptions] = useState(roles.data?.data || []);
  const [teamOptions, setTeamOptions] = useState(teams.data?.data || []);
  const [customerOptions, setCustomerOptions] = useState(
    customers.data?.data || []
  );

  const [updateParams, setUpdateParams] = useState({});

  useEffect(() => {
    if (roles.data?.data) {
      const filteredSelectedOut = roles.data.data.filter(
        (role: Role) => !fields?.roles?.some((r: Role) => r.name === role.name)
      );
      setRoleOptions([...filteredSelectedOut]);
    }
    setUpdateParams({
      ...updateParams,
      role_ids: fields?.roles?.map((role: Role) => role.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.roles, roles.data?.data]);

  useEffect(() => {
    if (teams.data?.data) {
      const filteredSelectedOut = teams.data.data.filter(
        (team: Team) => !fields?.teams?.some((t: Team) => t.name === team.name)
      );
      setTeamOptions([...filteredSelectedOut]);
    }
    setUpdateParams({
      ...updateParams,
      team_ids: fields?.teams?.map((team: Team) => team.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.teams, teams.data?.data]);

  useEffect(() => {
    if (customers.data?.data) {
      const filteredSelectedOut = customers.data.data.filter(
        (customer: Customer) =>
          !fields?.customers?.some((c: Customer) => c.name === customer.name)
      );
      setCustomerOptions([...filteredSelectedOut]);
    }
    setUpdateParams({
      ...updateParams,
      customer_ids: fields?.customers?.map((customer: Customer) => customer.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.customers, customers.data?.data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
    setUpdateParams({
      ...updateParams,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history.goBack();
    queryClient.removeQueries(["user", fields?.id]);
  };

  const handleSubmit = (event: any) => {
    history.push("/support/admin/users");
    onSubmit({ id: fields?.id, params: { ...updateParams } });
  };

  return (
    <>
      <Switch
        embedded={embedded}
        fields={
          <Grid container spacing={1}>
            {embedded && (
              <Grid item xs={12}>
                <Typography variant="h4">User Details</Typography>
              </Grid>
            )}

            <Grid item xs={12} md={9}>
              <TextField
                data-cy="user-form-name-field"
                fullWidth
                label="Full Name"
                name="full_name"
                onChange={handleChange}
                required
                value={fields?.full_name || ""}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField
                data-cy="user-form-email-field"
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={fields?.email || ""}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField
                data-cy="user-form-phone-field"
                fullWidth
                label="Phone"
                name="phone"
                onChange={handleChange}
                required
                value={fields?.phone || ""}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                data-cy="user-form-roles-autocomplete"
                placeholder="Type to search"
                label="Roles"
                loading={roles.isLoading}
                options={roleOptions || []}
                name="role"
                valueFrom="id"
                textFrom="label"
                onSelect={(role: Role) => {
                  if (role) {
                    setRoleQuery("");
                    setFields({
                      ...fields,
                      roles: [...(fields?.roles || []), role],
                    });
                  }
                }}
                onSearch={(query) => setRoleQuery(query)}
                onBlur={() => setRoleQuery("")}
                value={{ id: -1, label: roleQuery }}
              />
              {fields?.roles?.map((role: Role, index: number) => (
                <Chip
                  label={role.label}
                  key={role.id}
                  size="small"
                  style={{ margin: theme.spacing(0.5, 0.5, 0, 0) }}
                  onDelete={() => {
                    setFields({
                      ...fields,
                      roles: fields?.roles?.filter(
                        (r: Role) => !(r.name === role.name)
                      ),
                    });
                  }}
                />
              ))}
            </Grid>

            <Grid item md={6} />

            <Grid item xs={12} md={6}>
              <Autocomplete
                data-cy="user-form-teams-autocomplete"
                placeholder="Type to search"
                label="Teams"
                loading={teams.isLoading}
                options={teamOptions || []}
                name="team"
                valueFrom="id"
                textFrom="name"
                onSelect={(team: Team) => {
                  if (team) {
                    setTeamQuery("");
                    setFields({
                      ...fields,
                      teams: [...(fields?.teams || []), team],
                    });
                  }
                }}
                onSearch={(query) => setTeamQuery(query)}
                onBlur={() => setTeamQuery("")}
                value={{ id: -1, name: teamQuery }}
              />

              {fields?.teams?.map((team: Team, index: number) => (
                <Chip
                  label={team.name}
                  key={team.id}
                  size="small"
                  style={{ margin: theme.spacing(0.5, 0.5, 0, 0) }}
                  onDelete={() => {
                    setFields({
                      ...fields,
                      teams: fields?.teams?.filter(
                        (t: Team) => !(t.name === team.name)
                      ),
                    });
                  }}
                />
              ))}
            </Grid>
            <Grid item md={6} />

            <Grid item xs={12} md={6}>
              <Autocomplete
                data-cy="user-form-customers-autocomplete"
                placeholder="Type to search"
                label="Customers"
                loading={customers.isLoading}
                options={customerOptions || []}
                name="customers"
                valueFrom="id"
                textFrom="name"
                onSelect={(customer: Customer) => {
                  if (customer) {
                    setCustomerQuery("");
                    setFields({
                      ...fields,
                      customers: [...(fields?.customers || []), customer],
                    });
                  }
                }}
                onSearch={(query) => setCustomerQuery(query)}
                onBlur={() => setCustomerQuery("")}
                value={{ id: -1, name: customerQuery }}
              />

              {fields?.customers?.map((customer: Customer, index: number) => (
                <Chip
                  label={customer.name}
                  key={customer.id}
                  size="small"
                  style={{ margin: theme.spacing(0.5, 0.5, 0, 0) }}
                  onDelete={() => {
                    setFields({
                      ...fields,
                      customers: fields?.customers?.filter(
                        (c: Customer) => !(c.name === customer.name)
                      ),
                    });
                  }}
                />
              ))}
            </Grid>
          </Grid>
        }
        actions={
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={2}
            mb={embedded ? 0 : 1}
            ml={embedded ? 0 : 0.5}
          >
            <Button
              data-cy="user-form-cancel-button"
              disabled={loading}
              size="large"
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              data-cy="user-form-submit-button"
              color="primary"
              disabled={loading}
              size="large"
              type="submit"
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        }
      />
    </>
  );
}

export default Component;
