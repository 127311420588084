import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Query } from "../../types";
import AppliedFilter from "./AppliedFilter";

import {
  formatArrayParamWithData,
  formatArrayParamWithoutData,
} from "../../../../helpers/functions";
import { useCustomers, useTeams } from "../../../../hooks/autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersWrap: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignContent: "center",
      width: "100%",
      minHeight: theme.spacing(2),
      gap: "6px",
      padding: "3px",
      margin: theme.spacing(0.5),
      border: "1px solid",
      borderColor: theme?.palette?.grey?.[100],
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      listStyleType: "none",
    },
    chip: {
      maxWidth: "100%",
    },
  })
);

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ query, setQuery }: Props) {
  const classes = useStyles();

  const customers = useCustomers(
    { ids: query.customer_ids },
    { enabled: (query.customer_ids?.length ?? 0) > 0 }
  );

  const teams = useTeams(
    { ids: query.team_ids },
    { enabled: (query.team_ids?.length ?? 0) > 0 }
  );

  return (
    <ul className={classes.filtersWrap}>
      {query?.full_names?.length && (
        <AppliedFilter
          chipKey="full_names"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithoutData(
              "Full Name",
              "full_names",
              query?.full_names || []
            )
          }
        />
      )}
      {query?.emails?.length && (
        <AppliedFilter
          chipKey="emails"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithoutData("Email", "emails", query?.emails || [])
          }
        />
      )}
      {query?.phones?.length && (
        <AppliedFilter
          chipKey="phones"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithoutData(
              "Phone Number",
              "phones",
              query?.phones || []
            )
          }
        />
      )}
      {query?.team_ids?.length && (
        <AppliedFilter
          chipKey="team_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Team",
              "team_ids",
              "name",
              teams.data?.data || []
            )
          }
        />
      )}
      {query?.customer_ids?.length && (
        <AppliedFilter
          chipKey="customer_ids"
          query={query}
          setQuery={setQuery}
          formatter={() =>
            formatArrayParamWithData(
              "Company Name",
              "customer_ids",
              "name",
              customers.data?.data || []
            )
          }
        />
      )}
    </ul>
  );
}

export default Component;
