import React from "react";

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  action: {
    margin: theme.spacing(0.5),
  },
  actions: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
}));

function Component() {
  const classes = useStyles();

  const Placeholder = () => {
    return (
      <Card style={{ width: "100%" }}>
        <CardHeader
          action={
            <Skeleton className={classes.action} variant="text" width={30} />
          }
          className={classes.header}
          title={<Skeleton variant="text" width="30%" />}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton
                variant="circle"
                width={150}
                height={150}
                style={{ margin: "0 auto" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={6}>
                  <Skeleton variant="text" width="70%" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width="50%"
                    style={{ float: "right" }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Skeleton variant="text" width="70%" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width="50%"
                    style={{ float: "right" }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Skeleton variant="text" width="70%" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width="50%"
                    style={{ float: "right" }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Skeleton variant="text" width="70%" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width="50%"
                    style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Skeleton variant="text" width="100%" />
        </CardActions>
      </Card>
    );
  };

  return (
    <Grid data-cy="dashboard-card-preview" container spacing={2}>
      <Grid item xs={6}>
        <Placeholder />
      </Grid>
      <Grid item xs={6}>
        <Placeholder />
      </Grid>
    </Grid>
  );
}

export default Component;
