import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
  // Responsive
} from "mui-datatables";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { functions } from "../../../../../helpers";
import { SortDirection, Ticket } from "../../../../../types";
import { Query } from "../../../types";

type Props = {
  data: Ticket[];
  count: number;
  title: string;
  loading: boolean;
  removeAssociation: (id: number) => void;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({
  data,
  count,
  title,
  loading,
  removeAssociation,
  query,
  setQuery,
}: Props) {
  // Check if "sort" is equal with the given "columnName"
  // and return the value of "direction" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction : "none";

  const columns: MUIDataTableColumn[] = [
    {
      name: "identifier",
      label: "ID",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 115, paddingLeft: 30 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = data[tableMeta.rowIndex];

          return (
            <Button
              variant="text"
              color="primary"
              size="small"
              component={Link}
              to={`/support/tickets/${row.id}`}
            >
              {value}
            </Button>
          );
        },
        filter: false,
        sortDirection: getSortDirection("id"),
      },
    },
    {
      name: "customer.name",
      label: "Company",
      options: {
        filter: false,
        sortDirection: getSortDirection("customer"),
      },
    },
    {
      name: "short_description",
      label: "Short Description",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 145 },
        }),
        filter: false,
        sortDirection: getSortDirection("customer"),
      },
    },
    {
      name: "status.name",
      label: "Status",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 120 },
        }),
        filter: false,
        sortDirection: getSortDirection("status"),
      },
    },
    {
      name: "stage.name",
      label: "Substage",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 120 },
        }),
        filter: false,
        sortDirection: getSortDirection("stage"),
      },
    },
    {
      name: "user.full_name",
      label: "Assigned To",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 120 },
        }),
        filter: false,
        sortDirection: getSortDirection("user"),
      },
    },
    {
      name: "team.name",
      label: "Assigned Team",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 120 },
        }),
        filter: false,
        sortDirection: getSortDirection("team"),
      },
    },
    {
      name: "opened_at",
      label: "Opened",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 122 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return functions.formattedDate(value);
        },
        filter: false,
        sortDirection: getSortDirection("date_entered"),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 115, paddingLeft: 30 },
        }),
        customBodyRender: (id: number, tableMeta: MUIDataTableMeta) => {
          return (
            <Button
              data-cy={`associations-remove-button-${id}`}
              color="secondary"
              onClick={() => removeAssociation(id)}
              startIcon={<CloseIcon />}
              size="small"
              variant="text"
              disabled={loading}
            >
              Remove
            </Button>
          );
        },
        filter: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: count,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="ticket-associations-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
}
