import React, { ChangeEvent, useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { Box, Button, Grid, TextField, Tooltip } from "@material-ui/core";
import GridGroup from "./GridGroup";
import AlertWithButtons from "./AlertWithButtons";
import Autocomplete from "../../../components/Autocomplete";
import { Contact, TicketFields } from "../../../types";
import { ContactsData } from "../types";
import { useEffectWithPrev } from "../../../helpers/hooks";

interface Props {
  fields: TicketFields;
  setFields: (fields: any) => void;
  hasErrors: (field: string) => boolean;
  displayErrors: (field: string) => JSX.Element;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  resetValidation: (fields: string[]) => void;
  contacts: UseQueryResult<ContactsData, Error>;
  setContactInput: (query: string) => void;
}

const ContactSection = ({
  fields,
  setFields,
  hasErrors,
  displayErrors,
  handleChange,
  resetValidation,
  contacts,
  setContactInput,
}: Props) => {
  const [addingNewContact, setAddingNewContact] = useState(false);
  const [existingContact, setExistingContact] = useState<Contact | null>(null);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  // remove selected contact when company changes
  useEffectWithPrev(
    (inputs: any) => {
      const [prevCustomerId] = inputs;
      if (prevCustomerId && prevCustomerId !== fields.customer_id) {
        setSelectedContact(null);
        setFields({
          "contact.id": null,
          "contact.first_name": "",
          "contact.last_name": "",
          "contact.email": "",
          "contact.phone": "",
        });
        setAddingNewContact(false);
      }
    },
    [fields.customer_id]
  );

  // check for existing contact on changing contact fields
  useEffect(() => {
    if (
      contacts.data?.data.length === 1 &&
      !existingContact &&
      ((fields["contact.first_name"] && fields["contact.last_name"]) ||
        fields["contact.phone"] ||
        fields["contact.email"])
    ) {
      setExistingContact(contacts.data?.data[0]);
      // todo // these 4 lines make contact unremovable, needs a different solution for whatever it is supposed to do
      // } else if (contacts.data?.data.length === 1) {
      //   handleSelectContact(contacts.data?.data[0]);
      // } else if (existingContact) {
      //   setExistingContact(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contacts.data?.data,
    existingContact,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields["contact.first_name"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields["contact.last_name"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields["contact.phone"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields["contact.email"],
  ]);

  const handleSelectContact = (contact?: Contact) => {
    if (contact) setSelectedContact(contact);
    setFields({
      "contact.id": contact?.id ?? "",
      "contact.first_name": contact?.first_name ?? "",
      "contact.last_name": contact?.last_name ?? "",
      "contact.email": contact?.email ?? "",
      "contact.phone": contact?.phone ?? "",
    });
    resetValidation([
      "contact.first_name",
      "contact.last_name",
      "contact.email",
      "contact.phone",
    ]);
    if (!contact) setSelectedContact(null);
    setAddingNewContact(false);
  };

  const confirmUsageOfExistingContact = (closeAlert = true) => {
    if (existingContact) {
      handleSelectContact(existingContact);
      if (closeAlert) {
        setExistingContact(null);
      }
    }
  };

  const declineUsageOfExistingContact = () => setExistingContact(null);

  const alertMessage = () => {
    if (
      existingContact?.email ===
        fields["contact.email"]?.trim().toLowerCase() ||
      existingContact?.phone === fields["contact.phone"]?.trim().toLowerCase()
    ) {
      confirmUsageOfExistingContact(false);
      return `Found a contact with the same email or phone. It will be used.`;
    } else
      return `Found an existing contact: ${
        existingContact?.first_name +
        " " +
        existingContact?.last_name +
        ", " +
        existingContact?.email +
        ", " +
        existingContact?.phone
      }.\nDo you want to use it?`;
  };

  const hasContactError = () => {
    if (fields["contact.id"]) {
      return false;
    }
    return (
      hasErrors("contact.id") ||
      hasErrors("contact.first_name") ||
      hasErrors("contact.last_name") ||
      hasErrors("contact.email") ||
      hasErrors("contact.phone")
    );
  };

  const displayContactErrors = () => {
    if (hasContactError()) {
      return <span>Invalid contact.</span>;
    }
    return <></>;
  };

  return (
    <div data-cy="ticket-contact-section">
      {fields.customer_id && (
        <GridGroup>
          <Tooltip
            title="Contact can be searched for using the first name, last name, email address or phone number."
            placement="right"
          >
            <Grid item xs={12} sm={7} md={6}>
              <Autocomplete
                data-cy="contact-autocomplete"
                error={hasContactError()}
                helperText={displayContactErrors()}
                label="Select Contact"
                name="contact.id"
                placeholder="Type at least 3 characters to search a contact"
                loading={contacts?.isLoading}
                options={contacts.data?.data || []}
                value={
                  (contacts.data?.data || []).find(
                    (contact: Contact) => contact.id === fields["contact.id"]
                  ) || selectedContact
                }
                valueFrom="id"
                textFrom="full_name"
                onSelect={(contact: Contact) => handleSelectContact(contact)}
                onSearch={(query) =>
                  fields.customer_id && setContactInput(query)
                }
                onBlur={() => setContactInput("")}
              />
            </Grid>
          </Tooltip>
          <Grid item xs={12} sm={5} md={6}>
            {!fields["contact.id"] && (
              <Box>
                <Button
                  data-cy="add-contact-button"
                  color="primary"
                  size="medium"
                  onClick={() => setAddingNewContact(true)}
                  variant="text"
                  disabled={addingNewContact}
                >
                  Add New Contact
                </Button>
              </Box>
            )}
          </Grid>
        </GridGroup>
      )}{" "}
      {(fields["contact.id"] || addingNewContact) && (
        <GridGroup>
          <Grid item xs={12} md={6}>
            <TextField
              data-cy="contact-field-first-name"
              disabled={!!fields["contact.id"]}
              error={hasErrors("contact.first_name")}
              fullWidth
              helperText={displayErrors("contact.first_name")}
              label="First Name"
              name="contact.first_name"
              onChange={handleChange}
              required
              value={fields["contact.first_name"]}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              data-cy="contact-field-last-name"
              disabled={!!fields["contact.id"]}
              error={hasErrors("contact.last_name")}
              fullWidth
              helperText={displayErrors("contact.last_name")}
              label="Last Name"
              name="contact.last_name"
              onChange={handleChange}
              required
              value={fields["contact.last_name"]}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              data-cy="contact-field-email"
              disabled={!!fields["contact.id"]}
              error={hasErrors("contact.email")}
              fullWidth
              helperText={displayErrors("contact.email")}
              label="Email Address"
              name="contact.email"
              onChange={handleChange}
              required
              value={fields["contact.email"]}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              data-cy="contact-field-phone"
              disabled={!!fields["contact.id"]}
              error={hasErrors("contact.phone")}
              fullWidth
              helperText={displayErrors("contact.phone")}
              label="Phone"
              name="contact.phone"
              onChange={handleChange}
              required
              value={fields["contact.phone"]}
              variant="outlined"
              size="small"
            />
          </Grid>
          {!!existingContact && addingNewContact && (
            <Grid item xs={12}>
              <AlertWithButtons
                message={alertMessage()}
                onConfirm={confirmUsageOfExistingContact}
                onCancel={declineUsageOfExistingContact}
                showButtons={!fields["contact.id"]}
                hideCancel={
                  !fields["contact.first_name"] ||
                  !fields["contact.last_name"] ||
                  !fields["contact.email"] ||
                  !fields["contact.phone"]
                }
              />
            </Grid>
          )}
        </GridGroup>
      )}
    </div>
  );
};

export default ContactSection;
