import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Paper } from "@material-ui/core";
import Main from "../../../layout/Main";
import Attachments from "./components/Attachments";
import Details from "./components/Details";
import Info from "./components/Info";
import Menu from "./Menu";
import Reply from "./Reply";
import { LooseObject, Message } from "../../../types";
import { useMessageDetail } from "../../../hooks/messages";

export default function Component() {
  const [showReply, toggleReply] = useState(() => false);
  const { id } = useParams<LooseObject>();
  const message = useMessageDetail(parseInt(id), {
    onSuccess: () => toggleReply(false),
  });

  return (
    <Main
      error={message?.isError ? "Can't fetch message details." : undefined}
      title={
        message.data?.data?.is_open ? (
          `Message #${message.data?.data?.id ?? ""}`
        ) : (
          <>
            Message <del>{message.data?.data?.id}</del>{" "}
            <small>- {message.data?.data?.closure_reason?.name}</small>
          </>
        )
      }
      loading={message.isLoading || !message.data?.data?.id}
      menu={
        <Menu
          message={message.data?.data}
          toggleReply={() => toggleReply(!showReply)}
        />
      }
    >
      {message.data?.data && (
        <Paper elevation={4} style={{ width: "100%" }}>
          <Box m={1}>
            {showReply && (
              <>
                <Reply message={message.data?.data} />

                <Box mt={1} mb={1}>
                  <Divider />
                </Box>
              </>
            )}

            <Info message={message.data?.data} />

            <Box mt={1} mb={1}>
              <Divider />
            </Box>

            <Details message={message.data?.data} />

            {message.data?.data?.attachments?.length > 0 && (
              <Attachments attachments={message.data?.data?.attachments} />
            )}

            {message.data?.data?.replies?.map((reply: Message) => (
              <React.Fragment key={reply.id}>
                <Box mt={1} mb={1}>
                  <Divider />
                </Box>

                <Box mb={1}>
                  <Info message={reply} reply />
                </Box>

                <Details message={reply} />

                {reply.attachments?.length > 0 && (
                  <Attachments attachments={reply.attachments} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Paper>
      )}
    </Main>
  );
}
