import { UseQueryResult } from "react-query";
import { ValidationError } from "../../../api";
import {
  AuditTag,
  BreachReason,
  Category,
  ClosureReason,
  Contact,
  Customer,
  Escalator,
  Priority,
  Role,
  SortDirection,
  Stage,
  Status,
  Subscriber,
  Subscription,
  System,
  Tag,
  Team,
  Ticket,
  Type,
  User,
  Visit,
} from "../../../types";

export interface AutocompleteComponentProps {
  value: any;
  onSelect: (selected: any) => void;
  [x: string]: any;
}

export interface DashboardCardItem {
  color: string;
  data?: DashboardCardItem[];
  name: string;
  query: Partial<Query>;
  value?: number;
}

export interface DashboardCardItems {
  data: DashboardCardItem[];
}

export interface AuditTagsData {
  data: AuditTag[];
}
export interface BreachReasonData {
  data: BreachReason[];
}

export interface CategoriesData {
  data: Category[];
}

export interface ClosureReasonData {
  data: ClosureReason[];
}

export interface ContactsData {
  data: Contact[];
}

export interface CustomersData {
  data: Customer[];
}

export interface EscalatorsData {
  data: Escalator[];
}

export interface PrioritiesData {
  data: Priority[];
}

export type PrioritiesQueryType = UseQueryResult<
  PrioritiesData,
  Error | ValidationError
>;

export interface RolesData {
  data: Role[];
}

export interface StagesData {
  data: Stage[];
}

export interface StatusesData {
  data: Status[];
}

export interface SubscriberData {
  data: Subscriber[];
}

export interface SubscriptionData {
  data: Subscription[];
}

export interface SystemsData {
  data: System[];
}

export interface TagsData {
  data: Tag[];
}

export interface TeamsData {
  data: Team[];
}

export interface TicketData {
  data: Ticket;
}

export interface TicketsData {
  data: Ticket[];
}

export interface TypesData {
  data: Type[];
}

export interface UsersData {
  data: User[];
}

export interface VisitsData {
  data: Visit[];
}

export type UsersQueryType = UseQueryResult<UsersData, Error>;

export interface Query {
  category_ids?: Array<string | null> | null;
  child_id?: number | null;
  customer_ids?: Array<number | null> | null;
  identifiers?: Array<string | null> | null;
  is_escalated?: number | null;
  is_in_SLA?: number | null;
  is_in_response_SLA?: number | null;
  is_open?: number | null;
  is_watched_by_auth_user?: number | null;
  keyword?: string | null;
  opened_after?: string | null;
  opened_before?: string | null;
  opened_by_ids?: Array<number | null> | null;
  is_opened_by_system?: number | null;
  parent_id?: number | null;
  priority_ids?: Array<string | null> | null;
  resolved_before?: string | null;
  resolved_after?: string | null;
  short_descriptions?: Array<string | null> | null;
  status_ids?: Array<string | null> | null;
  stage_ids?: Array<string | null> | null;
  system_ids?: Array<string | null> | null;
  type_ids?: Array<number | null> | null;
  tag_ids?: Array<string | null> | null;
  user_id?: number | null;
  created_after?: string | null;
  created_before?: string | null;
  range?: string | null;
  team_ids?: Array<string | null> | null;
  user_ids?: Array<string | null> | null;
  view_id?: number | null;
  filter_id?: number | null;
  contact_ids?: Array<string | null> | null;
  subscriber_ids?: Array<string | null> | null;
  escalated_by_ids?: Array<string | null> | null;

  sort?: string;
  direction: SortDirection;
  limit?: number;
  page: number;
}

export const SortDirectionQueryParam = {
  encode: (value: SortDirection) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SortDirection,
};

export type QueryItem = keyof Partial<Query>;

export enum ViewType {
  table = "table",
  kanban = "kanban",
}

const viewTypes = Object.values(ViewType);
export type ViewTypeType = typeof viewTypes[number];

export const ViewTypeQueryParam = {
  encode: (value: ViewTypeType) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as ViewTypeType,
};
