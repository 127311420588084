import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "react-query";
import { useHistory } from "react-router-dom";
import { ValidationError } from "../api";
import * as api from "../api/tickets";
import * as attachmentsApi from "../api/tickets/attachments";
import {
  Query,
  SubscriptionData,
  TicketData,
  VisitsData,
} from "../screens/Tickets/types";
import { LooseObject, QueryData, Ticket, TicketFields } from "../types";

export function useAddAssociation(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "associations",
    ]);
    queryClient.invalidateQueries(["tickets", ticketId, "associations"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id, children }) => api.addAssociation(id, children), {
    ...options,
    onSuccess,
  });
}

export function useAddTag(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId, "details"]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "tags",
    ]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((name) => api.addTag(ticketId, name), {
    ...options,
    onSuccess,
  });
}

export type TicketCreateMutationType = UseMutationResult<
  Ticket,
  Error | ValidationError,
  Partial<TicketFields>
>;

export function useCreate(options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const history = useHistory();

  const onSuccess = (data: TicketData, variables: any) => {
    queryClient.setQueryData(["tickets", data.data.id, "details"], data);
    queryClient.invalidateQueries(["new-ticket"]);
    history.push(`/support/tickets/${data.data.id}`);
  };

  return useMutation<TicketData, Error, Partial<TicketFields>>(
    (fields) => api.store(fields),
    {
      ...options,
      onSuccess,
      // todo onError
    }
  );
}

export function useEscalate(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: TicketData) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);
    if (typeof options.onSuccess === "function") {
      options.onSuccess(data);
    }
  };

  return useMutation(
    (reference?: string) => api.escalate(ticketId, reference),
    {
      ...options,
      onSuccess,
    }
  );
}

export function useDeescalate(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: TicketData) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);
    if (typeof options.onSuccess === "function") {
      options.onSuccess(data);
    }
  };

  return useMutation(() => api.deescalate(ticketId), {
    ...options,
    onSuccess,
  });
}

export function useExport(query: Query, options: LooseObject = {}) {
  const onSuccess = (data: any, variables: any) => {
    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(() => api.exportXLSX(query), {
    ...options,
    onSuccess,
  });
}

export function useMove(status_id: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "kanban-status", status_id]);
    queryClient.invalidateQueries([
      "tickets",
      "kanban-status",
      variables.old_status_id,
    ]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id, old_status_id }) => api.update(id, { status_id }), {
    ...options,
    onSuccess,
  });
}

export function usePaginate(query: Partial<Query>, options = {}) {
  return useQuery<QueryData<Ticket>, any>(
    ["tickets", "table", query],
    () => api.paginate(query),
    options
  );
}

export function usePaginateAssociations(
  ticketId: number,
  query: Partial<Query>,
  options = {}
) {
  return useQuery<QueryData<Ticket>, any>(
    ["tickets", ticketId, "associations", query],
    () => api.associations(ticketId, query),
    options
  );
}

export function usePaginateKanban(status_id: number, query: Partial<Query>) {
  return useQuery<Ticket, Error, any>(
    ["tickets", "kanban-status", status_id, query],
    () => api.paginateKanban(status_id, query)
  );
}

export function useRemoveAssociation(
  ticketId: number,
  options: LooseObject = {}
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "associations",
    ]);
    queryClient.invalidateQueries(["tickets", ticketId, "associations"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(
    ({ id, children }) => api.removeAssociation(id, children),
    {
      ...options,
      onSuccess,
    }
  );
}

export function useRemoveTag(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId, "details"]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "tags",
    ]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((id) => api.removeTag(id), {
    ...options,
    onSuccess,
  });
}

export function useStartPause(
  ticketId: number,
  params: object,
  options: LooseObject = {}
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId, "details"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id }) => api.start(id, params), {
    ...options,
    onSuccess,
  });
}

export function useStopPause(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId, "details"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id, fields }) => api.stop(id, fields), {
    ...options,
    onSuccess,
  });
}

export function useWatch(
  ticketId: number,
  options: LooseObject = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);
    options.onSuccess(data, variables);
  };

  return useMutation((params) => api.watch(ticketId, params), {
    ...options,
    onSuccess,
  });
}

export function useUnwatch(
  ticketId: number,
  options: LooseObject = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);
    options.onSuccess(data, variables);
  };

  return useMutation((params) => api.unwatch(ticketId, params), {
    ...options,
    onSuccess,
  });
}

export function useSubscribe(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);

    queryClient.invalidateQueries(["tickets", ticketId, "subscriptions"]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "subscribers",
      "subscriptions",
    ]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((params) => api.subscribe(ticketId, params), {
    ...options,
    onSuccess,
  });
}

export function useSubscriptions(id: number, options: LooseObject = {}) {
  return useQuery<SubscriptionData, Error>(
    ["tickets", id, "subscriptions"],
    () => api.subscriptions(id),
    options
  );
}

export function useTicketDetail(ticketId: number, options: LooseObject = {}) {
  return useQuery<TicketData, Error>(
    ["tickets", ticketId, "details"],
    () => api.show(ticketId),
    options
  );
}

export function useUnsubscribe(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);

    queryClient.invalidateQueries(["tickets", ticketId, "subscriptions"]);
    queryClient.invalidateQueries([
      "autocomplete",
      "tickets",
      ticketId,
      "subscribers",
      "subscriptions",
    ]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((params) => api.unsubscribe(ticketId, params), {
    ...options,
    onSuccess,
  });
}

export function useUpdate(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tickets", ticketId, "details"], data);

    if (variables.user_id) {
      queryClient.invalidateQueries(["tickets", ticketId, "subscriptions"]);
    }

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((params) => api.update(ticketId, params), {
    ...options,
    onSuccess,
  });
}

export function useUpload(ticketId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", ticketId]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id, params }) => attachmentsApi.upload(id, params), {
    ...options,
    onSuccess,
  });
}

export function useVisits(id: number, options: LooseObject = {}) {
  return useQuery<VisitsData, Error>(
    ["tickets", id, "visits"],
    () => api.visits(id),
    options
  );
}
