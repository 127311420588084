import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";

export interface IndexedTitleProps {
  index?: number | string;
  title: string;
  withMargin?: boolean;
  negative?: boolean;
}

export const IndexedTitle = ({
  index,
  title,
  withMargin,
  negative,
}: IndexedTitleProps) => {
  const classes = useStyles({ withMargin, negative });

  return (
    <Box className={classes.box}>
      {!!index && <Typography className={classes.index}>{index}</Typography>}
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};

const useStyles = makeStyles<
  Theme,
  { withMargin?: boolean; negative?: boolean }
>((theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    marginTop: ({ withMargin }) => (withMargin ? theme.spacing(2) : 0),
  },
  index: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: ({ negative }) =>
      negative ? theme.palette.primary.main : theme.palette.common.white,
    backgroundColor: ({ negative }) =>
      negative ? theme.palette.common.white : theme.palette.primary.main,
    border: ({ negative }) =>
      negative ? `1px solid ${theme.palette.primary.main}` : "none",
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: 12,
    height: 20,
    width: 20,
    marginRight: theme.spacing(0.5),
  },
  title: {
    fontWeight: "bold",
  },
}));
