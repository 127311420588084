import React from "react";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useTheme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

/** A green check mark icon to show successes */
export const CheckIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return <CheckCircle htmlColor={theme.palette.success.main} {...props} />;
};
