import React from "react";

import { Box, Typography } from "@material-ui/core/";

import { Message } from "../../../../types";

interface Props {
  message: Message;
}

export default function Component({ message }: Props) {
  return (
    <Box>
      <Typography data-cy="mailbox-message-title" paragraph variant="h4">
        {message.subject}
      </Typography>

      <Typography
        data-cy="mailbox-message-content"
        paragraph
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message.content }}
      />
    </Box>
  );
}
