import React, { useState } from "react";
import { UseMutateFunction } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "../../../../components/Autocomplete";
import { useDuplicate } from "../../../../hooks/tickets/filters";
import { Filter, Team } from "../../../../types";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useTeams } from "../../../../hooks/autocomplete";

interface Props {
  filter: Filter;
  allTeams: Team[];
  loading: boolean;
  update: UseMutateFunction<any, unknown, any, unknown>;
  remove: UseMutateFunction<any, unknown, any, unknown>;
  moveUp?: UseMutateFunction<any, unknown, any, unknown>;
  moveDown?: UseMutateFunction<any, unknown, any, unknown>;
  setFilter: (filter: Filter | undefined) => void;
  resetQuery: (filter?: Filter) => void;
  handleClose: () => void;
  appliedFilterId?: number;
}

function Component({
  filter,
  allTeams,
  loading,
  update,
  remove,
  moveUp,
  moveDown,
  setFilter,
  resetQuery,
  handleClose,
  appliedFilterId,
}: Props) {
  const [shared, setShared] = useState(!!filter.teams?.length);
  const sharedWithEveryone = filter.teams?.length === allTeams?.length;

  const [teamQuery, setTeamQuery] = useState("");
  const teams = useTeams({ query: teamQuery });

  const teamsValue = allTeams.filter((team: Team) =>
    (filter.teams as Team[])?.some((t: Team) => t.id === team?.id)
  );

  const duplicate = useDuplicate(filter.id);

  const handleShareWith = (teams: Team[]) => {
    update({
      filter: {
        ...filter,
        ...filter.properties,
        teams: [...(teams?.map((t) => t.id) ?? [])],
      },
    });
    setTeamQuery("");
  };

  const handleShareWithEveryone = () => {
    update({
      filter: {
        ...filter,
        ...filter.properties,
        teams: sharedWithEveryone
          ? []
          : [...(teams.data?.data || [])?.map((t) => t.id)],
      },
    });
  };

  const handleSwitchShared = (shared: boolean) => {
    setShared(shared);
    if (!shared && filter.teams?.length) {
      update({
        filter: {
          ...filter,
          ...filter.properties,
          teams: [],
        },
      });
    }
  };

  const handleSetDefault = () => {
    update({
      filter: {
        ...filter,
        ...filter.properties,
        is_default: true,
        teams: [...(filter.teams?.map((t) => t.id) ?? [])],
      },
    });
  };

  const handleDeleteFilter = () => {
    remove(filter);
    setFilter(undefined);
  };

  const handleApplyFilter = () => {
    setFilter(filter);
    resetQuery(filter);
    handleClose();
  };

  return (
    <>
      {(moveUp || moveDown) && (
        <Box>
          {moveUp && (
            <IconButton
              onClick={() => moveUp(filter)}
              color="primary"
              size="small"
              disabled={loading}
            >
              <ArrowUpwardIcon />
            </IconButton>
          )}
          {moveDown && (
            <IconButton
              onClick={() => moveDown(filter)}
              color="primary"
              size="small"
              disabled={loading}
            >
              <ArrowDownwardIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Typography
        noWrap
        variant="subtitle1"
        style={{
          height: "40px",
          lineHeight: "40px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {filter.name}
      </Typography>
      {!!filter.teams?.length || shared ? (
        <Box display="flex" width="100%">
          <Box width="80%">
            <Autocomplete
              loading={false}
              label="Shared With"
              name="shared_with"
              placeholder="Type to search"
              shrink
              textFrom="name"
              valueFrom="id"
              value={teamsValue}
              inputValue={teamQuery}
              options={teams.data?.data || []}
              onSearch={(query: string) => setTeamQuery(query)}
              onSelect={(teams: Team[]) => handleShareWith(teams)}
              onBlur={() => setTeamQuery("")}
              multiple
              disabled={loading}
              limitTags={3}
            />
          </Box>
          <Box pl={1} display="flex" alignSelf="center">
            <FormControlLabel
              control={
                <Checkbox
                  name="with_everyone"
                  onChange={handleShareWithEveryone}
                  checked={sharedWithEveryone}
                  disabled={loading}
                />
              }
              label="All Teams"
            />
          </Box>
        </Box>
      ) : (
        <div />
      )}

      <FormControlLabel
        control={
          <Switch
            onChange={(event) => handleSwitchShared(event.target.checked)}
            checked={!!filter.teams?.length || shared}
            size="small"
            color="primary"
            disabled={loading}
          />
        }
        label="Shared"
      />

      {filter.is_default ? (
        <Chip size="small" color="primary" label="Default" disabled={loading} />
      ) : (
        <Button
          color="primary"
          onClick={handleSetDefault}
          size="small"
          variant="text"
          disabled={loading}
        >
          <Typography variant="caption">Set Default</Typography>
        </Button>
      )}

      <IconButton
        color="primary"
        size="small"
        onClick={() => duplicate.mutate({})}
        disabled={loading}
      >
        <FileCopyIcon />
      </IconButton>

      <IconButton
        color="primary"
        size="small"
        onClick={handleDeleteFilter}
        disabled={loading}
      >
        <DeleteIcon />
      </IconButton>

      <Button
        color="primary"
        onClick={handleApplyFilter}
        size="small"
        variant="outlined"
        disabled={loading || filter.id === appliedFilterId}
      >
        {filter.id === appliedFilterId ? "Applied" : "Apply"}
      </Button>
    </>
  );
}

export default Component;
