import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";
import { Grid } from "@material-ui/core";
import Table from "./Table";
import CommentsHeader from "./Table/components/CommentsHeader";
import { constants } from "../../helpers";
import { CommentFields, SortDirectionQueryParam } from "../../types";
import { useCommentTypes } from "../../hooks/comments";

interface Props {
  owner: string;
}

export default function Comments({ owner = "" }: Props) {
  const queryClient = useQueryClient();
  const [showAddCommentForm, setShowAddCommentForm] = useState(false);
  const commentTypes = useCommentTypes();

  const [query, setQuery] = useQueryParams({
    type_id: NumberParam,
    sort: withDefault(StringParam, "created_at"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
    limit: withDefault(NumberParam, constants.limit.comments),
    page: withDefault(NumberParam, 1),
  });

  useEffect(() => {
    return function cleanup() {
      queryClient.invalidateQueries([`${owner}comments`]);
    };
  }, [owner, queryClient]);

  const getCommentType = useCallback(
    () =>
      commentTypes.data?.data.find((type) => !type.is_internal)?.id ||
      commentTypes.data?.data[0]?.id ||
      -1,
    [commentTypes.data?.data]
  );

  const initialState: CommentFields = {
    "files[]": [],
    message: "",
    send_to_children: false,
    type_id: getCommentType(),
  };

  const [fields, setCommentFields] = useState(initialState);
  const clearFields = () => setCommentFields(initialState);

  const setFields = (fields: Partial<CommentFields>) => {
    setCommentFields((oldFields: CommentFields) => ({
      ...oldFields,
      ...fields,
    }));
  };

  useEffect(() => {
    if (fields.type_id === -1 && commentTypes.data?.data?.length) {
      setFields({ type_id: getCommentType() });
    }
  }, [commentTypes.data?.data, fields.type_id, getCommentType]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CommentsHeader
          data-cy="comments-header"
          commentTypes={commentTypes}
          showAddCommentForm={showAddCommentForm}
          setShowAddCommentForm={setShowAddCommentForm}
          query={query}
          setQuery={setQuery}
          owner={owner}
          fields={fields}
          setFields={setFields}
          clearFields={clearFields}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          owner={owner}
          fields={fields}
          setFields={setFields}
          clearFields={clearFields}
          showAddCommentForm={showAddCommentForm}
          setShowAddCommentForm={setShowAddCommentForm}
          query={query}
        />
      </Grid>
    </Grid>
  );
}
