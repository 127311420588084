import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { FilterChip } from "../../../../helpers/hooks";
import { Query, QueryItem } from "../../types";
import { ArrayFilterValue } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersWrap: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignContent: "center",
      width: "100%",
      minHeight: theme.spacing(2),
      gap: "6px",
      padding: "3px",
      margin: theme.spacing(0.5),
      border: "1px solid",
      borderColor: theme?.palette?.grey?.[100],
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      listStyleType: "none",
    },
    chip: {
      maxWidth: "100%",
    },
  })
);

interface Props {
  chipKey: string;
  setQuery: (query: Partial<Query>) => void;
  query?: Partial<Query>;
  formatter: Function;
}

function Component({ chipKey, query, setQuery, formatter }: Props) {
  const classes = useStyles();
  let chips: FilterChip[] = [];

  if (formatter) {
    chips = formatter();
  }

  const handleRemoveChip = (chip: FilterChip) => {
    if (Array.isArray(query?.[chipKey as QueryItem])) {
      setQuery({
        [chip.key as QueryItem]: (
          query?.[chipKey as QueryItem] as ArrayFilterValue
        )?.filter((val: string) => val !== chip.valueId.toString()),
      } as Partial<Query>);
    } else setQuery({ [chip.key as QueryItem]: undefined } as Partial<Query>);
  };

  return (
    <>
      {chips?.map((chip: FilterChip) => (
        <li className={classes.chip} key={`${chip.name}-${chip.id}`}>
          <Chip
            variant="outlined"
            size="small"
            label={`${chip.name}: ${chip.valueName}`}
            onDelete={() => handleRemoveChip(chip)}
            className={classes.chip}
          />
        </li>
      ))}
    </>
  );
}

export default Component;
