import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "../../../../components/Dialog";
import { useCreate, useUpdate } from "../../../../hooks/tickets/filters";
import { Filter } from "../../../../types";
import { Query } from "../../types";
import { ValidationError } from "../../../../api";

interface Props {
  query: Partial<Query>;
  filter: Filter;
  setFilter: (filter?: Filter) => void;
  open: boolean;
  setOpen: (shouldOpen: boolean) => void;
}

function Component({ query, filter, setFilter, open, setOpen }: Props) {
  const [savedFilterData, setSavedFilterData] = useState({
    name: "",
    is_default: false,
  });
  const onSuccess = ({ data }: { data: Filter }) => {
    setFilter(data);
    setOpen(false);
  };
  const create = useCreate({ onSuccess });
  const update = useUpdate({ onSuccess });

  const validation =
    create?.error instanceof ValidationError
      ? create?.error?.validation
      : update?.error instanceof ValidationError
      ? update?.error?.validation
      : null;

  useEffect(() => {
    if (!open)
      setSavedFilterData({
        name: filter?.name ?? "",
        is_default: filter?.is_default ?? false,
      });
  }, [open, filter]);

  useEffect(() => {
    if (savedFilterData?.name && validation) {
      create.reset();
      update.reset();
    }
  }, [savedFilterData?.name, create, update, validation]);

  const handleSaveFilter = () => {
    if (Object.keys(filter).length !== 0) {
      update.mutate({
        filter: {
          id: filter.id,
          properties: filter.properties,
          teams: filter.teams?.map((team) => team.id),
          ...savedFilterData,
        },
        query,
      });
    } else create.mutate({ filter: savedFilterData, query });
  };

  const hasErrors = (field: string) => {
    if (validation?.errors[field] && validation?.errors[field]?.length) {
      return true;
    }

    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {validation?.errors[field]?.map((error: string) => (
            <span key={error}>{error}</span>
          ))}
        </>
      );
    }

    return <></>;
  };

  return (
    <Dialog
      data-cy="save-filter-dialog"
      title="Save Filter"
      confirmText="Save"
      cancelText="Cancel"
      open={open}
      setOpen={setOpen}
      onConfirm={handleSaveFilter}
      onCancel={() => setOpen(false)}
      loading={create.isLoading || update.isLoading}
    >
      <Box pb={1}>
        <TextField
          data-cy="filter-name-field"
          error={hasErrors("name")}
          helperText={displayErrors("name")}
          fullWidth
          label="Filter Name"
          name="name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSavedFilterData({
              ...savedFilterData,
              name: event.target.value,
            });
          }}
          required
          value={savedFilterData?.name ?? filter?.name ?? ""}
          variant="outlined"
          size="small"
          disabled={create.isLoading || update.isLoading}
        />
      </Box>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              data-cy="is_default_checkbox"
              checked={
                savedFilterData.is_default ?? filter?.is_default ?? false
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSavedFilterData({
                  ...savedFilterData,
                  is_default: event.target.checked,
                });
              }}
              name="is_default"
              color="primary"
              size="small"
              disabled={create.isLoading || update.isLoading}
            />
          }
          label={<Typography variant="subtitle2">Default</Typography>}
        />
      </FormControl>
    </Dialog>
  );
}

export default Component;
