import React from "react";
import { useQueryClient, UseQueryResult } from "react-query";
import { Grid } from "@material-ui/core";
import Form from "../components/Form";
import { CommentFields } from "../../../types";
import { CommentTypesData } from "../types";
import { useCreate } from "../../../hooks/comments";
import { useTicketDetail } from "../../../hooks/tickets";

interface Props {
  owner: string;
  showAddCommentForm: boolean;
  setShowAddCommentForm: Function;
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
  commentTypes: UseQueryResult<CommentTypesData, Error>;
}

export default function Component({
  owner,
  showAddCommentForm,
  setShowAddCommentForm,
  fields,
  setFields,
  clearFields,
  commentTypes,
}: Props) {
  const { data } = useTicketDetail(parseInt(owner.split("/")[1]));
  const queryClient = useQueryClient();

  const create = useCreate(owner, {
    onSuccess: () => {
      setShowAddCommentForm(false);
      clearFields();
      if (!data?.data?.status?.is_open && data?.data?.status?.resolves_ticket) {
        queryClient.invalidateQueries(["tickets", data?.data?.id, "details"]);
      }
    },
  });

  const handleCancel = () => {
    setShowAddCommentForm(false);
    clearFields();
    create.reset();
  };

  return (
    <Grid container>
      {showAddCommentForm && (
        <Grid item xs={12}>
          <Form
            fields={fields}
            hasChildren={data?.data?.is_parent ?? false}
            submitButtonLabel={
              create.isLoading ? "Adding Comment" : "Add comment"
            }
            types={commentTypes}
            mutation={create}
            setFields={setFields}
            onSubmit={(fields: CommentFields) => create.mutate(fields)}
            onCancel={handleCancel}
          />
        </Grid>
      )}
    </Grid>
  );
}
