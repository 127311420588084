import { makeStyles, Theme, createStyles, Chip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Filter } from "./types";
import {
  formatCostCentre,
  formatCurrency,
  formatDateToMonthYear,
} from "./utils";

interface Props {
  filters: Filter[];
}

// Originally from packages/affinity-hub-fe/src/hooks/filter.ts
// TODO: Recompose this if we do the work to make the component fully generic in the DS
const filtersMap = {
  ACCOUNT: "1",
  USAGE_TYPE: "2",
  COST_CENTRE: "3",
  DATA_RANGE: "4",
  BILL_PERIOD: "5",
  SERVICES_BILL_PERIOD: "21",
  CALL_DURATION: "6",
  CLI: "7",
  DIALED_NUMBERS: "8",
  EXCLUDED_NUMBERS: "9",
  COST: "10",
  PRODUCT_TYPE: "11",
};

export const AddedFilters = ({ filters }: Props) => {
  const classes = useStyles();
  const [items, setItems] = useState<any[]>([]);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newItems: any[] = [];
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
      const filter = filters?.find(
        (filter: Filter) => filter.id === key && !filter.options.HIDDEN
      );
      const name = filter?.name;
      const valueDisplayFunc = filter?.valueDisplayFunc;
      newItems.push({
        key,
        name,
        value,
        valueDisplayFunc,
      });
    });
    setItems(newItems);
  }, [location, filters]);

  const deleteFilter = (item: any) => {
    queryParams.delete(item?.key);
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <ul className={classes.ctr} data-cy="added-filters">
      {items.map((item) => {
        let label: string;
        if (item.valueDisplayFunc) {
          item.value = item.valueDisplayFunc(item.value);
        }
        switch (item?.key) {
          case "10": // COST
          case "27": // COST
            let numbers: [number] = item?.value.match(/[+-]?\d+\.?(\d+)?/g);
            label = `${item?.name} - ${item?.value}`
              .split("_")
              ?.join(" ")
              .split(",")
              ?.join(" and ");
            numbers?.forEach((number) => {
              label = label.replace(String(number), formatCurrency(number));
            });
            label = label.replace("equal", "equals");
            break;
          case filtersMap.BILL_PERIOD: // PERIOD
          case filtersMap.SERVICES_BILL_PERIOD: // PERIOD
          case "70": // PERIOD
            let billPeriods = item?.value.split(",");
            let billPeriodsTitle = `${item?.name} - `;
            let billPeriodsLabels: string[] = [];
            billPeriods.forEach((period: string) => {
              if (label !== "UNBILLED") {
                billPeriodsLabels.push(
                  formatDateToMonthYear(period) !== undefined
                    ? String(formatDateToMonthYear(period))
                    : period
                );
              } else {
                billPeriodsLabels.push(period);
              }
            });
            label = billPeriodsTitle + billPeriodsLabels?.join(", ");
            break;
          case "6": // TIME
            label = `${item?.name} - ${item?.value}`
              .split("_")
              ?.join(" ")
              .split(",")
              ?.join(" and ");
            label = label.replace("equal", "equals");
            break;
          case "4": // DATE
            label = `${item?.name} - ${item?.value}`.split(",")?.join(" to ");
            break;
          case "3": // COST CENTER (Unassigned)
          case "23": // COST CENTER (Unassigned)
          case "34": // COST CENTER (Unassigned)
          case "44": // COST CENTER (Unassigned)
          case "54": // COST CENTER (Unassigned)
            let costCenters = item?.value.split(",");
            let costCentersTitle = `${item?.name} - `;
            let costCentersLabels: string[] = [];
            costCenters.forEach((cc: string) => {
              if (cc !== "false") {
                costCentersLabels.push(formatCostCentre(cc));
              } else {
                costCentersLabels.push("Unassigned");
              }
            });
            label = costCentersTitle + costCentersLabels?.join(", ");
            break;
          default:
            label = `${item?.name} - ${item?.value}`.split("_")?.join(" ");
        }
        if (!item?.name) return null;
        return (
          <li className={classes.chip} key={item?.key}>
            <Chip
              variant="outlined"
              size="small"
              label={label}
              onDelete={() => deleteFilter(item)}
              className={classes.chip}
              data-cy={`${"added-filter-" + item?.key}`}
            />
          </li>
        );
      })}
    </ul>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      minHeight: "32px",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "6px",
      width: "100%",
      listStyleType: "none",
      padding: "3px",
      marginLeft: "0px",
      marginRight: "0px",
      border: "1px solid",
      borderColor: theme.palette.grey[100],
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.spacing(2),
      alignContent: "center",
    },
    chip: {
      maxWidth: "100%",
    },
  })
);
