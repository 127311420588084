import React from "react";
import queryString from "query-string";

import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { DashboardCardItem, Query } from "../../types";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold",
    fontSize: 45,
  },
  legend: {
    display: "inline-block",
    width: "9rem",
    [theme.breakpoints.up("sm")]: {
      width: "13rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "9rem",
    },
    [theme.breakpoints.up("lg")]: {
      width: "13rem",
    },
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  percent: {
    color: theme.palette.action.disabled,
  },
  root: {
    width: "100%",
    height: 160,

    "& .legend-item-4": {
      display: "none!important",
    },
  },
}));

interface Props {
  data: DashboardCardItem;
  filter: Partial<Query>;
}

function Component({ data, filter }: Props) {
  const classes = useStyles();

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { payload } = entry;

    return (
      <div className={classes.legend}>
        <Link
          className={classes.link}
          to={
            "/support/tickets?" +
            queryString.stringify({ ...filter, ...payload.query })
          }
        >
          {value}
        </Link>
        <div style={{ float: "right", display: "inline-block" }}>
          <span className={classes.percent}>
            ({((payload.percent ?? 0) * 100).toFixed(2)}%)
          </span>{" "}
          <b>{payload.value}</b>
        </div>
      </div>
    );
  };

  let rows = data.data?.slice() ?? [];

  const foundNonZeroValue = rows.find((item) => (item?.value ?? 0) > 0);

  if (!foundNonZeroValue) {
    rows.push({ color: "#989898", query: {}, name: "Other", value: 1 });
  }

  return (
    <div data-cy="donut" className={classes.root}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={rows}
            innerRadius={50}
            outerRadius={80}
            dataKey="value"
            nameKey="name"
          >
            {rows.map((item: DashboardCardItem) => (
              <Cell key={`cell-${item.name}`} fill={item.color} stroke="none" />
            ))}
            <Label className={classes.label} width={50} position="center">
              {data.data
                ?.map((item: DashboardCardItem) => item.value)
                ?.reduce(
                  (value: number | undefined, current: number | undefined) =>
                    (value ?? 0) + (current ?? 0),
                  0
                )}
            </Label>
          </Pie>
          <Legend
            align="right"
            iconSize={8}
            iconType="circle"
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={{ lineHeight: "30px" }}
            formatter={renderColorfulLegendText}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Component;
