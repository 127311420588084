import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import InboxIcon from "@material-ui/icons/Inbox";
import ReplyIcon from "@material-ui/icons/Reply";
import UndoIcon from "@material-ui/icons/Undo";
import Autocomplete from "../../../../components/Autocomplete";
import { ClosureReason, Message } from "../../../../types";
import { useClosureReasons, useUpdate } from "../../../../hooks/messages";

interface Props {
  message?: Message;
  toggleReply: () => void;
}

export default function Component({ message, toggleReply }: Props) {
  const [closureReasonQuery, setClosureReasonQuery] = useState("");
  const closure_reasons = useClosureReasons(closureReasonQuery);
  const update = useUpdate(message?.id);

  const [shouldCaptureClosureReason, captureClosureReason] = useState(
    () => false
  );

  return message ? (
    <Box display="flex">
      {message.is_open && !shouldCaptureClosureReason && (
        <Box mr={1}>
          <Button
            data-cy="mailbox-close-button"
            color="primary"
            onClick={() => captureClosureReason(true)}
            size="medium"
            startIcon={<CancelIcon />}
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      )}

      {message.is_open && shouldCaptureClosureReason && (
        <Box mr={1} style={{ minWidth: 200 }}>
          <Autocomplete
            data-cy="mailbox-reason-autocomplete"
            disableClearable
            label="Reason for Closure"
            loading={closure_reasons?.isLoading}
            onSearch={(query) => setClosureReasonQuery(query)}
            onSelect={(reason: ClosureReason) =>
              reason &&
              update.mutate({
                id: message.id,
                params: {
                  is_open: 0,
                  closure_reason_id: reason.id,
                },
              })
            }
            options={closure_reasons.data?.data || []}
            placeholder="Type to search"
            value={null}
            valueFrom="id"
            textFrom="name"
          />
        </Box>
      )}

      {!message.is_open && (
        <Box mr={1}>
          <Button
            data-cy="mailbox-reopen-button"
            color="primary"
            onClick={() =>
              update.mutate({ id: message.id, params: { is_open: 1 } })
            }
            size="medium"
            startIcon={<UndoIcon />}
            variant="outlined"
          >
            Reopen
          </Button>
        </Box>
      )}

      <Box mr={1}>
        <Button
          data-cy="mailbox-reply-button"
          color="primary"
          onClick={() => toggleReply()}
          size="medium"
          startIcon={<ReplyIcon />}
          variant="outlined"
        >
          Reply
        </Button>
      </Box>

      {message.is_open && (
        <Box>
          <Button
            data-cy="mailbox-convert-button"
            color="primary"
            component={Link}
            size="medium"
            to={`/support/new-ticket?message_id=${message.id}`}
            startIcon={<InboxIcon />}
            variant="contained"
          >
            Convert to Ticket
          </Button>
        </Box>
      )}
    </Box>
  ) : null;
}
