import { ReportCategories, ReportCategory } from "../screens/Reports/types";

export const limit = {
  audits: 10,
  comments: 10,
  kanban: 10,
  kanbanMaxItems: 100,
  reports: 10,
  tasks: 10,
  tickets: 100,
  events: 10,
  timers: 10,
  users: 10,
} as const;

export const dashboardDateRanges = [
  {
    id: "24h",
    label: "Last 24 hours",
  },
  {
    id: "1wk",
    label: "This Week",
  },
  {
    id: "1mo",
    label: "This Month",
  },
  {
    id: "1yr",
    label: "This Year",
  },
] as const;

export const sortDirections = ["asc", "desc", "none"] as const;

export enum PermissionCodes {
  all = "*",
  admin = "admin",

  commentsCreateInternal = "comments.create.internal",
  commentsViewInternal = "comments.viewInternal",
  commentsCreateChildRelated = "comments.create.child.related",

  commitmentsPausesCreate = "commitments.pauses.create",

  eventsViewAny = "events.viewAny",
  eventsView = "events.view",
  eventsCreate = "events.create",
  eventsUpdate = "events.update",
  eventsDelete = "events.delete",

  messagesView = "messages.view",
  messagesViewAny = "messages.viewAny",

  tasksViewAny = "tasks.viewAny",
  tasksView = "tasks.view",
  tasksCreate = "tasks.create",
  tasksUpdate = "tasks.update",
  tasksDelete = "tasks.delete",

  ticketsViewAny = "tickets.viewAny",
  ticketsView = "tickets.view",
  ticketsViewAssignedTeam = "tickets.view.team",
  ticketsViewAssignedUser = "tickets.view.user",
  ticketsCreate = "tickets.create",
  ticketsUpdate = "tickets.update",
  ticketsDelete = "tickets.delete",
  ticketsReopen = "tickets.reopen", // ✓
  ticketsViewOnlyTeam = "tickets.viewOnly.team",
  ticketsViewOnlyCreator = "tickets.viewOnly.creator",
  ticketsUpdateStatus = "tickets.update.status", // ✓
  ticketsViewAnyStage = "tickets.stage.viewAny", // ✓
  ticketsUpdateStage = "tickets.stage.update", // ✓
  ticketsUpdateTeam = "tickets.update.team", // ✓
  ticketsUpdateUser = "tickets.update.user", // ✓
  ticketsUpdatePriority = "tickets.update.priority", // ✓
  ticketsUpdateParent = "tickets.update.parent",
  ticketsUpdateChildren = "tickets.update.children",
  ticketsUpdateTags = "tickets.update.tags",
  ticketsHistory = "tickets.history",
  ticketsSubscribeAgents = "tickets.subscribe.agents",
  ticketsUnsubscribeEveryone = "tickets.unsubscribe.everyone",
  ticketsUnsubscribeOthers = "tickets.unsubscribe.others",
  ticketsUnsubscribeMe = "tickets.unsubscribe.me",
  ticketsCommentsCreate = "tickets.comments.create",
  ticketsAttachmentsCreate = "tickets.attachments.create",
  ticketsDashboard = "tickets.dashboard",
  ticketsTagsViewAny = "tickets.tags.viewAny",
  ticketsTagsCreate = "tickets.tags.create",
  ticketsTagsDelete = "tickets.tags.delete",
  ticketsTimersViewAny = "tickets.timers.viewAny",
  ticketsTimersCreate = "tickets.timers.create",
  ticketsTimersEdit = "tickets.timers.edit",
  ticketsTimersDelete = "tickets.timers.delete",
  ticketsEscalatedView = "tickets.escalated.view", // ✓
  ticketsEscalatedUpdate = "tickets.escalated.update", // ✓
  ticketsEscalatedDelete = "tickets.escalated.delete", // ✓
  ticketsReportsView = "tickets.reports.view", // ✓
  // ticketsVisitsView = "tickets.visits.view", // todo
  ticketsKanbanView = "tickets.kanban.viewAny",

  timersView = "timers.view",
  timersUpdate = "timers.update",
  timersDelete = "timers.delete",

  slaCalendarViewAny = "slaCalendars.viewAny",
  slaCalendarView = "slaCalendars.view",
  slaCalendarCreate = "slaCalendars.create",
  slaCalendarUpdate = "slaCalendars.update",
  slaCalendarDelete = "slaCalendars.delete",

  filtersViewAny = "filters.viewAny",
  filtersViewSelf = "filters.viewSelf",
  filtersCreate = "filters.create",
  filtersUpdate = "filters.update",
  filtersDelete = "filters.delete",
  filtersShare = "filters.share",

  knowledgeBaseViewAny = "knowledgeBase.viewAny",

  usersUpdate = "users.update",

  customer = "customer",
}

export const ticketsReports: ReportCategory[] = [
  {
    id: ReportCategories.reportsDailyBacklog,
    name: "Daily backlog",
    description: "Tickets daily backlog report",
  },
  {
    id: ReportCategories.reportsOpenedBySeverity,
    name: "Opened tickets by severity",
    description: "Opened tickets by severity",
  },
  {
    id: ReportCategories.reportsOpenedPerWeekDay,
    name: "Average opened tickets per day of the week",
    description: "Average opened tickets per day of the week",
  },
  {
    id: ReportCategories.reportsTimeToResolve,
    name: "Average time to resolve per month",
    description: "Average time to resolve per month",
  },
  {
    id: ReportCategories.reportsPercentOfBreachedTickets,
    name: "Percent of breached tickets",
    description: "Percent of breached tickets",
  },
  {
    id: ReportCategories.reportsTicketsByStatus,
    name: "Opened tickets by status",
    description: "Opened tickets by status",
  },
  {
    id: ReportCategories.reportsTicketsByStage,
    name: "Opened tickets by stage",
    description: "Opened tickets by stage",
  },
  {
    id: ReportCategories.reportsRaisedTicketsPerMonth,
    name: "Raised tickets per month",
    description: "Raised tickets per month",
  },
  {
    id: ReportCategories.reportsActiveEscalations,
    name: "Number of active escalations",
    description: "Number of active escalations",
  },
  {
    id: ReportCategories.reportsEscalationsPerMonth,
    name: "Number of escalations per month",
    description: "Number of escalations per month",
  },
];
