import React, { useState } from "react";
import { Alert } from "@akj-dev/design-system";
import { Badge, Box, Button, Tooltip, Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FileItem from "./FileItem";

const FILE_SIZE_LIMIT_B = 200000000;
const FILE_SIZE_LIMIT_MB = FILE_SIZE_LIMIT_B / 1000000;

interface Props {
  label?: string;
  multiple?: boolean;
  name?: string;
  value: Array<File>;
  variant?: "text" | "outlined" | "contained";
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    files: Array<File>
  ) => void;
  setFiles: (files: File[]) => void;
  resetError: () => void;
  uploading?: boolean;
  disableAdding?: boolean;
}

function Component({
  label,
  onChange,
  multiple,
  name,
  value,
  variant,
  setFiles,
  resetError,
  uploading = false,
  disableAdding = false,
}: Props) {
  const [filesTooBig, setFilesTooBig] = useState<Array<string>>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetError();

    let addedFiles = value ? [...value] : [];
    let filesTooBig: string[] = [];

    if (event.target?.files) {
      for (let i = 0; i < event.target.files?.length; ++i) {
        const file = event.target.files.item(i);
        if (file) {
          if (file.size <= FILE_SIZE_LIMIT_B) {
            addedFiles.push(file);
          } else {
            filesTooBig.push(file?.name);
          }
        }
      }
    }

    onChange(event, addedFiles);
    setFilesTooBig(filesTooBig);
  };

  const Preview = () => {
    if (!value?.length) {
      return <AttachFileIcon />;
    }

    const title = (
      <Typography variant="body2">
        {`${value?.length} file${value?.length > 1 ? "s" : ""} attached`}
      </Typography>
    );

    return (
      <Tooltip title={title}>
        <Badge badgeContent={value?.length} color="primary">
          <AttachFileIcon />
        </Badge>
      </Tooltip>
    );
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...value];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    resetError();
  };

  return (
    <div data-cy="file-upload-section">
      <Box mr={1.5} mb={1.5} display="inline-block">
        <Box mb={0.5}>
          <Button
            data-cy="file-upload-button"
            color="primary"
            component="label"
            size="medium"
            startIcon={<Preview />}
            variant={variant || "text"}
            disabled={uploading || disableAdding}
          >
            {label || "Attach files"}
            <input
              hidden
              multiple={multiple}
              name={name}
              onChange={handleChange}
              type="file"
              onClick={(event) => {
                (event.target as HTMLInputElement).value = "";
              }}
            />
          </Button>
        </Box>
        <Box pl={0.5}>
          <Typography
            variant="caption"
            color="textSecondary"
          >{`Max. file size ${FILE_SIZE_LIMIT_MB} MB`}</Typography>
        </Box>
      </Box>
      {filesTooBig?.length > 0 && (
        <div
          style={{
            overflowWrap: "anywhere",
            marginBottom: "12px",
            marginTop: "8px",
          }}
        >
          <Alert
            message={`${
              filesTooBig?.length > 1
                ? "These files exceed"
                : "This file exceeds"
            } the size limit and can't be uploaded: ${filesTooBig.join(", ")}`}
            type="error"
          />
        </div>
      )}

      {value?.map((file, index) => (
        <FileItem
          key={`file-upload-item-${file.name}`}
          file={file}
          index={index}
          handleRemoveFile={handleRemoveFile}
          uploading={uploading}
        />
      ))}
    </div>
  );
}

export default Component;
