import React from "react";
import { useParams } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { ButtonGroup, Grid } from "@material-ui/core";
import Main from "../../../layout/Main";
import Table from "./components/Table";
import { constants } from "../../../helpers";
import { useReports } from "../../../hooks/tickets/reports";
import { ReportCategory, ReportsRouteParams } from "../types";
import { ticketsReports } from "../../../helpers/constants";
import ClickPopover from "../../../components/ClickPopover";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters, { Query } from "./components/Filters";

type MenuProps = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
  isLoading: boolean;
};

const Menu = ({ query, setQuery, resetQuery, isLoading }: MenuProps) => {
  return (
    <ButtonGroup size="small" variant="outlined">
      <ClickPopover trigger={<FilterListIcon />}>
        <div style={{ width: 368, padding: "24px 24px 36px 24px" }}>
          <Filters
            query={query}
            resetQuery={resetQuery}
            setQuery={setQuery}
            isLoading={isLoading}
          />
        </div>
      </ClickPopover>
    </ButtonGroup>
  );
};

export default function Component() {
  const [query, setQuery] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
    limit: withDefault(NumberParam, constants.limit.reports),
    page: withDefault(NumberParam, 1),
  });

  const { id } = useParams<ReportsRouteParams>();
  const tableData = useReports(id, query);
  const title =
    ticketsReports.find((r: ReportCategory) => r.id === id)?.name || "";
  const period = tableData?.data?.period;
  const titleWithPeriod = `${title} from ${period?.from} to ${period?.to}`;

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  return (
    <Main
      error=""
      title={title}
      menu={
        period ? (
          <Menu
            query={query}
            setQuery={setQuery}
            resetQuery={resetQuery}
            isLoading={tableData.isLoading}
          />
        ) : undefined
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            id={id}
            reportTitle={period ? titleWithPeriod : title}
            tableData={tableData.data?.data || []}
            loading={tableData.isLoading}
            query={query}
            setQuery={setQuery}
          />
        </Grid>
      </Grid>
    </Main>
  );
}
