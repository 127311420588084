import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import { Commitment } from "../../../../../types";
import CommitmentComponent from "./components/Commitment";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1.5, 0),
  },
}));

interface Props {
  commitments: Array<Commitment>;
}

export default function Component({ commitments }: Props) {
  const classes = useStyles();

  const shouldCountUp = (commitment: Commitment): boolean =>
    commitment.is_breached && !commitment.resolved_at && !commitment.is_paused;

  const shouldCountDown = (commitment: Commitment): boolean =>
    !commitment.is_breached && !commitment.resolved_at && !commitment.is_paused;

  return (
    <>
      {commitments.map((commitment: Commitment) => (
        <div key={commitment.id}>
          <Divider className={classes.divider} />
          <CommitmentComponent
            commitment={commitment}
            shouldCountUp={shouldCountUp(commitment)}
            shouldCountDown={shouldCountDown(commitment)}
          />
        </div>
      ))}
    </>
  );
}
