import React from "react";
import { Alert } from "@akj-dev/design-system";

interface MultilineAlertProps {
  message: string;
}

function MultilineAlert({ message }: MultilineAlertProps) {
  const newText = message
    .split("\n")
    .map((line) => <div key={line}>{line}</div>);

  return <Alert message={newText} type="info" />;
}

export default MultilineAlert;
