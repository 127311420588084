import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Main from "../../layout/Main";
import Breadcrumbs from "./components/Breadcrumbs";
import TopLevelGrid from "./TopLevelGrid";
import LowerLevelGrid from "./LowerLevelGrid";
import {
  usePaginateArticles,
  usePaginateCategories,
} from "../../hooks/knowledgebase";
import { Breadcrumb, Category } from "./types";

interface Props {
  topLevel?: boolean;
}

function KnowledgeBase({ topLevel = false }: Props) {
  let { params } = useRouteMatch();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const allCategories = usePaginateCategories();
  const categories = usePaginateCategories(selectedCategory);
  const articles = usePaginateArticles(selectedCategory, {
    enabled: selectedCategory !== 0,
  });

  const generateBreadcrumbs = () => {
    const urlBase = "/support/knowledge-base";
    const breadcrumbs: Breadcrumb[] = [
      {
        id: 0,
        title: "Home",
        link: "/support/knowledge-base",
      } as Breadcrumb,
    ];
    if (Object.keys(params).length === 0) return breadcrumbs;

    const parsedParams = (params as { 0: any })[0].split("/");

    let updatedUrl = `${urlBase}`;
    parsedParams.map((p: string, index: number) => {
      if (
        index === parsedParams.length - 1 &&
        selectedCategory !== parseInt(p)
      ) {
        setSelectedCategory(parseInt(p));
      }
      breadcrumbs.push({
        id: parseInt(p),
        title: `${
          allCategories.data?.data.find(
            (category: Category) => category.id === parseInt(p)
          )?.name ?? ""
        }`,
        link: `${updatedUrl}/${p}`,
      } as Breadcrumb);
      updatedUrl = updatedUrl + `/${p}`;
      return p;
    });
    return breadcrumbs;
  };

  const handleCategoryClick = (id: number) => setSelectedCategory(id);

  return (
    <Main error="" title="Knowledge Base">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs data={generateBreadcrumbs()} />
        </Grid>
        <Grid item xs={12}>
          {topLevel && (
            <TopLevelGrid
              categories={categories?.data?.data || []}
              handleCategoryClick={handleCategoryClick}
              loading={categories.isLoading || articles.isLoading}
            />
          )}
          {!topLevel && (
            <LowerLevelGrid
              categories={categories?.data?.data || []}
              article={articles?.data?.data}
              handleCategoryClick={handleCategoryClick}
              loading={categories.isLoading || articles.isLoading}
            />
          )}
        </Grid>
      </Grid>
    </Main>
  );
}

export default KnowledgeBase;
