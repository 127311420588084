import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Filter } from "../types";
import TextField from "@material-ui/core/TextField";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function TextFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueSelection = value.split(",");
    setSelection(valueSelection);
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <div className={classes.filterBody}>
          <TextField
            variant="outlined"
            size="small"
            name="phoneNumber"
            value={selection}
            onChange={handleInput}
            placeholder="Comma separated values"
            fullWidth
          />
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBody: {
      paddingInline: theme.spacing(2),
    },
  })
);
