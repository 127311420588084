import { useQuery } from "react-query";
import * as api from "../api/audits";
import { Query } from "../screens/Audits/types";
import { Audit, QueryData } from "../types";

export function usePaginate(
  owner?: string,
  query: Partial<Query> = {},
  options = {}
) {
  return useQuery<QueryData<Audit>, Error>(
    ["audits", owner, query],
    () => api.paginate(owner, query),
    options
  );
}
