import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { formattedDateTime } from "../../../../helpers/functions";
import { CommentChange, CommentVersion } from "../../types";

const useStyles = makeStyles<Theme>((theme) => ({
  marginRight: {
    marginRight: theme.spacing(0.5),
  },
}));

interface Props {
  comment: CommentVersion;
}

function Component({ comment }: Props) {
  const classes = useStyles();

  return (
    <Box pb={3}>
      <Box display="flex" alignItems="baseline">
        <Typography variant="h3" className={classes.marginRight}>
          {comment.user.full_name}
        </Typography>
        <Typography variant="subtitle2">
          {formattedDateTime(comment.created_at, "dd/MM/yyyy HH:mm")}
        </Typography>
      </Box>
      {comment.changes.map((change: CommentChange) => (
        <Typography
          key={change.comment}
          dangerouslySetInnerHTML={{ __html: change.comment }}
          variant="body1"
        />
      ))}
    </Box>
  );
}

export default Component;
