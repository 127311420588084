import React, { useState } from "react";
import Autocomplete from "../../../../components/Autocomplete";
import { useUsers } from "../../../../hooks/autocomplete";
import { useDebounce } from "../../../../helpers/hooks";
import { User } from "../../../../types";
import { Query } from "../../types";

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ query, setQuery }: Props) {
  const [fetchUsers, setFetchUsers] = useState(false);
  const [userInput, setUserInput] = useState("");
  const debouncedUserInput = useDebounce(userInput);
  const users = useUsers(
    { query: debouncedUserInput },
    { enabled: fetchUsers }
  );

  const value =
    users.data?.data.find((user: User) => user.id === query?.user_id) || null;

  return (
    <Autocomplete
      data-cy="audits-user-filter-autocomplete"
      options={users.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="full_name"
      name="user_id"
      label="Select User"
      placeholder="Type to search"
      onSearch={(query: string) => setUserInput(query)}
      onSelect={(user: User) => setQuery({ user_id: user?.id })}
      onOpen={() => setFetchUsers(true)}
      onBlur={() => setUserInput("")}
      loading={users?.isLoading}
      shrink
    />
  );
}

export default Component;
