import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Main from "../../../layout/Main";
import CardItems from "./components/CardItems";
import CardItemPreview from "./components/CardItemPreview";
import RangeSelector from "./components/RangeSelector";
import Filters from "../Filters";
import { useSearch as useFilterSearch } from "../../../hooks/tickets/filters";
import { hasPermission } from "../../../helpers/functions";
import { PermissionCodes } from "../../../helpers/constants";
import {
  applyFilter,
  useAppliedFilter,
  useFiltersQuery,
} from "../../../helpers/hooks";
import { useSearch } from "../../../hooks/tickets/dashboard";
import { DashboardCardItem } from "../types";
import { Filter } from "../../../types";
import { Helmet } from "react-helmet";
import { Alert } from "@akj-dev/design-system";

export default function Dashboard() {
  const filters = useFilterSearch(
    {},
    { enabled: hasPermission(PermissionCodes.filtersViewAny) }
  );

  const { query, setQuery, resetQuery } = useFiltersQuery({
    range: "1mo",
  });
  const appliedFilter = useAppliedFilter(filters.data?.data);
  const [filter, setFilter] = useState<Filter | undefined>(appliedFilter);

  useEffect(() => {
    setFilter(appliedFilter);
  }, [appliedFilter, setFilter]);

  useEffect(() => {
    setQuery(applyFilter({ range: "1mo" }, filter));
  }, [filter, setQuery]);

  const dashboard = useSearch(query);
  const canViewDashboard = hasPermission(PermissionCodes.ticketsDashboard);

  if (!canViewDashboard) {
    return (
      <Typography>You do not have permission to view this page</Typography>
    );
  }

  return (
    <>
      <Helmet>
        <title>Affinity | Support | Dashboard</title>
      </Helmet>
      <Main
        title="Support"
        menu={
          <RangeSelector
            data-cy="dashboard-range-selector"
            selected={query.range || "1mo"}
            setRange={(range) => setQuery({ range })}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" pt={1}>
              {hasPermission(PermissionCodes.ticketsCreate) && (
                <Button
                  data-cy="dashboard-new-ticket-button"
                  color="primary"
                  component={Link}
                  size="medium"
                  to="/support/new-ticket"
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  New Ticket
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box pt={1}>
              <Filters
                filter={filter}
                setFilter={setFilter}
                query={query}
                setQuery={(query) => {
                  setFilter(undefined);
                  setQuery({ ...query, filter_id: undefined });
                }}
                resetQuery={resetQuery}
              />
            </Box>
          </Grid>

          {dashboard.isError && (
            <Grid item xs={12}>
              <Alert
                message={
                  "There was a problem fetching dashboard stats. Please try again."
                }
                type="error"
              />
            </Grid>
          )}

          {(dashboard.isLoading || dashboard.isError) && (
            <Grid item xs={12}>
              <CardItemPreview />
            </Grid>
          )}

          {!dashboard.isLoading &&
            dashboard.data?.data?.map((item: DashboardCardItem) => {
              return (
                <CardItems
                  data-cy={`dashboard-card-${item.name}`}
                  key={`card-item-${item.name}-${item.value}`}
                  item={item}
                  query={query}
                />
              );
            })}
        </Grid>
      </Main>
    </>
  );
}
