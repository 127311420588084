import { useMutation, useQuery, useQueryClient } from "react-query";
import * as ticketsApi from "../../api/tickets";
import * as api from "../../api/filters";
import { Filter, LooseObject } from "../../types";

export function useCreate(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ filter, query }) => api.create(filter, query), {
    ...options,
    onSuccess,
  });
}

export function useDuplicate(id: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(() => api.duplicate(id), {
    ...options,
    onSuccess,
  });
}

export function useRemove(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((filter) => api.remove(filter.id), {
    ...options,
    onSuccess,
  });
}

export function useMoveUp(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((filter) => api.moveUp(filter.id), {
    ...options,
    onSuccess,
  });
}

export function useMoveDown(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((filter) => api.moveDown(filter.id), {
    ...options,
    onSuccess,
  });
}

export function useSearch(params = {}, options = {}) {
  return useQuery(
    ["tickets", "filters", params],
    () => ticketsApi.filters(params),
    options
  );
}

export function useUpdate(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: { data: Filter }, variables: any) => {
    queryClient.invalidateQueries(["tickets", "filters"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ filter, query }) => api.update(filter, query), {
    ...options,
    onSuccess,
  });
}
