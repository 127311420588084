import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete from "../../../../components/Autocomplete";
import { dateOrNull } from "../../../../helpers/functions";
import { useDebounce } from "../../../../helpers/hooks";
import { useStatuses } from "../../../../hooks/tasks";
import { useUsers } from "../../../../hooks/autocomplete";
import { LooseObject, Status, User } from "../../../../types";

const Switch = ({
  actions,
  embedded,
  fields,
}: {
  actions: React.ReactNode;
  embedded: boolean;
  fields: React.ReactNode;
}) => {
  if (!embedded) {
    return (
      <Card variant="outlined">
        <CardContent>{fields}</CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    );
  }

  return (
    <>
      {fields}
      {actions}
    </>
  );
};

interface Props {
  embedded?: boolean;
  fields: LooseObject;
  setFields: (fields: LooseObject) => void;
  onSubmit: (fields: LooseObject) => void;
  loading: boolean;
  validation: LooseObject;
}

interface RouteParams {
  id: string;
}

function Component({
  embedded = false,
  fields,
  setFields,
  onSubmit,
  loading,
  validation = { errors: {} },
}: Props) {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const [statusInput, setStatusInput] = useState("");
  const debouncedStatusInput = useDebounce(statusInput, 600);
  const statuses = useStatuses(debouncedStatusInput);
  const [userInput, setUserInput] = useState("");
  const debouncedUserInput = useDebounce(userInput, 600);
  const users = useUsers({ query: debouncedUserInput });

  const [formValidation, setValidation] = useState(validation);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value &&
      formValidation?.errors[event.target.name]?.length
    ) {
      resetValidation([event.target.name]);
    }
    setFields({
      [event.target.name]: event.target.value,
    });
  };

  const hasErrors = (field: string) => {
    if (formValidation?.errors[field]) {
      return true;
    }

    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {formValidation?.errors[field].map((error: string) => (
            <span key={error}>{error}</span>
          ))}
        </>
      );
    }

    return <></>;
  };

  const resetValidation = (fields: string[]) => {
    const updatedErrors = { ...formValidation?.errors };
    for (const field of fields) {
      updatedErrors[field] = null;
    }
    setValidation({
      ...formValidation,
      errors: { ...updatedErrors },
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit(fields);
  };

  const status =
    (statuses.data?.data || []).find(
      (status: Status) => status.id === fields.status_id
    ) || null;

  return (
    <form
      data-cy="tasks-form"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Switch
        embedded={embedded}
        fields={
          <Grid container spacing={1}>
            {embedded && (
              <Grid item xs={12}>
                <Typography variant="h4">Create New Task</Typography>
              </Grid>
            )}

            <Grid item xs={12} md={9}>
              <TextField
                data-cy="task-subject-field"
                error={hasErrors("name")}
                fullWidth
                helperText={displayErrors("name")}
                label="Task Subject"
                name="name"
                onChange={handleChange}
                required
                value={fields.name}
                variant="outlined"
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField
                data-cy="task-description-field"
                error={hasErrors("description")}
                fullWidth
                helperText={displayErrors("description")}
                label="Task Description"
                name="description"
                onChange={handleChange}
                required
                multiline
                rows={5}
                value={fields.description}
                variant="outlined"
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    data-cy="task-status-autocomplete"
                    error={hasErrors("status_id")}
                    helperText={displayErrors("status_id")}
                    label="Set Status"
                    loading={statuses.isLoading}
                    name="status_id"
                    onSearch={(query: string) => setStatusInput(query)}
                    onSelect={(selected: Status) => {
                      resetValidation(["status_id"]);
                      setFields({ status_id: selected ? selected.id : "" });
                    }}
                    options={statuses.data?.data || []}
                    placeholder="Type at least 3 characters to search a status"
                    value={status}
                    valueFrom="id"
                    textFrom="name"
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    data-cy="task-user-autocomplete"
                    error={hasErrors("user_id")}
                    helperText={displayErrors("user_id")}
                    label="Assign User"
                    loading={users?.isLoading}
                    name="user_id"
                    onSearch={(query: string) => setUserInput(query)}
                    onSelect={(selected: User) =>
                      setFields({ user_id: selected ? selected.id : "" })
                    }
                    options={users.data?.data || []}
                    placeholder="Type at least 3 characters to search an user"
                    value={
                      users.data?.data.find(
                        (user: User) => user.id === fields.user_id
                      ) || null
                    }
                    valueFrom="id"
                    textFrom="full_name"
                  />
                </Grid>

                {status && status.is_open === false && (
                  <Grid item xs={12}>
                    <DatePicker
                      data-cy="task-done-at-date-picker"
                      autoOk
                      disableToolbar
                      error={hasErrors("done_at")}
                      format="dd/MM/yyy"
                      fullWidth
                      helperText={displayErrors("done_at")}
                      inputVariant="outlined"
                      label="Date Completed"
                      margin="none"
                      name="done_at"
                      onChange={(date) =>
                        setFields({
                          done_at: format(date, "yyy-MM-dd"),
                        })
                      }
                      size="small"
                      value={dateOrNull(fields.done_at ?? "")}
                      variant="inline"
                      disableFuture
                    />
                  </Grid>
                )}

                {fields?.status_id !== 4 && (
                  <Grid item xs={12}>
                    <DatePicker
                      data-cy="task-due-date-picker"
                      autoOk
                      disableToolbar
                      error={hasErrors("due_date")}
                      format="dd/MM/yyy"
                      fullWidth
                      helperText={displayErrors("due_date")}
                      inputVariant="outlined"
                      label="Due Date"
                      margin="none"
                      name="due_date"
                      onChange={(date) =>
                        setFields({
                          due_date: format(date, "yyy-MM-dd"),
                        })
                      }
                      size="small"
                      value={dateOrNull(fields.due_date ?? "")}
                      variant="inline"
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      initialFocusedDate={new Date().setDate(
                        new Date().getDate() + 1
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        actions={
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={2}
            mb={embedded ? 0 : 1}
            ml={embedded ? 0 : 0.5}
          >
            <Box pr={0.5}>
              <Button
                data-cy="create-task-button"
                color="primary"
                disabled={loading}
                size="medium"
                startIcon={
                  loading ? <CircularProgress size={20} /> : <DoneIcon />
                }
                type="submit"
                variant="contained"
              >
                Create Task
              </Button>
            </Box>
            <Button
              data-cy="cancel-task-button"
              color="primary"
              disabled={loading}
              size="medium"
              startIcon={<ClearIcon />}
              variant="outlined"
              onClick={() => history.replace(`/support/tickets/${id}/tasks`)}
            >
              Cancel
            </Button>
          </Box>
        }
      />
    </form>
  );
}

export default Component;
