import { useQuery, UseQueryResult } from "react-query";
import { ValidationError } from "../../api";
import * as api from "../../api/messages";
import { Ticket } from "../../types";

type ConvertFromMessageDataType = {
  data: Ticket;
};

export type ConvertFromMessageType = UseQueryResult<
  ConvertFromMessageDataType,
  Error | ValidationError
>;

export function useConvertFromMessage(
  id?: number | null,
  options = {
    onSuccess: ({ data }: ConvertFromMessageDataType) => {},
  }
) {
  return useQuery<
    ConvertFromMessageDataType | undefined,
    Error | ValidationError,
    any
  >(["messages", id, "convert"], () => api.convert(id), {
    ...options,
    enabled: !!id,
  });
}
