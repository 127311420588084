import { UseQueryResult } from "react-query";
import { constants } from "../helpers";

export interface Audit {
  id: number;
  user: User;
  created_at: string;
  action: string;
  changes: Array<any>;
}

export interface AuditTag {
  value: string;
  label: string;
}

export interface Attachment {
  created_at: string;
  id: number;
  is_deleted?: boolean;
  name: string;
  type: string;
  preview_url: string;
  url: string;
}

export type Autocomplete<T> = {
  data: Array<T>;
  error: string;
  loading: boolean;
};

export interface AutocompleteParams {
  query?: string;
  ids?: Array<number | string | null> | null;
  limit?: number | string;
  [key: string]: any;
}
export interface BasicDataType {
  id: number;
  name: string;
  reference: string;
  [key: string]: any;
}

export interface BreachReason extends BasicDataType {}

export interface ClosureReason extends BasicDataType {}

export interface Category extends BasicDataType {}

export interface Column {
  accessor: string;
  checked?: boolean;
  id: number;
  label: string;
  name: string;
  order: number;
  relation: string;
  sort_by: string;
  type: string;
}

export interface Comment {
  attachments: Array<Attachment>;
  can_remove_attachments?: boolean;
  created_at: string;
  creator: User;
  date_entered: string;
  depth: number;
  descendants: Comment[];
  id: number;
  message: string;
  type: CommentTypeOption;
  updated_at: string;
  can_be_edited: boolean;
  was_edited: boolean;
}

export interface CommentTypeOption {
  id: number;
  is_editable?: boolean;
  is_internal?: boolean;
  name: string;
}

export interface CommentFields {
  "files[]": any[];
  id?: number;
  message: string;
  send_to_children: boolean;
  type_id: number;
  parent_id?: number;
}

export interface Contact {
  customer_id: number;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export interface Customer extends BasicDataType {
  number: string;
}

export interface Escalator {
  email: string;
  full_name: string;
  reference: string;
  roles: string[];
}

export interface LooseObject {
  [key: string]: any;
}

export interface Message {
  attachments: Array<Attachment>;
  closure_reason: ClosureReason;
  content: string;
  created_at: string;
  from: string;
  id: number;
  is_open: boolean;
  replies: Array<Message>;
  subject: string;
}

export interface PaginatorMeta {
  total: number;
  columns: Column[];
}

export type Paginator<T> = {
  data: Array<T>;
  error: string;
  loading: boolean;
  meta: PaginatorMeta;
};

export type QueryData<T> = {
  data: Array<T>;
  links: Array<Object>;
  meta: QueryDataMeta;
};

export type QueryNonArrayData<T> = {
  data: T;
  links: Array<Object>;
  meta: QueryDataMeta;
};

export type QueryResult<T> = UseQueryResult<T, Error>;

type QueryDataMeta = {
  columns?: Column[];
  current_page: number;
  from: number;
  last_page: number;
  links: Array<Object>;
  path: string;
  per_page: string;
  to: number;
  total: number;
};

export interface Priority extends BasicDataType {
  description: string;
  color: string;
}

export type RootState = LooseObject; // ReturnType<typeof reducer>;

export interface Commitment {
  id: number;
  name: string;
  status: string;
  type: string;
  alert_at: string;
  expected_at: string;
  resolved_at: string;
  is_breached: boolean;
  is_paused: boolean;
  can_be_paused: boolean;
  time_paused_human_readable: string;
  breach_reason: BreachReason;
  seconds_running: number;
  seconds_paused: number;
  ticket_id: number;
  percentage: number;
}

export interface SLACalendar extends BasicDataType {
  description: string;
}

export interface SLACalendarContract extends BasicDataType {
  name: string;
  sla_calendar?: SLACalendar;
}

export type SortDirection = typeof constants.sortDirections[number];

export const SortDirectionQueryParam = {
  encode: (value: SortDirection) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SortDirection,
};

export interface Status extends BasicDataType {
  is_open: boolean;
  resolves_ticket: boolean;
  order: number;
}

export interface Stage extends BasicDataType {}

export interface Subscriber {
  reference: string;
  full_name: string;
  email: string;
  label?: string;
  roles: Array<string>;
  name_with_email: string;
}

export interface Subscription {
  id: number;
  subscriber: Subscriber;
}

export interface System extends BasicDataType {}

export interface Tag extends BasicDataType {
  label?: string;
  error: boolean;
  removing: boolean;
}

export interface Task extends BasicDataType {
  description: string;
  created_at: string;
  due_date: string;
  done_at: string;
  status: Status;
  user: User;
  time_logged_human_readable: string;
  is_overdue: boolean;
}

export interface TaskFields {
  description: string;
  done_at?: string;
  due_date?: string;
  name: string;
  status_id: number | null;
  user_id?: number | null;
}

export interface Team extends BasicDataType {
  personal_team: boolean;
  user_id: number;
}

interface ActiveResolutionCommitment {
  breach_reason: string | null;
  can_be_paused: boolean;
  expected_at: string;
  id: number;
  is_breached: boolean;
  is_paused: boolean;
  name: string;
  percentage: number;
  resolved_at: string;
  seconds_paused: number;
  seconds_running: number;
  status: string;
  ticket_id: number;
  time_paused_human_readable: string;
  type: string;
  sla_calendar_contract?: SLACalendarContract;
}

interface Escalation {
  email: string;
  escalated_at: string;
  full_name: string;
}

export interface Ticket {
  attachments: Array<Attachment>;
  active_resolution_commitment: ActiveResolutionCommitment;
  active_resolution_commitment_percentage: number;
  can_be_child: boolean;
  can_be_parent: boolean;
  can_remove_attachments?: boolean;
  category: Category;
  closure_reason: ClosureReason;
  commitments: Array<Commitment>;
  contact: Contact;
  created_at: string;
  creator: User;
  customer: Customer;
  customer_reference: string;
  date_entered: string;
  date_modified: string;
  date_resolved: string;
  description: string;
  description_as_html: string;
  escalation: Escalation | null;
  id: number;
  identifier: string;
  is_breach_reason_id_required: boolean;
  is_child: boolean;
  is_in_s_l_a: boolean;
  is_in_response_SLA: boolean;
  is_open: number;
  is_parent: boolean;
  is_s_l_a_paused: boolean;
  is_watched_by_auth_user: boolean;
  messages: Array<Message>;
  opened_at: string;
  priority: Priority;
  resolution_date: string;
  s_l_a_calendar: SLACalendar;
  short_description: string;
  sla_calendar: SLACalendar;
  stage: Stage;
  status: Status;
  subscribed: boolean;
  subtype: string;
  system: System;
  tags: Array<Tag>;
  team: Team;
  time_logged_human_readable: string;
  total_paused_seconds: number;
  type: Type;
  updated_at: string;
  user: User;
  watched: boolean;
  subscriptions: Subscription[];
}

export interface TicketFields {
  breach_reason_id?: number;
  category_id?: number | null;
  children?: Array<number>;
  closure_reason_id?: number;
  customer_id?: number | null;
  customer_reference?: string;
  description?: string;
  message_id?: number;
  parent_id?: number | null;
  priority_id?: number | null;
  short_description?: string;
  stage_id?: number;
  status_id?: number;
  system_id?: number | null;
  team_id?: number | null;
  type_id?: number | null;
  user_id?: number | null;

  "contact.email"?: string;
  "contact.first_name"?: string;
  "contact.id"?: number | null;
  "contact.last_name"?: string;
  "contact.phone"?: string;

  "files[]"?: Array<File>;
}

export interface View extends BasicDataType {
  type: string;
  is_default: boolean;
  columns: Column[];
}

// todo types
export interface EventType {
  alert: string; // ?
  asset: string; // ?
  asset_name: string;
  data_point: string; // ?
  duration: number; // ?
  ended_at: string; // ?
  external_reference: string; // ?
  id: number; // ?
  level: EventLevel;
  message: string;
  processed_at: string;
  rule: string; // ?
  started_at: string;
  status: EventStatus;
  threshold: string; // ?
}

export interface EventLevel extends BasicDataType {
  color: string;
}

interface EventStatus extends BasicDataType {}

export interface Permission extends BasicDataType {}

export interface Role extends BasicDataType {
  label: string;
}

export interface System extends BasicDataType {}

export interface Timer {
  id: number;
  message: string;
  started_at: string;
  ended_at: string;
  minutes: number;
  time_logged_human_readable: string;
  is_billable: boolean;
  owner: Ticket;
  pauses: Array<TimerPause>;
  user: User;
}

export interface TimerData {
  data: Timer;
}

export interface TimerFields {
  hours: string | number;
  minutes: string | number;
  message: string;
  is_billable: boolean;
  started_at: string;
}

export interface TimerPause {
  id: number;
  type: string;
  started_at: string;
  ended_at: string;
  minutes: number;
  time_paused_human_readable: string;
}

export enum TimerType {
  ticket = "TICKET",
  task = "TASK",
}

export interface Type extends BasicDataType {
  abbreviation: string;
  description: string;
}

export interface User {
  full_name: string | null;
  email: string | null;
  id: number;
  phone: string | null;
  customers?: Customer[];
  roles?: Role[];
  permissions?: Permission[];
  teams?: Team[];
}

export interface Visit {
  id: number;
  first_visit: string;
  last_visit: string;
  user: User;
  image?: string;
}

export interface Filter extends BasicDataType {
  is_default?: boolean;
  is_public?: boolean;
  is_shared_with_me?: boolean;
  properties?: FilterProperty[];
  teams?: Team[];
}

export interface FilterProperty {
  key: string;
  value: string;
  ticket_filter_id?: number;
}

export interface Query {
  limit?: number;
  page: number;
  sort?: string;
  direction?: SortDirection;
}
