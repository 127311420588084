import React from "react";
import {
  makeStyles,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export interface SearchFieldProps {
  /** The data attribute used for targeting this component in Cypress */
  "data-cy"?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler;
  loading?: boolean;
  placeholder?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  inputRoot: {
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
  },
  go: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  },
}));

/** A combined search input */
export const SearchField = ({
  "data-cy": dataCy,
  value,
  onChange,
  onClick,
  loading,
  placeholder = "What are you looking for?",
}: SearchFieldProps) => {
  const classes = useStyles();
  // Note: label prop doesn't do the animation thing when combined with startAdornment
  // Hence placeholder used here.
  return (
    <div className={classes.root}>
      <FormControl fullWidth variant="outlined">
        {/* <InputLabel htmlFor="standard-adornment-amount">*/}
        {/*  What are you looking for?*/}
        {/* </InputLabel>*/}
        <OutlinedInput
          placeholder={placeholder}
          id="standard-adornment-amount"
          value={value}
          onChange={onChange}
          // labelWidth={172}
          className={classes.inputRoot}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.go}
        onClick={onClick}
        disabled={loading}
      >
        {loading ? <CircularProgress color="inherit" size="1rem" /> : "Go"}
      </Button>
    </div>
  );
};
