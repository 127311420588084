import { get, multiPartPost, post, put } from "./";

export function create(owner: string, params: any) {
  if (params["files[]"]?.length) {
    return multiPartPost(`${owner}comments`, params);
  }

  return post(`${owner}comments`, params);
}

export function comments(owner: string, params = { page: 1 }) {
  return get(`${owner}comments`, params);
}

export function commentHistory(id: number | string, params: any) {
  return get(`comments/${id}/history`, params);
}

export function types({ query = "", limit = 10 }) {
  return get("comments/types", {
    query,
    limit,
  });
}

export function update(owner: number | string | undefined, params: any) {
  if (params["files[]"]?.length) {
    return multiPartPost(`comments/${owner}`, { ...params, _method: "PUT" });
  }

  return put(`comments/${owner}`, params);
}
