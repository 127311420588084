import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQueryParam, withDefault } from "use-query-params";
import { Alert } from "@akj-dev/design-system";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Fade,
  Grid,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Apps as AppsIcon,
  List as ListIcon,
  GetApp as DownloadIcon,
} from "@material-ui/icons";
import Main from "../../layout/Main";
import Loading from "../../layout/Loading";
import SearchBar from "../../components/SearchBar";
import Kanban from "./Kanban";
import FilterSection from "./Filters";
import Table from "./Table";
import { hasPermission } from "../../helpers/functions";
import { PermissionCodes } from "../../helpers/constants";
import {
  applyFilter,
  useAppliedFilter,
  useFiltersQuery,
} from "../../helpers/hooks";
import { useExport, usePaginate } from "../../hooks/tickets";
import { useSearch } from "../../hooks/tickets/filters";
import { ViewType, ViewTypeQueryParam, ViewTypeType } from "./types";
import { Filter } from "../../types";
import { Helmet } from "react-helmet";

interface MenuProps {
  view: ViewType;
  setView: (view: ViewType) => void;
}

function Menu({ view, setView }: MenuProps) {
  return (
    <ButtonGroup size="small" variant="outlined">
      <Button
        disabled={view === "table"}
        onClick={() => setView(ViewType.table)}
      >
        <ListIcon />
      </Button>
      <Button
        disabled={view === "kanban"}
        onClick={() => setView(ViewType.kanban)}
      >
        <AppsIcon />
      </Button>
    </ButtonGroup>
  );
}

interface PageProps {
  filters: any;
}

function Page({ filters }: PageProps) {
  const [view, setView] = useQueryParam<ViewTypeType>(
    "view",
    withDefault(ViewTypeQueryParam, ViewType.table)
  );

  const { query, setQuery, resetQuery } = useFiltersQuery();
  const appliedFilter = useAppliedFilter(filters.data?.data);
  const [filter, setFilter] = useState<Filter | undefined>(appliedFilter);

  useEffect(() => {
    setFilter(appliedFilter);
  }, [appliedFilter, setFilter]);

  // update query when filter is changed
  useEffect(() => {
    setQuery(applyFilter({}, filter));
  }, [filter, setQuery]);

  const { isLoading } = usePaginate(query);

  const [showDownloadAlert, setShowDownloadAlert] = useState(false);

  const exportXLSX = useExport(query, {
    onSuccess: () => {
      setShowDownloadAlert(true);
      window.setTimeout(() => setShowDownloadAlert(false), 3000);
    },
  });

  const handleClick = () => {
    exportXLSX.mutate({});
  };

  return (
    <>
      <Helmet>
        <title>
          Affinity | Support | Tickets{filter ? ` | ${filter.name}` : ""}
        </title>
      </Helmet>
      <Main
        error=""
        title="Tickets"
        menu={
          hasPermission(PermissionCodes.ticketsKanbanView) && (
            <Menu view={view} setView={setView} />
          )
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={5} lg={4}>
                <Box pt={1}>
                  <SearchBar
                    value={query.keyword}
                    placeholder="Search Tickets"
                    loading={isLoading}
                    onSearch={(keyword) => setQuery({ keyword })}
                    onClear={resetQuery}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <Box display="flex" justifyContent="flex-end" pt={1}>
                  <Box pr={1}>
                    <Button
                      data-cy="export-tickets-button"
                      color="primary"
                      size="medium"
                      startIcon={
                        exportXLSX.isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <DownloadIcon />
                        )
                      }
                      variant="outlined"
                      onClick={handleClick}
                      disabled={exportXLSX.isLoading}
                    >
                      {exportXLSX.isLoading
                        ? "Downloading XLSX"
                        : "Download XLSX"}
                    </Button>
                  </Box>
                  {hasPermission(PermissionCodes.ticketsCreate) && (
                    <Box>
                      <Button
                        data-cy="tickets-new-ticket-button"
                        color="primary"
                        component={Link}
                        size="medium"
                        to="/support/new-ticket"
                        startIcon={<AddIcon />}
                        variant="contained"
                      >
                        New Ticket
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Fade in={showDownloadAlert} unmountOnExit timeout={1000}>
              <Grid item xs={12}>
                <Box pt={1}>
                  <Alert
                    type="info"
                    message="Once the report is ready, it will be sent to your email."
                  />
                </Box>
              </Grid>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <Box pt={1}>
              <FilterSection
                filter={filter}
                view={view}
                setFilter={setFilter}
                query={query}
                setQuery={(query) => {
                  setFilter(undefined);
                  setQuery({ ...query, filter_id: undefined });
                }}
                resetQuery={resetQuery}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={view !== "table" ? { padding: "16px 0" } : {}}
          >
            {view === "table" && hasPermission(PermissionCodes.ticketsView) && (
              <Table query={query} setQuery={setQuery} />
            )}
            {view !== "table" && hasPermission(PermissionCodes.ticketsView) && (
              <Kanban query={query} />
            )}
          </Grid>
        </Grid>
      </Main>
    </>
  );
}

function Tickets() {
  const filters = useSearch(
    {},
    {
      enabled: hasPermission(PermissionCodes.filtersViewAny),
    }
  );

  if (filters.isLoading) {
    return <Loading loading={true} />;
  }

  return <Page filters={filters} />;
}

export default Tickets;
