import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import { Question } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    card: {
      "&:hover": {
        boxShadow: theme.shadows[12],
      },
      padding: "8px 0",
    },
    cardContent: {
      paddingTop: "24px",
    },
    button: {
      width: "100%",
      display: "block",
    },
    icon: {
      margin: "0 18px 0 8px",
    },
  })
);

type Props = {
  question: Question;
};

function Component({ question }: Props) {
  const classes = useStyles();

  return (
    <a
      data-cy={`knowledge-base-question-item-${question.id}`}
      target="_blank"
      rel="noreferrer"
      href={question.url}
      className={classes.link}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <DescriptionIcon className={classes.icon} />
            <Box height="40px" display="flex" alignItems="center">
              <Typography variant="h4">{question.name}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </a>
  );
}

export default Component;
