import React from "react";
import { DatePicker } from "@material-ui/pickers";

interface Props {
  label: string;
  name: string;
  value: Date | null;
  disabled: boolean;
  onChange: (date: Date) => void;
}

function Component({ label, name, value, disabled, onChange }: Props) {
  return (
    <DatePicker
      data-cy="audits-date-filter-picker"
      label={label}
      name={name}
      format="dd/MM/yyy"
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoOk
      fullWidth
      disableToolbar
      size="small"
      variant="inline"
      inputVariant="outlined"
      margin="none"
    />
  );
}

export default Component;
