import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AvailableFilters from "./components/AvailableFilters";
import ClickPopover from "../../../components/ClickPopover";
import AppliedFilters from "./components/AppliedFilters";
import SavedFilters from "./components/SavedFilters";
import SaveFilterDialog from "./components/SaveFilterDialog";
import { Filter } from "../../../types";
import { Query, ViewType } from "../types";
import { hasAnyFilterApplied, hasPermission } from "../../../helpers/functions";
import { PermissionCodes } from "../../../helpers/constants";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
  },
  buttonMargin: {
    marginRight: theme.spacing(1),
  },
  showHideButton: {
    width: theme.spacing(5),
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 0.1s linear",
    "&.open": {
      transform: "rotate(180deg)",
    },
  },
  contentBox: {
    maxHeight: "0px",
    transition: "max-height 0.2s ease-out",
    "&.shown": {
      maxHeight: "600px",
      transition: "max-height 0.2s ease-in",
    },
  },
  cardContent: {
    opacity: 0,
    transition: "opacity 0.2s ease-out",
    "&.shown": {
      opacity: 1,
      transition: "opacity 0.2s ease-in",
    },
  },
}));

interface Props {
  view?: ViewType;
  filter?: Filter;
  setFilter: (filter?: Filter) => void;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  resetQuery: (filter?: Filter) => void;
}

function Component({
  filter,
  view,
  setFilter,
  query,
  setQuery,
  resetQuery,
}: Props) {
  const classes = useStyles();
  const [filtersShown, setFiltersShown] = useState(false);
  const [appliedFiltersShown, setAppliedFiltersShown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClickSave = () => setIsDialogOpen(true);

  const onReset = useCallback(() => {
    setFilter();
    resetQuery();
  }, [resetQuery, setFilter]);

  return (
    <>
      <Card data-cy="filters-section" raised>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between">
              <Box alignSelf="center">
                <>
                  <ClickPopover
                    button={{
                      color: "primary",
                      variant: "contained",
                      size: "small",
                      endIcon: <ExpandMoreIcon />,
                      className: classes.buttonMargin,
                    }}
                    trigger="Saved Filters"
                  >
                    {(open: boolean, handleClose: () => void) => (
                      <div style={{ padding: "12px 0 36px 0" }}>
                        <SavedFilters
                          appliedFilterId={filter?.id}
                          setFilter={setFilter}
                          resetQuery={resetQuery}
                          handleClose={handleClose}
                        />
                      </div>
                    )}
                  </ClickPopover>
                  {hasPermission(PermissionCodes.filtersCreate) && (
                    <Button
                      data-cy="save-filter-button"
                      color="primary"
                      variant="outlined"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={handleClickSave}
                      className={classes.buttonMargin}
                    >
                      Save Filter
                    </Button>
                  )}
                  {filter && (
                    <Typography
                      data-cy="applied-filter-name"
                      variant="caption"
                      color="textSecondary"
                    >{`Applied filter: ${filter?.name}`}</Typography>
                  )}
                </>
              </Box>
              <Box>
                <Button
                  data-cy="reset-filters-button"
                  color="primary"
                  variant="outlined"
                  size="small"
                  className={classes.buttonMargin}
                  onClick={onReset}
                >
                  Reset
                </Button>
                <Button
                  data-cy="show-filters-button"
                  variant="text"
                  size="small"
                  onClick={() => {
                    setFiltersShown((prevValue) => !prevValue);
                    setTimeout(
                      () => setAppliedFiltersShown((prevValue) => !prevValue),
                      200
                    );
                  }}
                  endIcon={
                    <ExpandMoreIcon
                      className={`${classes.expandIcon} ${
                        filtersShown ? "open" : ""
                      }`}
                    />
                  }
                  className={classes.showHideButton}
                >
                  {filtersShown ? "Hide" : "Show"}
                </Button>
              </Box>
            </Box>
          }
          className={classes.header}
        />
        <Box className={`${classes.contentBox} ${filtersShown ? "shown" : ""}`}>
          {(filtersShown || appliedFiltersShown) && (
            <CardContent
              className={`${classes.cardContent} ${
                filtersShown ? "shown" : ""
              }`}
            >
              <AvailableFilters view={view} query={query} setQuery={setQuery} />
            </CardContent>
          )}
        </Box>
        {hasAnyFilterApplied(query) && (
          <CardActions>
            <AppliedFilters view={view} query={query} setQuery={setQuery} />
          </CardActions>
        )}
      </Card>
      <SaveFilterDialog
        query={query}
        filter={filter || ({} as Filter)}
        setFilter={(filter?: Filter) => {
          if (filter) {
            setQuery({ filter_id: filter.id });
            setFilter(filter);
          }
        }}
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
    </>
  );
}

export default Component;
