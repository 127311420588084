import React from "react";
import { Grid } from "@material-ui/core";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
  ArrayParam,
} from "use-query-params";
import Main from "../../layout/Main";
import Filters from "./Filters";
import Table from "./Table";
import { constants } from "../../helpers";
import { SortDirectionQueryParam } from "../../types";
import { usePaginate } from "../../hooks/users";

function Users() {
  const [query, setQuery] = useQueryParams({
    customer_ids: ArrayParam,
    emails: ArrayParam,
    full_names: ArrayParam,
    phones: ArrayParam,
    team_ids: ArrayParam,

    limit: withDefault(NumberParam, constants.limit.users),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "full_name"),
    direction: withDefault(SortDirectionQueryParam, "asc"),
  });

  const users = usePaginate(query);

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  return (
    <Main error="" title="User Management">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters query={query} resetQuery={resetQuery} setQuery={setQuery} />
        </Grid>
        <Grid item xs={12}>
          <Table
            data={users.data?.data || []}
            loading={users?.isFetching}
            count={users?.data?.meta?.total || 0}
            query={query}
            setQuery={setQuery}
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export default Users;
