import React from "react";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";
import { ButtonGroup, Grid } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Main from "../../layout/Main";
import ClickPopover from "../../components/ClickPopover";
import Filters from "./Filters";
import Table from "./Table";
import { constants } from "../../helpers";
import { SortDirectionQueryParam } from "../../types";
import { Helmet } from "react-helmet";

function Mailbox() {
  const [query, setQuery] = useQueryParams({
    id: StringParam,
    from: StringParam,
    subject: StringParam,
    created_before: StringParam,
    created_after: StringParam,
    is_open: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, constants.limit.tasks),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
  });

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  const Menu = (
    <ButtonGroup size="small" variant="outlined">
      <ClickPopover trigger={<FilterListIcon />}>
        <div style={{ width: 368, padding: "24px 24px 36px 24px" }}>
          <Filters query={query} resetQuery={resetQuery} setQuery={setQuery} />
        </div>
      </ClickPopover>
    </ButtonGroup>
  );

  return (
    <>
      <Helmet>
        <title>Affinity | Support | Mailbox</title>
      </Helmet>
      <Main error="" title="Mailbox" menu={Menu}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table query={query} setQuery={setQuery} />
          </Grid>
        </Grid>
      </Main>
    </>
  );
}

export default Mailbox;
