import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { functions } from "../../../../helpers";
import { Task } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  card: {
    "&:hover": {
      boxShadow: theme.shadows[12],
    },

    "& .MuiCardContent-root": {
      paddingBottom: 0,
    },

    "& .MuiCardActions-root": {
      "& .status svg": {
        position: "relative",
        top: theme.spacing(0.1),
        width: theme.typography.h5.fontSize,
        height: theme.typography.h5.fontSize,
      },
    },

    "&.in_time .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.success.main}`,

      "& .status svg": {
        color: theme.palette.success.main,
      },
    },

    "&.overdue .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.error.main}`,

      "& .status svg": {
        color: theme.palette.error.main,
      },
    },

    "& .MuiGrid-item": {
      padding: theme.spacing(0.25),
    },
  },
  link: {
    textDecoration: "none",
    width: "100%",
  },
}));

interface Props {
  task: Task;
}

export default function Component({ task }: Props) {
  const classes = useStyles();

  const Status = () => (
    <Typography className="status" variant="button">
      <FiberManualRecord /> {task.is_overdue ? "Overdue" : "In Time"}
    </Typography>
  );

  return (
    <Link
      data-cy={`tasks-kanban-item-${task.id}`}
      className={classes.link}
      to={"/support/tasks/" + task.id}
    >
      <Card
        className={
          classes.card + " " + (task.is_overdue ? "overdue" : "in_time")
        }
        elevation={3}
      >
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="caption">
                Created at{" "}
                {functions.formattedDate(task.created_at, "dd MMM ''yy")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography align="right" color="textSecondary" variant="caption">
                #{task.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="right" variant="caption">
                {task.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="caption">
                Assigned to {task.user.full_name}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
        </CardContent>
        <CardActions>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Status />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography align="right" variant="button">
                {functions.formattedDate(task.due_date, "dd MMM ''yy")}
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Link>
  );
}
