import React, { useEffect, useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useQueryClient } from "react-query";
import { Box, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import Autocomplete from "../../../../components/Autocomplete";
import InlineEdit from "../../../../components/InlineEdit";
import InfoItem from "../../../../components/InfoItem";
import {
  LooseObject,
  Priority,
  Status,
  Stage,
  Ticket,
  ClosureReason,
  BreachReason,
} from "../../../../types";
import {
  formattedDateTime,
  hasPermission,
} from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useDebounce, useEffectWithPrev } from "../../../../helpers/hooks";
import {
  useBreachReasons,
  useClosureReasons,
  usePriorities,
  useStages,
  useStatuses,
} from "../../../../hooks/tickets/autocomplete";
import { useUpdate } from "../../../../hooks/tickets";
import { AutocompleteComponentProps } from "../../types";

type ItemWrapProps = {
  children: React.ReactNode;
  last?: boolean;
  isInlineEdit?: boolean;
};

function ItemWrap({
  children,
  isInlineEdit = false,
  last = false,
}: ItemWrapProps) {
  return (
    <Box
      paddingRight={last ? 0 : "40px"}
      paddingBottom="24px"
      boxSizing="content-box"
      minWidth={isInlineEdit ? "190px" : "max-content"}
    >
      {children}
    </Box>
  );
}

function StatusAutocomplete({
  value,
  onSelect,
  ticket,
  onClose,
}: AutocompleteComponentProps) {
  const [statusInput, setStatusInput] = useState("");
  const debouncedStatusInput = useDebounce(statusInput);
  const statuses = useStatuses({ query: debouncedStatusInput });

  return (
    <Autocomplete
      data-cy="ticket-status-autocomplete"
      options={statuses.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="name"
      onSearch={(query) => setStatusInput(query)}
      onSelect={(status: Status) => {
        onSelect(status);
        if (onClose) onClose();
      }}
      getOptionDisabled={(option: Status) => {
        if (ticket?.status.id === option.id) return true;
        if (ticket?.is_s_l_a_paused) {
          return !option.is_open && !option.resolves_ticket;
        }
        if (!ticket?.status.resolves_ticket) {
          return !option.is_open && !option.resolves_ticket;
        }
        return false;
      }}
      loading={statuses?.isLoading}
      openOnFocus
      disableClearable
    />
  );
}

function BreachReasonAutocomplete({
  value,
  onSelect,
  disabled,
}: AutocompleteComponentProps) {
  const [breachReasonInput, setBreachReasonInput] = useState("");
  const debouncedBreachReasonInput = useDebounce(breachReasonInput);
  const breach_reasons = useBreachReasons(debouncedBreachReasonInput);

  return (
    <Autocomplete
      data-cy="ticket-breach-reason-autocomplete"
      options={breach_reasons.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="name"
      label="SLA Breach Reason"
      placeholder="Type to search"
      onSearch={(query) => setBreachReasonInput(query)}
      onSelect={onSelect}
      loading={breach_reasons?.isLoading}
      disabled={disabled}
      openOnFocus
      disableClearable
    />
  );
}

function ClosureReasonAutocomplete({
  value,
  onSelect,
  disabled,
}: AutocompleteComponentProps) {
  const [closureReasonInput, setClosureReasonInput] = useState("");
  const debouncedClosureReasonInput = useDebounce(closureReasonInput);
  const closure_reasons = useClosureReasons(debouncedClosureReasonInput);

  return (
    <Autocomplete
      data-cy="ticket-closure-reason-autocomplete"
      options={closure_reasons.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="name"
      label="Closure Reason"
      placeholder="Type to search"
      onSearch={(query) => setClosureReasonInput(query)}
      onSelect={onSelect}
      loading={closure_reasons?.isLoading}
      disabled={disabled}
      openOnFocus
      disableClearable
    />
  );
}

function StageAutocomplete({
  value,
  onSelect,
  ticket,
  statusId,
  onClose,
}: AutocompleteComponentProps) {
  const [stagesInput, setStagesInput] = useState("");
  const debouncedStagesInput = useDebounce(stagesInput);
  const stages = useStages({
    query: debouncedStagesInput,
    status_id: statusId,
  });

  return (
    <Autocomplete
      data-cy="ticket-stage-autocomplete"
      options={stages.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="name"
      onSearch={(query) => setStagesInput(query)}
      onSelect={(stage: Stage) => {
        onSelect(stage);
        if (onClose) onClose();
      }}
      getOptionDisabled={(option: Status) => {
        if (ticket.stage?.id === option.id) return true;
        return false;
      }}
      loading={stages?.isLoading}
      openOnFocus
      disableClearable
    />
  );
}

function PriorityAutocomplete({
  value,
  onSelect,
  ticket,
}: AutocompleteComponentProps) {
  const [priorityInput, setPriorityInput] = useState("");
  const debouncedPriorityInput = useDebounce(priorityInput);
  const priorities = usePriorities({ query: debouncedPriorityInput });

  return (
    <Autocomplete
      data-cy="ticket-priority-autocomplete"
      options={
        priorities.data?.data.filter(
          (priority: Priority) => ticket.type.id === 2 || priority.id !== 4
        ) || []
      }
      value={value}
      valueFrom="id"
      textFrom="name"
      onSearch={(query) => setPriorityInput(query)}
      onSelect={onSelect}
      getOptionDisabled={(option: Status) => {
        if (ticket.priority.id === option.id) return true;
        return false;
      }}
      loading={priorities?.isLoading}
      openOnFocus
      disableClearable
    />
  );
}

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const canEditStatus =
    hasPermission(PermissionCodes.ticketsUpdateStatus) ||
    (!ticket?.status?.is_open && hasPermission(PermissionCodes.ticketsReopen));
  const canEditSeverity = hasPermission(PermissionCodes.ticketsUpdatePriority);
  const canEditStage = hasPermission(PermissionCodes.ticketsUpdateStage);
  const canViewStage = hasPermission(PermissionCodes.ticketsViewAnyStage);

  const queryClient = useQueryClient();

  const [local, setLocal] = useStateWithCallbackLazy<LooseObject>({
    breach_reason: null,
    closure_reason: null,
    collect_sla_breach_reason: false,
    collect_closure_reason: false,
    priority: ticket.priority,
    status: ticket.status,
    stage: ticket.stage,
    resolve_child_tickets: false,
  });

  const update = useUpdate(ticket.id, {
    onSuccess: () =>
      queryClient.invalidateQueries(["tickets", ticket.id, "associations"]),
  });

  useEffect(() => {
    setLocal(
      {
        breach_reason: null,
        closure_reason: null,
        collect_sla_breach_reason: false,
        collect_closure_reason: false,
        priority: ticket.priority,
        status: ticket.status,
        stage: ticket.stage,
        resolve_child_tickets: false,
      },
      () => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.id]);

  const setStatus = (status: Status) => {
    setLocal(
      {
        ...local,
        status,
        collect_sla_breach_reason:
          ticket.is_breach_reason_id_required &&
          !status.is_open &&
          !status.resolves_ticket,
        collect_closure_reason: status.resolves_ticket,
      },
      submitChanges
    );
  };

  const setStage = (stage: Stage) => {
    setLocal(
      {
        ...local,
        stage,
      },
      submitChanges
    );
  };

  const setPriority = (priority: Priority) => {
    setLocal(
      {
        ...local,
        priority,
      },
      submitChanges
    );
  };

  const setBreachReason = (breachReason: BreachReason) => {
    setLocal(
      {
        ...local,
        collect_sla_breach_reason: false,
        breach_reason: breachReason,
      },
      submitChanges
    );
  };

  const setClosureReason = (closureReason: ClosureReason) => {
    setLocal(
      {
        ...local,
        collect_closure_reason: false,
        closure_reason: closureReason,
      },
      submitChanges
    );
  };

  const submitChanges = (updates: any) => {
    if (!local.status?.is_open && !local.status?.resolves_ticket) {
      // this is here for correct update of status/stage for closed tickets. the logic is weird but it works ¯\_(ツ)_/¯
      if (updates.status.is_open || updates.status.resolves_ticket) {
        update.mutate({
          status_id: updates?.status?.id,
        });
      } else {
        update.mutate({
          stage_id: updates?.stage?.id,
        });
      }
    } else {
      update.mutate({
        breach_reason_id: updates.breach_reason?.id,
        closure_reason_id: updates.closure_reason?.id,
        status_id: updates.status?.id,
        priority_id: updates.priority?.id,
        stage_id: updates.stage?.id,
        resolve_child_tickets: updates.status.resolves_ticket
          ? updates.resolve_child_tickets
          : false,
      });
    }
  };

  useEffectWithPrev(
    (inputs: any) => {
      const [prevTicketStatus] = inputs;
      if (prevTicketStatus?.id !== ticket.status?.id) {
        setLocal(
          {
            ...local,
            status: ticket.status,
            resolve_child_tickets: false,
          },
          null
        );
      }
    },
    [ticket.status, local, setLocal, submitChanges]
  );

  useEffectWithPrev(
    (inputs: any) => {
      const [prevTicketStage] = inputs;
      if (prevTicketStage?.id !== ticket.stage?.id) {
        setLocal(
          {
            ...local,
            stage: ticket.stage,
          },
          null
        );
      }
    },
    [ticket.stage, local, setLocal, submitChanges]
  );

  useEffectWithPrev(
    (inputs: any) => {
      const [prevTicketPriority] = inputs;
      if (prevTicketPriority?.id !== ticket.priority?.id) {
        setLocal(
          {
            ...local,
            priority: ticket.priority,
          },
          null
        );
      }
    },
    [ticket.priority, local, setLocal, submitChanges]
  );

  return (
    <Box display="flex" justifyContent="space-between" overflow="auto">
      <ItemWrap isInlineEdit>
        {canEditStatus ? (
          <InfoItem
            title="Status"
            value={
              <>
                <InlineEdit
                  input={
                    <StatusAutocomplete
                      value={
                        local.collect_closure_reason
                          ? local.status
                          : ticket.status
                      }
                      onSelect={(status: Status) => status && setStatus(status)}
                      ticket={ticket}
                    />
                  }
                  disabled={update.isLoading}
                >
                  <Typography variant="h4">{ticket.status?.name}</Typography>
                </InlineEdit>
                {local.collect_sla_breach_reason && (
                  <BreachReasonAutocomplete
                    value={null}
                    onSelect={(breachReason: BreachReason) =>
                      breachReason && setBreachReason(breachReason)
                    }
                    disabled={update.isLoading}
                  />
                )}
                {local.collect_closure_reason && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={local.resolve_child_tickets}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setLocal(
                              {
                                ...local,
                                resolve_child_tickets: event.target.checked,
                              },
                              null
                            );
                          }}
                          disabled={update.isLoading}
                        />
                      }
                      label="Resolve child tickets"
                    />
                    <ClosureReasonAutocomplete
                      value={null}
                      onSelect={(closureReason: ClosureReason) =>
                        closureReason && setClosureReason(closureReason)
                      }
                      disabled={update.isLoading}
                    />
                  </>
                )}
              </>
            }
          />
        ) : (
          <InfoItem title="Status" value={ticket.status?.name} />
        )}
        {(!ticket.status.is_open || ticket.status.resolves_ticket) && (
          <Typography variant="caption">
            {ticket.closure_reason?.name}
          </Typography>
        )}
      </ItemWrap>
      {canEditStage ? (
        <ItemWrap isInlineEdit>
          <InfoItem
            title="Substage"
            value={
              <InlineEdit
                input={
                  <StageAutocomplete
                    value={local.stage}
                    onSelect={(stage: Stage) => stage && setStage(stage)}
                    ticket={ticket}
                    statusId={local.status.id}
                  />
                }
                disabled={update.isLoading}
              >
                <Typography variant="h4">{ticket.stage?.name}</Typography>
              </InlineEdit>
            }
          />
        </ItemWrap>
      ) : (
        canViewStage && (
          <ItemWrap>
            <InfoItem title="Substage" value={ticket.stage?.name} />
          </ItemWrap>
        )
      )}
      <ItemWrap>
        <InfoItem title="System" value={ticket.system?.name} />
      </ItemWrap>
      <ItemWrap isInlineEdit>
        {canEditSeverity && ticket.status.is_open ? (
          <InfoItem
            title="Severity"
            value={
              <InlineEdit
                input={
                  <PriorityAutocomplete
                    value={ticket.priority}
                    onSelect={(priority: Priority) =>
                      priority && setPriority(priority)
                    }
                    ticket={ticket}
                  />
                }
                disabled={update.isLoading}
              >
                <Typography variant="h4">{ticket.priority?.name}</Typography>
              </InlineEdit>
            }
          />
        ) : (
          <InfoItem title="Severity" value={ticket.priority?.name} />
        )}
      </ItemWrap>
      <ItemWrap>
        <InfoItem title="Opened By" value={ticket.creator?.full_name} />
      </ItemWrap>
      <ItemWrap>
        <InfoItem
          title="Opened"
          value={formattedDateTime(ticket.opened_at, "dd/MM/yyyy HH:mm")}
        />
      </ItemWrap>
      <ItemWrap last>
        <InfoItem
          title="Last Modified"
          value={formattedDateTime(ticket.updated_at, "dd/MM/yyyy HH:mm")}
        />
      </ItemWrap>
    </Box>
  );
}
