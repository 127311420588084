import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";
import ContactItem from "../components/ContactItem";
import { Ticket } from "../../../../types";

interface Props {
  ticket: Ticket;
}

const Component: FunctionComponent<Props> = ({ ticket }) => {
  return (
    <Grid container spacing={1}>
      <ContactItem title="Company Name" value={ticket.customer?.name} />
    </Grid>
  );
};

export default Component;
