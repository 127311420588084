import React, { useEffect } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { differenceInMonths, format } from "date-fns";
import { IndexedCardInfo } from "../../../../atoms/indexed-card-info";
import { AddressFields } from "../AddressFields";
import { RHFSelectField } from "../../../../atoms/form-fields/RHFSelectField";
import { Alert } from "../../../../Alert/Alert";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { useFormContext } from "react-hook-form";
import { RHFSwitchField } from "../../../../atoms/form-fields/RHFSwitchField";
import { FetchWLR3AddressSearch } from "../../../AddressPicker/types";
import { RHFTextField } from "../../../../atoms/form-fields/RHFTextField";
import { useIsVfDirectConsumerAccount } from "../../hooks/useIsVfDirectConsumerAccount";

type CompanyAddressProps = {
  cardIndex: string;
  accountSettings: AccountSettings;
  accountId?: string;
  api: FetchWLR3AddressSearch;
};

export const CompanyAddress = ({
  cardIndex,
  accountSettings,
  accountId,
  api,
}: CompanyAddressProps) => {
  const { watch, setValue } = useFormContext();

  const isVfDirectConsumerAcc = useIsVfDirectConsumerAccount(accountSettings);

  /**
   * Convert date inputs to "months at address" fields which need to be sent to DC
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === `companyAddressDateFrom` && type === "change") {
        setValue(
          `company_months_at_address`,
          differenceInMonths(
            new Date(),
            new Date(value[`companyAddressDateFrom`])
          )
        );
      }
      if (name === `companyAddressDateFrom2` && type === "change") {
        setValue(
          `company_months_at_address2`,
          differenceInMonths(
            new Date(value[`companyAddressDateFrom`]),
            new Date(value[`companyAddressDateFrom2`])
          )
        );
      }
      if (name === `companyAddressDateFrom3` && type === "change") {
        setValue(
          `company_months_at_address3`,
          differenceInMonths(
            new Date(value[`companyAddressDateFrom2`]),
            new Date(value[`companyAddressDateFrom3`])
          )
        );
      }
    });
    return () => subscription.unsubscribe();
  });

  const company_months_at_address = watch("company_months_at_address");
  const company_months_at_address2 = watch("company_months_at_address2");
  const company_months_at_address3 = watch("company_months_at_address3");
  const companyAddressDateFrom = watch("companyAddressDateFrom");
  const companyAddressDateFrom2 = watch("companyAddressDateFrom2");

  const partialAddressHistory =
    company_months_at_address + company_months_at_address2;
  const totalMonthsHistory =
    company_months_at_address +
    company_months_at_address2 +
    company_months_at_address3;
  const enoughHistory = totalMonthsHistory >= 36;

  // TODO: Maybe some util function for this. Not repeated code.
  //  Refactor when there's time. This was moved from pre-existing functionality
  const showAddress2 =
    isVfDirectConsumerAcc &&
    company_months_at_address !== "" &&
    company_months_at_address < 36;
  const showAddress3 =
    isVfDirectConsumerAcc &&
    company_months_at_address2 !== "" &&
    partialAddressHistory < 36;

  return (
    <IndexedCardInfo index={cardIndex} title="Company Address">
      <Grid container spacing={2}>
        {isVfDirectConsumerAcc && (
          <Grid item sm={12}>
            <Alert
              type="info"
              message="For Sole Trader / Owner Manager we require a minimum of 3 years address history."
            />
          </Grid>
        )}
        <AddressFields prefix="company_" api={api} accountId={accountId} />
        {isVfDirectConsumerAcc && (
          <>
            <Grid item sm={6}>
              <RHFSelectField
                label="Residential Status"
                name="residential_status"
                rules={{ required: true }}
              >
                <MenuItem value="OWNER">Owner</MenuItem>
                <MenuItem value="TENANT">Tenant</MenuItem>
                <MenuItem value="PARENTS">Parents</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </RHFSelectField>
            </Grid>
            <Grid item sm={3}>
              <RHFTextField
                name="companyAddressDateFrom"
                label="Date from"
                rules={{
                  required: true,
                }}
                InputProps={{
                  inputProps: { max: format(new Date(), "yyyy-MM-dd") },
                }}
                type="date"
              />
            </Grid>
          </>
        )}

        <RHFSwitchField
          label="Billing address same as company address"
          name="billing_address_same"
        />

        {showAddress2 === true && (
          <>
            <Grid item sm={12}>
              <Typography variant="h4">Previous Company Address 1</Typography>
            </Grid>
            <AddressFields
              prefix="company_"
              suffix="2"
              api={api}
              accountId={accountId}
            />
            <Grid item sm={3}>
              <RHFTextField
                name="companyAddressDateFrom2"
                label="Date from"
                rules={{
                  required: true,
                }}
                InputProps={{
                  inputProps: {
                    max: format(new Date(companyAddressDateFrom), "yyyy-MM-dd"),
                  },
                }}
                type="date"
              />
            </Grid>
            <Grid item sm={3}>
              <RHFTextField
                name="companyAddressDateFrom"
                label="Date to"
                disabled
              />
            </Grid>
          </>
        )}
        {showAddress3 === true && (
          <>
            <Grid item sm={12}>
              <Typography variant="h4">Previous Company Address 2</Typography>
            </Grid>
            <AddressFields
              prefix="company_"
              suffix="3"
              api={api}
              accountId={accountId}
            />
            <Grid item sm={3}>
              <RHFTextField
                name="companyAddressDateFrom3"
                label="Date from"
                rules={{
                  required: true,
                  validate: (val) => {
                    return enoughHistory ? true : "Must be over 3 years ago";
                  },
                }}
                InputProps={{
                  inputProps: {
                    max: format(
                      new Date(companyAddressDateFrom2),
                      "yyyy-MM-dd"
                    ),
                  },
                }}
                type="date"
              />
            </Grid>
            <Grid item sm={3}>
              <RHFTextField
                name="companyAddressDateFrom2"
                label="Date to"
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </IndexedCardInfo>
  );
};
