import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { NumberParam, useQueryParams } from "use-query-params";
import { Box, Grid, Paper } from "@material-ui/core";
import AssignParent from "../AssignParent";
import AssignChildren from "../AssignChildren";
import Main from "../../../layout/Main";
import FileUpload from "../../../components/FileUpload";
import Form from "../components/Form";
import Autocomplete from "../../../components/Autocomplete";
import { Team, Ticket, TicketFields, User } from "../../../types";
import {
  formatContactFields,
  hasAnyPermission,
  hasPermission,
} from "../../../helpers/functions";
import { PermissionCodes } from "../../../helpers/constants";
import { useCreate } from "../../../hooks/tickets";
import { useConvertFromMessage } from "../../../hooks/tickets/messages";
import { ValidationError } from "../../../api";
import { useEventDetail } from "../../../hooks/events";
import { useTeams, useUsers } from "../../../hooks/autocomplete";

function Component() {
  const queryClient = useQueryClient();

  const [{ message_id, event_id }] = useQueryParams({
    message_id: NumberParam,
    event_id: NumberParam,
  });

  const initialState: Partial<TicketFields> = {
    category_id: null,
    children: [],
    customer_id: null,
    customer_reference: "",
    description: "",
    parent_id: null,
    priority_id: null,
    short_description: "",
    system_id: null,
    team_id: null,
    type_id: 1,
    user_id: null,

    "contact.email": "",
    "contact.first_name": "",
    "contact.id": null,
    "contact.last_name": "",
    "contact.phone": "",

    "files[]": [],
  };

  const [ticketFields, setTicketFields] = useState(initialState);

  const [teamQuery, setTeamQuery] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const teams = useTeams({ query: teamQuery });
  const users = useUsers({
    query: userQuery,
    team_id: ticketFields?.team_id,
  });

  const setFields = (fields: Partial<TicketFields>) =>
    setTicketFields((oldFields) => ({ ...oldFields, ...fields }));

  const event = useEventDetail(event_id, {
    enabled: !!event_id,
  });
  const [eventFieldsSet, setEventFieldsSet] = useState(false);
  useEffect(() => {
    if (event.isSuccess && !eventFieldsSet) {
      setFields({
        type_id: 2,
        short_description: `Event ID: ${event.data?.data?.id}; Monitor reference: ${event.data?.data?.external_reference}`,
        priority_id: event.data?.data?.level.id,
      });
      setEventFieldsSet(true);
    }
  }, [event, eventFieldsSet]);

  const create = useCreate();

  useConvertFromMessage(message_id, {
    onSuccess: ({ data }) => {
      setFields({
        category_id: data.category?.id ?? "",
        customer_id: data.customer?.id ?? "",
        customer_reference: data.customer_reference ?? "",
        description: data.description ?? "",
        priority_id: data.priority?.id ?? "",
        short_description: data.short_description ?? "",
        system_id: data.system?.id ?? "",
        type_id: data.type?.id ?? "",

        "contact.id": data.contact?.id ?? "",
        "contact.first_name": data.contact?.first_name ?? "",
        "contact.last_name": data.contact?.last_name ?? "",
        "contact.email": data.contact?.email ?? "",
        "contact.phone": data.contact?.phone ?? "",
      });
    },
  });

  useEffect(() => {
    if (message_id) {
      setFields({
        message_id: message_id,
      });
    }

    return function cleanup() {
      if (message_id)
        queryClient.invalidateQueries([`ticket-from-message-${message_id}`]);
    };
  }, [message_id, queryClient]);

  return (
    <Main title="New Ticket">
      <Paper elevation={4} style={{ width: "100%" }}>
        <Form
          fields={ticketFields}
          setFields={setFields}
          onSubmit={(fields: Partial<TicketFields>) =>
            create.mutate(formatContactFields(fields))
          }
          loading={create.isLoading}
          validation={
            create?.error instanceof ValidationError
              ? create?.error?.validation
              : null
          }
          isConvertingFromEvent={!!event_id}
        >
          {hasAnyPermission([
            PermissionCodes.ticketsUpdateTeam,
            PermissionCodes.ticketsUpdateUser,
          ]) && (
            <Grid container spacing={1}>
              {hasPermission(PermissionCodes.ticketsUpdateTeam) && (
                <Grid item xs={6}>
                  <Autocomplete
                    data-cy="ticket-form-team-autocomplete"
                    label="Team"
                    loading={teams.isLoading}
                    options={teams.data?.data || []}
                    placeholder="Type to search"
                    value={
                      (teams.data?.data || []).find(
                        (team: Team) => team.id === ticketFields.team_id
                      ) || null
                    }
                    valueFrom="id"
                    textFrom="name"
                    onSelect={(team: Team) =>
                      setFields({ team_id: team ? team.id : null })
                    }
                    onSearch={(query) => setTeamQuery(query)}
                    shrink
                  />
                </Grid>
              )}
              {hasPermission(PermissionCodes.ticketsUpdateUser) && (
                <Grid item xs={6}>
                  <Autocomplete
                    data-cy="ticket-form-user-autocomplete"
                    label="User"
                    loading={users.isLoading}
                    options={users.data?.data || []}
                    placeholder="Type to search"
                    value={
                      (users.data?.data || []).find(
                        (user: User) => user.id === ticketFields.user_id
                      ) || null
                    }
                    valueFrom="id"
                    textFrom="full_name"
                    onSelect={(user: User) =>
                      setFields({ user_id: user ? user.id : null })
                    }
                    onSearch={(query) => setUserQuery(query)}
                    shrink
                  />
                </Grid>
              )}
            </Grid>
          )}
          {hasAnyPermission([
            PermissionCodes.ticketsUpdateParent,
            PermissionCodes.ticketsUpdateChildren,
          ]) && (
            <Grid container spacing={1}>
              {hasPermission(PermissionCodes.ticketsUpdateParent) && (
                <Grid item xs={6}>
                  <AssignParent
                    disabled={ticketFields?.children?.length ? true : false}
                    onSelect={(ticket: Ticket | null) => {
                      setFields({ parent_id: ticket ? ticket.id : null });
                    }}
                  />
                </Grid>
              )}
              {hasPermission(PermissionCodes.ticketsUpdateChildren) && (
                <Grid item xs={6}>
                  <AssignChildren
                    disabled={ticketFields?.parent_id ? true : false}
                    onSelect={(tickets) =>
                      setFields({
                        children: tickets.map((ticket: Ticket) => ticket.id),
                      })
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box paddingTop="16px">
                <FileUpload
                  variant="outlined"
                  multiple
                  name="files[]"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    files: Array<File | null>
                  ) => setFields({ [event.target.name]: files })}
                  value={ticketFields["files[]"] || []}
                  setFiles={(newFiles: File[]) =>
                    setFields({ "files[]": newFiles })
                  }
                  resetError={create.reset}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Main>
  );
}

export default Component;
