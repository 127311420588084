import React from "react";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
// import ToolbarOptions from "../../components/ToolbarOptions";
import { Query, ReportCategories } from "../../types";
import tableConfig from "../tableConfig";
import { LooseObject } from "../../../../types";

type Props = {
  reportTitle: string;
  id: ReportCategories;
  tableData?: LooseObject[];
  loading: boolean;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
};

const useStyles = makeStyles((theme) => ({
  table: {
    "& th": {
      whiteSpace: "nowrap",
      width: "50%",
    },
    "& td": {
      whiteSpace: "nowrap",
    },
  },
}));

export default function Component({
  reportTitle,
  id,
  loading,
  tableData,
  query,
  setQuery,
}: Props) {
  const classes = useStyles();
  const columns: MUIDataTableColumn[] = tableConfig[id];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    sort: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,

    // customToolbar: () => <ToolbarOptions />,

    // responsive: "standard",
    serverSide: true,

    rowsPerPage: query.limit,
    page: (query.page || 1) - 1,
    count: tableData?.length,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    setTableProps: () => ({
      className: classes.table,
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="reports-subtable"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">{reportTitle}</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={tableData || []}
      columns={columns}
      options={options}
    />
  );
}
