import React, { ChangeEvent } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { isValidPostcode } from "./helpers/isValidPostcode";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ChooseAddressDialog } from "./ChooseAddressDialog";
import { FetchWLR3AddressSearch, WLR3Address } from "./types";

export interface AddressPickerProps {
  includeUPRN?: boolean;
  /* Postcode for when this is controlled component */
  postcode?: string;
  /* Set Postcode for when this is controlled component */
  setPostcode?: (postcode: string) => void;
  saveAddress: (address: WLR3Address) => void;
  saveUPRN?: (uprn: string) => void;
  /**
   * accountId is necessary when it can't be inferred from the request according to @davet
   * i.e. when directly to DC and not through Kong
   * This is to do with multi-tenancy and each tenant having their own SI key
   */
  accountId?: string;
  api: FetchWLR3AddressSearch;
}

interface AddressPickerState {
  dialogOpen: boolean;
  postcode: string;
  filter: string;
}

/**
 * Address Picker
 *
 * Ported from Affinity Sales CPQ
 */
export class AddressPicker extends React.Component<
  AddressPickerProps,
  AddressPickerState
> {
  state = {
    dialogOpen: false,
    postcode: "",
    filter: "",
  };

  static propTypes = {
    saveAddress: PropTypes.func.isRequired,
    includeUPRN: PropTypes.bool,
    saveUPRN: PropTypes.func,
    postcode: PropTypes.string, // ...when it's optionally controlled
    setPostcode: PropTypes.func,
  };

  setPostcode = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.setPostcode) {
      this.props.setPostcode(event.target.value);
    } else {
      this.setState({ postcode: event.target.value });
    }
  };
  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  getAddresses = () => {
    this.setState({
      dialogOpen: true,
      filter: "",
    });
  };

  render() {
    const { dialogOpen } = this.state;

    const postcode = this.props.postcode || this.state.postcode;

    return (
      <>
        <Box
          mr={2}
          display="flex"
          alignItems="flex-start"
          data-cy="AddressPicker"
        >
          <TextField
            variant={"outlined"}
            label="Postcode"
            name="postcode"
            style={{ width: 140, marginRight: 10 }}
            onChange={this.setPostcode}
            onKeyDown={(e) => e.keyCode === 13 && this.getAddresses()}
            value={postcode}
            helperText={
              postcode &&
              !isValidPostcode(postcode) &&
              "Must be a valid postcode"
            }
            error={!!(postcode && !isValidPostcode(postcode))}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={this.getAddresses}
            data-cy="AddressPickerButton"
            disabled={!isValidPostcode(postcode)}
            style={{ margin: 8 }}
          >
            Choose Address
          </Button>
        </Box>
        <ChooseAddressDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          postcode={postcode}
          includeUPRN={this.props.includeUPRN}
          setAddress={this.props.saveAddress}
          setUPRN={(uprn) =>
            this.props.saveUPRN && this.props.saveUPRN(uprn.uprn)
          }
          accountId={this.props.accountId}
          api={this.props.api}
        />
      </>
    );
  }
}
