import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import InfoItem from "../../../../components/InfoItem";
import { EventType } from "../../../../types";
import { formattedDateTime } from "../../../../helpers/functions";

interface Props {
  event: EventType;
}

export default function Component({ event }: Props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <InfoItem
              title="Severity"
              value={event?.level?.name}
              style={{ color: event?.level?.color, fontWeight: 200 }}
            />
          </Grid>
          <Grid item xs={3}>
            <InfoItem
              title="Date Reported"
              value={
                event?.processed_at &&
                formattedDateTime(event?.processed_at, "dd/MM/yyyy HH:mm")
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InfoItem
              title="Logic Monitor Reference"
              value={event?.external_reference}
            />
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                data-cy="event-convert-button"
                color="primary"
                component={Link}
                size="medium"
                to={`/support/new-ticket?event_id=${event.id}`}
                startIcon={<InboxIcon />}
                variant="contained"
              >
                Convert to Ticket
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
