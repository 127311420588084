import { useQuery } from "react-query";
import * as api from "../api/autocomplete";
import {
  ContactsData,
  CustomersData,
  TeamsData,
  UsersData,
  RolesData,
} from "../screens/Tickets/types";
import { AutocompleteParams, LooseObject } from "../types";

export function useContacts(params = {}, options = {}) {
  return useQuery<ContactsData, Error>(
    ["autocomplete", "contacts", params],
    () => api.contacts(params),
    options
  );
}

export function useCustomers(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<CustomersData, Error>(
    ["autocomplete", "customers", params],
    () => api.customers(params),
    options
  );
}

export function useTeams(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<TeamsData, Error>(
    ["autocomplete", "teams", params],
    () => api.teams(params),
    options
  );
}

export function useUsers(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<UsersData, Error>(
    ["autocomplete", `team-${params.team_id || "all"}`, "users", params],
    () => api.users(params),
    options
  );
}

export function useRoles(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<RolesData, Error>(
    ["autocomplete", "roles", params],
    () => api.roles(params),
    options
  );
}
