import React, { useEffect, useState } from "react";
import { DataTable, DataTablePagination } from "@akj-dev/design-system";
import { Link, makeStyles, Typography } from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Loading from "../../../layout/Loading";
import { Audit, LooseObject, SortDirection } from "../../../types";
import { Query } from "../types";
import { formattedDate, formattedTime } from "../../../helpers/functions";
import { usePaginate } from "../../../hooks/audits";

const useStyles = makeStyles((theme) => ({
  audit: {
    "& .audit_field": {
      fontWeight: "bold",
    },
    "& .audit_old": {
      color: theme.palette.error.main,
    },
    "& .audit_new": {
      color: theme.palette.success.main,
    },
  },
}));

interface Props {
  expanded?: boolean;
  owner?: string;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
}

export default function Component({
  expanded,
  owner = "",
  query,
  setQuery,
}: Props) {
  const classes = useStyles();
  const paginator = usePaginate(owner, query);
  const [localExpanded, setExpanded] = useState<LooseObject>({});

  useEffect(() => {
    setExpanded({});
  }, [expanded, setExpanded]);

  const columns = [
    {
      name: "created_at",
      label: "Date",
      sortable: true,
    },
    {
      name: "created_at",
      label: "Time",
      sortable: true,
    },
    {
      name: "action",
      label: "Action",
      sortable: true,
    },
    {
      name: "user",
      label: "User",
      sortable: true,
    },
  ];

  const Action = ({ audit }: { audit: Audit }) => {
    return (
      <>
        <Link
          data-cy="audits-expand-button"
          color="inherit"
          href="#"
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();

            setExpanded({
              ...localExpanded,
              [audit.id]: !localExpanded[audit.id],
            });
          }}
        >
          {audit.action}
          {!expanded && !localExpanded[audit.id] && (
            <ExpandMoreIcon fontSize="inherit" />
          )}
          {(expanded || localExpanded[audit.id]) && (
            <ExpandLessIcon fontSize="inherit" />
          )}
        </Link>

        {(expanded || localExpanded[audit.id]) && (
          <>
            {audit.changes?.map((change) => (
              <Typography
                dangerouslySetInnerHTML={{ __html: change.description_as_html }}
                variant="body2"
              />
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <Loading
      error={paginator.isError ? "Can't load ticket history" : undefined}
      loading={paginator.isLoading}
    >
      {(paginator.data?.meta?.total ?? 0) > 0 && (
        <div className={classes.audit}>
          <DataTable
            data-cy="audits-table"
            columns={columns}
            count={paginator.data?.meta?.total}
            data={(paginator.data?.data || []).map((audit: Audit) => [
              formattedDate(audit.created_at),
              formattedTime(audit.created_at),
              <Action audit={audit} />,
              <>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    paddingRight: "6px",
                  }}
                >
                  <img
                    alt="profile"
                    src={`https://ui-avatars.com/api/?rounded=true&size=18&background=random&name=${audit.user?.full_name}`}
                  />
                </div>
                {audit.user?.full_name}
              </>,
            ])}
            error={paginator.isError ? "Can't load ticket history" : undefined}
            loading={paginator.isLoading}
            onChangePage={(event, page) => setQuery({ page: page + 1 })}
            onChangeRowsPerPage={(event) =>
              setQuery({ limit: parseInt(event.target.value) })
            }
            onChangeSort={(state) =>
              setQuery({
                sort: columns[state.columnIndex]?.name,
                direction: state.dir.toLocaleLowerCase() as SortDirection,
              })
            }
            page={query.page - 1}
            pagination={DataTablePagination.Pages}
            rowsPerPage={query.limit}
            title="Results"
          />
        </div>
      )}
    </Loading>
  );
}
