import React from "react";
import Typography from "@material-ui/core/Typography";
import { EventType } from "../../../../types";

interface Props {
  event: EventType;
}

export default function Component({ event }: Props) {
  return (
    <>
      <Typography paragraph variant="h4">
        {event?.message}
      </Typography>

      <Typography paragraph variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
        bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
        justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam
        fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
        sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit.
      </Typography>
    </>
  );
}
