import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Form from "../components/Form";
import Main from "../../../layout/Main";
import { LooseObject, TicketFields } from "../../../types";
import { useTicketDetail, useUpdate } from "../../../hooks/tickets";
import { formatContactFields } from "../../../helpers/functions";
import { ValidationError } from "../../../api";

function Component() {
  const { id } = useParams<LooseObject>();
  const history = useHistory();

  let ticket = useTicketDetail(parseInt(id));

  const initialState: Partial<TicketFields> = {
    category_id: null,
    children: [],
    customer_id: null,
    customer_reference: "",
    description: "",
    parent_id: null,
    priority_id: null,
    short_description: "",
    system_id: null,
    team_id: null,
    type_id: null,
    user_id: null,

    "contact.email": "",
    "contact.first_name": "",
    "contact.id": null,
    "contact.last_name": "",
    "contact.phone": "",
    "files[]": [],
  };

  const update = useUpdate(parseInt(id), {
    onSuccess: () => history.push(`/support/tickets/${id}`),
  });
  const [ticketFields, setTicketFields] = useState(initialState);

  const setFields = (fields: Partial<TicketFields>) =>
    setTicketFields((oldFields) => ({ ...oldFields, ...fields }));

  return (
    <Main
      title={
        ticket.data?.data?.identifier
          ? `Update Ticket: ${ticket.data.data.identifier}`
          : "Update Ticket"
      }
      loading={update.isLoading}
      stickyHeader
    >
      {ticket.data?.data && (
        <Paper elevation={4} style={{ width: "100%" }}>
          <Form
            id={id}
            ticket={ticket}
            // todo // not sure why this was here, keeping it because it might be needed again
            // contacts={{
            //   ...contacts,
            //   ...{
            //     data: contacts.data.length
            //       ? contacts.data
            //       : fields.customer_id &&
            //         ticket.data.data.contact &&
            //         fields.customer_id === ticket.data.data.contact.customer_id
            //       ? [ticket.data.data.contact]
            //       : []
            //   }
            // }}
            loading={update.isLoading}
            fields={ticketFields}
            setFields={setFields}
            onSubmit={(fields: Partial<TicketFields>) =>
              update.mutate(formatContactFields(fields))
            }
            validation={
              update?.error instanceof ValidationError
                ? update?.error?.validation
                : null
            }
          />
        </Paper>
      )}
    </Main>
  );
}

export default Component;
