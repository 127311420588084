import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Grid, Typography } from "@material-ui/core";
import Commitments from "./Commitments";
import CommitmentPause from "./CommitmentPause";
import { Commitment, Ticket } from "../../../../types";
import { secondsToHumanReadable } from "../../../../helpers/functions";
import { useMemoizedCallback } from "../../../../helpers/hooks";

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const [totalPausedTime, setTotalPausedTime] = useState<number>(
    ticket.total_paused_seconds
  );

  const queryClient = useQueryClient();

  const updateTotalTime = useMemoizedCallback(() => {
    setTotalPausedTime(totalPausedTime + 1);
  }, [totalPausedTime, setTotalPausedTime]);

  useEffect(() => {
    setTotalPausedTime(ticket.total_paused_seconds);
  }, [ticket.total_paused_seconds]);

  useEffect(() => {
    queryClient.invalidateQueries(["tickets", ticket.id, "details"]);
  }, [queryClient, ticket.id]);

  const getPauseTime = () => {
    if (ticket.is_s_l_a_paused) {
      if (totalPausedTime < 60) return "less than 1 minute";
      return `~ ${secondsToHumanReadable(totalPausedTime, {
        format: ["weeks", "days", "hours", "minutes"],
      })}`;
    } else {
      return secondsToHumanReadable(totalPausedTime);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography paragraph variant="caption">
          SLA Name
        </Typography>
        <Typography paragraph variant="h4">
          {ticket.s_l_a_calendar?.name}
        </Typography>
        <Typography paragraph variant="caption">
          SLA Description
        </Typography>
        <Typography paragraph variant="body1">
          {ticket.s_l_a_calendar?.description}
        </Typography>

        <Commitments commitments={ticket.commitments || []} />
      </Grid>
      <Grid item xs={4}>
        {totalPausedTime > 0 && (
          <Typography paragraph variant="h5">
            The total SLA pause time is {getPauseTime()}.
          </Typography>
        )}
        {ticket.commitments
          ?.filter(
            (commitment: Commitment) =>
              commitment.is_paused || commitment.can_be_paused
          )
          .map((commitment: Commitment) => (
            <CommitmentPause
              commitment={commitment}
              key={commitment.id}
              updateTotalTime={updateTotalTime}
            />
          ))}
      </Grid>
    </Grid>
  );
}
