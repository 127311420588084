import { MUIDataTableColumn } from "mui-datatables";
import {
  formattedDate,
  hoursAndMinutesToHumanReadable,
} from "../../../helpers/functions";
import { ReportCategories } from "../types";

type TableConfig = Record<ReportCategories, MUIDataTableColumn[]>;

const columns: TableConfig = {
  [ReportCategories.reportsDailyBacklog]: [
    {
      name: "active_tickets",
      label: "Active Tickets",
    },
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (value: string) => formattedDate(value, "dd/MM/yyyy"),
      },
    },
  ],
  [ReportCategories.reportsOpenedBySeverity]: [
    {
      name: "number_of_tickets",
      label: "Number of Tickets",
    },
    {
      name: "severity",
      label: "Severity",
    },
  ],
  [ReportCategories.reportsOpenedPerWeekDay]: [
    {
      name: "day",
      label: "Day",
    },
    {
      name: "average",
      label: "Average",
    },
  ],
  [ReportCategories.reportsTimeToResolve]: [
    {
      name: "month",
      label: "Month",
    },
    {
      name: "avg_resolution_time",
      label: "Average Resolution Time",
      options: {
        customBodyRender: (value: string) =>
          hoursAndMinutesToHumanReadable(
            ...value.split(":").map((v) => parseInt(v))
          ),
      },
    },
  ],
  [ReportCategories.reportsPercentOfBreachedTickets]: [
    {
      name: "percent",
      label: "Percent",
    },
  ],
  [ReportCategories.reportsTicketsByStatus]: [
    {
      name: "status",
      label: "Status",
    },
    {
      name: "number_of_tickets",
      label: "Number of tickets",
    },
  ],
  [ReportCategories.reportsTicketsByStage]: [
    {
      name: "stage",
      label: "Stage",
    },
    {
      name: "number_of_tickets",
      label: "Number of tickets",
    },
  ],
  [ReportCategories.reportsRaisedTicketsPerMonth]: [
    {
      name: "month",
      label: "Month",
    },
    {
      name: "number_of_tickets",
      label: "Number of tickets",
    },
  ],
  [ReportCategories.reportsActiveEscalations]: [
    {
      name: "number_of_escalations",
      label: "Active escalations",
    },
  ],
  [ReportCategories.reportsEscalationsPerMonth]: [
    {
      name: "month",
      label: "Month",
    },
    {
      name: "number_of_escalations",
      label: "Escalations",
    },
  ],
};

export default columns;
