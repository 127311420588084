import React from "react";
import { useQueryClient } from "react-query";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextFilter from "./TextFilter";
import { Query, ViewType } from "../../types";
import {
  useCategories,
  useEscalators,
  usePriorities,
  useStages,
  useStatuses,
  useSubscribers,
  useSystems,
  useTags,
  useTypes,
} from "../../../../hooks/tickets/autocomplete";
import AutocompleteFilterMultiple from "./AutocompleteFilterMultiple";
import SelectorFilter, { SelectorOption } from "./SelectorFilter";
import SwitchFilter from "./SwitchFilter";
import DateFilter from "./DateFilter";
import CombinedFilter from "./CombinedFilter";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import {
  useContacts,
  useCustomers,
  useTeams,
  useUsers,
} from "../../../../hooks/autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnWrap: {
      display: "flex",
      alignContent: "space-evenly",
      flexWrap: "wrap",
      boxSizing: "content-box",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(1244)]: {
        width: "150px",
      },
    },
  })
);

interface Props {
  view?: ViewType;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ view = ViewType.table, query, setQuery }: Props) {
  const queryClient = useQueryClient();
  const classes = useStyles();

  const ticketStates: Array<SelectorOption> = [
    { label: "Open", value: 1 },
    { label: "Closed", value: 0 },
  ];

  const slaStates: Array<SelectorOption> = [
    { label: "In SLA's", value: 1 },
    { label: "Out SLA's", value: 0 },
  ];

  const slaResponseStates: Array<SelectorOption> = [
    { label: "In SLA's", value: 1 },
    { label: "Out SLA's", value: 0 },
  ];

  return (
    <Box data-cy="available-filters-section" className={classes.columnWrap}>
      <Box pr={1.5} className={classes.column}>
        <TextFilter
          filterName="ID"
          filterKey="identifiers"
          inputType="number"
          queryPart={query.identifiers || []}
          setQuery={setQuery}
        />
        {hasPermission(PermissionCodes.ticketsEscalatedView) && (
          <SwitchFilter
            filterName="Escalated Only"
            filterKey="is_escalated"
            query={query}
            setQuery={setQuery}
          />
        )}
        <SwitchFilter
          filterName="Watched Only"
          filterKey="is_watched_by_auth_user"
          query={query}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        <SelectorFilter
          filterName="Ticket State"
          filterKey="is_open"
          options={ticketStates}
          query={query}
          setQuery={setQuery}
        />
        <AutocompleteFilterMultiple
          filterName="Ticket Type"
          filterKey="type_ids"
          queryFn={useTypes}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "types",
            { ids: query.type_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        <TextFilter
          filterName="Short Description"
          filterKey="short_descriptions"
          queryPart={query.short_descriptions || []}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        <AutocompleteFilterMultiple
          filterName="Company Name"
          filterKey="customer_ids"
          queryFn={useCustomers}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "customers",
            { ids: query.customer_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        <AutocompleteFilterMultiple
          filterName="System"
          filterKey="system_ids"
          queryFn={useSystems}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "systems",
            { ids: query.system_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        <AutocompleteFilterMultiple
          filterName="Severity"
          filterKey="priority_ids"
          queryFn={usePriorities}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "priorities",
            { ids: query.priority_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        <AutocompleteFilterMultiple
          filterName="Categories"
          filterKey="category_ids"
          queryFn={useCategories}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "categories",
            { ids: query.category_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        {view !== ViewType.kanban && (
          <AutocompleteFilterMultiple
            filterName="Statuses"
            filterKey="status_ids"
            queryFn={useStatuses}
            alreadySelected={queryClient.getQueryData([
              "autocomplete",
              "tickets",
              "statuses",
              { ids: query.status_ids },
            ])}
            query={query}
            setQuery={setQuery}
          />
        )}
        {hasPermission(PermissionCodes.ticketsViewAnyStage) &&
          view !== ViewType.kanban && (
            <AutocompleteFilterMultiple
              filterName="Substages"
              filterKey="stage_ids"
              queryFn={useStages}
              alreadySelected={queryClient.getQueryData([
                "autocomplete",
                "tickets",
                "stages",
                { ids: query.stage_ids },
              ])}
              query={query}
              setQuery={setQuery}
            />
          )}
      </Box>
      <Box pr={1.5} className={classes.column}>
        <AutocompleteFilterMultiple
          filterName="Tags"
          filterKey="tag_ids"
          queryFn={useTags}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "n/a",
            "tags",
            { ids: query.stage_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        <AutocompleteFilterMultiple
          filterName="Teams"
          filterKey="team_ids"
          queryFn={useTeams}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "teams",
            { ids: query.team_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
        <AutocompleteFilterMultiple
          filterName="Users"
          filterKey="user_ids"
          queryFn={useUsers}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "team-all",
            "users",
            { ids: query.user_ids },
          ])}
          query={query}
          setQuery={setQuery}
          textFrom="full_name"
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        <AutocompleteFilterMultiple
          filterName="Contacts"
          filterKey="contact_ids"
          queryFn={useContacts}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "contacts",
            { ids: query.contact_ids },
          ])}
          query={query}
          setQuery={setQuery}
          textFrom="full_name"
        />
        <AutocompleteFilterMultiple
          filterName="Subscribers"
          filterKey="subscriber_ids"
          queryFn={useSubscribers}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "tickets",
            "subscribers",
            { references: query.subscriber_ids },
          ])}
          query={query}
          setQuery={setQuery}
          textFrom="full_name"
          valueFrom="reference"
        />
        <Box pr={1.5} className={classes.column}>
          <AutocompleteFilterMultiple
            filterName="Escalated by"
            filterKey="escalated_by_ids"
            queryFn={useEscalators}
            alreadySelected={queryClient.getQueryData([
              "autocomplete",
              "tickets",
              "escalators",
              { references: query.escalated_by_ids },
            ])}
            query={query}
            setQuery={setQuery}
            textFrom="full_name"
            valueFrom="reference"
          />
        </Box>
      </Box>
      <Box pr={1.5} className={classes.column}>
        <DateFilter
          filterName="Opened From"
          filterKey="opened_after"
          query={query}
          setQuery={setQuery}
        />
        <DateFilter
          filterName="Opened To"
          filterKey="opened_before"
          query={query}
          setQuery={setQuery}
        />
        <CombinedFilter
          filterName="Opened By"
          switchFilterName="Opened by System"
          filterKey="opened_by_ids"
          switchFilterKey="is_opened_by_system"
          queryFn={useUsers}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "team-all",
            "users",
            { ids: query.opened_by_ids },
          ])}
          query={query}
          setQuery={setQuery}
          textFrom="full_name"
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        <DateFilter
          filterName="Resolved From"
          filterKey="resolved_after"
          query={query}
          setQuery={setQuery}
        />
        <DateFilter
          filterName="Resolved To"
          filterKey="resolved_before"
          query={query}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1.5} className={classes.column}>
        {hasPermission(PermissionCodes.slaCalendarViewAny) && (
          <SelectorFilter
            filterName="Target Resolution"
            filterKey="is_in_SLA"
            options={slaStates}
            query={query}
            setQuery={setQuery}
            allLabel="All SLA's"
          />
        )}
        {hasPermission(PermissionCodes.slaCalendarViewAny) && (
          <SelectorFilter
            filterName="Target Response"
            filterKey="is_in_response_SLA"
            options={slaResponseStates}
            query={query}
            setQuery={setQuery}
            allLabel="All SLA's"
          />
        )}
      </Box>
    </Box>
  );
}

export default Component;
