import * as api from "./";
import {
  mapPropertiesToQuery,
  mapQueryToProperties,
} from "../helpers/functions";
import { Filter } from "../types";
import { Query } from "../screens/Tickets/types";

export function create(params: Filter, query: any) {
  return api.post("tickets/filters", {
    is_public: false,
    ...params,
    properties: mapQueryToProperties(query),
  });
}

export function duplicate(id: number) {
  return api.post(`tickets/filters/${id}/duplicate`);
}

export function remove(id: number) {
  return api.remove(`tickets/filters/${id}`);
}

export function show(id: number) {
  return api.get(`tickets/filters/${id}`);
}

export function update(filter: Filter, query?: Partial<Query>) {
  return api.put(`tickets/filters/${filter.id}`, {
    is_default: filter.is_default,
    is_public: filter.is_public,
    name: filter.name,
    teams: filter.teams,
    properties: query
      ? mapQueryToProperties(query)
      : mapQueryToProperties(mapPropertiesToQuery(filter.properties || [])), // genius
  });
}

export function moveUp(id: number) {
  return api.put(`tickets/filters/${id}/move-up`);
}

export function moveDown(id: number) {
  return api.put(`tickets/filters/${id}/move-down`);
}
