import React from "react";
import { useParams } from "react-router-dom";
import Create from "../../../Tasks/Create";
import Table from "../../../Tasks";
import { LooseObject, Ticket } from "../../../../types";

type Props = {
  ticket: Ticket;
};

function Component({ ticket }: Props) {
  const { action = "search" } = useParams<LooseObject>();

  if (action === "search") {
    return (
      <Table
        elevation={0}
        embedded
        owner={`tickets/${ticket.id}/`}
        ticket={ticket}
      />
    );
  }

  return <Create embedded={true} owner={`tickets/${ticket.id}/`} />;
}

export default Component;
