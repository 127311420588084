import React from "react";
import { format } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import FreeSearch from "../../../components/FreeSearch";
import { eventLevels, eventStatuses, Query } from "../types";
import { dateOrNull } from "../../../helpers/functions";

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
};

function Component({ query, resetQuery, setQuery }: Props) {
  return (
    <Grid container spacing={1}>
      <Button color="primary" onClick={resetQuery} size="small" variant="text">
        RESET
      </Button>

      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="Event ID"
          name="id"
          onDebouncedChange={(value) => setQuery({ id: value })}
          size="small"
          value={query.id ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Severity"
          name="level"
          onChange={(event) => setQuery({ level: event.target.value })}
          select
          size="small"
          value={query.level ?? ""}
          variant="outlined"
        >
          {eventLevels.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="State"
          name="status"
          onChange={(event) => setQuery({ status: event.target.value })}
          select
          size="small"
          value={query.status ?? ""}
          variant="outlined"
        >
          {eventStatuses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        {/* todo */}
        <FreeSearch
          fullWidth
          label="Company"
          name="company"
          onDebouncedChange={(value) => setQuery({ company: value })}
          size="small"
          value={query.company ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          autoOk
          disableToolbar
          format="dd/MM/yyy"
          fullWidth
          inputVariant="outlined"
          label="Reported"
          margin="none"
          name="processed_at"
          onChange={(date) =>
            setQuery({
              processed_at: format(date, "yyy-MM-dd"),
            })
          }
          size="small"
          value={dateOrNull(query.processed_at ?? "")}
          variant="inline"
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="Asset"
          name="asset_name"
          onDebouncedChange={(value) => setQuery({ asset_name: value })}
          size="small"
          value={query.asset_name ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="Threshold"
          name="threshold"
          onDebouncedChange={(value) => setQuery({ threshold: value })}
          size="small"
          value={query.threshold ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="LM Reference"
          name="external_reference"
          onDebouncedChange={(value) => setQuery({ external_reference: value })}
          size="small"
          value={query.external_reference ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="Alert Value"
          name="alert"
          onDebouncedChange={(value) => setQuery({ alert: value })}
          size="small"
          value={query.alert ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          fullWidth
          label="Rule"
          name="rule"
          onDebouncedChange={(value) => setQuery({ rule: value })}
          size="small"
          value={query.rule ?? ""}
          variant="outlined"
          wait={500}
        />
      </Grid>
    </Grid>
  );
}

export default Component;
