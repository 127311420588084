import React from "react";
import styled, { keyframes } from "styled-components";
import { Box } from "@material-ui/core";

const slide = keyframes`
  to {
    background-position: -100% 0;
  }
`;
const Bar = styled.div<{ $width: string }>`
  background-color: rgba(255, 255, 255, 0.2);
  background-image: linear-gradient(
    90deg,
    transparent 0,
    rgba(255, 255, 255, 0.4) 20px,
    transparent 40px
  );
  background-size: 200%;
  background-position: 20% 0;
  background-repeat: no-repeat;
  animation: ${slide} 2s linear infinite;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${(p) => p.$width};
  height: 16px;
`;

export const SkeletonList = () => {
  return (
    <Box m={1}>
      <Bar $width="100%" />
      <Bar $width="100%" />
      <Bar $width="60%" />
      <Bar $width="0" />
      <Bar $width="100%" />
      <Bar $width="100%" />
      <Bar $width="60%" />
    </Box>
  );
};
