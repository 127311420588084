import React from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import Loading from "../../../layout/Loading";
import Panel from "./Panel";
import { useStatuses } from "../../../hooks/tasks";
import { Status } from "../../../types";
import { Query } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      width: "100%",
      position: "relative",
      "&::before": {
        content: '""',
        width: "16px",
        height: "100%",
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1), rgba(255,0,0,0))",
        position: "absolute",
        zIndex: 900,
      },
      "&::after": {
        content: '""',
        width: "16px",
        height: "100%",
        backgroundImage:
          "linear-gradient(to left, rgba(255,255,255,1), rgba(255,0,0,0))",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 900,
      },
    },
    box: {
      alignItems: "flex-start",
      display: "flex",
      padding: "0 16px",
      overflowX: "scroll",
      overflowY: "hidden",
    },
  })
);

type Props = {
  query: Query;
};

function Component({ query }: Props) {
  const classes = useStyles();
  const statuses = useStatuses("");

  return (
    <Loading
      loading={statuses.isLoading || statuses.isFetching}
      error={statuses.isError ? "Can't load kanban" : undefined}
    >
      <div className={classes.wrap}>
        <Box data-cy="tasks-kanban" className={classes.box}>
          {statuses.data?.data?.map((status: Status, index: number) => (
            <Panel
              key={status.id}
              query={query}
              status={status}
              showDivider={index !== statuses.data?.data?.length - 1}
            />
          ))}
        </Box>
      </div>
    </Loading>
  );
}

export default Component;
