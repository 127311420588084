import React, { useState } from "react";
import Autocomplete from "../../../../components/Autocomplete";
import { useAuditTags } from "../../../../hooks/tickets/autocomplete";
import { Query } from "../../types";
import { AuditTag } from "../../../../types";

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ query, setQuery }: Props) {
  const [fetchTags, setFetchTags] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const auditTags = useAuditTags("", {
    enabled: fetchTags || !!query.tags?.length,
  });

  const value = (auditTags.data?.data || []).filter((auditTag: AuditTag) =>
    (query.tags as string[])?.some(
      (tag: string) => tag === auditTag?.value.toString()
    )
  );

  const handleSelect = (tags: AuditTag[]) => {
    setQuery({
      tags: tags.map((tag: AuditTag) => tag.value),
    });
  };

  const clearFilter = () => {
    setTagInput("");
  };

  return (
    <Autocomplete
      data-cy="audits-tag-filter-autocomplete"
      options={
        auditTags.data?.data
          .filter((tag) => !query.tags?.includes(tag.value))
          .filter((tag) => tag.value.includes(tagInput)) || []
      }
      inputValue={tagInput}
      value={value}
      valueFrom="value"
      textFrom="label"
      name="tag"
      label="Tags"
      placeholder="Type to search"
      onSearch={(query: string) => setTagInput(query)}
      onSelect={(tags: AuditTag[]) => handleSelect(tags)}
      onOpen={() => setFetchTags(true)}
      onBlur={clearFilter}
      loading={auditTags.isLoading}
      multiple
      shrink
    />
  );
}

export default Component;
