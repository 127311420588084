import { getErrorMessage } from "@akj-dev/affinity-platform";
import { format, parseISO, intervalToDuration, formatDuration } from "date-fns";
import { Query } from "../screens/Tickets/types";
import { FilterChip } from "./hooks";
import { PermissionCodes } from "./constants";
import { Filter, FilterProperty, Permission, TicketFields } from "../types";

export function formattedDate(
  dateTimeString: string,
  pattern: string = "dd/MM/yy"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.warn(getErrorMessage(e));
  }

  return dateTimeString;
}

export function formattedDateTime(
  dateTimeString: string,
  pattern: string = "HH:mm dd/MM/yyyy"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.warn(getErrorMessage(e));
  }

  return dateTimeString;
}

export function formattedTime(
  dateTimeString: string,
  pattern: string = "HH:mm"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.warn(getErrorMessage(e));
  }

  return dateTimeString;
}

// will convert
// 2d 3h 0m to 2 days and 3 hours
// 0d 3h 0m to 3 hours
export function humanReadableSlaDuration(duration: string) {
  const clean = duration
    .replace("-", "")
    // remove "0d "
    .replace(/^0d\s/, "")
    // remove "0h "
    .replace(/^(\d+d)?(\s)?0h\s/, "$1")
    // remove "0m"
    .replace(/^(\d+d)?(\s)?(\d+h)?(\s)?0m/, "$1$2$3")
    .replace("d", " days")
    .replace("h", " hours")
    .replace("m", " minutes")
    // replace space with "and"
    .replace(/\s(\d+)/g, " and $1");

  return clean;
}

export const dateOrNull = (date: string) => (date ? parseISO(date) : null);

export const hoursAndMinutesToHumanReadable = (
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0
): string => {
  const days = Math.floor(hours / 24);
  const formattedHours = hours % 24;
  return formatDuration({ days, hours: formattedHours, minutes, seconds });
};

export const addWeeks = (duration: Duration): Duration => {
  if (!duration.weeks) {
    duration.weeks = ((duration.days ?? 0) / 7) | 0;
    duration.days = (duration.days ?? 0) - duration.weeks * 7;
  }

  return duration;
};

export const secondsToHumanReadable = (
  seconds: number,
  options?: Object
): string =>
  formatDuration(
    addWeeks(
      intervalToDuration({
        start: new Date(Date.now() - seconds * 1000),
        end: new Date(),
      })
    ),
    options
  );

export const formatContactFields = (
  fields: TicketFields & { [x: string]: any }
) => {
  const newFields: TicketFields & { [x: string]: any } = {};
  for (const field in fields) {
    if (field.includes("contact"))
      newFields[field.split(".").join("[") + "]"] = fields[field];
    else newFields[field] = fields[field];
  }
  return newFields;
};

// event functions
export const formatSeverity = (severityId: number): string => {
  switch (severityId) {
    case 1:
      return "Low";
    case 2:
      return "Medium";
    case 3:
      return "High";
    case 4:
      return "Critical";
    default:
      return "N/A";
  }
};

export const getDefaultFilter = () => {
  const user = JSON.parse(
    localStorage.getItem("affinity-support.user") ?? "{}"
  );

  return user?.default_ticket_filter;
};

// permissions
export const getPermissionsFromLocalStorage = () => {
  const user = JSON.parse(
    localStorage.getItem("affinity-support.user") ?? "{}"
  );

  return user?.permissions?.map((permission: Permission) => permission.name);
};

export function hasPermission(permissionCode: PermissionCodes) {
  const userPermissions = getPermissionsFromLocalStorage();

  if (!userPermissions.length) return false;

  return !!userPermissions.find(
    (permission: string) =>
      permission === permissionCode || permission === PermissionCodes.all
  );
}

export function hasAnyPermission(permissionCodes: Array<PermissionCodes>) {
  return permissionCodes
    .map(function (permissionCode: PermissionCodes) {
      return hasPermission(permissionCode);
    })
    .find((ok) => ok === true);
}

// filters

export const mapPropertiesToQuery = (properties: FilterProperty[]) => {
  return properties.reduce((previous, item) => {
    return {
      ...previous,
      [item.key]: item.value,
    };
  }, {});
};

export const mapQueryToProperties = (query: Partial<Query>) => {
  const properties: FilterProperty[] = [];
  for (const property in query) {
    //@ts-ignore
    if (query[property] !== undefined) {
      //@ts-ignore
      properties.push({ key: property, value: query[property] });
    }
  }

  return properties;
};

export const formatFiltersResponse = (response: Filter[]) => {
  const formattedResponse = response.map((filter: Filter) => {
    return {
      ...filter,
      properties: filter?.properties
        ?.filter(
          (prop: FilterProperty) =>
            prop?.value || prop?.value.toString() === "0"
        )
        ?.map((property: FilterProperty) => {
          return {
            key: property.key,
            value: property.value.includes(",")
              ? property.value.split(",")
              : property.value,
          };
        }),
    };
  });
  return formattedResponse;
};

export const formatArrayParamWithData = (
  name: string,
  key: string,
  nameKey: string,
  items: any,
  lookup: string = "id"
) => {
  return items.map((item: any) => ({
    id: JSON.stringify(item),
    name,
    key,
    valueId: item[lookup],
    valueName: item[nameKey],
  }));
};

export const formatArrayParamWithoutData = (
  name: string,
  key: string,
  values: any[] = []
) => {
  const chips: FilterChip[] = [];
  values.map((i: any) => {
    chips.push({
      id: JSON.stringify(i),
      name,
      key,
      valueId: i,
      valueName: formatNumberValues(key, i),
    });
    return true;
  });
  return chips;
};

export const formatNonArrayParamWithoutData = (
  name: string,
  key: string,
  queryItem: any
) => {
  const chips: FilterChip[] = [];
  chips.push({
    id: `${name}-${key}-${queryItem}`,
    key,
    name,
    valueName: formatNumberValues(key, queryItem),
    valueId: queryItem,
  });
  return chips;
};

export const formatNumberValues = (key: string, value: string | number) => {
  if (key === "is_open") {
    if (value === "0" || value === 0) return "Closed";
    if (value === "1" || value === 1) return "Open";
  }

  if (
    key === "is_escalated" ||
    key === "is_watched_by_auth_user" ||
    key === "is_opened_by_system"
  ) {
    if (value === "1" || value === 1) return "Yes";
  }

  if (key === "is_in_SLA" || key === "is_in_response_SLA") {
    if (value === "0" || value === 0) return "Out of SLA";
    if (value === "1" || value === 1) return "In SLA";
  }

  if (
    key === "resolved_before" ||
    key === "resolved_after" ||
    key === "opened_before" ||
    key === "opened_after"
  ) {
    return formattedDate(value as string);
  }

  return value?.toString() ?? value;
};

export const hasAnyFilterApplied = (query: Partial<Query>) => {
  for (const property in query) {
    if (
      property !== "direction" &&
      property !== "limit" &&
      property !== "range" &&
      property !== "sort" &&
      property !== "page" &&
      query[property as keyof Partial<Query>] !== undefined
    ) {
      return true;
    }
  }
  return false;
};
