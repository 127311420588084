import React from "react";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import ColumnItem from "./Column";
import { Column } from "../../../../types";

interface Props {
  columns: Column[];
  disabled?: boolean;
  updateColumn: (column: Column, fields: Partial<Column>) => void;
}

export default function Columns({ columns, disabled, updateColumn }: Props) {
  return (
    <Droppable
      enabled
      onDrop={(data: any) => {}}
      style={{ alignSelf: "stretch", height: "100%" }}
      types="column"
    >
      {columns
        .sort((a, b) =>
          a.order ?? 2 > b.order ?? 2 ? 1 : a.order ?? 2 < b.order ?? 2 ? -1 : 0
        )
        .map((column: Column) => (
          <ColumnItem
            column={column}
            disabled={disabled}
            key={column.id}
            updateColumn={updateColumn}
          />
        ))}
    </Droppable>
  );
}
