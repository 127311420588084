import React from "react";

import { Link } from "react-router-dom";
// @ts-ignore
import { Draggable } from "react-drag-and-drop";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Check, Close, Pause, TrendingUp, Person } from "@material-ui/icons";
import { Status, Ticket } from "../../../../types";
import { formattedDate, hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useCustomers } from "../../../../hooks/autocomplete";

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  active: {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.light,
  },
  card: {
    "&.active": {
      "&:hover": {
        boxShadow: theme.shadows[12],
      },
    },

    "& .MuiCardContent-root": {
      paddingBottom: 0,
    },

    "& .MuiCardActions-root": {
      "& .sla_status svg": {
        position: "relative",
        top: theme.spacing(0.1),
        width: theme.typography.h5.fontSize,
        height: theme.typography.h5.fontSize,
      },
    },

    "&.priorityBorder .MuiCardActions-root": {
      borderBottom: ({ priorityColor }) =>
        `${theme.shape.borderRadius}px solid ${priorityColor}`,
    },

    "&.in_sla .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.success.main}`,

      "& .sla_status svg": {
        color: theme.palette.success.main,
      },
    },

    "&.out_sla .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.error.main}`,

      "& .sla_status svg": {
        color: theme.palette.error.main,
      },
    },

    "&.paused_sla .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.info.main}`,

      "& .sla_status svg": {
        color: theme.palette.info.main,
      },
    },

    "& .MuiGrid-item": {
      padding: theme.spacing(0.25),
    },
  },
  chip: {
    maxWidth: "100%",
  },
  link: {
    textDecoration: "none",
    width: "100%",
  },
  escalated: {
    fontSize: "1.3em",
    paddingRight: "3px",
    marginBottom: "-3px",
    color: theme.palette.error.main,
  },
}));

interface StyleProps {
  priorityColor: string;
}

interface Props {
  ticket: Ticket;
  status: Status;
}

export default function Component({ ticket, status }: Props) {
  const styleProps: StyleProps = { priorityColor: ticket.priority.color };
  const classes = useStyles(styleProps);

  const customers = useCustomers();

  const SLA = () => (
    <Typography className="sla_status" variant="button">
      {ticket.is_in_s_l_a && !ticket.is_s_l_a_paused && (
        <>
          <Check fontSize="inherit" /> In SLA
        </>
      )}
      {!ticket.is_in_s_l_a && !ticket.is_s_l_a_paused && (
        <>
          <Close fontSize="inherit" /> Out SLA
        </>
      )}
      {ticket.is_s_l_a_paused && (
        <>
          <Pause fontSize="inherit" /> SLA Paused
        </>
      )}
    </Typography>
  );

  return (
    <Draggable
      data={[ticket.id, status.is_open, status.resolves_ticket, status.id]}
      style={{ width: "100%" }}
      type="ticket"
      enabled={
        hasPermission(PermissionCodes.ticketsUpdateStatus) &&
        (status.is_open || (status.resolves_ticket && !ticket.is_s_l_a_paused))
      }
    >
      <Link className={classes.link} to={"/support/tickets/" + ticket.id}>
        <Card
          className={
            classes.card +
            " " +
            (hasPermission(PermissionCodes.ticketsUpdateStatus) &&
              status.is_open &&
              "active") +
            " " +
            (!hasPermission(PermissionCodes.slaCalendarView)
              ? "priorityBorder"
              : "") +
            " " +
            (ticket.is_in_s_l_a && !ticket.is_s_l_a_paused ? "in_sla" : "") +
            (!ticket.is_in_s_l_a && !ticket.is_s_l_a_paused ? "out_sla" : "") +
            (ticket.is_s_l_a_paused ? "paused_sla" : "")
          }
          elevation={3}
        >
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography className={classes.link} variant="caption">
                  {ticket.escalation?.escalated_at && (
                    <Tooltip title="Ticket escalated">
                      <TrendingUp
                        className={classes.escalated}
                        fontSize="inherit"
                      />
                    </Tooltip>
                  )}
                  {ticket.identifier} {ticket.priority.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                  align="right"
                  color="textSecondary"
                  variant="caption"
                >
                  {formattedDate(ticket.opened_at, "dd MMM ''yy")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography noWrap variant="h4">
                  {(customers.data?.data?.length ?? 0) > 1 ||
                  !hasPermission(PermissionCodes.customer)
                    ? ticket.customer?.name
                    : ticket.category?.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mb={0.5} overflow="hidden">
                  {ticket.system?.name && (
                    <Chip
                      label={ticket.system?.name}
                      size="small"
                      className={classes.chip}
                    />
                  )}
                </Box>
              </Grid>
              {ticket.user && (
                <Grid item xs={12}>
                  <Box mb={0.5}>
                    <Tooltip title="Assigned User">
                      <Typography noWrap>
                        <Person fontSize="inherit" /> {ticket.user?.full_name}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          {!hasPermission(PermissionCodes.slaCalendarView) && <CardActions />}
          {hasPermission(PermissionCodes.slaCalendarView) && (
            <CardActions>
              <Divider />
              <Grid container spacing={0}>
                <Grid item xs={ticket.resolution_date ? 6 : 12}>
                  <SLA />
                </Grid>
                {ticket.resolution_date && (
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Typography
                      align="right"
                      variant="caption"
                      color="textSecondary"
                    >
                      {formattedDate(ticket.resolution_date, "dd MMM ''yy")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardActions>
          )}
        </Card>
      </Link>
    </Draggable>
  );
}
