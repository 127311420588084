import { useQuery, useQueryClient, useMutation } from "react-query";
import * as api from "../api/messages";
import {
  ClosureReasonsData,
  MessageData,
  Query,
} from "../screens/Mailbox/types";
import { LooseObject, Message, QueryData } from "../types";

export function useClosureReasons(query: string, options = {}) {
  return useQuery<ClosureReasonsData, Error>(
    ["autocomplete", "messages", "closure-reasons", query],
    () => api.closureReasons({ query }),
    options
  );
}

// ?
export function useCreate(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["messages"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((params) => api.create(params), {
    ...options,
    onSuccess,
  });
}

export function useMessageDetail(messageId: number, options = {}) {
  return useQuery<MessageData, Error>(
    ["message", messageId],
    () => api.show(messageId),
    options
  );
}

export function usePaginate(query: Partial<Query>, options = {}) {
  return useQuery<QueryData<Message>, Error>(
    ["messages", query],
    () => api.paginate(query),
    options
  );
}

export function useReply(id: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["message", id]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };
  return useMutation(({ id, params }) => api.reply(id, params), {
    ...options,
    onSuccess,
  });
}

export function useUpdate(id?: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["message", id]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation(({ id, params }) => api.update(id, params), {
    ...options,
    onSuccess,
  });
}
