import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { EventLevel } from "../../../../types";
import { SortDirection } from "../../../../types";
import { EventsQueryType, Query } from "../../types";
import { formattedDateTime } from "../../../../helpers/functions";

type Props = {
  paginator: EventsQueryType;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({ paginator, query, setQuery }: Props) {
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction : "none";

  // todo columns
  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Event ID",
      options: {
        customBodyRender: (value: string) => (
          <Button
            color="primary"
            size="small"
            component={Link}
            to={`/support/events/${value}`}
          >
            #{value}
          </Button>
        ),
        sortDirection: getSortDirection("id"),
      },
    },
    {
      name: "level",
      label: "Severity",
      options: {
        customBodyRender: (value: EventLevel) => (
          <div style={{ color: value.color }}>{value.name}</div>
        ),
        sortDirection: getSortDirection("level.name"),
      },
    },
    {
      name: "status.name",
      label: "State",
      options: {
        sortDirection: getSortDirection("status.name"),
      },
    },
    {
      name: "company", // todo
      label: "Company Name",
      options: {
        customBodyRender: (value: string) => `${value || "Company"}`,
        sortDirection: getSortDirection("company"),
      },
    },
    {
      name: "processed_at", // todo
      label: "Reported",
      options: {
        customBodyRender: (value: string) =>
          formattedDateTime(value, "dd/MM/yyyy HH:mm"),
        sortDirection: getSortDirection("processed_at"),
      },
    },
    {
      name: "asset_name",
      label: "Asset",
      options: {
        sortDirection: getSortDirection("asset_name"),
      },
    },
    {
      name: "threshold",
      label: "Threshold",
      options: {
        sortDirection: getSortDirection("threshold"),
      },
    },
    {
      name: "external_reference",
      label: "LM Reference",
      options: {
        sortDirection: getSortDirection("external_reference"),
      },
    },
    {
      name: "alert",
      label: "Alert Value",
      options: {
        sortDirection: getSortDirection("alert"),
      },
    },
    {
      name: "rule",
      label: "Rule",
      options: {
        sortDirection: getSortDirection("rule"),
      },
    },
    {
      name: "convert",
      label: " ",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return (
            <Button
              color="primary"
              size="small"
              component={Link}
              to="/support/new-ticket"
            >
              Convert
            </Button>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: paginator.data?.meta.total,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="events-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Events</Typography>
          </Box>
          {paginator.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={paginator.data?.data || []}
      columns={columns}
      options={options}
    />
  );
}
