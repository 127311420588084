import React from "react";
import { Alert } from "@akj-dev/design-system";
import Loading from "../../../layout/Loading";
import Table from "./components/Table";
import { CommentFields } from "../../../types";
import { useComments } from "../../../hooks/comments";
import { Query } from "../types";

interface Props {
  owner: string;
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
  showAddCommentForm: boolean;
  setShowAddCommentForm: React.Dispatch<React.SetStateAction<boolean>>;
  query: Partial<Query>;
}

export default function Component({
  owner,
  fields,
  setFields,
  clearFields,
  showAddCommentForm,
  setShowAddCommentForm,
  query,
}: Props) {
  const comments = useComments(owner, query);
  const { data, isLoading, isError, error } = comments;
  const hasData = data?.pages?.length && data?.pages[0].data?.length > 0;

  return (
    <Loading
      error={
        isError
          ? error instanceof Error
            ? error.message
            : "Unknown Error"
          : ""
      }
      loading={isLoading}
    >
      {!isLoading && !hasData && (
        <Alert type="info" message="No comments added yet." />
      )}
      {hasData && (
        <Table
          data-cy="comments-table"
          owner={owner}
          comments={comments}
          fields={fields}
          setFields={setFields}
          clearFields={clearFields}
          showAddCommentForm={showAddCommentForm}
          setShowAddCommentForm={setShowAddCommentForm}
        />
      )}
    </Loading>
  );
}
