import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";
import { format } from "date-fns";
import {
  Grid,
  Paper,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Add as AddIcon, ChevronLeft } from "@material-ui/icons";
import Main from "../../../layout/Main";
import Info from "./Info";
import Details from "./components/Details";
import TimerTable from "../../../components/TimerTable";
import InfoItem from "../../../components/InfoItem";
import { constants } from "../../../helpers";
import { useTaskDetail } from "../../../hooks/tasks";
import { usePaginate } from "../../../hooks/timers";
import {
  LooseObject,
  SortDirectionQueryParam,
  TimerType,
  TimerFields,
} from "../../../types";

export default function Component() {
  const { id } = useParams<LooseObject>();
  const { data, isLoading, isFetching, isError, error } = useTaskDetail(
    parseInt(id)
  );

  const [query, setQuery] = useQueryParams({
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
    limit: withDefault(NumberParam, constants.limit.timers),
    page: withDefault(NumberParam, 1),
  });

  const initialState: TimerFields = {
    hours: "",
    minutes: "",
    message: "",
    is_billable: false,
    started_at: format(
      new Date(new Date().setHours(0, 0, 0, 0)),
      "yyy-MM-dd HH:mm:ss"
    ),
  };

  const [timerFields, setTimerFields] = useState(initialState);
  const setFields = (fields: Partial<TimerFields>) =>
    setTimerFields((oldFields) => ({ ...oldFields, ...fields }));

  const resetFields = () => setTimerFields(initialState);

  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [addingNewTimeLog, setAddingNewTimeLog] = useState(false);

  const paginator = usePaginate(parseInt(id), TimerType.task, query);

  return (
    <Main
      error={isError ? error?.message : ""}
      title={`Task ${id}`}
      loading={isLoading || !data?.data?.id}
      stickyHeader
    >
      <Box display="flex" width="100%" pb={1}>
        <Button
          data-cy="task-back-button"
          startIcon={<ChevronLeft />}
          variant="text"
          color="primary"
          component={Link}
          to="/support/tasks"
        >
          Back to all Tasks
        </Button>
      </Box>
      {data?.data && (
        <Paper elevation={4} style={{ width: "100%" }}>
          <Box m={1.5}>
            <Info task={data.data} />

            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Details task={data.data} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InfoItem
                  title="Total Time Logged"
                  value={
                    isLoading || isFetching ? (
                      <CircularProgress size={20} style={{ marginTop: 5 }} />
                    ) : (
                      data.data.time_logged_human_readable
                    )
                  }
                  hasBottomMargin
                  key={Math.random()}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    data-cy="tasks-log-time-button"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => setAddingNewTimeLog(true)}
                    disabled={
                      addingNewTimeLog ||
                      editingRowIndex > -1 ||
                      isLoading ||
                      isFetching ||
                      paginator.isLoading ||
                      paginator.isFetching
                    }
                  >
                    Log Time
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TimerTable
                  paginator={paginator}
                  entity={data.data}
                  timerType={TimerType.task}
                  fields={timerFields}
                  setFields={setFields}
                  resetFields={resetFields}
                  addingNewTimeLog={addingNewTimeLog}
                  editingRowIndex={editingRowIndex}
                  setEditingRowIndex={setEditingRowIndex}
                  setAddingNewTimeLog={setAddingNewTimeLog}
                  query={query}
                  setQuery={setQuery}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Main>
  );
}
