import React, { useEffect, useMemo, useState } from "react";
import { uniqBy, isEqual } from "lodash";
import FilterPopup from "./FilterPopup";
import Autocomplete from "../../../../components/Autocomplete";
import { QueryResult } from "../../../../types";
import { Query, QueryItem } from "../../types";
import { ArrayFilterValue } from "../types";
import { useDebounce } from "../../../../helpers/hooks";

interface Props {
  filterName: string;
  filterKey: string;
  valueFrom?: string;
  textFrom?: string;
  queryFn: any;
  alreadySelected?: QueryResult<any>;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({
  filterName,
  filterKey,
  valueFrom = "id",
  textFrom = "name",
  queryFn,
  alreadySelected,
  query,
  setQuery,
}: Props) {
  const queryItem = query?.[filterKey as QueryItem] as ArrayFilterValue;
  const [localSelected, setLocalSelected] = useState(
    alreadySelected?.data ?? []
  );
  const [input, setInput] = useState<string | undefined>(undefined);
  const debouncedInput = useDebounce(input ?? "");

  const [selectedValue, setSelectedValue] = useState(queryItem);

  const queryData = queryFn(
    { query: debouncedInput },
    { enabled: input !== undefined || !!query?.[filterKey as QueryItem] }
  );

  const options = useMemo(
    () => uniqBy(localSelected?.concat(queryData?.data?.data ?? []), valueFrom),
    [localSelected, queryData?.data?.data, valueFrom]
  );

  useEffect(() => {
    const newSelected = options.filter((item: any) =>
      selectedValue?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    );
    if (!isEqual(newSelected, localSelected)) setLocalSelected(newSelected);
  }, [options, selectedValue, localSelected, setLocalSelected, valueFrom]);

  // update selected values according to values in query
  useEffect(() => {
    const newSelected = options.filter((item: any) =>
      queryItem?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    );
    if (!isEqual(newSelected, localSelected))
      setSelectedValue(
        (newSelected as ArrayFilterValue).map((v: any) =>
          v[valueFrom].toString()
        )
      );
    // :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, filterKey, valueFrom]);

  const getSelected = () =>
    options.filter((item: any) =>
      selectedValue?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    ) || [];

  const applyFilter = (handleClose: Function) => {
    setQuery({ [filterKey]: selectedValue });
    handleClose();
  };

  const resetValue = () =>
    setSelectedValue(query?.[filterKey as QueryItem] as ArrayFilterValue);

  return (
    <FilterPopup
      filterName={filterName}
      applyFilter={applyFilter}
      resetValue={resetValue}
    >
      <div style={{ width: "260px" }}>
        <Autocomplete
          data-cy={`ticket-filter-autocomplete-${filterName}`}
          loading={queryData.isLoading}
          label={filterName}
          name={filterKey}
          placeholder="Type to search"
          shrink
          textFrom={textFrom}
          valueFrom={valueFrom}
          value={getSelected()}
          inputValue={input ?? ""}
          options={options}
          onSearch={(query: string) => setInput(query)}
          onSelect={(value: any) => {
            setInput("");
            setSelectedValue(value.map((v: any) => v[valueFrom].toString()));
          }}
          onBlur={() => setInput("")}
          searchOnFocus={true}
          multiple
        />
      </div>
    </FilterPopup>
  );
}

export default Component;
