import React from "react";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParam,
  useQueryParams,
} from "use-query-params";
import { ButtonGroup, Button } from "@material-ui/core";
import {
  Apps as AppsIcon,
  FilterList as FilterListIcon,
  List as ListIcon,
} from "@material-ui/icons";
import Main from "../../layout/Main";
import ClickPopover from "../../components/ClickPopover";
import Kanban from "./Kanban";
import Filters from "./Filters";
import Table from "./Table";
import { ViewType } from "../Tickets/types";
import { SortDirectionQueryParam, Ticket } from "../../types";
import { constants } from "../../helpers";
import { Helmet } from "react-helmet";

interface Props {
  elevation?: number;
  embedded?: boolean;
  owner?: string;
  ticket?: Ticket;
}

function Tasks({ elevation, embedded, owner = "", ticket }: Props) {
  const [view, setView] = useQueryParam(
    "view",
    withDefault(StringParam, "table")
  );

  const [query, setQuery] = useQueryParams({
    created_before: StringParam,
    created_after: StringParam,
    name: StringParam,
    status_id: NumberParam,
    user_id: NumberParam,
    done_at: StringParam,
    due_before: StringParam,
    due_after: StringParam,
    limit: withDefault(NumberParam, constants.limit.tasks),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "due_date"),
    direction: withDefault(SortDirectionQueryParam, "asc"),
  });

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  const Menu = (
    <ButtonGroup size="small" variant="outlined">
      <ClickPopover trigger={<FilterListIcon />}>
        <div style={{ width: 368, padding: "24px 24px 36px 24px" }}>
          <Filters
            resetQuery={resetQuery}
            query={query}
            setQuery={setQuery}
            view={view}
          />
        </div>
      </ClickPopover>
      <Button
        disabled={view === "table"}
        onClick={() => setView(ViewType.table)}
      >
        <ListIcon />
      </Button>
      <Button
        disabled={view === "kanban"}
        onClick={() => setView(ViewType.kanban)}
      >
        <AppsIcon />
      </Button>
    </ButtonGroup>
  );

  const Switch = ({ children }: { children: React.ReactNode }) => {
    if (embedded) {
      return <>{children}</>;
    }

    return (
      <Main error="" title="Tasks" menu={Menu}>
        {children}
      </Main>
    );
  };

  return (
    <Switch>
      <Helmet>
        <title>Affinity | Support | Tasks</title>
      </Helmet>
      {view === "table" && (
        <Table
          elevation={elevation}
          embedded={embedded}
          owner={owner}
          query={query}
          setQuery={setQuery}
          ticket={ticket}
        />
      )}
      {view !== "table" && <Kanban query={query} />}
    </Switch>
  );
}

export default Tasks;
