import { useCallback, useEffect, useRef, useState } from "react";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  Filter,
  LooseObject,
  SortDirection,
  SortDirectionQueryParam,
} from "../types";
import { constants } from "./index";
import { getDefaultFilter } from "./functions";

export function useOnClickOutside(
  ref: any,
  handler: (event: MouseEvent) => void,
  timeout?: number
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        setTimeout(() => handler(event), timeout || 200);
      };

      document.addEventListener("mousedown", listener);
      // @ts-ignore
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        // @ts-ignore
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, timeout]
  );
}

/**
 * Used to avoid triggering re-renders when passing functions as props that rely on some state.
 *
 * @example
 *
 * function Parent() {
 *  const onChange = useMemoizedCallback((value: T) => {
 *    if (someState) setSomething(true);
 * }, [someState])
 *
 *  return <Child onChange={onChange} />
 * }
 *
 */
export function useMemoizedCallback(callback: any, inputs: any = []) {
  const callbackRef = useRef(callback);
  const memoizedCallback = useCallback((...args) => {
    return callbackRef.current(...args);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatedCallback = useCallback(callback, inputs);
  useEffect(() => {
    callbackRef.current = updatedCallback;
  }, [updatedCallback]);
  return memoizedCallback;
}

/**
 * Used to provide old state values to compare with new.
 *
 * @example
 *
 * useEffectWithPrev(([prevValue1]) => {
 *    if (prevValue1 !== value1) setSomething(true);
 * }, [value1])
 *
 */
export const useEffectWithPrev = (fn: any, inputs: any[] = []) => {
  const prevInputsRef = useRef([...inputs]);
  useEffect(() => {
    fn?.(prevInputsRef.current);
    prevInputsRef.current = [...inputs];
  }, [fn, inputs]);
};

export interface FilterChip {
  id: string;
  key?: string;
  name: string;
  valueId: string | number;
  valueName: string;
}

export const applyFilter = (defaults: LooseObject = {}, filter?: Filter) => {
  if (!filter) {
    return defaults;
  }

  filter.properties?.forEach((property) => {
    if (property.key.indexOf("_ids") > 0) {
      defaults[property.key] = property.value.split(",");
    } else {
      defaults[property.key] = property.value;
    }
  });

  return {
    ...defaults,
    identifiers: defaults.identifiers?.split(","),
    short_descriptions: defaults.short_descriptions?.split(","),
  };
};

export const useAppliedFilter = (filters: Filter[] = []) => {
  const [filter_id] = useQueryParam("filter_id");

  if (filter_id) {
    return filters.find(
      (filter) => filter.id === parseInt((filter_id ?? "") as string)
    );
  } else if (!window.location.search) {
    return getDefaultFilter();
  }

  return undefined;
};

export const useFiltersQuery = (initial?: LooseObject) => {
  const defaults = {
    category_ids: initial?.category_ids,
    child_id: initial?.child_id,
    customer_ids: initial?.customer_ids,
    filter_id: initial?.filter_id,
    identifiers: initial?.identifiers,
    is_escalated: initial?.is_escalated,
    is_in_SLA: initial?.is_in_SLA,
    is_in_response_SLA: initial?.is_in_response_SLA,
    is_open: initial?.is_open,
    is_opened_by_system: initial?.is_opened_by_system,
    is_watched_by_auth_user: initial?.is_watched_by_auth_user,
    keyword: initial?.keyword,
    opened_after: initial?.opened_after,
    opened_before: initial?.opened_before,
    opened_by_ids: initial?.opened_by_ids,
    parent_id: initial?.parent_id,
    priority_ids: initial?.priority_ids,
    range: initial?.range,
    resolved_after: initial?.resolved_after,
    resolved_before: initial?.resolved_before,
    short_descriptions: initial?.short_descriptions,
    stage_ids: initial?.stage_ids,
    status_ids: initial?.status_ids,
    system_ids: initial?.system_ids,
    tag_ids: initial?.tag_ids,
    team_ids: initial?.team_ids,
    type_id: initial?.type_id,
    type_ids: initial?.type_ids,
    user_ids: initial?.user_ids,
    view_id: initial?.view_id,
    contact_ids: initial?.contact_ids,
    subscriber_ids: initial?.subscriber_ids,
    escalated_by_ids: initial?.escalated_by_ids,

    sort: initial?.sort ?? "opened_at",
    direction: initial?.direction ?? ("desc" as SortDirection),
    limit: initial?.limit ?? constants.limit.tickets,
    page: initial?.page ?? 1,
  };

  const [query, setQuery] = useQueryParams({
    category_ids: withDefault(ArrayParam, defaults.category_ids),
    child_id: withDefault(NumberParam, defaults.child_id),
    customer_ids: withDefault(ArrayParam, defaults.customer_ids),
    filter_id: withDefault(NumberParam, defaults.filter_id),
    identifiers: withDefault(ArrayParam, defaults.identifiers),
    is_escalated: withDefault(NumberParam, defaults.is_escalated),
    is_in_SLA: withDefault(NumberParam, defaults.is_in_SLA),
    is_in_response_SLA: withDefault(NumberParam, defaults.is_in_response_SLA),
    is_open: withDefault(NumberParam, defaults.is_open),
    is_opened_by_system: withDefault(NumberParam, defaults.is_opened_by_system),
    is_watched_by_auth_user: withDefault(
      NumberParam,
      defaults.is_watched_by_auth_user
    ),
    keyword: withDefault(StringParam, defaults.keyword),
    opened_after: withDefault(StringParam, defaults.opened_after),
    opened_before: withDefault(StringParam, defaults.opened_before),
    opened_by_ids: withDefault(ArrayParam, defaults.opened_by_ids),
    parent_id: withDefault(NumberParam, defaults.parent_id),
    priority_ids: withDefault(ArrayParam, defaults.priority_ids),
    range: withDefault(StringParam, defaults.range),
    resolved_before: withDefault(StringParam, defaults.resolved_before),
    resolved_after: withDefault(StringParam, defaults.resolved_after),
    short_descriptions: withDefault(ArrayParam, defaults.short_descriptions),
    stage_ids: withDefault(ArrayParam, defaults.stage_ids),
    status_ids: withDefault(ArrayParam, defaults.status_ids),
    system_ids: withDefault(ArrayParam, defaults.system_ids),
    tag_ids: withDefault(ArrayParam, defaults.tag_ids),
    team_ids: withDefault(ArrayParam, defaults.team_ids),
    type_id: withDefault(NumberParam, defaults.type_id),
    type_ids: withDefault(ArrayParam, defaults.type_ids),
    user_ids: withDefault(ArrayParam, defaults.user_ids),
    view_id: withDefault(NumberParam, defaults.view_id),
    contact_ids: withDefault(ArrayParam, defaults.contact_ids),
    subscriber_ids: withDefault(ArrayParam, defaults.subscriber_ids),
    escalated_by_ids: withDefault(ArrayParam, defaults.escalated_by_ids),

    sort: withDefault(StringParam, defaults.sort),
    direction: withDefault(SortDirectionQueryParam, defaults.direction),
    limit: withDefault(NumberParam, defaults.limit),
    page: withDefault(NumberParam, defaults.page),
  });

  const resetQuery = (filter?: Filter) => {
    setQuery(applyFilter(defaults, filter));
  };

  return {
    query,
    setQuery,
    resetQuery,
  };
};

export function useDebounce(value: string, delay: number = 600) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
