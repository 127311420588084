import React from "react";

import { Grid } from "@material-ui/core";
import { DashboardCardItem, Query } from "../../types";
import CardItem from "./CardItem";

interface Props {
  item: DashboardCardItem;
  query: Query;
}

function Component({ item, query }: Props) {
  return (
    <>
      {item.data?.map((data) => (
        <Grid item md={6} xs={12} key={`${data.name}-${data.value}`}>
          <CardItem
            data={data}
            query={{
              ...query,
              ...item.query,
            }}
            title={item.name}
          />
        </Grid>
      ))}
    </>
  );
}

export default Component;
