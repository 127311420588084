import React, { ReactNode } from "react";
import { Alert } from "@akj-dev/design-system";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  children?: ReactNode;
  error?: string;
  loading?: boolean;
}

function Loading({ children, loading = false, error }: Props) {
  if (error) {
    return (
      <Box paddingTop={2} width="100%">
        <Alert message={error} type="error" />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box padding={2} textAlign="center" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  return <>{children}</>;
}

export default Loading;
