import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import Autocomplete from "../../../../components/Autocomplete";
import InlineEdit from "../../../../components/InlineEdit";
import { PermissionCodes } from "../../../../helpers/constants";
import { hasPermission } from "../../../../helpers/functions";
import { useDebounce } from "../../../../helpers/hooks";
import { useUpdate } from "../../../../hooks/tickets";
import { useTeams, useUsers } from "../../../../hooks/autocomplete";
import { Team, Ticket, User } from "../../../../types";
import { AutocompleteComponentProps } from "../../types";

function TeamAutocomplete({
  value,
  onSelect,
  onClose,
}: AutocompleteComponentProps) {
  const [refetchUsers, setRefetchUsers] = useState(false);
  const [teamInput, setTeamInput] = useState("");
  const debouncedTeamInput = useDebounce(teamInput);
  const teams = useTeams({ query: debouncedTeamInput });
  useUsers({ query: "", team_id: value?.id }, { enabled: refetchUsers });

  return (
    <Autocomplete
      data-cy="ticket-assigned-team-autocomplete"
      options={teams.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="name"
      label="Team"
      placeholder="Type to search"
      onSelect={(team: Team) => {
        onSelect(team);
        if (team) setRefetchUsers(true);
        setTeamInput("");
        if (onClose) onClose();
      }}
      onSearch={(query) => setTeamInput(query)}
      onBlur={() => setTeamInput("")}
      loading={teams?.isLoading}
      openOnFocus
      shrink
    />
  );
}

function UserAutocomplete({
  value,
  onSelect,
  onBlur,
  teamId,
}: AutocompleteComponentProps) {
  const [userInput, setUserInput] = useState("");
  const debouncedUserInput = useDebounce(userInput);
  const users = useUsers({
    query: debouncedUserInput,
    team_id: teamId,
  });

  return (
    <Autocomplete
      data-cy="ticket-assigned-user-autocomplete"
      options={users.data?.data || []}
      value={value}
      valueFrom="id"
      textFrom="full_name"
      label="User"
      placeholder="Type to search"
      onSelect={(user: User) => {
        onSelect(user);
        setUserInput("");
      }}
      onSearch={(query) => setUserInput(query)}
      onBlur={() => {
        onBlur();
        setUserInput("");
      }}
      loading={users.isLoading}
      openOnFocus
      shrink
    />
  );
}

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(ticket.team);
  const [selectedUser, setSelectedUser] = useState<User | null>(ticket.user);

  const canEditAssignedTeam = hasPermission(PermissionCodes.ticketsUpdateTeam);
  const canEditAssignedUser = hasPermission(PermissionCodes.ticketsUpdateUser);
  const canViewAssignedTeam = hasPermission(
    PermissionCodes.ticketsViewAssignedTeam
  );
  const canViewAssignedUser = hasPermission(
    PermissionCodes.ticketsViewAssignedUser
  );

  const update = useUpdate(ticket.id);

  return (
    <Box>
      <Typography paragraph variant="caption">
        Assigned To
      </Typography>
      {canViewAssignedTeam && (
        <Box paddingBottom="12px" height="48px">
          <InlineEdit
            disabled={!canEditAssignedTeam || update.isLoading}
            input={
              <TeamAutocomplete
                value={selectedTeam}
                onSelect={(team: Team) => {
                  if (team) {
                    setSelectedTeam(team);
                    setSelectedUser(null);
                    update.mutate({ team_id: team.id });
                  } else {
                    setSelectedTeam(null);
                    setSelectedUser(null);
                    update.mutate({ team_id: "" });
                  }
                }}
                disabled={update.isLoading}
              />
            }
          >
            <Typography variant="h4">
              {selectedTeam?.name || "Team not assigned"}
            </Typography>
          </InlineEdit>
        </Box>
      )}
      {canViewAssignedUser && (
        <Box height="48px">
          {update.isLoading && (!!ticket.user || selectedUser) && (
            <CircularProgress size={18} />
          )}
          {(!update.isLoading ||
            (update.isLoading && !ticket.user && !selectedUser)) && (
            <InlineEdit
              disabled={!canEditAssignedUser || update.isLoading}
              input={
                <UserAutocomplete
                  value={selectedUser || null}
                  onSelect={(user: User) => {
                    if (user) {
                      setSelectedUser(user);
                      update.mutate({ user_id: user.id });
                    } else {
                      setSelectedUser(null);
                      update.mutate({ user_id: "" });
                    }
                  }}
                  teamId={(selectedTeam as Team)?.id}
                  onBlur={() => {
                    if (ticket.user?.id) update.mutate({ user_id: "" });
                  }}
                />
              }
            >
              <Typography variant="h4">
                {ticket.user?.full_name || "User not assigned"}
              </Typography>
            </InlineEdit>
          )}
        </Box>
      )}
    </Box>
  );
}
