import { get, multiPartPost, post, put } from "./";
import { Query } from "../types";

export function convert(id?: number | null) {
  if (id) return get(`mailbox/messages/${id}/convert`);
}

// ?
export function create(params: any) {
  return post("mailbox/messages", params);
}

export function paginate(query: Partial<Query> = { limit: 10, page: 1 }) {
  return get("mailbox/messages", query);
}

export function reply(id: number, params: any) {
  if (params["files[]"]?.length) {
    return multiPartPost(`mailbox/messages/${id}/reply`, params);
  }

  return post(`mailbox/messages/${id}/reply`, params);
}

export function closureReasons({ query = "", limit = 10 }) {
  return get("mailbox/messages/closure-reasons/autocomplete", {
    query,
    limit,
  });
}

export function show(id: number) {
  return get(`mailbox/messages/${id}`);
}

export function update(id: number, params: any) {
  return put(`mailbox/messages/${id}`, params);
}
