import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Ticket } from "../../../../types";
import ContactItem from "../components/ContactItem";

interface Props {
  ticket: Ticket;
}

const Component: FunctionComponent<Props> = ({ ticket }) => {
  return (
    <Grid container spacing={1}>
      <ContactItem
        title="From Message"
        value={
          <Typography
            variant="h4"
            color="primary"
            component={Link}
            to={`/support/mailbox/${ticket.messages[0]?.id}`}
          >
            #{ticket.messages[0]?.id}
          </Typography>
        }
      />
      <ContactItem title="Message From" value={ticket.messages[0]?.from} />
    </Grid>
  );
};

export default Component;
