import React from "react";
import { Typography, Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Query } from "../../types";
import { CommentTypeOption } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  group: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));

interface Props {
  options: CommentTypeOption[];
  selected?: number;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ options, selected, setQuery }: Props) {
  const classes = useStyles();
  return (
    <ButtonGroup data-cy="comments-type-selector" className={classes.group}>
      {options.map((option: CommentTypeOption) => (
        <Button
          data-cy={`comments-type-button-${option.id}`}
          className={selected === option.id ? classes.button : ""}
          key={option.id}
          onClick={() =>
            setQuery({
              type_id: option?.id > 0 ? option?.id : undefined,
            })
          }
          variant={selected === option.id ? "contained" : "outlined"}
        >
          <Typography variant="h5">{option.name}</Typography>
        </Button>
      ))}
      )
    </ButtonGroup>
  );
}

export default Component;
