import React from "react";
import { format } from "date-fns";
import { useAuth } from "@akj-dev/affinity-platform";
import { Box, CircularProgress, Typography } from "@material-ui/core/";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Viewer from "./Viewer";
import { Visit } from "../../../../types/index";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    circleContent: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "default",
    },
    image: {
      borderRadius: "50%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  })
);

type Props = {
  visits: Visit[];
  loading: boolean;
  maxShown: number;
};

const Component = ({ visits, loading, maxShown = 1 }: Props) => {
  const classes = useStyles();
  const { externalUserName } = useAuth();

  const getInitials = (viewer: Visit) => {
    const allNames = viewer.user.full_name?.trim().split(" ") ?? [];
    return viewer.user.full_name
      ?.trim()
      .split(" ")
      .reduce((acc: string, curr: string, index: number) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, "");
  };

  return (
    <Box display="flex">
      {visits.length > 0 && (
        <Box display="flex" alignItems="center" mr={1}>
          <Typography variant="caption">Viewed By:</Typography>
        </Box>
      )}
      {loading && (
        <Box margin="auto">
          <CircularProgress size={12} />
        </Box>
      )}
      {!loading &&
        visits.map((viewer: Visit, index: number) => {
          if (!maxShown || index < maxShown) {
            return (
              <Viewer
                tooltipText={`${
                  externalUserName === viewer.user.full_name
                    ? "You"
                    : viewer.user.full_name
                } viewed at ${format(
                  new Date(viewer.last_visit),
                  "dd/MM/yy HH:mm"
                )}`}
                zIndex={visits.length - index}
              >
                {viewer.image ? (
                  <img
                    src={viewer.image}
                    alt="viewer"
                    className={classes.image}
                  />
                ) : (
                  <Typography variant="h4" className={classes.circleContent}>
                    {getInitials(viewer)}
                  </Typography>
                )}
              </Viewer>
            );
          } else if (index === maxShown) {
            return (
              <Viewer
                tooltipText={`Previously viewed by ${visits
                  .map((v) => v.user.full_name)
                  .join(", ")}.`}
                zIndex={visits.length - index}
              >
                <Typography variant="h4" className={classes.circleContent}>
                  +{visits.length - index}
                </Typography>
              </Viewer>
            );
          } else {
            return null;
          }
        })}
    </Box>
  );
};

export default Component;
