import React, { useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import Autocomplete from "../../../components/Autocomplete";
import { Ticket } from "../../../types";
import { useTicketsList } from "../../../hooks/tickets/autocomplete";
import { useDebounce } from "../../../helpers/hooks";

interface Props {
  disabled?: boolean;
  onSelect: (tickets: Array<Ticket>) => void;
  except?: number;
  keyForResetting?: number;
}

export default function Component({
  disabled,
  onSelect,
  except,
  keyForResetting,
}: Props) {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [ticketInput, setTicketInput] = useState("");
  const debouncedTicketInput = useDebounce(ticketInput);

  const childTickets = useTicketsList(
    debouncedTicketInput,
    { can_be_child: 1, except },
    "child",
    { enabled: fetchTickets }
  );

  return (
    <Box pb={1}>
      <Typography data-cy="assign-child-title" paragraph variant="body2">
        <LinkIcon style={{ position: "relative", top: "7px" }} /> Assign Child
        Tickets
      </Typography>

      <Autocomplete
        key={keyForResetting}
        options={childTickets.data?.data || []}
        renderOption={(ticket: Ticket, { selected }) => (
          <FormControlLabel
            control={<Checkbox checked={selected} />}
            label={`${ticket.identifier} ${ticket.short_description ?? ""}`}
          />
        )}
        textFrom={(ticket: Ticket) =>
          `${ticket.identifier} ${ticket.short_description ?? ""}`
        }
        valueFrom="id"
        inputValue={ticketInput}
        placeholder="Search Tickets"
        onSearch={(query) => setTicketInput(query)}
        onSelect={(tickets: Array<Ticket>) => {
          onSelect(tickets);
          setTicketInput("");
        }}
        onOpen={() => setFetchTickets(true)}
        onBlur={() => setTicketInput("")}
        loading={childTickets?.isLoading}
        disabled={disabled}
        disableCloseOnSelect
        multiple
        shrink
      />
    </Box>
  );
}
