import React from "react";
import { format } from "date-fns";
import { Button, Grid, Switch } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FreeSearch from "../../../components/FreeSearch";
import { Query } from "../types";
import { dateOrNull } from "../../../helpers/functions";
import { usePaginate } from "../../../hooks/messages";

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
};

function Component({ query, resetQuery, setQuery }: Props) {
  const { isLoading } = usePaginate(query);

  return (
    <Grid container spacing={1}>
      <Button
        data-cy="mailbox-filters-reset-button"
        color="primary"
        onClick={resetQuery}
        size="small"
        variant="text"
      >
        RESET
      </Button>

      <Grid item xs={12}>
        <FreeSearch
          data-cy="mailbox-filters-id-field"
          disabled={isLoading}
          fullWidth
          label="ID"
          id="filter-id"
          variant="outlined"
          value={query.id ?? ""}
          size="small"
          onDebouncedChange={(value) => setQuery({ id: value })}
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          data-cy="mailbox-filters-subject-field"
          disabled={isLoading}
          fullWidth
          label="Subject"
          id="filter-name"
          variant="outlined"
          value={query.subject ?? ""}
          size="small"
          onDebouncedChange={(value) => setQuery({ subject: value })}
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <FreeSearch
          data-cy="mailbox-filters-from-field"
          disabled={isLoading}
          fullWidth
          label="From"
          id="filter-from"
          variant="outlined"
          value={query.from ?? ""}
          size="small"
          onDebouncedChange={(value) => setQuery({ from: value })}
          wait={500}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DatePicker
              data-cy="mailbox-filters-date-from-picker"
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date from"
              margin="none"
              onChange={(date) =>
                setQuery({
                  created_after: format(date, "yyy-MM-dd"),
                })
              }
              size="small"
              value={dateOrNull(query.created_after ?? "")}
              variant="inline"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              data-cy="mailbox-filters-date-to-picker"
              autoOk
              disabled={isLoading}
              disableToolbar
              format="dd/MM/yyy"
              fullWidth
              inputVariant="outlined"
              label="Date to"
              margin="none"
              onChange={(date) =>
                setQuery({
                  created_before: format(date, "yyy-MM-dd"),
                })
              }
              size="small"
              value={dateOrNull(query.created_before ?? "")}
              variant="inline"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          data-cy="mailbox-filters-only-open-checkbox"
          control={
            <Switch
              onChange={(event) =>
                setQuery({ is_open: event.target.checked ? 1 : 0 })
              }
              checked={query.is_open !== 0}
              name="checkedB"
              color="primary"
            />
          }
          label="Only Show Open Messages"
        />
      </Grid>
    </Grid>
  );
}

export default Component;
