import React, { useEffect, useState } from "react";
import { Chip, TextField } from "@material-ui/core";
import { Query } from "../../types";
import FilterPopup from "./FilterPopup";

interface Props {
  filterName: string;
  filterKey: string;
  inputType?: string;
  queryPart: any[];
  setQuery: (query: Partial<Query>) => void;
}

function Component({
  filterName,
  filterKey,
  inputType,
  queryPart = [],
  setQuery,
}: Props) {
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState(queryPart);

  useEffect(() => {
    setChips(queryPart);
  }, [queryPart]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputType === "number" && parseInt(event.target.value) < 1) {
      setInputValue("1");
    } else {
      setInputValue(event.target.value);
    }
  };

  const applyFilter = (handleClose: Function) => {
    setQuery({
      [filterKey]: inputValue ? [...chips, inputValue] : chips,
    });
    handleClose();
    setInputValue("");
  };

  const handleRemoveChip = (chip: string | number) => {
    if (Array.isArray(chips)) {
      setChips(chips?.filter((val: string) => val !== chip.toString()));
    }
  };

  const resetValue = () => setChips(queryPart);

  return (
    <FilterPopup
      filterName={filterName}
      applyFilter={applyFilter}
      resetValue={resetValue}
    >
      <TextField
        data-cy={`ticket-filter-field-${filterName}`}
        label={filterName}
        name={filterName}
        value={inputValue}
        onChange={handleChange}
        type={inputType ?? "text"}
        size="small"
        variant="outlined"
        fullWidth
      />
      {chips?.map((q) => (
        <Chip
          label={q}
          size="small"
          variant="outlined"
          onDelete={() => handleRemoveChip(q)}
          style={{ margin: "10px 10px 0 0" }}
        />
      ))}
    </FilterPopup>
  );
}

export default Component;
