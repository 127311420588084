import React, { isValidElement } from "react";
import { Typography } from "@material-ui/core/";

type Props = {
  title: string;
  value: string | React.ReactNode;
  style?: Object;
  hasBottomMargin?: boolean;
};

const Component = ({
  title,
  value,
  style = {},
  hasBottomMargin = false,
}: Props) => {
  return (
    <div data-cy={`info-item-${title}`}>
      <Typography display="block" variant="caption">
        {title}
      </Typography>
      {isValidElement(value) ? (
        value
      ) : (
        <Typography
          variant="h4"
          paragraph={hasBottomMargin}
          style={{ marginTop: "6px", ...style }}
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

export default Component;
