import React, { useEffect, useState } from "react";
import { UseInfiniteQueryResult } from "react-query";
import {
  Box,
  Table,
  TableBody,
  TableFooter,
  Button,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CommentSection from "./CommentSection";
import { CommentFields } from "../../../../types";
import { CommentsData } from "../../types";
import { useCreate, useUpdate } from "../../../../hooks/comments";
import { ValidationError } from "../../../../api";

interface Props {
  owner: string;
  comments: UseInfiniteQueryResult<CommentsData, Error | ValidationError>;
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
  showAddCommentForm: boolean;
  setShowAddCommentForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function Component({
  owner,
  comments,
  fields,
  setFields,
  clearFields,
  showAddCommentForm,
  setShowAddCommentForm,
}: Props) {
  const [page, setPage] = useState(1);
  const [replying, setReplying] = useState(-1);
  const [editing, setEditing] = useState(-1);

  const create = useCreate(owner, {
    onSuccess: () => {
      if (replying > -1) setReplying(-1);
      clearFields();
    },
  });
  const update = useUpdate(owner, {
    onSuccess: () => {
      setEditing(-1);
      clearFields();
    },
  });

  useEffect(() => {
    if (showAddCommentForm) {
      clearFields();
      setReplying(-1);
      setEditing(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddCommentForm]);

  const hideZeroLevelCommentForm = () => {
    if (showAddCommentForm) setShowAddCommentForm(false);
  };

  return (
    <Table>
      <TableBody>
        {comments.data?.pages.map((page: CommentsData, index: number) => (
          <CommentSection
            key={page?.data[0]?.id ?? index}
            comments={page.data || []}
            fields={fields}
            setFields={setFields}
            clearFields={clearFields}
            hideZeroLevelCommentForm={hideZeroLevelCommentForm}
            replying={replying}
            setReplying={setReplying}
            editing={editing}
            setEditing={setEditing}
            create={create}
            update={update}
            loaded={comments.isSuccess}
          />
        ))}
      </TableBody>

      {comments.hasNextPage && (
        <TableFooter>
          <tr>
            <td>
              <Box display="flex" justifyContent="center" pt={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPage((old) => old + 1);
                    comments.fetchNextPage({ pageParam: page + 1 });
                  }}
                  startIcon={
                    comments.isFetching ? (
                      <CircularProgress size={10} />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  color="primary"
                >
                  {comments.isFetching ? "Loading Comments" : "Load More"}
                </Button>
              </Box>
            </td>
          </tr>
        </TableFooter>
      )}
    </Table>
  );
}

export default Component;
