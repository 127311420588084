import { useQuery } from "react-query";
import * as api from "../api/events";
import { EventData, Query } from "../screens/Events/types";
import { EventType, QueryData } from "../types";

export function useEventDetail(eventId?: number | null, options = {}) {
  return useQuery<EventData | null, Error>(
    ["events", eventId],
    () => api.show(eventId),
    options
  );
}

export function usePaginate(query: Partial<Query>, options = {}) {
  return useQuery<QueryData<EventType>, Error>(
    ["events", query],
    () => api.paginate(query),
    options
  );
}
