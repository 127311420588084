import React from "react";
import Loading from "../../../layout/Loading";
import { usePaginate } from "../../../hooks/events";
import Table from "./components/Table";
import { Query } from "../types";

type Props = {
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({ query, setQuery }: Props) {
  const paginator = usePaginate(query);

  return (
    <Loading error={paginator.isError ? "Can't load tickets" : undefined}>
      <Table paginator={paginator} query={query} setQuery={setQuery} />
    </Loading>
  );
}
