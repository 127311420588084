import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface RHFSwitchFieldProps {
  name: UseControllerProps["name"];
  label: string;
  rules?: UseControllerProps["rules"];
}

/**
 * reach-hook-form switch / checkbox field
 * Use inside a <FormProvider>
 */
export const RHFSwitchField = ({ name, label, rules }: RHFSwitchFieldProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, formState }) => {
        return (
          <FormControlLabel
            control={<Switch {...field} checked={!!field.value} />}
            label={label}
            disabled={formState.isSubmitting}
          />
        );
      }}
    />
  );
};
