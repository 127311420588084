import React from "react";
import { UseQueryResult } from "react-query";
import { Typography, makeStyles, Button, Box, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CommentTypeSelector from "../../Table/components/CommentTypeSelector";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { CommentTypesData, Query } from "../../types";
import { CommentFields, CommentTypeOption } from "../../../../types";
import Create from "../../Create";

const useStyles = makeStyles((theme) => ({
  commentsTitleWrap: {
    height: theme.spacing(3),
  },
  commentsTitle: {
    height: "inherit",
    display: "table-cell",
    verticalAlign: "middle",
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  filtersWrap: {
    height: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface Props {
  commentTypes: UseQueryResult<CommentTypesData, Error>;
  showAddCommentForm: boolean;
  setShowAddCommentForm: Function;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
  owner: string;
  fields: CommentFields;
  setFields: (fields: Partial<CommentFields>) => void;
  clearFields: () => void;
}

function Component({
  commentTypes,

  showAddCommentForm,
  setShowAddCommentForm,
  query,
  setQuery,
  owner,
  fields,
  setFields,
  clearFields,
}: Props) {
  const classes = useStyles();

  const getFilterCommentTypes = () =>
    commentTypes.data?.data?.filter(
      (type) =>
        (type.is_internal &&
          hasPermission(PermissionCodes.commentsViewInternal)) ||
        !type.is_internal
    ) || [];

  const selectorOptions = [
    { id: 0, name: "All Comments" } as CommentTypeOption,
    ...(getFilterCommentTypes() || []),
  ];

  const handleChangeSortDirection = () => {
    setQuery({ direction: query.direction === "desc" ? "asc" : "desc" });
  };

  return (
    <>
      <Grid item xs={12} className={classes.commentsTitleWrap}>
        <Typography variant="h4" className={classes.commentsTitle}>
          Comments
          {!showAddCommentForm &&
            hasPermission(PermissionCodes.ticketsCommentsCreate) && (
              <Button
                data-cy="comments-add-button"
                color="primary"
                onClick={() => setShowAddCommentForm(true)}
                size="small"
                startIcon={<AddIcon />}
                variant="contained"
                className={classes.addButton}
              >
                Add Comment
              </Button>
            )}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Create
          owner={owner}
          showAddCommentForm={showAddCommentForm}
          setShowAddCommentForm={setShowAddCommentForm}
          fields={fields}
          setFields={setFields}
          clearFields={clearFields}
          commentTypes={commentTypes}
        />
      </Grid>

      <Grid item xs={12} className={classes.filtersWrap}>
        {hasPermission(PermissionCodes.commentsViewInternal) && (
          <Box pr={1}>
            <CommentTypeSelector
              options={selectorOptions}
              selected={query.type_id || 0}
              setQuery={setQuery}
            />
          </Box>
        )}
        <Button
          data-cy="comments-sort-button"
          color="primary"
          onClick={handleChangeSortDirection}
          size="small"
          startIcon={
            query.direction === "desc" ? (
              <ArrowDownwardIcon />
            ) : (
              <ArrowUpwardIcon />
            )
          }
          variant="outlined"
        >
          {query.direction === "desc"
            ? "Newest First Shown"
            : "Oldest First Shown"}
        </Button>
      </Grid>
    </>
  );
}

export default Component;
