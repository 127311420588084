import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from "@material-ui/core";
import {
  ContactMail as ContactIcon,
  Person as UserIcon,
  AlternateEmail as SubscriberIcon,
  Clear as DeleteIcon,
} from "@material-ui/icons";
import Autocomplete from "../../../../components/Autocomplete";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { Subscriber, Subscription, Ticket } from "../../../../types";
import {
  useSubscribe,
  useSubscriptions,
  useUnsubscribe,
} from "../../../../hooks/tickets";
import { useTicketSubscribers } from "../../../../hooks/tickets/autocomplete";

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const [subscribersQuery, setSubscribersQuery] = useState("");
  const subscriptions = useSubscriptions(ticket.id);
  const subscribers = useTicketSubscribers(ticket.id, subscribersQuery);
  const [keyForResetting, setKeyForResetting] = useState<number>(0);

  const [options, setOptions] = useState(subscribers.data?.data || []);
  const subscribe = useSubscribe(ticket.id);
  const [unsubscribingId, setUnsubscribingId] = useState<number>();
  const unsubscribe = useUnsubscribe(ticket.id, {
    onSettled: () => setUnsubscribingId(undefined),
  });

  useEffect(() => {
    if (subscribers.data?.data?.length) {
      setOptions(subscribers.data?.data);
    }
    if (!subscribers.data?.data?.length && subscribersQuery.length > 0) {
      setOptions([
        {
          label: `Create new subscriber "${subscribersQuery}"`,
          email: subscribersQuery,
          reference: "new",
        } as Subscriber,
      ]);
    }
  }, [subscribers.data?.data, subscribersQuery]);

  const roles = (subscriptions.data?.data || [])
    .map((subscription: Subscription) => {
      return subscription.subscriber?.roles;
    })
    .flat()
    .filter((item: string, pos: any, self: string | any[]) => {
      return (
        self.indexOf(item) === pos &&
        item !== "External Subscriber" &&
        item !== "Contact"
      );
    });

  const formattedSubscribers = options.map((option: Subscriber) => {
    let name = option.label || option.full_name;
    if (name !== option.email) {
      name += " (" + option.email + ")";
    }
    return {
      ...option,
      name_with_email: name,
    };
  });

  //we want these as the last 2 options
  roles.push("Contact");
  roles.push("External Subscriber");

  return (
    <>
      <Typography paragraph variant="body1">
        Subscribed
      </Typography>

      {subscriptions?.isLoading && <CircularProgress size={24} />}

      {roles.map((roleLabel: string) => (
        <Box display="flex-none" key={`role-${roleLabel}`} marginBottom={1}>
          {(subscriptions.data?.data || []).filter(
            (subscription: Subscription) =>
              subscription.subscriber?.roles.includes(roleLabel)
          ).length > 0 && (
            <Box pb={0.5}>
              <Typography variant="body2">{roleLabel}</Typography>
            </Box>
          )}

          {(subscriptions.data?.data || [])
            .filter((subscription: Subscription) => {
              return subscription.subscriber?.roles.includes(roleLabel);
            })
            .map((subscription: Subscription) => (
              <Box
                display="flex"
                alignItems="center"
                height={28}
                key={subscription.id}
              >
                <Box display="flex" alignItems="center">
                  {subscription.subscriber?.reference.startsWith(
                    "contact-"
                  ) && (
                    <Tooltip title="These are people that may not be users but can still receive notifications where subscribed. They have an affiliation with one company only.">
                      <ContactIcon fontSize="inherit" />
                    </Tooltip>
                  )}
                  {subscription.subscriber?.reference.startsWith(
                    "subscriber-"
                  ) && (
                    <Tooltip title="These are people that do not have any affiliation with companies but can still be setup to receive notifications. Any email can be added here.">
                      <SubscriberIcon fontSize="inherit" />
                    </Tooltip>
                  )}
                  {subscription.subscriber?.reference.startsWith("user-") && (
                    <Tooltip title="These are people with credentials to access the Affinity Support system and can comprise of customers, agents, agent_managers and administrators. Customer users have an affiliation with one or more companies.">
                      <UserIcon fontSize="inherit" />
                    </Tooltip>
                  )}
                </Box>
                <Box ml={1} overflow="hidden" textOverflow="ellipsis">
                  <Tooltip title={"Email: " + subscription.subscriber?.email}>
                    <Typography variant="caption">
                      {subscription.subscriber?.full_name}
                    </Typography>
                  </Tooltip>
                </Box>
                {hasPermission(PermissionCodes.ticketsUnsubscribeOthers) && (
                  <Box ml="auto">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setUnsubscribingId(subscription.id);
                        unsubscribe.mutate({
                          reference: subscription.subscriber?.reference,
                          subscription_id: subscription.id,
                        });
                      }}
                      disabled={
                        !!unsubscribingId && unsubscribingId !== subscription.id
                      }
                    >
                      {unsubscribe.isLoading &&
                      unsubscribingId === subscription.id ? (
                        <CircularProgress size={18} />
                      ) : (
                        <DeleteIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      ))}
      {hasPermission(PermissionCodes.ticketsSubscribeAgents) && (
        <Autocomplete
          data-cy="subscribers-autocomplete"
          placeholder="Type to search"
          label="Person to subscribe"
          loading={subscribers?.isLoading || subscribe.isLoading}
          options={formattedSubscribers || []}
          valueFrom="reference"
          textFrom={(option: Subscriber) => option.name_with_email}
          onSelect={(subscriber: Subscriber) => {
            subscriber &&
              subscribe.mutate({
                reference: subscriber.reference,
                email: subscriber.email,
              });
            setKeyForResetting(Math.random());
          }}
          onSearch={(query) => setSubscribersQuery(query)}
          value={null}
          blurOnSelect
          onBlur={() => setSubscribersQuery("")}
          key={keyForResetting}
        />
      )}
    </>
  );
}
