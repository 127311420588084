import { platformApiFetch } from "@akj-dev/affinity-platform";
import queryString from "query-string";

class BaseError {
  constructor() {
    // @ts-ignore
    Error.apply(this, arguments);
  }
}

export class AuthorizationError extends BaseError {
  constructor(public validation: any, public message: string) {
    super();
  }
}

export class ValidationError extends BaseError {
  constructor(public validation: any, public message: string) {
    super();
  }
}

function blob(response: any) {
  return response.blob();
}

async function fetch(url: string, options?: any) {
  const headers = options?.headers;

  const response = await platformApiFetch(`support-app/${url}`, {
    ...options,
    headers: {
      Accept: "application/json",
      pragma: "no-cache",
      "cache-control": "no-cache",
      ...headers,
    },
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new AuthorizationError(response.json(), response.statusText);
    }

    if (response.status === 422) {
      throw new ValidationError(await response.json(), response.statusText);
    }

    throw Error(response.statusText);
  }

  return response;
}

function formatParams(params: any) {
  let formattedParams: any = {};
  for (let param in params) {
    if (typeof params[param] === "boolean") {
      formattedParams[param] = params[param] ? 1 : 0;
    } else if (Array.isArray(params[param])) {
      if (typeof params[param][0] !== "object")
        formattedParams[param] = params[param];
      else formatArrayParams(formattedParams, param, params[param]);
    } else {
      formattedParams[param] = params[param];
    }
  }
  return formattedParams;
}

const formatArrayParams = (
  formattedParams: any,
  propertyName: string,
  params: any[]
) =>
  params.map((p, i) => {
    for (let property in p) {
      formattedParams[`${propertyName}[${i}][${property}]`] = `${p[property]}`;
    }
    return true;
  });

function json(response: any) {
  return response.json();
}

export function download(url: string, params?: any, options?: any) {
  const query = params
    ? "?" +
      queryString.stringify(params, { arrayFormat: "bracket", skipNull: true })
    : "";

  return fetch(url + query, options).then(blob);
}

export function get(url: string, params?: any, options?: any) {
  const query = params
    ? "?" +
      queryString.stringify(params, { arrayFormat: "bracket", skipNull: true })
    : "";

  return fetch(url + query, options).then(json);
}

export function multiPartPost(url: string, params: any, options?: any) {
  let body = new FormData();

  for (const name in params) {
    const value = params[name];

    if (Array.isArray(value)) {
      for (const index in value) {
        body.append(name, value[index]);
      }
    } else if (value !== null) {
      body.append(name, value);
    }
  }

  return fetch(url, {
    body,
    method: "POST",
    ...options,
  }).then(json);
}

export function post(url: string, params?: any, options?: any) {
  return fetch(url, {
    body: queryString.stringify(formatParams(params), {
      arrayFormat: "bracket",
      skipNull: true,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    ...options,
  }).then(json);
}

export function put(url: string, params?: any, options?: any) {
  return post(url, params, {
    method: "PUT",
    ...options,
  });
}

export function remove(url: string, params?: any, options?: any) {
  return post(url, params, {
    method: "DELETE",
    ...options,
  });
}
