import React, { FormEvent, useCallback, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
        paddingLeft: theme.spacing(0.5),
      },
      "& .MuiIconButton-root": {
        padding: 0,
      },
    },
    button: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 40,
    },
  })
);

interface Props {
  value: string;
  placeholder?: string;
  loading: boolean;
  onSearch: (query: string) => void;
  onClear?: () => void;
  wait?: number;
}

const SearchBar = ({
  value,
  placeholder,
  loading,
  onSearch,
  onClear,
  wait = 300,
}: Props) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(value);

  const updateOnSearch = useMemo(
    () =>
      debounce((value: string) => {
        onSearch(value);

        if (!value && onClear) onClear();
      }, wait),
    [wait, onSearch, onClear]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      updateOnSearch(value);
    },
    [setSearchValue, updateOnSearch]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSearch(searchValue);
    },
    [searchValue, onSearch]
  );

  return (
    <form
      data-cy="search-bar"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <TextField
        data-cy="search-field"
        name="search"
        value={searchValue}
        className={classes.textField}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={placeholder ?? "Type to Search"}
        variant="outlined"
        fullWidth
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {searchValue ? (
                <IconButton onClick={() => handleSearch("")}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                data-cy="search-button"
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                disabled={loading || !searchValue}
              >
                {loading && searchValue ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Go"
                )}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchBar;
