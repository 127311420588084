import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import Pause from "./components/Pause";
import { Commitment } from "../../../../../types";
import { secondsToHumanReadable } from "../../../../../helpers/functions";

interface Props {
  commitment: Commitment;
  updateTotalTime: Function;
}

export default function Component({ commitment, updateTotalTime }: Props) {
  const [counter, setCounter] = useState<number>(
    commitment?.seconds_paused || 0
  );

  const shouldCount = commitment.is_paused;

  useEffect(() => {
    setCounter(commitment?.seconds_paused);
  }, [commitment]);

  useEffect(() => {
    if (shouldCount) updateTotalTime();
  }, [counter, shouldCount, updateTotalTime]);

  useEffect(() => {
    let interval: number | null = null;
    if (shouldCount && !interval) {
      interval = window.setInterval(
        () => setCounter((prevCounter) => prevCounter + 1),
        1000
      );
    }

    if (!shouldCount && interval) {
      window.clearInterval(interval);
    }

    return function cleanup() {
      if (interval) window.clearInterval(interval);
    };
  }, [shouldCount]);

  const getTimePaused = (): string => {
    if (!shouldCount) return commitment.time_paused_human_readable;
    return secondsToHumanReadable(counter);
  };

  return (
    <>
      <Typography paragraph={true} variant="caption">
        {commitment.name}
        {(commitment.is_paused || commitment.seconds_paused > 0) && (
          <>
            &nbsp;was paused for <b>{getTimePaused()}.</b>
          </>
        )}
      </Typography>
      <Pause commitment={commitment} />
    </>
  );
}
