import { useMutation } from "react-query";
import * as api from "../api/attachments";

export function useDownload(
  options = {
    enabled: false,
    onSuccess: (data: any, variables: any) => {},
  }
) {
  return useMutation((attachment) => api.download(attachment), {
    ...options,
  });
}
