class ImageToBase64 {
  loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => resolve({ default: reader.result });
          reader.onerror = (error) => reject(error);
        })
    );
  }
}

export default ImageToBase64;
