import React from "react";
import { Grid, TextField } from "@material-ui/core";

interface Props {
  loading: boolean;
  message: string;
  setMessage: Function;
}

export default function Component({ loading, message, setMessage }: Props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          data-cy="sla-pause-message-field"
          disabled={loading}
          fullWidth
          label="Pause Note"
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={3}
          value={message}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  );
}
