import React from "react";
import { Alert } from "@akj-dev/design-system";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import FileUpload from "../../../components/FileUpload";
import { CommentFields, CommentTypeOption } from "../../../types";
import { hasPermission } from "../../../helpers/functions";
import { PermissionCodes } from "../../../helpers/constants";
import { debounce } from "lodash";
import RichTextEditor from "../../../components/RichTextEditor";
import { ValidationError } from "../../../api";
import {
  CommentCreateMutationType,
  CommentTypesQueryType,
  CommentUpdateMutationType,
} from "../types";

interface Props {
  fields: CommentFields;
  hasChildren?: boolean;
  submitButtonLabel: string;
  submitDisabled?: boolean;
  types?: CommentTypesQueryType;
  mutation: CommentCreateMutationType | CommentUpdateMutationType;
  setFields: (fields: Partial<CommentFields>) => void;
  onSubmit: (fields: CommentFields) => void;
  onCancel?: () => void;
}

function Component({
  fields,
  hasChildren,
  submitButtonLabel,
  submitDisabled,
  types,
  mutation,
  setFields,
  onSubmit,
  onCancel,
}: Props) {
  const theme = useTheme();
  const canSendCommentsToChildTickets = hasPermission(
    PermissionCodes.commentsCreateChildRelated
  );

  const submitError = mutation.isError
    ? mutation.error instanceof Error
      ? mutation.error.message
      : mutation.error instanceof ValidationError
      ? mutation.error.validation.message
      : "Unknown Error"
    : "";

  const validation =
    mutation.error instanceof ValidationError
      ? mutation.error.validation
      : null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (submitError) mutation.reset();
    setFields({
      [event.target.name]: event.target.value,
    } as Partial<CommentFields>);
  };

  const handleChangeDelayed = debounce((fields: any, error) => {
    if (error) mutation.reset();
    setFields(fields);
  }, 300);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(fields);
  };

  const hasErrors = (field: string) => {
    if (validation?.errors[field] && validation?.errors[field]?.length) {
      return true;
    }
    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {validation.errors[field].map((error: string) => (
            <span key={error}>{error}</span>
          ))}
        </>
      );
    }

    return <></>;
  };

  const getCommentTypes = () =>
    (types?.data?.data || []).filter(
      (type) =>
        (type.is_internal &&
          hasPermission(PermissionCodes.commentsCreateInternal)) ||
        !type.is_internal
    );

  return (
    <form
      data-cy="comments-form"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      style={{ width: "100%" }}
    >
      <Grid container spacing={1}>
        {(types?.data?.data || []).length > 1 && (
          <Grid item xs={12}>
            {types?.isLoading && (
              <Typography variant="caption">
                <CircularProgress size={12} /> Loading comment types
              </Typography>
            )}
            <RadioGroup
              data-cy="comments-type-radio-group"
              aria-label="type_id"
              name="type_id"
              onChange={handleChange}
              row
              value={
                fields.type_id !== -1
                  ? fields.type_id
                  : types?.data?.data?.[0].id
              }
            >
              {getCommentTypes().map((type: CommentTypeOption) => (
                <FormControlLabel
                  data-cy={`comments-type-radio-button-${type.id}`}
                  control={<Radio />}
                  key={type.id}
                  label={type.name}
                  value={`${type.id}`}
                  disabled={types?.isLoading}
                  checked={type.id?.toString() === fields.type_id?.toString()}
                />
              ))}
            </RadioGroup>
          </Grid>
        )}
        <Grid item xs={12}>
          <RichTextEditor
            data-cy="comments-message-field"
            onChange={(message: string) =>
              handleChangeDelayed({ message }, submitError)
            }
            placeholder="Message *"
            value={fields.message}
          />

          {hasErrors("message") && (
            <Typography variant="caption" color="error">
              {displayErrors("message")}
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <FileUpload
            multiple
            name="files[]"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              files: Array<File | null>
            ) => setFields({ [event.target.name]: files })}
            setFiles={(newFiles: File[]) => setFields({ "files[]": newFiles })}
            value={fields["files[]"]}
            resetError={mutation.reset}
            uploading={mutation.isLoading}
          />
        </Grid>

        {canSendCommentsToChildTickets && hasChildren && (
          <Grid item xs={6}>
            <Box textAlign="right">
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy="comments-send-to-children-checkbox"
                    checked={fields.send_to_children}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFields({ [event.target.name]: event.target.checked })
                    }
                    name="send_to_children"
                    color="primary"
                  />
                }
                label="Send to Child Tickets"
              />
            </Box>
          </Grid>
        )}

        {(submitError || validation?.message) && (
          <Grid item xs={12}>
            <Alert message={submitError || validation.message} type="error" />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            data-cy="comments-submit-button"
            color="primary"
            size="small"
            startIcon={
              mutation.isLoading ? (
                <CircularProgress size={10} />
              ) : (
                <MessageIcon />
              )
            }
            type="submit"
            variant="contained"
            disabled={
              mutation.isLoading || !fields.message.length || submitDisabled
            }
          >
            {submitButtonLabel}
          </Button>

          {typeof onCancel === "function" && (
            <Button
              data-cy="comments-cancel-button"
              color="primary"
              onClick={() => onCancel()}
              size="small"
              style={{ marginLeft: theme.spacing(1) }}
              variant="outlined"
              disabled={mutation.isLoading}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default Component;
