import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import Form from "./components/Form";
import Loading from "../../../layout/Loading";
import Main from "../../../layout/Main";
import { ValidationError } from "../../../api";
import { useCreate } from "../../../hooks/tasks";
import { LooseObject, TaskFields } from "../../../types";

const Switch = ({
  children,
  error,
  embedded,
  loading,
}: {
  children: React.ReactNode;
  error: string;
  embedded: boolean;
  loading: boolean;
}) => {
  if (embedded) {
    return (
      <Loading error={error} loading={loading}>
        {children}
      </Loading>
    );
  }

  return (
    <Main error={error} title="New Task" loading={loading}>
      <Paper elevation={4} style={{ width: "100%" }}>
        {children}
      </Paper>
    </Main>
  );
};

interface Props {
  embedded?: boolean;
  owner?: string;
}

export default function Create({ embedded = false, owner = "" }: Props) {
  const initialState: Partial<TaskFields> = {
    description: "",
    due_date: "",
    name: "",
    status_id: null,
    user_id: null,
  };

  const [taskFields, setTaskFields] = useState(initialState);

  const setFields = (fields: Partial<TaskFields>) =>
    setTaskFields((oldFields) => ({ ...oldFields, ...fields }));

  const create = useCreate(owner);

  return (
    <Switch
      error={create.isError ? create.error.message : ""}
      embedded={embedded}
      loading={create.isLoading}
    >
      <Form
        embedded={embedded}
        fields={taskFields}
        setFields={(fields: LooseObject) => setFields(fields)}
        onSubmit={(fields: LooseObject) => create.mutate(fields)}
        validation={
          create?.error instanceof ValidationError
            ? create?.error?.validation
            : null
        }
        loading={create.isLoading}
      />
    </Switch>
  );
}
