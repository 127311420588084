import React from "react";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import {
  ProductContainer,
  StandaloneAppWrapper,
  BrandedDocumentTitle,
} from "@akj-dev/affinity-wrapper";

import Application from "./Application";
import { ROOT_PATH } from "./routes";

const queryCache = new QueryCache(); // possible attributes are onError and onSuccess

const queryClient = new QueryClient({
  queryCache, //Set Cache system
  defaultOptions: {
    //Default options for some queries
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 31,
    },
  },
});

const AppStandaloneComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ProductContainer>
          <BrandedDocumentTitle title="Support" />
          <Application />
          <ReactQueryDevtools position="bottom-right" />
        </ProductContainer>
      </QueryParamProvider>
    </QueryClientProvider>
  );
};

export const AppStandalone = () => (
  <StandaloneAppWrapper root={ROOT_PATH}>
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
