import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Alert } from "@akj-dev/design-system";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  List,
  Typography,
  ListItem,
  Divider,
} from "@material-ui/core";
import Task from "./components/Task";
import { constants } from "../../../helpers";
import { usePaginate } from "../../../hooks/tasks";
import { Query } from "../types";
import { Status, Task as TaskType } from "../../../types";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  list: {
    width: "100%",
    minWidth: 225,
  },
  title: {
    width: "100%",

    "& span": {
      float: "right",
    },
  },
}));

type Props = {
  query: Query;
  status: Status;
  showDivider: boolean;
};

function Component({ query, status, showDivider }: Props) {
  const classes = useStyles();

  const [local, setLocal] = useState({
    append: false,
    limit: query.limit || constants.limit.kanban,
    page: 1,
  });

  const paginator = usePaginate("", query, status.id);

  const LoadingIndicator = () => {
    if (!paginator.isLoading) {
      return <></>;
    }

    return (
      <ListItem disableGutters>
        <Button
          data-cy="loading-tasks-button"
          disabled
          fullWidth
          size="small"
          startIcon={<CircularProgress size={24} />}
          variant="text"
        >
          Loading
        </Button>
      </ListItem>
    );
  };

  const ErrorIndicator = () => {
    if (!paginator.error) {
      return <></>;
    }

    return (
      <ListItem disableGutters>
        <Alert message={paginator.error} type="error" />
      </ListItem>
    );
  };

  const LoadMore = () => {
    // Are there more items?
    if (
      !paginator.isLoading &&
      paginator.data &&
      local.page * local.limit < paginator.data.meta.total
    ) {
      // Do we want to allow the user to load more?
      if (paginator.data.data?.length < constants.limit.kanbanMaxItems) {
        return (
          <ListItem disableGutters>
            <Button
              data-cy="load-more-tasks-button"
              fullWidth
              onClick={() =>
                setLocal({ ...local, append: true, page: local.page + 1 })
              }
              size="small"
              variant="text"
            >
              Load more
            </Button>
          </ListItem>
        );
      }

      const { limit, page } = local;

      // Inform the user that we already displayed the maximum number of tasks on Kanban view
      // And show a button that will take them to Table view on the desired page
      return (
        <>
          <ListItem disableGutters>
            <Typography align="center" variant="caption">
              A maximum of {constants.limit.kanbanMaxItems} items can be viewed
              on Kanban view.
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <Button
              data-cy="view-more-tasks-button"
              component={Link}
              fullWidth
              size="small"
              to={
                "/support/tasks?" +
                queryString.stringify({
                  limit,
                  page: page + 1,
                  status_id: status.id,
                })
              }
              variant="text"
            >
              View more on Table view
            </Button>
          </ListItem>
        </>
      );
    }

    return <></>;
  };

  return (
    <Box
      data-cy={`tasks-kanban-panel-${status.id}`}
      display="flex"
      justifyContent="space-between"
      mb={1}
      flex={1}
    >
      <List className={classes.list}>
        <ListItem disableGutters>
          <Typography
            data-cy="tasks-kanban-panel-title"
            className={classes.title}
            variant="h3"
          >
            {status.name} <span>{paginator.data?.meta.total}</span>
          </Typography>
        </ListItem>

        {paginator.data?.data?.map((task: TaskType) => (
          <ListItem key={task.id} disableGutters>
            <Task task={task} />
          </ListItem>
        ))}

        <LoadingIndicator />
        <ErrorIndicator />
        <LoadMore />
      </List>

      {showDivider && (
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
          light
        />
      )}
    </Box>
  );
}

export default Component;
