import React from "react";
import { useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import {
  getPathForCrossAppRedirect,
  useAuth,
} from "@akj-dev/affinity-platform";

import { platformMenuItems as items } from "./utils/platformMenuItems";
import { ListItem, IconWrap, PlatformMenuWrapper, iconSize } from "./styled";
import { getStylesConfig } from "./utils/getStylesConfig";

export const PlatformMenu = () => {
  const location = useLocation();
  const theme = useTheme();
  const { accountSettings } = useAuth();

  const { fgColor, bgColor, round, activeBorderColor } = getStylesConfig(theme);

  const enabledItems = items.filter((item) =>
    item.permissions?.length
      ? item.permissions?.filter(
          (permission) => accountSettings[permission] === "1"
        ).length > 0
      : true
  );

  return (
    <PlatformMenuWrapper>
      {enabledItems.map((item, index) => (
        <a
          href={getPathForCrossAppRedirect(item.subDomain, item.pathname)}
          key={item.subDomain || index}
        >
          <ListItem>
            <IconWrap
              className={
                location.pathname.startsWith(item.pathname) &&
                (item.pathname !== "/" || location.pathname === "/")
                  ? "active"
                  : undefined
              }
              round={round}
              activeBorderColor={activeBorderColor}
            >
              {React.cloneElement(item.icon, {
                size: iconSize,
                bgColor,
                fgColor,
              })}
            </IconWrap>
            {item.label}
          </ListItem>
        </a>
      ))}
    </PlatformMenuWrapper>
  );
};
