import React, { useEffect, useMemo, useState } from "react";
import { uniqBy, isEqual } from "lodash";
import { Box, FormControlLabel, Switch } from "@material-ui/core";
import FilterPopup from "./FilterPopup";
import Autocomplete from "../../../../components/Autocomplete";
import { QueryResult } from "../../../../types";
import { Query, QueryItem } from "../../types";
import { ArrayFilterValue, NumberFilterValue } from "../types";
import { useDebounce, useEffectWithPrev } from "../../../../helpers/hooks";

interface Props {
  filterName: string;
  switchFilterName: string;
  filterKey: string;
  switchFilterKey: string;
  valueFrom?: string;
  textFrom?: string;
  queryFn: any;
  alreadySelected?: QueryResult<any>;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({
  filterName,
  switchFilterName,
  filterKey,
  switchFilterKey,
  valueFrom = "id",
  textFrom = "name",
  queryFn,
  alreadySelected,
  query,
  setQuery,
}: Props) {
  const queryItem = query?.[filterKey as QueryItem] as ArrayFilterValue;
  const querySwitchItem = query?.[
    switchFilterKey as QueryItem
  ] as NumberFilterValue;

  const [localSelected, setLocalSelected] = useState(
    alreadySelected?.data ?? []
  );
  const [input, setInput] = useState("");
  const debouncedInput = useDebounce(input);

  const [selectedValue, setSelectedValue] = useState(
    query?.[filterKey as QueryItem] as ArrayFilterValue
  );

  const [switchValue, setSwitchValue] = useState<boolean | undefined>(
    query[switchFilterKey as QueryItem] === 1 ? true : false
  );

  const queryData = queryFn(
    { query: debouncedInput },
    { enabled: !!debouncedInput }
  );

  const options = useMemo(
    () => uniqBy(localSelected?.concat(queryData?.data?.data ?? []), "id"),
    [localSelected, queryData?.data?.data]
  );

  useEffect(() => {
    const newSelected = options.filter((item: any) =>
      selectedValue?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    );
    if (!isEqual(newSelected, localSelected)) setLocalSelected(newSelected);
  }, [options, selectedValue, localSelected, setLocalSelected, valueFrom]);

  // update selected values according to values in query
  useEffect(() => {
    const newSelected = options.filter((item: any) =>
      queryItem?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    );
    if (!isEqual(newSelected, localSelected))
      setSelectedValue(
        (newSelected as ArrayFilterValue).map((v: any) =>
          v[valueFrom].toString()
        )
      );
    // :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, filterKey, valueFrom]);

  useEffectWithPrev(
    (inputs: any) => {
      const [prevQuerySwitchItem] = inputs;
      if (querySwitchItem !== prevQuerySwitchItem) {
        setSwitchValue(querySwitchItem === 1 ? true : false);
      }
    },
    [querySwitchItem, switchValue, switchFilterKey]
  );

  const getSelected = () =>
    options.filter((item: any) =>
      selectedValue?.some(
        (id: string) => id?.toString() === item?.[valueFrom].toString()
      )
    ) || [];

  const applyFilter = (handleClose: Function) => {
    setQuery({
      [filterKey]: selectedValue,
      [switchFilterKey]: switchValue ? 1 : undefined,
    });
    handleClose();
  };

  const handleSelect = (value: any) => {
    setInput("");
    setSelectedValue(value.map((v: any) => v?.[valueFrom].toString()));
    setSwitchValue(undefined);
  };

  const resetValue = () => {
    setSelectedValue(query?.[filterKey as QueryItem] as ArrayFilterValue);
    setSwitchValue(
      (query?.[switchFilterKey as QueryItem] as NumberFilterValue) === 1
        ? true
        : false
    );
  };

  return (
    <FilterPopup
      filterName={filterName}
      applyFilter={applyFilter}
      resetValue={resetValue}
    >
      <div style={{ width: "260px" }}>
        <Autocomplete
          data-cy={`ticket-filter-autocomplete-${filterName}`}
          loading={false}
          label={filterName}
          name={filterKey}
          placeholder="Type to search"
          shrink
          textFrom={textFrom ?? "name"}
          valueFrom={valueFrom ?? "id"}
          value={getSelected()}
          inputValue={input}
          options={options}
          onSearch={(query: string) => setInput(query)}
          onSelect={(value: any) => handleSelect(value)}
          onBlur={() => setInput("")}
          multiple
          disabled={!!switchValue}
        />
        <Box pt={1}>
          <FormControlLabel
            control={
              <Switch
                name={switchFilterKey}
                checked={!!switchValue}
                onChange={(event) => setSwitchValue(event.target.checked)}
                color="primary"
                disabled={!!selectedValue?.length}
              />
            }
            label={switchFilterName}
          />
        </Box>
      </div>
    </FilterPopup>
  );
}

export default Component;
