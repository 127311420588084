import React, { useState } from "react";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Button, Grid, Box } from "@material-ui/core";
import Loading from "../../../../layout/Loading";
import Table from "./components/Table";
import AssignParent from "../../AssignParent";
import AssignChildren from "../../AssignChildren";
import { SortDirectionQueryParam, Ticket } from "../../../../types";
import { limit, PermissionCodes } from "../../../../helpers/constants";
import { hasAnyPermission } from "../../../../helpers/functions";
import {
  useAddAssociation,
  usePaginateAssociations,
  useRemoveAssociation,
} from "../../../../hooks/tickets";

type Props = {
  ticket: Ticket;
};

function Component({ ticket }: Props) {
  const [keyForResetting, setKeyForResetting] = useState<number>(0);
  const [children, setChildren] = useState<Array<number>>([]);

  const addAssociation = useAddAssociation(ticket.id);
  const removeAssociation = useRemoveAssociation(ticket.id);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, limit.tickets),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
  });

  const ticketAssociations = usePaginateAssociations(ticket.id, query, {
    enabled: ticket.is_parent || ticket.is_child,
  });

  const handleSave = () => {
    addAssociation.mutate({ id: ticket.id, children });
    setChildren([]);
    setKeyForResetting(Math.random());
  };

  return (
    <Loading error={ticketAssociations?.error}>
      {hasAnyPermission([
        PermissionCodes.ticketsUpdateParent,
        PermissionCodes.ticketsUpdateChildren,
      ]) && (
        <Grid container spacing={1}>
          {ticket.can_be_child && (
            <Grid item xs>
              <AssignParent
                disabled={
                  children.length > 0 ||
                  ticketAssociations?.isLoading ||
                  addAssociation?.isLoading ||
                  removeAssociation?.isLoading
                }
                onSelect={(parent: Ticket | null) => {
                  if (parent) {
                    addAssociation.mutate({
                      id: parent.id,
                      children: [ticket.id],
                    });
                  }
                }}
                except={ticket.id}
              />
            </Grid>
          )}
          {ticket.can_be_parent && (
            <Grid item xs>
              <Box display="flex" justifyContent="space-between">
                <Box width="100%">
                  <AssignChildren
                    onSelect={(tickets) => {
                      setChildren(tickets.map((ticket) => ticket.id));
                    }}
                    except={ticket.id}
                    keyForResetting={keyForResetting}
                    disabled={
                      ticketAssociations?.isLoading ||
                      addAssociation?.isLoading ||
                      removeAssociation?.isLoading
                    }
                  />
                </Box>
                {children.length > 0 && (
                  <Box display="flex" alignSelf="flex-end" pb={1} ml={1}>
                    <Button
                      data-cy="save-association-button"
                      color="primary"
                      onClick={handleSave}
                      size="medium"
                      type="button"
                      variant="contained"
                      style={{ height: "40px" }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      {(ticket.is_child || ticket.is_parent) && (
        <Table
          data={ticketAssociations.data?.data || []}
          count={ticketAssociations?.data?.meta?.total || 0}
          title={ticket.is_child ? "Parent Ticket" : "Child Tickets"}
          loading={
            ticketAssociations?.isLoading ||
            ticketAssociations?.isFetching ||
            addAssociation?.isLoading ||
            removeAssociation?.isLoading
          }
          query={query}
          setQuery={setQuery}
          removeAssociation={(id: number) =>
            removeAssociation.mutate({
              id: ticket.is_parent ? ticket.id : id,
              children: ticket.is_parent ? [id] : [ticket.id],
            })
          }
        />
      )}
    </Loading>
  );
}

export default Component;
