import React, { FormEvent, useState } from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Form from "./components/Form";
import { Commitment } from "../../../../../../../types";
import { hasPermission } from "../../../../../../../helpers/functions";
import { PermissionCodes } from "../../../../../../../helpers/constants";
import {
  useStopPause,
  useStartPause,
} from "../../../../../../../hooks/tickets";

interface Props {
  commitment: Commitment;
}

export default function Component({ commitment }: Props) {
  const [message, setMessage] = useState("");

  const pauseTimer = useStartPause(commitment.ticket_id, { message: message });
  const resumeTimer = useStopPause(commitment.ticket_id);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    pauseTimer.mutate({ id: commitment.id });
  };

  if (!hasPermission(PermissionCodes.commitmentsPausesCreate)) {
    return <></>;
  }

  return (
    <>
      {commitment.is_paused && (
        <Box display="flex" justifyContent="flex-start">
          <Button
            data-cy="resume-sla-button"
            color="primary"
            disabled={resumeTimer?.isLoading}
            onClick={(e) => resumeTimer.mutate({ id: commitment.id })}
            size="small"
            type="button"
            variant="contained"
            startIcon={
              resumeTimer?.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <PlayArrowIcon />
              )
            }
          >
            Resume SLA
          </Button>
        </Box>
      )}

      {commitment.can_be_paused && (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Form
            loading={pauseTimer?.isLoading}
            message={message}
            setMessage={setMessage}
          />

          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button
              data-cy="pause-sla-button"
              color="primary"
              disabled={pauseTimer?.isLoading}
              size="small"
              type="submit"
              variant="contained"
              startIcon={
                pauseTimer?.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <PauseIcon />
                )
              }
            >
              Pause SLA
            </Button>
          </Box>
        </form>
      )}
    </>
  );
}
