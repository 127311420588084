import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "react-query";
import { useHistory } from "react-router-dom";
import { ValidationError } from "../api";
import * as api from "../api/tasks";
import { Query, StatusesData, TaskData } from "../screens/Tasks/types";
import { LooseObject, QueryData, Task, TaskFields } from "../types";

export type TaskCreateMutationType = UseMutationResult<
  Task,
  Error | ValidationError,
  Partial<TaskFields>
>;

export function useCreate(owner: string, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const history = useHistory();

  const onSuccess = (data: TaskData) => {
    queryClient.setQueryData(["tasks", data.data.id, "details"], data);

    queryClient.invalidateQueries(["new-task"]);
    queryClient.invalidateQueries(["tasks", "table", owner]);

    history.push(`/support/${owner}tasks`);
  };

  return useMutation<TaskData, Error, Partial<TaskFields>>(
    (fields) => api.create(owner, fields),
    {
      ...options,
      onSuccess,
    }
  );
}

export function usePaginate(
  owner: string,
  query: Partial<Query>,
  status_id?: number,
  options = {}
) {
  return useQuery<QueryData<Task>, Error>(
    ["tasks", "table", owner, query, status_id],
    () => api.paginate(owner, query, status_id),
    options
  );
}

export function useStatuses(query: string, options = {}) {
  return useQuery<StatusesData, Error>(
    ["tasks", "statuses", query],
    () => api.statuses({ query }),
    options
  );
}

export function useTaskDetail(taskId: number, options: LooseObject = {}) {
  return useQuery<TaskData, Error>(
    ["tasks", taskId, "details"],
    () => api.show(taskId),
    options
  );
}

export function useUpdate(taskId: number, options: LooseObject = {}) {
  const queryClient = useQueryClient();
  const onSuccess = (data: any, variables: any) => {
    queryClient.setQueryData(["tasks", taskId, "details"], data);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((params) => api.update(taskId, params), {
    ...options,
    onSuccess,
  });
}
