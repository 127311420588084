import React from "react";
import { format } from "date-fns";
import { Button, Grid, Typography } from "@material-ui/core";
import DateFilter from "./components/DateFilter";
import UserFilter from "./components/UserFilter";
import TagFilter from "./components/TagFilter";
import { Query } from "../types";
import { dateOrNull } from "../../../helpers/functions";
import { usePaginate } from "../../../hooks/audits";

type Props = {
  owner?: string;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  resetQuery: () => void;
};

function Component({ owner, query, setQuery, resetQuery }: Props) {
  const { isLoading } = usePaginate(owner, query);

  return (
    <Grid container spacing={1}>
      <Button
        data-cy="audits-filters-reset-button"
        color="primary"
        onClick={resetQuery}
        size="small"
        variant="text"
      >
        RESET
      </Button>
      <Grid item xs={12}>
        <TagFilter query={query} setQuery={setQuery} />
      </Grid>
      <Grid item xs={12}>
        <UserFilter query={query} setQuery={setQuery} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <DateFilter
              label="Start Date"
              name="created_after"
              value={dateOrNull(query.created_after ?? "")}
              disabled={isLoading}
              onChange={(date) => {
                setQuery({
                  created_after: format(date, "yyyy-MM-dd 00:00:00"),
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography align="center">to</Typography>
          </Grid>
          <Grid item xs={5}>
            <DateFilter
              label="End Date"
              name="created_before"
              value={dateOrNull(query.created_before ?? "")}
              disabled={isLoading}
              onChange={(date) => {
                setQuery({
                  created_before: format(date, "yyyy-MM-dd 23:59:59"),
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Component;
