import React from "react";
import { makeStyles, Paper, lighten } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export interface AlertProps {
  "data-cy"?: string;
  type: "error" | "warning" | "info" | "success";
  message: React.ReactNode | string;
}

/** Communicate warnings, info and success messages to the user */
export const Alert = ({ "data-cy": dataCy, type, message }: AlertProps) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.typography.body2,
      backgroundColor: lighten(theme.palette[type].main, 0.9),
      display: "flex",
      padding: "6px 16px",
    },
    message: {
      padding: "8px 0",
    },
    icon: {
      marginRight: 12,
      padding: "7px 0",
      display: "flex",
      fontSize: 22,
      opacity: 0.9,
      color: theme.palette[type].main,
    },
  }));

  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0} data-c={dataCy}>
      <div className={classes.icon}>
        {type === "error" && (
          <ErrorOutlineIcon fontSize="inherit" color="inherit" />
        )}
        {type === "warning" && (
          <ReportProblemOutlinedIcon fontSize="inherit" color="inherit" />
        )}
        {type === "info" && (
          <InfoOutlinedIcon fontSize="inherit" color="inherit" />
        )}
        {type === "success" && (
          <CheckCircleOutlineIcon fontSize="inherit" color="inherit" />
        )}
      </div>

      <div className={classes.message}>{message}</div>
    </Paper>
  );
};
