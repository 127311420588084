import React, { ChangeEvent, useState } from "react";
import { Box, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@akj-dev/design-system";
import SavedFilter from "./SavedFilter";
import { Filter } from "../../../../types";
import {
  useRemove,
  useSearch,
  useUpdate,
  useMoveUp,
  useMoveDown,
} from "../../../../hooks/tickets/filters";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useTeams } from "../../../../hooks/autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      "& .MuiTabs-root": {
        backgroundColor: "white",
        opacity: 1,
        zIndex: 1,
      },
      minWidth: "50vw",
    },
    tabs: {
      position: "sticky",
      top: 0,
    },
    gridContainer: {
      padding: theme.spacing(1.5, 2, 0, 2),
      display: "grid",
      gridGap: theme.spacing(1, 2),
      gridTemplateColumns: "auto auto auto auto auto auto auto auto", // one auto for each column in the saved filters menu
      alignItems: "center",
    },
  })
);

interface Props {
  appliedFilterId?: number;
  setFilter: (filter: Filter | undefined) => void;
  resetQuery: (filter?: Filter) => void;
  handleClose: () => void;
}

function Component({
  appliedFilterId,
  setFilter,
  resetQuery,
  handleClose,
}: Props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("all");
  const filters = useSearch({ [activeTab]: 1 });
  const allTeams = useTeams();
  const update = useUpdate();
  const remove = useRemove();
  const moveUp = useMoveUp();
  const moveDown = useMoveDown();

  const tabs = hasPermission(PermissionCodes.filtersViewSelf)
    ? null
    : [
        { value: "all", label: "All" },
        { value: "private", label: "Private" },
        { value: "shared", label: "Shared" },
      ];

  const handleChange = (e: ChangeEvent<{}>, value: string) => {
    setActiveTab(value);
  };

  return (
    <Box data-cy="saved-filters-popover" className={classes.wrap}>
      {tabs && (
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleChange}
          className={classes.tabs}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      )}
      {(filters.isLoading ||
        moveDown.isLoading ||
        filters.isFetching ||
        moveUp.isLoading) && (
        <Box display="flex" justifyContent="center" pt={2}>
          <CircularProgress size={24} />
        </Box>
      )}
      {!filters.isLoading && !filters.data?.data?.length ? (
        <Box mt={2} mr={1} ml={1}>
          <Alert
            type="info"
            message={`No ${activeTab !== "all" ? activeTab + " " : ""}filters.`}
          />
        </Box>
      ) : (
        <div className={classes.gridContainer}>
          {filters.data?.data?.map((filter: Filter, index: number) => (
            <SavedFilter
              data-cy={`saved-filter-${filter.id}`}
              key={filter.id}
              filter={filter}
              allTeams={allTeams.data?.data || []}
              loading={
                filters?.isLoading ||
                update?.isLoading ||
                remove?.isLoading ||
                filters.isFetching
              }
              update={update.mutate}
              remove={remove.mutate}
              moveUp={index > 0 ? moveUp.mutate : undefined}
              moveDown={
                index < filters.data?.data?.length - 1
                  ? moveDown.mutate
                  : undefined
              }
              setFilter={setFilter}
              resetQuery={resetQuery}
              handleClose={handleClose}
              appliedFilterId={appliedFilterId}
            />
          ))}
        </div>
      )}
    </Box>
  );
}

export default Component;
