import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import FilterPopup from "./FilterPopup";
import { Query, QueryItem } from "../../types";
import { dateOrNull } from "../../../../helpers/functions";

interface Props {
  filterName: string;
  filterKey: string;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ filterName, filterKey, query, setQuery }: Props) {
  const [selectedValue, setSelectedValue] = useState(
    dateOrNull((query?.[filterKey as QueryItem] as string) ?? "")
  );

  const filterValue = query?.[filterKey as QueryItem];

  useEffect(() => {
    setSelectedValue(dateOrNull((filterValue as string) ?? ""));
  }, [filterValue]);

  const applyFilter = (handleClose: Function) => {
    setQuery({
      [filterKey]: format(selectedValue as Date, "yyy-MM-dd 00:00:00"),
    });
    handleClose();
  };

  return (
    <FilterPopup filterName={filterName} applyFilter={applyFilter}>
      <DatePicker
        label={filterName}
        name={filterKey}
        format="dd/MM/yyy"
        value={selectedValue}
        onChange={(value) => setSelectedValue(value)}
        autoOk
        fullWidth
        disableToolbar
        size="small"
        variant="inline"
        inputVariant="outlined"
        margin="none"
      />
    </FilterPopup>
  );
}

export default Component;
