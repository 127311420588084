import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

interface Props {
  anchorOrigin?: any;
  button?: any;
  children: React.ReactNode | Function;
  onOpen?: () => void;
  trigger?: React.ReactNode;
  transformOrigin?: any;
  closeCallback?: Function;
}

export default function Component({
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  button = {},
  children,
  onOpen,
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  trigger,
  closeCallback,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    if (closeCallback) closeCallback();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} onClick={handleClick} {...button}>
        {trigger ?? ""}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {typeof children === "function"
          ? children(open, handleClose)
          : children}
      </Popover>
    </>
  );
}
