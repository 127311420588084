import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Filter } from "../types";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function AmountFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const val1 = selection?.[0]?.split(" ");
  const val2 = selection?.[1]?.split(" ");
  const [value, setValue] = React.useState(val1 ? val1?.[0] : "none");
  const [inputValue, setInputValue] = React.useState(val1 ? val1?.[1] : "");
  const [criteriaValue, setCriteriaValue] = React.useState(
    val2 ? val2?.[0] : "none"
  );
  const [inputCriteriaValue, setInputCriteriaValue] = React.useState(
    val2 ? val2?.[1] : ""
  );

  const [criteriaShow, setCriteria] = React.useState(!!val2);

  React.useEffect(() => {
    const firstConditionValues = [value, inputValue];
    const secondConditionValues = [criteriaValue, inputCriteriaValue];

    const firstCondition = firstConditionValues?.join(" ");
    const secondCondition = secondConditionValues?.join(" ");

    const result = [
      ...(firstCondition !== "none " ? [firstCondition] : []),
      ...(secondCondition !== "none " ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, inputValue, criteriaValue, inputCriteriaValue]);

  const addCriteria = () => {
    if (criteriaShow) {
      setCriteriaValue("none");
      setInputCriteriaValue("");
      setCriteria((oldCriteria) => !oldCriteria);
    } else {
      setCriteria((oldCriteria) => !oldCriteria);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const handleCriteriaChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCriteriaValue(event.target.value as string);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputValue(validatedValue);
  };

  const handleCriteriaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputCriteriaValue(validatedValue);
  };

  React.useEffect(() => {
    if (value === "=") {
      setCriteria(false);
      setCriteriaValue("none");
      setInputCriteriaValue("");
    }
  }, [setCriteria, value]);

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && inputValue === "") ||
          (criteriaValue === "none" && inputCriteriaValue !== "") ||
          (criteriaValue !== "none" && inputCriteriaValue === "")
        }
      >
        <div>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formSelect}
          >
            <Select
              native
              value={value}
              onChange={handleChange}
              inputProps={{
                name: "value",
                id: "value",
              }}
            >
              <option value="none" hidden />
              <option value="equal">=</option>
              <option value="greater_than">&gt;</option>
              <option value="less_than">&lt;</option>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            name="inputValue"
            value={inputValue}
            onChange={handleInput}
            className={classes.formInput}
            placeholder="Enter call cost"
          />
        </div>

        <Grid container direction="row" justify="center" alignItems="center">
          <Button
            size="small"
            disabled={value === "=" || value === "none"}
            onClick={() => addCriteria()}
            className={classes.criteriaButton}
          >
            {!criteriaShow ? "add another criteria" : "remove criteria"}
          </Button>
        </Grid>

        {criteriaShow && (
          <div>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formSelect}
            >
              <Select
                native
                value={criteriaValue}
                onChange={handleCriteriaChange}
                inputProps={{
                  name: "criteriaValue",
                  id: "criteriaValue",
                }}
              >
                {value === "greater_than" ? (
                  <>
                    <option value="none" hidden />
                    <option value="less_than">&lt;</option>
                  </>
                ) : (
                  <>
                    <option value="none" hidden />
                    <option value="greater_than">&gt;</option>
                  </>
                )}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              name="inputCriteriaValue"
              value={inputCriteriaValue}
              onChange={handleCriteriaInput}
              placeholder="Enter call cost"
              className={classes.formInput}
            />
          </div>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSelect: {
      marginLeft: theme.spacing(2),
    },
    formInput: {
      marginInline: theme.spacing(2),
    },
    criteriaButton: {
      margin: theme.spacing(1),
    },
  })
);
