import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Filter } from "../types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { formatDateWithPattern, parseStringToDate } from "../utils";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { KeyboardTimePicker } from "@material-ui/pickers/TimePicker";
import { TimePickerToolbar } from "@material-ui/pickers/TimePicker/TimePickerToolbar";
import { Typography } from "@material-ui/core";
import { Timer } from "@material-ui/icons";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

const DEFAULT_TIME = null;

export default function TimeFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const PATTERN = "mm:ss";

  const val1 = selection[0]?.split(" ");
  const val2 = selection[1]?.split(" ");

  const [value, setValue] = React.useState(val1?.[0] || "none");
  const [criteriaValue, setCriteriaValue] = React.useState(val2?.[0] || "none");

  const [selectedDate, handleDateChange] = React.useState<Date | null>(
    val1 ? parseStringToDate(val1?.[1], PATTERN) : DEFAULT_TIME
  );

  const [selectedCriteriaDate, handleCriteriaDateChange] =
    React.useState<Date | null>(
      val2 ? parseStringToDate(val2?.[1], PATTERN) : DEFAULT_TIME
    );

  const [criteriaShow, setCriteria] = React.useState(!!val2);

  const formatDateValue = (date: any) => {
    if (!isNaN(new Date(date)?.getDate())) {
      const dateToIso = new Date(date).toISOString();
      const formatedDate = formatDateWithPattern(dateToIso, PATTERN);
      return formatedDate;
    }
  };

  const addCriteria = () => {
    setCriteria((oldCriteria) => !oldCriteria);
    if (criteriaShow) {
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  };

  React.useEffect(() => {
    const formatTime = formatDateValue(selectedDate);
    const formatCriteriaTime = formatDateValue(selectedCriteriaDate);

    const firstConditionValues = [value, formatTime];
    const secondConditionValues = [criteriaValue, formatCriteriaTime];

    const firstCondition = firstConditionValues.join(" ");
    const secondCondition = secondConditionValues.join(" ");

    const result = [
      ...(firstCondition !== "none 00:00" ? [firstCondition] : []),
      ...(secondCondition !== "none 00:00" ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, selectedDate, criteriaValue, selectedCriteriaDate]);

  React.useEffect(() => {
    if (value === "equal") {
      setCriteria(false);
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  }, [setCriteria, value]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const handleCriteriaChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCriteriaValue(event.target.value as string);
  };

  const renderTimePicker = (value: any, onChange: any, props?: any) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          ampm={false}
          autoOk={true}
          openTo="minutes"
          views={["minutes", "seconds"]}
          format={PATTERN}
          placeholder="HH:MM"
          variant="inline"
          inputVariant="outlined"
          size="small"
          value={value}
          onChange={onChange}
          className={classes.formInput}
          KeyboardButtonProps={{ className: classes.timeButton }}
          keyboardIcon={<Timer />}
          ToolbarComponent={(props: any) => (
            <>
              <TimePickerToolbar {...props} />
              <Typography
                className={classes.timeToolbarLabel}
                align="center"
                variant="body2"
              >
                Select duration in hours and minutes
              </Typography>
            </>
          )}
          {...props}
        />
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && selectedDate === DEFAULT_TIME) ||
          (criteriaValue === "none" && selectedCriteriaDate !== DEFAULT_TIME) ||
          (criteriaValue !== "none" && selectedCriteriaDate === DEFAULT_TIME)
        }
      >
        <div>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formSelect}
          >
            <Select
              native
              value={value}
              onChange={handleChange}
              inputProps={{
                name: "value",
                id: "value",
              }}
            >
              <option value="none" hidden />
              <option value="equal">=</option>
              <option value="greater_than">&gt;</option>
              <option value="less_than">&lt;</option>
            </Select>
          </FormControl>
          {renderTimePicker(selectedDate, handleDateChange)}
        </div>

        <Grid container direction="row" justify="center" alignItems="center">
          <Button
            size="small"
            disabled={value === "equal" || value === "none"}
            onClick={() => addCriteria()}
            className={classes.criteriaButton}
          >
            {!criteriaShow ? "add another criteria" : "remove criteria"}
          </Button>
        </Grid>

        {criteriaShow && (
          <div>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formSelect}
            >
              <Select
                native
                value={criteriaValue}
                onChange={handleCriteriaChange}
                inputProps={{
                  name: "criteriaValue",
                  id: "criteriaValue",
                }}
              >
                {value === "greater_than" ? (
                  <>
                    <option value="none" hidden />
                    <option value="less_than">&lt;</option>
                  </>
                ) : (
                  <>
                    <option value="none" hidden />
                    <option value="greater_than">&gt;</option>
                  </>
                )}
              </Select>
            </FormControl>
            {renderTimePicker(selectedCriteriaDate, handleCriteriaDateChange)}
          </div>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSelect: {
      marginLeft: theme.spacing(2),
    },
    formInput: {
      marginInline: theme.spacing(2),
    },
    criteriaButton: {
      margin: theme.spacing(1),
    },
    timeToolbarLabel: {
      color: theme.palette.primary.light,
      top: "10px",
      position: "absolute",
      width: "100%",
    },
    timeButton: {
      padding: 0,
    },
  })
);
