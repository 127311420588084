import React from "react";
import { Grid } from "@material-ui/core";
import { IndexedCardInfo } from "../../../atoms/indexed-card-info";
import { FetchWLR3AddressSearch } from "../../AddressPicker/types";
import { AddressFields } from "./AddressFields";

interface BillingAddressProps {
  cardIndex: string;
  accountId?: string;
  api: FetchWLR3AddressSearch;
}
export const BillingAddress = ({
  cardIndex,
  accountId,
  api,
}: BillingAddressProps) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Billing Address">
      <Grid container spacing={2}>
        <AddressFields prefix="billing_" accountId={accountId} api={api} />
      </Grid>
    </IndexedCardInfo>
  );
};
