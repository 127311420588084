import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from "@akj-dev/design-system";
import { Article, Category, Question } from "../types";
import CategoryItem from "./components/CategoryItem";
import QuestionItem from "./components/QuestionItem";

type Props = {
  categories: Category[];
  article?: Article;
  loading: boolean;
  handleCategoryClick: (id: number) => void;
};

function Component({
  categories,
  article,
  loading,
  handleCategoryClick,
}: Props) {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (categories.length) {
    return (
      <Box
        data-cy="knowledge-base-categories-subgrid"
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(260px, 1fr))"
        gridGap={16}
      >
        {categories.map((category: Category) => (
          <CategoryItem
            key={category.id}
            id={category.id}
            category={category}
            onClick={handleCategoryClick}
          />
        ))}
      </Box>
    );
  }

  if (article && article.published_questions.length) {
    return (
      <Box
        data-cy="knowledge-base-questions-subgrid"
        display="grid"
        gridTemplateColumns="repeat(auto-fit, 1fr)"
        gridGap={16}
      >
        {article.published_questions.map((question: Question) => (
          <QuestionItem question={question} />
        ))}
      </Box>
    );
  }

  return <Alert type="info" message={`No subcategories or articles found.`} />;
}

export default Component;
