import React, { useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Popover,
  Theme,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import { useSearch } from "../../../../hooks/tickets/views";
import Views from "./Views";
import { Query } from "../../types";
import { useAutocomplete } from "../../../../hooks/tickets/views/columns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      width: "500px",
      padding: theme.spacing(2),
      overflow: "hidden",
    },
    iconButton: {
      color: theme.palette.background.default,
    },
  })
);

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

export default function CustomViews({ query, setQuery }: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const [search, setSearch] = useState<string>("");

  const viewsQuery = useSearch(search, {
    enabled: open,
  });

  const columnsQuery = useAutocomplete("", {
    enabled: open,
  });

  return (
    <Box>
      <Tooltip title="View Columns">
        <IconButton
          size="small"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
        >
          <ViewWeekIcon fontSize="small" className={classes.iconButton} />
        </IconButton>
      </Tooltip>
      <Popover
        id="view-columns-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {open && (
          <Grid container spacing={1} className={classes.popover}>
            <Grid item xs={12}>
              <Views
                columns={columnsQuery.data?.data ?? []}
                loading={viewsQuery.isLoading}
                onSearch={(query: string) => setSearch(query)}
                query={query}
                setQuery={setQuery}
                views={viewsQuery.data?.data ?? []}
              />
            </Grid>
          </Grid>
        )}
      </Popover>
    </Box>
  );
}
