import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  List,
  Typography,
  ListItem,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  ArrowDownward as SortDescIcon,
  ArrowUpward as SortAscIcon,
} from "@material-ui/icons/";
import { Alert } from "@akj-dev/design-system";
import Ticket from "./components/Ticket";
import { Query } from "../types";
import { Status, Ticket as TicketType, SortDirection } from "../../../types";
import { hasPermission } from "../../../helpers/functions";
import { constants } from "../../../helpers";
import { useMove, usePaginateKanban } from "../../../hooks/tickets";
import { useCustomers } from "../../../hooks/autocomplete";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 1),
  },
  title: {
    width: "100%",

    "& span": {
      float: "right",
    },
  },
  formControl: {
    width: "85%",
  },
}));

type Props = {
  query: Partial<Query>;
  status: Status;
  showDivider: boolean;
};

function Component({ query, status, showDivider }: Props) {
  const classes = useStyles();
  const customers = useCustomers();

  const [local, setLocal] = useState({
    append: false,
    limit: query.limit || constants.limit.kanban,
    page: 1,
    direction: "desc" as SortDirection,
    sort: "",
  });

  const tickets = usePaginateKanban(status.id, {
    ...query,
    ...local,
  });
  const move = useMove(status.id);

  const handleSortChange = (event: any) => {
    setLocal({
      ...local,
      sort: event.target.value,
      direction: "desc",
    });
  };

  const handleDirectionChange = () => {
    setLocal({
      ...local,
      direction: local.direction === "desc" ? "asc" : "desc",
    });
  };

  const LoadingIndicator = () => {
    if (!tickets.isLoading && !tickets.isRefetching) {
      return null;
    }

    return (
      <ListItem disableGutters>
        <Button
          disabled={true}
          fullWidth
          size="small"
          startIcon={<CircularProgress size={24} />}
          variant="text"
        >
          Loading
        </Button>
      </ListItem>
    );
  };

  const ErrorIndicator = () => {
    if (!tickets.isError) {
      return null;
    }
    return (
      <ListItem disableGutters>
        <Alert
          message={
            tickets.isError
              ? tickets?.error instanceof Error
                ? tickets.error?.message
                : "Unknown Error"
              : null
          }
          type="error"
        />
      </ListItem>
    );
  };

  const LoadMore = () => {
    // Are there more items?
    if (
      !tickets?.isLoading &&
      !tickets?.isRefetching &&
      local.page * local.limit < tickets?.data?.meta.total
    ) {
      // Do we want to allow the user to load more?
      if (tickets.data?.data.length < constants.limit.kanbanMaxItems) {
        return (
          <ListItem disableGutters>
            <Button
              fullWidth
              onClick={() =>
                setLocal({ ...local, append: true, page: local.page + 1 })
              }
              size="small"
              variant="text"
            >
              Load more
            </Button>
          </ListItem>
        );
      }

      const { limit, page } = local;

      // Inform the user that we already displayed the maximum number of tickets on Kanban view
      // And show a button that will take them to Table view on the desired page
      return (
        <>
          <ListItem disableGutters>
            <Typography align="center" variant="caption">
              A maximum of {constants.limit.kanbanMaxItems} items can be viewed
              on Kanban view.
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <Button
              component={Link}
              fullWidth
              size="small"
              to={
                "/support/tickets?" +
                queryString.stringify({
                  limit,
                  page: page + 1,
                  status_ids: [status.id],
                })
              }
              variant="text"
            >
              View more on Table view
            </Button>
          </ListItem>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Box alignSelf="stretch" flexGrow={1} width={225}>
        <Box>
          <Typography className={classes.title} variant="h4">
            {status.name} <span>{tickets.data?.data?.length}</span>
          </Typography>
        </Box>

        <Box display="flex">
          <FormControl className={classes.formControl}>
            <InputLabel>Sort by</InputLabel>
            <Select value={local.sort} onChange={handleSortChange}>
              {hasPermission(constants.PermissionCodes.slaCalendarViewAny) && (
                <MenuItem value={"target_resolution"}>
                  Target Resolution
                </MenuItem>
              )}
              {hasPermission(constants.PermissionCodes.slaCalendarViewAny) && (
                <MenuItem value="target_response">Target Response</MenuItem>
              )}
              {(customers.data?.data?.length ?? 0) > 1 && (
                <MenuItem value="customer.name">Company Name</MenuItem>
              )}
              <MenuItem value="priority_id">Severity</MenuItem>
              <MenuItem value="user.full_name">Assigned to User</MenuItem>
              <MenuItem value="team.name">Assigned to Team</MenuItem>
              {hasPermission(constants.PermissionCodes.ticketsViewAnyStage) && (
                <MenuItem value="stage_id">Substage</MenuItem>
              )}
            </Select>
          </FormControl>
          <Box mt="auto" ml="auto">
            <IconButton size="small" onClick={handleDirectionChange}>
              {local.direction === "desc" ? <SortDescIcon /> : <SortAscIcon />}
            </IconButton>
          </Box>
        </Box>

        <Box alignSelf="stretch" height="100%">
          <Droppable
            onDrop={(data: any) => {
              const [ticketId, isOpen, resolvesTicket, oldStatusId] =
                data.ticket.split(","); // [ticketId, is_open, resolves_ticket, new_status_id]
              // move if ticket is open to open or resolved / or resolved to anywhere
              if (
                (oldStatusId !== status.id.toString() &&
                  isOpen === "true" &&
                  (status.is_open || status.resolves_ticket)) ||
                (resolvesTicket === "true" && !status.resolves_ticket)
              ) {
                move.mutate({
                  id: parseInt(ticketId),
                  old_status_id: parseInt(oldStatusId),
                });
              }
            }}
            style={{ alignSelf: "stretch", height: "100%" }}
            types={["ticket"]}
          >
            <List style={{ height: "100%" }}>
              <>
                {tickets.data?.data?.map((ticket: TicketType) => (
                  <ListItem key={ticket.id} disableGutters>
                    <Ticket ticket={ticket} status={status} />
                  </ListItem>
                ))}
              </>

              <LoadingIndicator />
              <ErrorIndicator />
              <LoadMore />
            </List>
          </Droppable>
        </Box>
      </Box>

      {showDivider && (
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
          light
        />
      )}
    </>
  );
}

export default Component;
