import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../../api/tickets/views";
import { LooseObject } from "../../types";

export function useCreate(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "views"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((view) => api.create(view), {
    ...options,
    onSuccess,
  });
}

export function useRemove(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "views"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((view) => api.remove(view.id), {
    ...options,
    onSuccess,
  });
}

export function useSearch(query = "", options = {}) {
  return useQuery(["tickets", "views", query], () => api.search(), options);
}

export function useUpdate(options: LooseObject = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["tickets", "table"]);
    queryClient.invalidateQueries(["tickets", "views"]);

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data, variables);
    }
  };

  return useMutation((view) => api.update(view), {
    ...options,
    onSuccess,
  });
}
