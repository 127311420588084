import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import Loading from "../../../layout/Loading";
import Table from "./components/Table";
import { Ticket } from "../../../types";
import { Query } from "../types";
import { usePaginate } from "../../../hooks/tasks";

interface Props {
  elevation?: number;
  embedded?: boolean;
  owner?: string;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
  ticket?: Ticket;
}

export default function Component({
  elevation,
  owner = "",
  embedded,
  query,
  setQuery,
  ticket,
}: Props) {
  const paginator = usePaginate(owner, query);

  return (
    <Loading error={paginator.isError ? "Can't load tasks" : undefined}>
      <Grid container spacing={2}>
        {ticket && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" pt={1}>
              <Button
                data-cy="new-task-button"
                color="primary"
                component={Link}
                size="medium"
                to={`/support/${owner}tasks/create`}
                startIcon={<AddIcon />}
                variant="contained"
              >
                New Task
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Table
            elevation={elevation}
            paginator={paginator}
            query={query}
            setQuery={setQuery}
            embedded={embedded}
          />
        </Grid>
      </Grid>
    </Loading>
  );
}
