import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { format } from "date-fns";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
} from "@material-ui/core/";
import { DatePicker } from "@material-ui/pickers";
import { useTheme } from "@material-ui/core/styles";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import { useAuth } from "@akj-dev/affinity-platform";
import Loading from "../layout/Loading";
import { dateOrNull, formattedDate, hasPermission } from "../helpers/functions";
import { PermissionCodes } from "../helpers/constants";
import { useEffectWithPrev } from "../helpers/hooks";
import { useCreate, useRemove, useUpdate } from "../hooks/timers";
import {
  LooseObject,
  Query,
  QueryData,
  SortDirection,
  Task,
  Ticket,
  Timer,
  TimerFields,
  TimerType,
} from "../types";
import { ValidationError } from "../api";

interface Props {
  paginator: UseQueryResult<QueryData<Timer>, Error>;
  entity: Ticket | Task;
  timerType: TimerType;
  fields: TimerFields;
  setFields: (fields: Partial<TimerFields>) => void;
  resetFields: () => void;
  editingRowIndex: number;
  addingNewTimeLog: boolean;
  setEditingRowIndex: Dispatch<SetStateAction<number>>;
  setAddingNewTimeLog: Dispatch<SetStateAction<boolean>>;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

export default function Component({
  paginator,
  entity,
  timerType,
  fields,
  setFields,
  resetFields,
  editingRowIndex,
  addingNewTimeLog,
  setEditingRowIndex,
  setAddingNewTimeLog,
  query,
  setQuery,
}: Props) {
  const { externalUserName } = useAuth();
  const [tableData, setTableData] = useState<Timer[]>([]);
  const theme = useTheme();

  const [formValidation, setValidation] = useState<LooseObject>();

  const create = useCreate(entity.id, timerType, {
    onSuccess: ({ data }: { data: Timer }) => {
      setTableData([data, ...tableData]);
      setAddingNewTimeLog(false);
      resetFields();
    },
    onError: (error: ValidationError) => {
      setValidation(error.validation);
    },
  });

  const update = useUpdate(entity.id, timerType, {
    onSuccess: () => {
      setEditingRowIndex(-1);
      resetFields();
    },
    onError: (error: ValidationError) => {
      setValidation(error.validation);
    },
  });

  const remove = useRemove(entity.id, timerType);

  const loading =
    create.isLoading ||
    update.isLoading ||
    remove.isLoading ||
    paginator.isLoading ||
    paginator.isFetching;

  // saves loaded/refetched table data
  useEffect(() => {
    if (paginator.data?.data && !addingNewTimeLog)
      setTableData(paginator.data?.data);
  }, [paginator.data?.data, addingNewTimeLog]);

  // adds empty row for new log
  useEffect(() => {
    if (addingNewTimeLog) {
      if (paginator.data?.data) {
        setTableData([{} as Timer, ...paginator.data?.data]);
      }
    }
  }, [paginator.data?.data, addingNewTimeLog, setTableData]);

  // sets values from currently updated log to the fields
  useEffectWithPrev(
    (inputs: any) => {
      const [prevEditingRowIndex] = inputs;
      if (editingRowIndex !== prevEditingRowIndex) {
        const hours = Math.trunc(
          (paginator.data?.data || [])[editingRowIndex]?.minutes / 60
        );
        const minutes =
          (paginator.data?.data || [])[editingRowIndex]?.minutes % 60;
        setFields({
          ...(paginator.data?.data || [])[editingRowIndex],
          hours: hours.toString(),
          minutes: minutes.toString(),
        });
      }
    },
    [editingRowIndex, paginator.data?.data, setFields]
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    max?: number
  ) => {
    resetValidation([event.target.name]);
    if (event.target.name === "hours" || event.target.name === "minutes") {
      resetValidation(["hours", "minutes"]);
    }
    setFields({
      [event.target.name]: max
        ? formatInputValue(event.target.value, max)
        : event.target.value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      [event.target.name]: event.target.checked,
    });
  };

  const formatInputValue = (value: string, max: number): number | null => {
    if (!value) return null;
    if (parseInt(value) < 0) return 0;
    if (parseInt(value) > max) return max;
    return parseInt(value);
  };

  const formatFields = (fields: Partial<TimerFields>) => {
    return {
      hours: (fields?.hours ?? 0) > 0 ? fields.hours : undefined,
      id: tableData[editingRowIndex]?.id,
      minutes: (fields?.minutes ?? 0) > 0 ? fields.minutes : undefined,
      started_at:
        fields.started_at &&
        [formattedDate(fields.started_at, "yyyy-MM-dd"), "00:00:00"].join(" "),
      message: fields.message,
      is_billable: fields.is_billable,
    } as Partial<TimerFields>;
  };

  const saveNewLog = () => create.mutate(formatFields(fields));

  const saveEditedLog = () => {
    update.mutate({
      id: tableData[editingRowIndex]?.id!,
      fields: formatFields(fields),
    });
  };

  const cancelNewLog = () => {
    if (addingNewTimeLog && tableData) {
      const newTableData = [...tableData];
      newTableData.shift();
      setTableData(newTableData);
      setAddingNewTimeLog(false);
    }
  };

  const removeExistingLog = (id: number) => {
    const newTableData = tableData.filter((row: Timer) => row.id !== id);
    setTableData(newTableData);
    remove.mutate(id);
  };

  const handleEdit = (rowIndex: number) => {
    resetFields();
    if (editingRowIndex !== rowIndex) {
      setEditingRowIndex(rowIndex);
    }
  };

  const handleEditSave = (
    event: React.FormEvent<HTMLFormElement>,
    rowIndex: number
  ) => {
    event.preventDefault();

    if (!isEditing(rowIndex)) return handleEdit(rowIndex);

    if (isNewRow(rowIndex)) {
      saveNewLog();
    } else {
      saveEditedLog();
    }
  };

  const handleCancel = (rowIndex: number) => {
    if (isNewRow(rowIndex)) cancelNewLog();
    else setEditingRowIndex(-1);
    resetFields();
    resetValidation();
  };

  const handleDelete = (id: number) => {
    removeExistingLog(id);
  };

  const hasErrors = (field: string) => {
    if (formValidation?.errors[field]) return true;
    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {formValidation?.errors[field]?.map((error: string) => (
            <span key={error}>{error}</span>
          ))}
        </>
      );
    }
    return <></>;
  };

  const resetValidation = (fields?: string[]) => {
    if (!fields) return setValidation(undefined);
    const updatedErrors = { ...formValidation?.errors };
    for (const field of fields) {
      updatedErrors[field] = null;
    }
    setValidation({
      ...formValidation,
      errors: { ...updatedErrors },
    });
  };

  const isNewRow = (rowIndex: number): boolean =>
    addingNewTimeLog && rowIndex === 0;

  const isEditingExistingLog = (rowIndex: number): boolean =>
    !addingNewTimeLog && editingRowIndex === rowIndex;

  const isEditing = (rowIndex: number) =>
    isNewRow(rowIndex) || isEditingExistingLog(rowIndex);

  const isButtonDisabled = (rowIndex: number, save?: boolean) =>
    (!isNewRow(rowIndex) && addingNewTimeLog) ||
    (rowIndex !== editingRowIndex && editingRowIndex > -1) ||
    (save &&
      (fields.hours === 0 || fields.hours === "0") &&
      (fields.minutes === "0" || fields.minutes === 0)) ||
    update.isLoading ||
    loading;

  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction || "none" : "none";

  const columns = [
    {
      name: "user.full_name",
      label: "Username",
      options: {
        sort: false,
        customBodyRender: (value: string) => value || externalUserName,
      },
    },
    {
      name: "started_at",
      label: "Date",
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          if (isEditing(tableMeta.rowIndex)) {
            return (
              <DatePicker
                error={hasErrors("started_at")}
                format="dd/MM/yyy"
                helperText={displayErrors("started_at")}
                label="Date"
                name="started_at"
                onChange={(date) =>
                  setFields({
                    started_at: [
                      formattedDate(date.toISOString(), "yyyy-MM-dd"),
                      "00:00:00",
                    ].join(" "),
                  })
                }
                value={dateOrNull(fields.started_at ?? "")}
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="inline"
                margin="none"
                style={{ minWidth: "111px" }}
                autoOk
                disableFuture
                disableToolbar
                disabled={loading}
              />
            );
          } else
            return <div>{value && format(new Date(value), "dd/MM/yy")}</div>;
        },
        sortDirection: getSortDirection("started_at"),
      },
    },
    {
      name: "time_logged_human_readable",
      label: "Time Logged",
      options: {
        sort: false,
        customBodyRender: (value: number, tableMeta: any) => {
          if (isEditing(tableMeta.rowIndex)) {
            return (
              <Box
                style={{ maxWidth: "421px", minWidth: "280px" }}
                display="flex"
              >
                <TextField
                  error={hasErrors("hours")}
                  helperText={displayErrors("hours")}
                  name="hours"
                  label="Hours"
                  placeholder="00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, 23)
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                  value={fields.hours}
                  variant="outlined"
                  size="small"
                  style={{ width: "50%" }}
                  required
                  disabled={loading}
                />
                <Box style={{ marginRight: "auto", padding: "12px" }}>:</Box>
                <TextField
                  error={hasErrors("minutes")}
                  helperText={displayErrors("minutes")}
                  name="minutes"
                  label="Minutes"
                  placeholder="00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, 59)
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 59 } }}
                  value={fields.minutes}
                  variant="outlined"
                  size="small"
                  style={{ width: "50%" }}
                  required
                  disabled={loading}
                />
              </Box>
            );
          } else return value;
        },
      },
    },
    {
      name: "message",
      label: "Notes",
      options: {
        sort: false,
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          if (isEditing(tableMeta.rowIndex)) {
            return (
              <TextField
                error={hasErrors("message")}
                fullWidth
                helperText={displayErrors("message")}
                name="message"
                onChange={handleChange}
                inputProps={{ maxLength: 60 }}
                required
                value={fields.message}
                variant="outlined"
                size="small"
                style={{ minWidth: "280px" }}
                disabled={loading}
              />
            );
          } else return value;
        },
      },
    },
    {
      name: "is_billable",
      label: "Billable?",
      options: {
        sort: false,
        customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
          if (
            isEditing(tableMeta.rowIndex) &&
            !isEditingExistingLog(tableMeta.rowIndex)
          ) {
            return (
              <Checkbox
                name="is_billable"
                onChange={handleCheckboxChange}
                checked={fields.is_billable}
                disabled={loading}
              />
            );
          } else return value ? "Yes" : "No";
        },
      },
    },
    {
      name: "id",
      label: " ",
      options: {
        sort: false,
        customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
          return (
            <>
              {hasPermission(
                isNewRow(tableMeta.rowIndex)
                  ? PermissionCodes.ticketsTimersCreate
                  : PermissionCodes.timersUpdate
              ) && (
                <form
                  onSubmit={(event) =>
                    handleEditSave(event, tableMeta.rowIndex)
                  }
                >
                  <Button
                    data-cy={`timers-edit-button-${value}`}
                    type="submit"
                    variant="text"
                    disabled={isButtonDisabled(tableMeta.rowIndex, true)}
                    style={
                      !isButtonDisabled(tableMeta.rowIndex, true)
                        ? { color: theme.palette.primary.main, fontWeight: 200 }
                        : {}
                    }
                    startIcon={
                      isEditing(tableMeta.rowIndex) &&
                      loading && <CircularProgress size={10} />
                    }
                  >
                    {isEditing(tableMeta.rowIndex) ? "Save" : "Edit"}
                  </Button>
                </form>
              )}
            </>
          );
        },
      },
    },
    {
      name: "id",
      label: " ",
      options: {
        sort: false,
        customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
          return (
            <>
              {hasPermission(PermissionCodes.timersDelete) && (
                <Button
                  data-cy={`timers-delete-button-${value}`}
                  onClick={() => {
                    if (isEditing(tableMeta.rowIndex)) {
                      return handleCancel(tableMeta.rowIndex);
                    } else return handleDelete(value);
                  }}
                  variant="text"
                  disabled={isButtonDisabled(tableMeta.rowIndex)}
                  style={
                    !isButtonDisabled(tableMeta.rowIndex)
                      ? { color: "red", fontWeight: 200 }
                      : {}
                  }
                >
                  {isEditing(tableMeta.rowIndex) ? "Cancel" : "Delete"}
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 0,
    disableToolbarSelect: true,
    serverSide: true,
    rowsPerPage: query.limit,
    page: (query.page || 1) - 1,
    count: paginator.data?.meta.total,

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <Loading
      error={paginator.isError ? paginator.error.message : ""}
      loading={paginator.isLoading || paginator.isFetching}
    >
      {tableData.length > 0 && (
        <MUIDataTable
          data-cy="timer-table"
          title=""
          columns={columns}
          data={tableData}
          options={options}
          key={create.isLoading ? 1 : 0}
        />
      )}
    </Loading>
  );
}
