import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
  file: File | null;
  index: number;
  handleRemoveFile: Function;
  uploading: boolean;
}

function Component({ file, index, handleRemoveFile, uploading }: Props) {
  return (
    <Box
      data-cy={`file-upload-item-${file?.name}`}
      key={index}
      display="flex"
      alignItems="center"
      paddingBottom="8px"
    >
      <CheckIcon fontSize="small" />
      <Box paddingLeft="8px" paddingRight="8px" width="200px">
        <Typography noWrap variant="subtitle2">
          {file?.name}
        </Typography>
      </Box>
      <Button
        component="label"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => handleRemoveFile(index)}
        disabled={uploading}
      />
    </Box>
  );
}

export default Component;
