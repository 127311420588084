import React from "react";

import { AccountSettings, AppSubdomains } from "@akj-dev/affinity-platform";
import {
  AffinitySalesIcon,
  AffinityOrderIcon,
  AffinityInsightIcon,
  AffinityBillingIcon,
  AffinitySupportIcon,
  AffinityHubIcon,
  AffinityCustomersIcon,
  AffinityHomeIcon,
  affinityColors as colors,
} from "@akj-dev/design-system";

type PlatformMenuItemFlash = {
  primary: string;
  secondary: string;
};

type PlatformMenuItem = {
  icon: React.ReactElement;
  label: string;
  subDomain: AppSubdomains;
  pathname: string;
  flash?: PlatformMenuItemFlash;
  permissions?: (keyof AccountSettings)[];
};

export const platformMenuItems: PlatformMenuItem[] = [
  {
    icon: <AffinityHomeIcon />,
    subDomain: AppSubdomains.HOME,
    label: "Home",
    pathname: "/",
  },
  {
    icon: <AffinityCustomersIcon />,
    label: "Customers",
    subDomain: AppSubdomains.CUSTOMERS,
    pathname: "/customers",
    permissions: ["can_use_affinity_customers"],
  },
  {
    icon: <AffinitySalesIcon />,
    label: "Sales",
    subDomain: AppSubdomains.SALES,
    pathname: "/sales",
    flash: {
      primary: colors.affinityBlueDark.value,
      secondary: colors.affinityBlueLight.value,
    },
    permissions: ["can_use_affinity_sales"],
  },
  {
    icon: <AffinityOrderIcon />,
    label: "Orders",
    subDomain: AppSubdomains.ORDERS,
    pathname: "/orders",
    flash: {
      primary: colors.affinityTealDark.value,
      secondary: colors.affinityTealLight.value,
    },
    permissions: ["can_use_affinity_orders"],
  },
  {
    icon: <AffinityBillingIcon />,
    label: "Billing",
    subDomain: AppSubdomains.BILLING,
    pathname: "/billing",
    flash: {
      primary: colors.affinityGreenDarker.value,
      secondary: colors.affinityGreenLighter.value,
    },
    permissions: ["can_use_affinity_billing"],
  },
  {
    icon: <AffinityInsightIcon />,
    label: "Insight",
    subDomain: AppSubdomains.INSIGHT,
    pathname: "/insight",
    flash: {
      primary: colors.affinityVioletDark.value,
      secondary: colors.affinityVioletLight.value,
    },
    permissions: ["can_use_affinity_insight"],
  },
  {
    icon: <AffinityHubIcon />,
    label: "Hub",
    subDomain: AppSubdomains.HUB,
    pathname: "/hub",
    flash: {
      primary: colors.affinityPinkDarker.value,
      secondary: colors.affinityPinkLighter.value,
    },
    permissions: ["can_use_affinity_hub"],
  },
  {
    icon: <AffinitySupportIcon />,
    label: "Support",
    subDomain: AppSubdomains.SUPPORT,
    pathname: "/support",
    flash: {
      primary: colors.affinityOrangeDark.value,
      secondary: colors.affinityOrangeLight.value,
    },
    permissions: ["can_use_affinity_support", "can_use_affinity_support_new"],
  },
];
