import { useQuery } from "react-query";
import * as api from "../api/knowledgebase";
import { Article, Category } from "../screens/KnowledgeBase/types";
import { QueryData, QueryNonArrayData } from "../types";

export function usePaginateCategories(id?: number, options = {}) {
  const response = useQuery<QueryData<Category>, any>(
    ["knowledgebase-categories", id],
    () => api.paginateCategories(id),
    options
  );

  let categories = response.data?.data || [];
  if (id === 0) {
    categories = categories.filter((category: Category) => !category.parent_id);
  }

  return {
    ...response,
    data: { data: categories },
  };
}

export function usePaginateArticles(id: number, options = {}) {
  return useQuery<QueryNonArrayData<Article>, any>(
    ["knowledgebase-articles", id],
    () => api.paginateArticles(id),
    options
  );
}
