import { createMuiTheme } from "@material-ui/core/styles";
import { baseThemeOptions, createOverrides } from "../_base";

// See https://material-ui.com/customization/default-theme/ for avaliable options
const baseVodaTheme = createMuiTheme(
  {
    ...baseThemeOptions,
    typography: {
      ...baseThemeOptions.typography,
      fontFamily: "Vodafone, 'Open Sans', sans-serif",
    },
    props: {
      ...baseThemeOptions.props,
      MuiPaper: {
        elevation: 3,
      },
    },
    palette: {
      primary: {
        main: "#e60000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#333333",
      },
      info: {
        main: "#022f6b",
      },
      error: {
        main: "#a30115",
      },
      warning: {
        main: "#f29400",
      },
      success: {
        main: "#50b063",
        contrastText: "#fff",
      },
      background: {
        default: "#ffffff",
      },
      text: {
        primary: "#333333",
      },
      // we need a proper greyscale defining (see affinityTheme), but use these values for now.
      // will merge with default mui greyscale
      grey: {
        50: "#f8f7f9", // neutralAthensGrey
        100: "#e9e9ec", // neutralGhost
        500: "#524b65", // neutralStorm
        600: "#333333", // replacement for neutralDeepPurple
        800: "#140f1e", // neutralAbyss
      },
    },
  } as any // TODO: fix typing error
);

// JSS styling overrides
const overrides = {
  ...createOverrides(baseVodaTheme),
  // add Voda specific overrides here
};

const vodaTheme = createMuiTheme({
  ...baseVodaTheme,
  overrides,
});

export { vodaTheme };
