import React, { useState } from "react";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";
import { format } from "date-fns";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import Loading from "../../../../layout/Loading";
import TimerTable from "../../../../components/TimerTable";
import InfoItem from "../../../../components/InfoItem";
import { constants } from "../../../../helpers";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useTicketDetail } from "../../../../hooks/tickets";
import { usePaginate } from "../../../../hooks/timers";
import {
  SortDirectionQueryParam,
  Ticket,
  TimerFields,
  TimerType,
} from "../../../../types";

type Props = {
  ticket: Ticket;
};

function Component({ ticket }: Props) {
  const { data, isLoading, isFetching } = useTicketDetail(ticket.id);

  const [query, setQuery] = useQueryParams({
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
    limit: withDefault(NumberParam, constants.limit.timers),
    page: withDefault(NumberParam, 1),
  });

  const initialState: TimerFields = {
    hours: "",
    minutes: "",
    message: "",
    is_billable: false,
    started_at: format(
      new Date(new Date().setHours(0, 0, 0, 0)),
      "yyy-MM-dd HH:mm:ss"
    ),
  };

  const [timerFields, setTimerFields] = useState(initialState);
  const setFields = (fields: Partial<TimerFields>) =>
    setTimerFields((oldFields) => ({ ...oldFields, ...fields }));

  const resetFields = () => setTimerFields(initialState);

  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [addingNewTimeLog, setAddingNewTimeLog] = useState(false);

  const paginator = usePaginate(ticket.id, TimerType.ticket, query);

  return (
    <Loading error={paginator.isError ? paginator.error.message : ""}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InfoItem
            title="Total Time Logged"
            value={
              isLoading || isFetching ? (
                <CircularProgress size={20} style={{ marginTop: 5 }} />
              ) : (
                data?.data?.time_logged_human_readable || 0
              )
            }
            hasBottomMargin
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            {hasPermission(PermissionCodes.ticketsTimersCreate) && (
              <Button
                data-cy="log-time-button"
                color="primary"
                size="medium"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => setAddingNewTimeLog(true)}
                disabled={
                  addingNewTimeLog ||
                  editingRowIndex > -1 ||
                  paginator.isLoading ||
                  paginator.isFetching ||
                  isLoading ||
                  isFetching
                }
              >
                Log Time
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TimerTable
            paginator={paginator}
            entity={ticket}
            timerType={TimerType.ticket}
            fields={timerFields}
            setFields={setFields}
            resetFields={resetFields}
            addingNewTimeLog={addingNewTimeLog}
            editingRowIndex={editingRowIndex}
            setEditingRowIndex={setEditingRowIndex}
            setAddingNewTimeLog={setAddingNewTimeLog}
            query={query}
            setQuery={setQuery}
          />
        </Grid>
      </Grid>
    </Loading>
  );
}

export default Component;
