import { UseQueryResult } from "react-query";
import { EventType, SortDirection } from "../../../types";

export interface Query {
  id?: string | null;
  level?: string | null;
  status?: string | null;
  company?: string | null;
  processed_at?: string | null;
  asset_name?: string | null;
  threshold?: string | null;
  external_reference?: string | null;
  alert?: string | null;
  rule?: string | null;

  sort?: string;
  direction: SortDirection;
  limit?: number;
  page: number;
}

export interface EventData {
  data: EventType;
}

export interface EventsData {
  data: EventType[];
  meta: { total: number };
}

export type EventsQueryType = UseQueryResult<EventsData, Error>;

// todo
export const eventLevels = [
  {
    id: 1,
    name: "Warning",
  },
  {
    id: 2,
    name: "Critical",
  },
  {
    id: 3,
    name: "Error",
  },
];

// todo
export const eventStatuses = [
  {
    id: 1,
    name: "Update",
  },
  {
    id: 2,
    name: "Clear",
  },
];
