import React from "react";

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";

import { Box, Button, CircularProgress, Typography } from "@material-ui/core";

import { Link } from "react-router-dom";

import { Message, QueryData } from "../../../../types";
import { Query } from "../../types";
import { SortDirection } from "../../../../types";
import { formattedDateTime } from "../../../../helpers/functions";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";

type Props = {
  loading: boolean;
  paginator?: QueryData<Message>;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({
  loading,
  paginator,
  query,
  setQuery,
}: Props) {
  // Check if "sort" is equal with the given "columnName"
  // and return the value of "order" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction : "none";

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = paginator?.data[tableMeta.rowIndex];

          return (
            <Button
              variant="text"
              color="primary"
              size="small"
              component={Link}
              to={`/support/mailbox/${row?.id}`}
              disabled={!hasPermission(PermissionCodes.messagesView)}
            >
              #{value}
            </Button>
          );
        },
        sortDirection: getSortDirection("id"),
      },
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        sortDirection: getSortDirection("subject"),
      },
    },
    {
      name: "from",
      label: "From",
      options: {
        sortDirection: getSortDirection("from"),
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return formattedDateTime(value);
        },
        sortDirection: getSortDirection("created_at"),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: paginator?.meta.total,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="mailbox-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Mailbox</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={paginator?.data || []}
      columns={columns}
      options={options}
    />
  );
}
