import * as api from "../";
import { View } from "../../types";

export function create(view: Partial<View>) {
  return api.post("tickets/views", view);
}

export function remove(id: number) {
  return api.remove(`tickets/views/${id}`);
}

export function search() {
  return api.get("tickets/views");
}

export function update(view: Partial<View>) {
  return api.put(`tickets/views/${view.id}`, view);
}
