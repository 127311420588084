import React, { useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { format } from "date-fns";
import { Grid, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "../../../../components/Autocomplete";
import InlineEdit from "../../../../components/InlineEdit";
import InfoItem from "../../../../components/InfoItem";
import { formattedDate } from "../../../../helpers/functions";
import { useDebounce } from "../../../../helpers/hooks";
import { useStatuses, useUpdate } from "../../../../hooks/tasks";
import { useUsers } from "../../../../hooks/autocomplete";
import { Status, Task, User } from "../../../../types";

interface Props {
  task: Task;
}

export default function Component({ task }: Props) {
  const [local, setLocal] = useStateWithCallbackLazy({
    collect_done_at: false,
    done_at: "",
    status: task && task.status,
  });

  const [statusInput, setStatusInput] = useState("");
  const debouncedStatusInput = useDebounce(statusInput, 600);
  const statuses = useStatuses(debouncedStatusInput);

  const [userInput, setUserInput] = useState("");
  const debouncedUserInput = useDebounce(userInput, 600);
  const users = useUsers({ query: debouncedUserInput });

  const update = useUpdate(task.id);

  const setStatus = (status: Status) => {
    setLocal(
      {
        ...local,
        status,
        collect_done_at: !status.is_open,
      },
      submitChanges
    );
  };

  const submitChanges = (updates: any) => {
    if (!updates.collect_done_at) {
      update.mutate({
        done_at: updates.done_at,
        status_id: updates.status?.id,
      });
    } else {
      update.mutate({
        status_id: updates.status?.id,
      });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={task?.done_at || local.collect_done_at ? 8 : 6}>
        <Grid container spacing={1}>
          <Grid item xs={task?.done_at || local.collect_done_at ? 4 : 6}>
            <InfoItem
              title="Status"
              value={
                <InlineEdit
                  flexGrow={0}
                  input={
                    <Autocomplete
                      disableClearable
                      loading={statuses.isLoading}
                      onSearch={(query: string) => setStatusInput(query)}
                      onSelect={(status: Status) => status && setStatus(status)}
                      options={statuses.data?.data || []}
                      placeholder="Select status"
                      value={
                        statuses.data?.data?.find(
                          (status: Status) => status.id === local.status?.id
                        ) || null
                      }
                      valueFrom="id"
                      textFrom="name"
                      openOnFocus
                    />
                  }
                  disabled={update.isLoading}
                >
                  <Typography variant="h4">{local.status?.name}</Typography>
                </InlineEdit>
              }
            />
          </Grid>

          {(task?.done_at || local.collect_done_at) && (
            <Grid item xs={4}>
              <InfoItem
                title="Date Completed"
                value={
                  <InlineEdit
                    flexGrow={0}
                    input={
                      <DatePicker
                        autoOk
                        disableToolbar
                        format="dd/MM/yyy"
                        fullWidth
                        inputVariant="outlined"
                        margin="none"
                        name="done_at"
                        onChange={(date) =>
                          setLocal(
                            {
                              ...local,
                              collect_done_at: false,
                              done_at: format(date, "yyy-MM-dd"),
                            },
                            submitChanges
                          )
                        }
                        size="small"
                        value={task?.done_at}
                        disableFuture
                        DialogProps={{
                          disablePortal: true,
                          hideBackdrop: true,
                          PaperProps: {
                            style: {
                              top: "-30px",
                            },
                          },
                        }}
                      />
                    }
                  >
                    <Typography variant="h4">
                      {task?.done_at &&
                        formattedDate(task?.data?.data?.done_at)}
                    </Typography>
                  </InlineEdit>
                }
              />
            </Grid>
          )}
          <Grid item xs={task?.done_at || local.collect_done_at ? 4 : 6}>
            <InfoItem
              title="Assigned To"
              value={
                <InlineEdit
                  flexGrow={0}
                  input={
                    <Autocomplete
                      placeholder="Type to search"
                      loading={users?.isLoading}
                      options={users.data?.data || []}
                      valueFrom="id"
                      textFrom="full_name"
                      onSelect={(user: User) => {
                        user && update.mutate({ user_id: user.id });
                        setUserInput("");
                      }}
                      onSearch={(query) => setUserInput(query)}
                      onBlur={() => setUserInput("")}
                      shrink
                      openOnFocus
                    />
                  }
                  disabled={update.isLoading}
                >
                  <Typography variant="h4">
                    <img
                      alt=""
                      src={`https://ui-avatars.com/api/?rounded=true&size=18&background=random&name=${task?.user?.full_name}`}
                      style={{ verticalAlign: "top" }}
                    />{" "}
                    {task?.user?.full_name}
                  </Typography>
                </InlineEdit>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={task?.done_at || local.collect_done_at ? 4 : 6}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <InfoItem
              title="Time Logged"
              value={task?.time_logged_human_readable}
            />
          </Grid>
          <Grid item xs={4}>
            <InfoItem
              title="Due Date"
              value={formattedDate(task?.due_date || "") || "N/A"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
