import React, { useState } from "react";

import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";

import { Box, Grid, Switch, ButtonGroup } from "@material-ui/core";
import Table from "./Table";

import { constants } from "../../helpers";
import { SortDirectionQueryParam } from "../../types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClickPopover from "../../components/ClickPopover";
import Filters from "./Filters";
import { FilterList as FilterListIcon } from "@material-ui/icons";

interface Props {
  owner?: string;
}

export default function Comments({ owner = "" }: Props) {
  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, constants.limit.audits),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
    user_id: NumberParam,
    created_after: StringParam,
    created_before: StringParam,
    tags: ArrayParam,
  });

  const [expanded, setExpanded] = useState(false);

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  return (
    <Grid data-cy="audits-section" container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow="1">
            <FormControlLabel
              control={
                <Switch
                  data-cy="audits-expand-switch-button"
                  onChange={(event) => setExpanded(event.target.checked)}
                  checked={expanded}
                  name="expanded"
                  color="primary"
                />
              }
              label="Expand All"
            />
          </Box>
          <Box>
            <ButtonGroup size="small" variant="outlined">
              <ClickPopover trigger={<FilterListIcon />}>
                <div style={{ width: 368, padding: "24px 24px 36px 24px" }}>
                  <Filters
                    owner={owner}
                    query={query}
                    setQuery={setQuery}
                    resetQuery={resetQuery}
                  />
                </div>
              </ClickPopover>
            </ButtonGroup>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Table
          expanded={expanded}
          owner={owner}
          query={query}
          setQuery={setQuery}
        />
      </Grid>
    </Grid>
  );
}
