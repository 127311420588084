import { useQuery } from "react-query";
import * as api from "../../api/tickets/reports";
import { ReportCategories, ReportDetails } from "../../screens/Reports/types";

export function useReports(
  id: ReportCategories,
  query = { limit: 10, page: 1 },
  options = {}
) {
  return useQuery<ReportDetails, Error>(
    ["reports", id, query],
    () => api.reports(id, query),
    options
  );
}
