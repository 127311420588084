import React, { useState } from "react";
import { Chip, CircularProgress, useTheme } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "../../../../components/Autocomplete";
import { Tag, Ticket } from "../../../../types";
import { useTags } from "../../../../hooks/tickets/autocomplete";
import { useAddTag, useRemoveTag } from "../../../../hooks/tickets";
import { hasPermission } from "../../../../helpers/functions";
import { PermissionCodes } from "../../../../helpers/constants";
import { useDebounce } from "../../../../helpers/hooks";

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const theme = useTheme();

  const canViewTags = hasPermission(PermissionCodes.ticketsTagsViewAny);
  const canCreateTags = hasPermission(PermissionCodes.ticketsTagsCreate);
  const canDeleteTags = hasPermission(PermissionCodes.ticketsTagsDelete);

  const [fetchTags, setFetchTags] = useState(false);
  const [tagsInput, setTagsInput] = useState("");
  const debouncedTagsInput = useDebounce(tagsInput);
  const tags = useTags(
    { query: debouncedTagsInput, ticket_id: ticket.id },
    { enabled: canViewTags && fetchTags }
  );

  const addTag = useAddTag(ticket.id);
  const removeTag = useRemoveTag(ticket.id);

  return (
    <>
      {canCreateTags && (
        <Autocomplete
          data-cy="tags-autocomplete"
          options={tags.data?.data ?? []}
          value={{ id: -1, name: tagsInput, label: tagsInput }}
          valueFrom="id"
          textFrom={(option: Tag) => option.label || option.name}
          label="Add Tags"
          placeholder="Type to search"
          onSearch={(query) => setTagsInput(query)}
          onSelect={(tag: Tag) => {
            tag && addTag.mutate(tag.name);
            setTagsInput("");
          }}
          onOpen={() => setFetchTags(true)}
          onBlur={() => setTagsInput("")}
          loading={tags.isLoading || addTag.isLoading}
          shrink
        />
      )}
      {canViewTags &&
        ticket.tags?.map((tag: Tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            deleteIcon={
              tag.removing ? <CircularProgress size={14} /> : <CancelIcon />
            }
            onDelete={
              canDeleteTags
                ? () => {
                    tag.removing = true;
                    removeTag.mutate(tag.id);
                  }
                : undefined
            }
            disabled={tag.removing}
            size="small"
            style={{ margin: theme.spacing(0.5, 0.5, 0, 0) }}
          />
        ))}
    </>
  );
}
