import { LooseObject } from "../../types";
import { useQuery } from "react-query";
import { ContactsData } from "../../screens/Tickets/types";
import * as api from "../../api/autocomplete";

export function useContacts(
  query: string,
  customer_id: number | null | undefined,
  params: LooseObject = {},
  options: LooseObject = {}
) {
  return useQuery<ContactsData, Error>(
    ["autocomplete", "contacts", query, customer_id, params],
    () => api.customerContacts({ query, customer_id, params }),
    options
  );
}
