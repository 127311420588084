import React, { useEffect, useState } from "react";
import { Column, View } from "../../../../types";
import Autocomplete from "../../../../components/Autocomplete";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useCreate,
  useRemove,
  useUpdate,
} from "../../../../hooks/tickets/views";
import Columns from "./Columns";
import { Query } from "../../types";
import { ValidationError } from "../../../../api";

interface Props {
  columns: Column[];
  loading: boolean;
  onSearch: (query: string) => void;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  views: View[];
}

export default function Views({
  columns,
  loading,
  onSearch,
  query,
  setQuery,
  views,
}: Props) {
  const [action, setAction] = useState<string>("");
  const [localColumns, updateColumns] = useState<Column[]>(columns);
  const [view, updateView] = useState<Partial<View> | null>(null);
  const [name, updateName] = useState<string>("");
  const [isDefault, updateIsDefault] = useState<boolean>(false);

  const create = useCreate({
    onSuccess: (data: any) => {
      setAction("");
      setQuery({ view_id: data.data?.id });
    },
  });
  const remove = useRemove({
    onSuccess: () => {
      setAction("");
      setQuery({ view_id: undefined });
    },
  });
  const update = useUpdate({ onSuccess: () => setAction("") });

  const handleSave = () => {
    const fields = {
      id: view?.id,
      name,
      is_default: isDefault,
      columns: localColumns
        .filter((column) => column.checked)
        .map((column, index) => ({
          id: column.id,
          order: index,
        })),
    };

    if (action === "create") {
      create.mutate(fields);
    } else {
      update.mutate(fields);
    }
  };

  const updateColumn = (update: Column, fields: Partial<Column>) => {
    updateColumns(
      localColumns.map((column: Column) => {
        if (column.id === update.id) {
          return {
            ...column,
            ...fields,
          };
        }

        return column;
      })
    );
  };

  useEffect(() => {
    const view = views.find(
      (view: View) =>
        view.id === query.view_id || (!query.view_id && view.is_default)
    );

    updateView(view ?? null);
  }, [query, updateView, views]);

  useEffect(() => {
    updateColumns(
      columns.map((column: Column) => {
        let checked = column.checked;

        if (view?.columns?.find((c) => c.id === column.id)) {
          checked = true;
        }

        return {
          ...column,
          checked,
        };
      }) ?? []
    );

    if (action === "edit") {
      updateName(view?.name ?? "");
      updateIsDefault(view?.is_default ?? false);
    } else if (action === "create") {
      updateColumns(columns);
      updateName("");
      updateIsDefault(false);
    }
  }, [action, columns, view]);

  const getNameFieldError = () => {
    if (!create.isError && !update.isError) return null;
    if (create.isError) {
      if (create.error instanceof ValidationError) {
        return create.error.validation.errors.name;
      } else {
        return "Can't create view";
      }
    }
    if (update.isError) {
      if (update.error instanceof ValidationError) {
        return update.error.validation.errors.name;
      } else {
        return "Can't create view";
      }
    }
    return "Unknown Error.";
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {action && (
          <TextField
            data-cy="views-name-field"
            error={create.isError || update.isError}
            fullWidth
            helperText={getNameFieldError()}
            label="View Name"
            name="name"
            onChange={(event) => {
              if (!!getNameFieldError()) {
                create.reset();
                update.reset();
              }
              updateName(event.target.value);
            }}
            required
            value={name}
            variant="outlined"
            size="small"
            disabled={create.isLoading || update.isLoading}
          />
        )}
        {!action && (
          <Autocomplete
            data-cy="views-autocomplete"
            loading={loading}
            label="Saved Views"
            name="saved_view"
            placeholder="Type to search"
            shrink
            textFrom="name"
            valueFrom="id"
            value={view ?? null}
            options={views}
            onSearch={onSearch}
            onSelect={(view: View) => setQuery({ view_id: view?.id })}
            noOptionsText="There are no saved views."
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1}>
            {action && (
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy="views-default-checkbox"
                    checked={isDefault}
                    onChange={(event) => updateIsDefault(event.target.checked)}
                    name="default"
                    color="primary"
                    size="small"
                    disabled={create.isLoading || update.isLoading}
                  />
                }
                label={<Typography variant="subtitle2">Default</Typography>}
              />
            )}
            {!action && view && (
              <>
                <Button
                  data-cy={`views-remove-button-${view.id}`}
                  color="primary"
                  component="label"
                  disabled={remove.isLoading}
                  onClick={() => remove.mutate(view)}
                  size="small"
                  startIcon={
                    remove.isLoading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <ClearIcon />
                    )
                  }
                  variant="text"
                >
                  Remove
                </Button>
                <Button
                  data-cy={`views-edit-button-${view.id}`}
                  color="primary"
                  component="label"
                  onClick={() => setAction("edit")}
                  size="small"
                  startIcon={<EditIcon />}
                  variant="text"
                  disabled={remove.isLoading}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
          {action && (
            <>
              <Box pl={1}>
                <Button
                  data-cy="views-cancel-button"
                  color="primary"
                  component="label"
                  onClick={() => {
                    create.reset();
                    update.reset();
                    setAction("");
                  }}
                  size="small"
                  variant="outlined"
                  disabled={
                    create.isLoading || update.isLoading || remove.isLoading
                  }
                >
                  Cancel
                </Button>
              </Box>
              <Box pl={1}>
                <Button
                  data-cy="views-save-button"
                  color="primary"
                  component="label"
                  onClick={handleSave}
                  size="small"
                  startIcon={
                    create.isLoading || update.isLoading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  variant="contained"
                  disabled={
                    create.isLoading ||
                    update.isLoading ||
                    !name ||
                    !localColumns.find((column) => column.checked)
                  }
                >
                  Save View
                </Button>
              </Box>
            </>
          )}
          {!action && (
            <Box pl={1}>
              <Button
                data-cy="views-create-view-button"
                color="primary"
                component="label"
                onClick={() => setAction("create")}
                size="small"
                startIcon={<AddIcon />}
                variant="contained"
                disabled={remove.isLoading}
              >
                Create View
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
      {(action || view) && (
        <Grid item xs={12}>
          <Columns
            disabled={!action}
            columns={localColumns}
            updateColumn={updateColumn}
          />
        </Grid>
      )}
    </Grid>
  );
}
