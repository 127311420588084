import { useQuery } from "react-query";
import * as autocompleteApi from "../../api/autocomplete";
import * as api from "../../api/tickets";
import {
  AuditTagsData,
  BreachReasonData,
  CategoriesData,
  ClosureReasonData,
  PrioritiesData,
  StagesData,
  StatusesData,
  SubscriberData,
  SystemsData,
  TagsData,
  TicketsData,
  TypesData,
  EscalatorsData,
} from "../../screens/Tickets/types";
import { AutocompleteParams, LooseObject, Tag } from "../../types";

export function useAuditTags(query: string, options: LooseObject = {}) {
  return useQuery<AuditTagsData, Error>(
    ["autocomplete", "tickets", "audit", "tags", query],
    () => api.auditTags({ query }),
    options
  );
}

export function useBreachReasons(query: string, options: LooseObject = {}) {
  return useQuery<BreachReasonData, Error>(
    ["autocomplete", "breach-reasons", query],
    () => autocompleteApi.breachReasons({ query }),
    options
  );
}

export function useCategories(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<CategoriesData, Error>(
    ["autocomplete", "tickets", "categories", params],
    () => api.categories(params),
    options
  );
}

export function useClosureReasons(query: string, options: LooseObject = {}) {
  return useQuery<ClosureReasonData, Error>(
    ["autocomplete", "closure-reasons", query],
    () => autocompleteApi.closureReasons({ query }),
    options
  );
}

export function useEscalators(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<EscalatorsData, Error>(
    ["autocomplete", "tickets", "escalators", params],
    () => autocompleteApi.escalators(params),
    options
  );
}

export function usePriorities(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<PrioritiesData, Error>(
    ["autocomplete", "tickets", "priorities", params],
    () => api.priorities(params),
    options
  );
}

export function useStages(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<StagesData, Error>(
    ["autocomplete", "tickets", "stages", params],
    () => autocompleteApi.stages(params),
    options
  );
}

export function useStatuses(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<StatusesData, Error>(
    ["autocomplete", "tickets", "statuses", params],
    () => api.statuses(params),
    options
  );
}

export function useSubscribers(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<SubscriberData, Error>(
    ["autocomplete", "tickets", "subscribers", params],
    () => api.subscribers(params),
    options
  );
}

export function useTicketEscalators(
  id: number,
  query: string,
  options: LooseObject = {}
) {
  return useQuery<EscalatorsData, Error>(
    ["autocomplete", "tickets", id, "escalators", query],
    () => autocompleteApi.ticketEscalators(id, { query }),
    options
  );
}

export function useTicketSubscribers(
  id: number,
  query: string,
  options: LooseObject = {}
) {
  return useQuery<SubscriberData, Error>(
    ["autocomplete", "tickets", id, "subscribers", query],
    () => autocompleteApi.ticketSubscribers(id, { query }),
    options
  );
}

export function useSystems(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<SystemsData, Error>(
    ["autocomplete", "tickets", "systems", params],
    () => api.systems(params),
    options
  );
}

export function useTags(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  const onSuccess = (data: any) => {
    if (
      (params.query?.length ?? 0) > 0 &&
      !data.data.find((tag: Tag) => tag.name === params.query)
    ) {
      data.data.push({
        id: 0,
        label: `Create new tag "${params.query}"`,
        name: params.query,
      });
    }

    if (typeof options.onSuccess === "function") {
      options.onSuccess(data);
    }
  };

  return useQuery<TagsData, Error>(
    ["autocomplete", "tickets", params.ticket_id ?? "n/a", "tags", params],
    () => api.tags(params),
    {
      ...options,
      onSuccess,
    }
  );
}

export function useTicketsList(
  query: string,
  params: { can_be_parent?: number; can_be_child?: number; except?: number },
  purpose: string = "parent",
  options: LooseObject = {}
) {
  return useQuery<TicketsData, Error>(
    ["autocomplete", "tickets", params.except, "associations", purpose, query],
    () => autocompleteApi.tickets({ ...params, query, purpose }),
    options
  );
}

export function useTypes(
  params: AutocompleteParams = {},
  options: LooseObject = {}
) {
  return useQuery<TypesData, Error>(
    ["autocomplete", "tickets", "types", params],
    () => api.types(params),
    options
  );
}
