import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { getRoutes } from "./routes";
import Loading from "./layout/Loading";
import { SubNav, SwitchRoutes, ViewContainer } from "@akj-dev/affinity-wrapper";
import { Route, Switch, useLocation } from "react-router-dom";
import CreateTicket from "./screens/Tickets/Create";
import EditTicket from "./screens/Tickets/Edit";
import Ticket from "./screens/Tickets/Show";
import CreateTask from "./screens/Tasks/Create";
import Task from "./screens/Tasks/Show";
import ReportSubTable from "./screens/Reports/ReportsSubTable";
import EditUser from "./screens/Users/Update";
import Event from "./screens/Events/Show";
import Mailbox from "./screens/Mailbox/Show";
import { hasPermission } from "./helpers/functions";
import { PermissionCodes } from "./helpers/constants";
import Dashboard from "./screens/Tickets/Dashboard";
import Tickets from "./screens/Tickets";
import KnowledgeBase from "./screens/KnowledgeBase";
import { Box, Chip, useTheme } from "@material-ui/core";
import { useUser } from "./hooks/users";
import { User } from "./types";

const showUatBanner =
  process.env.REACT_APP_STAGE === "uat" ||
  process.env.REACT_APP_STAGE === "development";

export const ContentContainer = styled.div`
  padding: 0 32px ${showUatBanner ? 100 : 16}px 32px;
`;

interface RoutesProps {
  user: User;
}

function Routes({ user }: RoutesProps) {
  const queryClient = useQueryClient();
  const routes = getRoutes(queryClient);
  const { pathname } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    return function cleanup() {
      queryClient.clear();
    };
  }, [queryClient]);

  return (
    <>
      {pathname.includes(routes.rootPath) && (
        <SubNav routes={routes} rootTitle="Support">
          <Box
            style={{
              position: "absolute",
              bottom: 50,
              left: 1,
              right: 1,
              textAlign: "center",
            }}
          >
            <Chip
              color="primary"
              label={user.roles?.map((role) => role.label).join(", ")}
              size="small"
              style={{ backgroundColor: theme.palette.success.main }}
            />
          </Box>
        </SubNav>
      )}
      <ViewContainer>
        <ContentContainer>
          <Switch>
            <Route path="/" exact>
              {hasPermission(PermissionCodes.ticketsDashboard) ? (
                <Dashboard />
              ) : (
                <Tickets />
              )}
            </Route>
            <Route path={routes.rootPath + "/new-ticket"}>
              <CreateTicket />
            </Route>
            <Route path={routes.rootPath + "/tickets/:id/edit"}>
              <EditTicket />
            </Route>
            <Route path={routes.rootPath + "/tickets/:id/:section?/:action?"}>
              <Ticket />
            </Route>
            <Route path={routes.rootPath + "/tasks/create"}>
              <CreateTask />
            </Route>
            <Route path={routes.rootPath + "/tasks/:id"}>
              <Task />
            </Route>
            <Route path={routes.rootPath + "/reports/:id"}>
              <ReportSubTable />
            </Route>
            <Route path={routes.rootPath + "/admin/users/:id/edit"}>
              <EditUser />
            </Route>
            <Route path={routes.rootPath + "/events/:id"}>
              <Event />
            </Route>
            <Route path={routes.rootPath + "/mailbox/:id"}>
              <Mailbox />
            </Route>
            <Route path={routes.rootPath + "/knowledge-base/*"}>
              <KnowledgeBase topLevel={false} />
            </Route>
            <SwitchRoutes routes={routes} />
          </Switch>
        </ContentContainer>
      </ViewContainer>
    </>
  );
}

function Application() {
  const user = useUser();

  return (
    <Loading
      loading={user.isLoading}
      error={
        user.isError ? "Can't fetch user details. Refresh the page." : undefined
      }
    >
      <Routes user={user} />
    </Loading>
  );
}

export default Application;
