import React from "react";
import { createRoutingObject } from "@akj-dev/affinity-wrapper";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InboxIcon from "@material-ui/icons/Inbox";
import MailIcon from "@material-ui/icons/Mail";
import TocIcon from "@material-ui/icons/Toc";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EventIcon from "@material-ui/icons/Event";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import PeopleIcon from "@material-ui/icons/People";

import Dashboard from "./screens/Tickets/Dashboard";
import Mailbox from "./screens/Mailbox";
import Tickets from "./screens/Tickets";
import Tasks from "./screens/Tasks";
import Reports from "./screens/Reports";
import Events from "./screens/Events";
import Users from "./screens/Users";
import KnowledgeBase from "./screens/KnowledgeBase";

import { hasPermission } from "./helpers/functions";
import { PermissionCodes } from "./helpers/constants";

import { QueryClient } from "react-query";

export const ROOT_PATH = "/support";
export function getRoutes(queryClient?: QueryClient) {
  let supportRoutes = [];

  if (hasPermission(PermissionCodes.ticketsDashboard)) {
    supportRoutes.push({
      path: "",
      title: "Dashboard",
      subject: <Dashboard />,
      icon: <DashboardIcon />,
    });
  } else {
    if (window.location.pathname === "/support") {
      window.location.href = "/support/tickets";
    }
  }

  if (hasPermission(PermissionCodes.ticketsViewAny)) {
    supportRoutes.push({
      path: "/tickets",
      title: "Tickets",
      subject: <Tickets />,
      icon: <InboxIcon />,
      onClick: (): void => {
        // refresh the tickets
        queryClient?.setQueriesData(["tickets"], {});
        queryClient?.invalidateQueries(["tickets"]);
      },
    });
  }

  if (hasPermission(PermissionCodes.tasksViewAny)) {
    supportRoutes.push({
      path: "/tasks",
      title: "Tasks",
      subject: <Tasks />,
      icon: <TocIcon />,
    });
  }

  if (hasPermission(PermissionCodes.ticketsReportsView)) {
    supportRoutes.push({
      path: "/reports",
      title: "Reports",
      subject: <Reports />,
      icon: <AssessmentIcon />,
    });
  }

  if (hasPermission(PermissionCodes.messagesViewAny)) {
    supportRoutes.push({
      path: "/mailbox",
      title: "Mailbox",
      subject: <Mailbox />,
      icon: <MailIcon />,
    });
  }

  if (hasPermission(PermissionCodes.eventsViewAny)) {
    supportRoutes.push({
      path: "/events",
      title: "Events",
      subject: <Events />,
      icon: <EventIcon />,
    });
  }

  if (hasPermission(PermissionCodes.usersUpdate)) {
    const userManagementRoute = {
      path: "/users",
      title: "User Management",
      subject: <Users />,
      icon: <PeopleIcon />,
    };

    supportRoutes.push({
      path: "/admin",
      title: "Admin Settings",
      subject: [userManagementRoute],
      icon: <SettingsIcon />,
    });
  }

  if (hasPermission(PermissionCodes.knowledgeBaseViewAny)) {
    supportRoutes.push({
      path: "/knowledge-base",
      title: "Knowledge Base",
      subject: <KnowledgeBase topLevel />,
      icon: <HelpIcon />,
    });
  }

  return createRoutingObject(supportRoutes, ROOT_PATH);
}
