import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";

interface LinkButtonProps {
  children: ReactNode;
  onClick: () => any;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    display: "inline",
    margin: "0",
    padding: "0",
    font: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none",
    },
  },
});

/**
 * Link Button
 * Programmatic link without an anchor
 * Same as <a href="#"> but compliant with accessibility standards
 * @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
 * @param children
 * @param onClick
 * @constructor
 */
export const LinkButton = ({ children, onClick }: LinkButtonProps) => {
  const classes = useStyles();
  return (
    <button type="button" className={classes.root} onClick={onClick}>
      {children}
    </button>
  );
};
