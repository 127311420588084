import { createMuiTheme } from "@material-ui/core/styles";
import { baseThemeOptions, createOverrides } from "../_base";

// See https://material-ui.com/customization/default-theme/ for avaliable options

const baseDwpTheme = createMuiTheme({
  ...baseThemeOptions,
  // TODO: What's tertiary about in the TS def?
  // @ts-ignore
  palette: {
    primary: {
      light: "#ff54a4",
      main: "#d60075",
      dark: "#9f004a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffec4d",
      main: "#fbba00",
      dark: "#c38a00",
      contrastText: "#000",
    },
  },
  // @ts-ignore
  typography: {
    ...baseThemeOptions.typography,
    fontFamily: "'Open Sans', sans-serif",
  },
});

// JSS styling overrides
const overrides = {
  ...createOverrides(baseDwpTheme),
  // add Daisy specific overrides here
};

const dwpTheme = createMuiTheme({
  ...baseDwpTheme,
  overrides,
});

export { dwpTheme };
