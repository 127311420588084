import React, { useState } from "react";
import { Alert } from "@akj-dev/design-system";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import FileUpload from "../../../../components/FileUpload";
import { Message } from "../../../../types";
import { useReply } from "../../../../hooks/messages";
import { ValidationError } from "../../../../api";

interface Props {
  message: Message;
}

interface MailFields {
  content: string;
  files: File[];
}

export default function Component({ message }: Props) {
  const [fields, setFields] = useState<MailFields>({ content: "", files: [] });
  const reply = useReply(message.id);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    reply.reset();
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const hasErrors = (field: string) => {
    if (
      reply?.error instanceof ValidationError &&
      reply?.error?.validation?.errors?.[field]
    ) {
      return true;
    }

    return false;
  };

  const displayErrors = (field: string) => {
    if (hasErrors(field)) {
      return (
        <>
          {reply?.error instanceof ValidationError &&
            reply?.error?.validation?.errors?.[field].map((error: string) => (
              <span key={error}>{error}</span>
            ))}
        </>
      );
    }

    return <></>;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    reply.mutate({ id: message.id, params: fields });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ReplyIcon fontSize="inherit" /> {message.from}
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy="mailbox-reply-message-field"
            error={hasErrors("content")}
            fullWidth
            helperText={displayErrors("content")}
            label="Message"
            name="content"
            onChange={handleChange}
            required
            multiline
            rows={5}
            value={fields.content}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex">
            <Box mr={1}>
              <Button
                data-cy="mailbox-reply-send-button"
                color="primary"
                size="medium"
                startIcon={<ReplyIcon />}
                type="submit"
                variant="contained"
              >
                Send
              </Button>
            </Box>
            <Box>
              <FileUpload
                multiple
                name="files[]"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  files: Array<File>
                ) => setFields({ ...fields, files: files })}
                value={fields.files}
                setFiles={(newFiles: File[]) =>
                  setFields({ ...fields, files: newFiles })
                }
                resetError={reply.reset}
              />
            </Box>
          </Box>
          {reply.isError && (
            <Box>
              <Alert
                type="error"
                message={
                  reply.error instanceof Error
                    ? reply.error.message
                    : reply.error instanceof ValidationError
                    ? "The given data was invalid."
                    : "Unknown Error"
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
