import { LooseObject } from "../../../types";

export enum ReportCategories {
  reportsDailyBacklog = "daily-backlog",
  reportsOpenedBySeverity = "opened-tickets-by-severity",
  reportsOpenedPerWeekDay = "average-opened-tickets-per-day-of-the-week",
  reportsTimeToResolve = "average-time-to-resolve-per-month",
  reportsPercentOfBreachedTickets = "percent-of-breached-tickets",
  reportsTicketsByStatus = "tickets-by-status",
  reportsTicketsByStage = "tickets-by-stage",
  reportsRaisedTicketsPerMonth = "number-of-raised-tickets-per-month",
  reportsActiveEscalations = "number-of-active-escalations",
  reportsEscalationsPerMonth = "number-of-escalations-per-month",
}

export interface ReportCategory {
  id: string;
  name: string;
  description: string;
}

export interface ReportDetails {
  data: LooseObject[];
  name: string;
  period?: ReportPeriod;
}

export interface ReportPeriod {
  from: string;
  to: string;
}

export interface Query {
  limit: number;
  page: number;
}

export interface ReportsRouteParams {
  id: ReportCategories;
}
