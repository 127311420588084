import React, { isValidElement } from "react";
import { Grid, Typography } from "@material-ui/core/";

type Props = {
  title: string;
  value: string | React.ReactNode;
};

const Component = ({ title, value }: Props) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="caption">{title}</Typography>
      </Grid>
      <Grid item xs={8}>
        {isValidElement(value) ? (
          value
        ) : (
          <div style={{ wordWrap: "break-word" }}>
            <Typography variant="h4">{value}</Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default Component;
