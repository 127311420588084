import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Query, QueryItem } from "../../types";
import FilterPopup from "./FilterPopup";

interface Props {
  filterName: string;
  filterKey: string;
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ filterName, filterKey, query, setQuery }: Props) {
  const [selectedValue, setSelectedValue] = useState<boolean>(
    query[filterKey as QueryItem] === 1 ? true : false
  );

  const filterValue = query?.[filterKey as QueryItem];

  useEffect(() => {
    setSelectedValue(filterValue === 1 ? true : false);
  }, [filterValue]);

  const applyFilter = (handleClose: Function) => {
    setQuery({
      [filterKey]: selectedValue ? 1 : undefined,
    });
    handleClose();
  };

  const resetValue = () =>
    setSelectedValue(query[filterKey as QueryItem] === 1 ? true : false);

  return (
    <FilterPopup
      filterName={filterName}
      applyFilter={applyFilter}
      resetValue={resetValue}
    >
      <FormControlLabel
        control={
          <Switch
            name={filterKey}
            checked={!!selectedValue}
            onChange={(event) => setSelectedValue(event.target.checked)}
            color="primary"
          />
        }
        label={filterName}
      />
    </FilterPopup>
  );
}

export default Component;
