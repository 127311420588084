import { get, post, put } from "./";
import { Query } from "../screens/Tasks/types";
import { TaskFields } from "../types";

export function create(owner: string, params: any) {
  return post(`${owner}tasks`, params);
}

export function paginate(
  owner: string,
  query: Partial<Query> = { limit: 10, page: 1 },
  status_id?: number
) {
  return get(`${owner}tasks`, { ...query, status_id });
}

export function show(id: number | string) {
  return get(`tasks/${id}`);
}

export function statuses({ query = "", limit = 10 }) {
  return get("tasks/statuses", {
    query,
    limit,
  });
}

export function update(id: number | string, params: Partial<TaskFields>) {
  return put(`tasks/${id}`, params);
}
