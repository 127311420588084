import React from "react";

import { Grid, makeStyles, Paper, Typography } from "@material-ui/core/";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { Attachment } from "../../../../types";
import { affinityColors } from "@akj-dev/design-system";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    margin: "0 auto",
    maxWidth: "100%",
    maxHeight: "85%",
  },
  link: {
    color: affinityColors.primary.value,
    textDecoration: "none",
  },
  paper: {
    height: 150,
    padding: theme.spacing(0.5),
  },
}));

interface Props {
  attachments: Array<Attachment>;
}

export default function Component({ attachments }: Props) {
  const classes = useStyles();

  return (
    <>
      <Typography paragraph={true} variant="h6">
        <AttachFileIcon fontSize="inherit" /> Attachments ({attachments.length})
      </Typography>

      <Grid data-cy="mailbox-attachments-section" container spacing={1}>
        {attachments.map((attachment: Attachment) => (
          <Grid item key={`grid-${attachment.id}`} xs={3}>
            <Paper className={classes.paper} elevation={3} variant="outlined">
              <a
                className={classes.link}
                download
                href={attachment.url}
                rel="noopener noreferrer"
                target="_blank"
                title={attachment.name}
              >
                <img
                  alt={attachment.name}
                  className={classes.image}
                  src={attachment.preview_url}
                />

                <Typography noWrap variant="body1">
                  {attachment.name}
                </Typography>
              </a>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
