import React, { ReactNode } from "react";
import { Card, Box, makeStyles, Theme } from "@material-ui/core";
import { IndexedTitle } from "./IndexedTitle";

export interface IndexedCardInfoProps {
  index?: number | string;
  title: string;
  children: ReactNode;
}

export const IndexedCardInfo = ({
  index,
  title,
  children,
}: IndexedCardInfoProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box className={classes.box}>
        <IndexedTitle index={index} title={title} />
      </Box>
      <Box className={classes.box}>{children}</Box>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: theme.spacing(0.25),
    borderLeft: `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
  },
  box: {
    padding: theme.spacing(1),
  },
}));
