import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import KnowledgeBase from "../..";
import { Category } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    card: {
      textAlign: "center",
      "&:hover": {
        boxShadow: theme.shadows[12],
      },
    },
    cardContent: {
      paddingTop: "24px",
    },
    button: {
      width: "100%",
      display: "block",
    },
    icon: {
      margin: "0 18px 0 8px",
    },
  })
);

type Props = {
  id: number;
  category: Category;
  onClick: (id: number) => void;
};

function Component({ id, category, onClick }: Props) {
  const classes = useStyles();
  let { url } = useRouteMatch();

  return (
    <div data-cy={`knowledge-base-subgrid-item-${category.id}`}>
      <Link className={classes.link} to={`${url}/${id}`}>
        <Button onClick={() => onClick(id)} className={classes.button}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box display="flex" alignItems="center">
                <FolderOpenIcon className={classes.icon} />
                <Box>
                  <Typography variant="h4">{category.name}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Button>
      </Link>
      <Switch>
        <Route path={`${url}/:id`}>
          <Link to={`${url}/${id}`}>
            <KnowledgeBase topLevel={false} />
          </Link>
        </Route>
      </Switch>
    </div>
  );
}

export default Component;
