import React, { useEffect, useState } from "react";
import { useParams, useHistory, withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import {
  Button,
  Paper,
  Tab,
  Tabs,
  Divider,
  makeStyles,
  Box,
  Chip,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import PauseIcon from "@material-ui/icons/Pause";
import TimerIcon from "@material-ui/icons/Timer";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { amber } from "@material-ui/core/colors";
import Main from "../../../layout/Main";
import ClickPopover from "../../../components/ClickPopover";
import SplitButton from "../../../components/SplitButton";
import Autocomplete from "../../../components/Autocomplete";
import Audits from "../../Audits";
import Comments from "../../Comments";
import Associations from "./Associations";
import Details from "./Details";
import Info from "./Info";
import Time from "./Time";
import SLADetails from "./SLADetails";
import Subscriptions from "./Subscriptions";
import Tasks from "./Tasks";
import Viewers from "./components/Viewers";
import { Escalator, LooseObject } from "../../../types";
import { formattedDateTime, hasPermission } from "../../../helpers/functions";
import { PermissionCodes } from "../../../helpers/constants";
import { useDebounce } from "../../../helpers/hooks";
import {
  useDeescalate,
  useEscalate,
  usePaginate,
  useSubscribe,
  useTicketDetail,
  useUnsubscribe,
  useUnwatch,
  useVisits,
  useWatch,
} from "../../../hooks/tickets";
import { useTicketEscalators } from "../../../hooks/tickets/autocomplete";
import { useQueryClient } from "react-query";
import { Query } from "../types";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 1.5, 1.5),
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
  in_sla: {
    color: theme.palette.success.main,

    "& .MuiTab-wrapper": {
      flexDirection: "row",

      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  out_of_sla: {
    color: theme.palette.error.main,

    "& .MuiTab-wrapper": {
      flexDirection: "row",

      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  paused_sla: {
    color: theme.palette.info.main,

    "& .MuiTab-wrapper": {
      flexDirection: "row",

      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  button: {
    marginRight: "8px",
  },
}));

type PanelProps = {
  children: React.ReactNode;
  name: string;
};

const Panel = ({ children, name }: PanelProps) => {
  const { section = "info" } = useParams<LooseObject>();

  return (
    <div
      data-cy={`ticket-${name}-panel`}
      role="tabpanel"
      hidden={section !== name}
      id={`tabpanel-${name}`}
      aria-labelledby={`tab-${name}`}
    >
      {section === name && <>{children}</>}
    </div>
  );
};

function Component() {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [shouldShowSubscriptions, showSubscriptions] = useState(false);

  const { id, section = "info" } = useParams<LooseObject>();
  const { data, isLoading, error } = useTicketDetail(parseInt(id));
  const ticket = data?.data;

  const query = queryClient.getQueryData([
    "tickets",
    "query",
  ]) as Partial<Query>;
  const paginator = usePaginate(query ?? {}, {
    enabled: !!query,
  });

  const ticketsIds = paginator.data?.data?.map((ticket) => ticket.id) || [];
  const currentIndex = ticketsIds.findIndex((tid: number) => `${tid}` === id);
  const prevId = currentIndex > -1 ? ticketsIds[currentIndex - 1] : "";
  const nextId = currentIndex > -1 ? ticketsIds[currentIndex + 1] : "";

  const subscribe = useSubscribe(parseInt(id));
  const unsubscribe = useUnsubscribe(parseInt(id));

  const watch = useWatch(parseInt(id));
  const unwatch = useUnwatch(parseInt(id));

  const escalate = useEscalate(parseInt(id));
  const deescalate = useDeescalate(parseInt(id));

  const visits = useVisits(parseInt(id));

  const [escalatorInput, setEscalatorInput] = useState("");
  const [shouldLoadEscalators, setShouldLoadEscalators] = useState(false);
  const debouncedEscalatorInput = useDebounce(escalatorInput);
  const escalators = useTicketEscalators(
    parseInt(id),
    debouncedEscalatorInput,
    {
      enabled: shouldLoadEscalators || !!escalatorInput,
      onSuccess: () => setShouldLoadEscalators(false),
    }
  );

  useEffect(() => {
    if (shouldLoadEscalators) setShouldLoadEscalators(false);
  }, [id, shouldLoadEscalators]);

  return (
    <>
      <Helmet>
        <title>Affinity | Support | {ticket?.identifier ?? "Loading"}</title>
      </Helmet>
      <Main
        error={error?.message}
        title={ticket?.identifier ?? ""}
        subtitle={ticket?.short_description}
        loading={isLoading || !ticket?.id}
        stickyHeader
        menu={
          ticket && (
            <Box display="flex" justifyContent="center" height="32px">
              {ticket.is_parent && <Chip label="parent" variant="outlined" />}

              {ticket.is_child && <Chip label="child" variant="outlined" />}

              <ClickPopover
                button={{
                  color: "primary",
                  size: "small",
                  startIcon: <GroupIcon />,
                  variant: "text",
                }}
                onOpen={() => showSubscriptions(true)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div style={{ width: 268, padding: "24px 24px 12px 24px" }}>
                  {shouldShowSubscriptions && (
                    <Subscriptions
                      data-cy="ticket-subscriptions-popover"
                      ticket={ticket}
                    />
                  )}
                </div>
              </ClickPopover>

              {ticket.subscribed &&
                hasPermission(PermissionCodes.ticketsUnsubscribeMe) && (
                  <Button
                    data-cy="ticket-unsubscribe-button"
                    color="primary"
                    onClick={() => unsubscribe.mutate({})}
                    size="small"
                    startIcon={
                      unsubscribe.isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <NotificationsOffIcon />
                      )
                    }
                    variant="contained"
                    disabled={unsubscribe.isLoading}
                    className={classes.button}
                  >
                    Unsubscribe
                  </Button>
                )}
              {!ticket.subscribed && (
                <Button
                  data-cy="ticket-subscribe-button"
                  color="primary"
                  onClick={() => subscribe.mutate({})}
                  size="small"
                  startIcon={
                    subscribe.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <NotificationsIcon />
                    )
                  }
                  variant="outlined"
                  disabled={subscribe.isLoading}
                  className={classes.button}
                >
                  Subscribe
                </Button>
              )}
              {!ticket.watched && (
                <Button
                  data-cy="ticket-watch-button"
                  color="primary"
                  onClick={() => watch.mutate({ id: ticket.id })}
                  size="small"
                  startIcon={
                    watch.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <VisibilityIcon />
                    )
                  }
                  variant="outlined"
                  disabled={watch.isLoading}
                  className={classes.button}
                >
                  Watch
                </Button>
              )}
              {ticket.watched && (
                <Button
                  data-cy="ticket-unwatch-button"
                  color="primary"
                  onClick={() => unwatch.mutate({ id: ticket.id })}
                  size="small"
                  startIcon={
                    unwatch.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <VisibilityOffIcon />
                    )
                  }
                  variant="contained"
                  disabled={unwatch.isLoading}
                  className={classes.button}
                >
                  Unwatch
                </Button>
              )}
              {hasPermission(PermissionCodes.ticketsEscalatedUpdate) &&
                !ticket.escalation?.escalated_at && (
                  <SplitButton
                    data-cy="ticket-escalate-button"
                    label="Escalate"
                    handleClick={escalate.mutate}
                    handleArrowClick={() => {
                      setShouldLoadEscalators((prevValue) => !prevValue);
                    }}
                    disabled={escalate.isLoading}
                    startIcon={
                      escalate.isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <TrendingUpIcon />
                      )
                    }
                  >
                    <Box minWidth="300px" p={1}>
                      <Autocomplete
                        data-cy="ticket-escalate-autocomplete"
                        label="Escalated By"
                        name="escalated_by"
                        placeholder="Type to search"
                        loading={escalators.isLoading}
                        disabled={escalate.isLoading}
                        options={escalators.data?.data || []}
                        valueFrom="reference"
                        textFrom="full_name"
                        value={null}
                        onSelect={(escalator: Escalator) =>
                          escalate.mutate(escalator.reference)
                        }
                        onSearch={(query) => setEscalatorInput(query)}
                        onBlur={() => setEscalatorInput("")}
                      />
                    </Box>
                  </SplitButton>
                )}
              {hasPermission(PermissionCodes.ticketsEscalatedView) &&
                !!ticket.escalation?.escalated_at && (
                  <SplitButton
                    startIcon={
                      escalate.isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <TrendingUpIcon />
                      )
                    }
                    label="Escalated"
                    disabled
                  >
                    <Box p={1} textAlign="right">
                      <Typography variant="body2" paragraph>
                        Escalation time:{" "}
                        <b>
                          {formattedDateTime(
                            ticket.escalation.escalated_at,
                            "dd/MM/yyyy HH:mm"
                          )}{" "}
                        </b>
                      </Typography>
                      <Typography variant="body2">
                        Escalator: <b>{ticket.escalation.full_name}</b>
                      </Typography>
                      {hasPermission(
                        PermissionCodes.ticketsEscalatedDelete
                      ) && (
                        <Box mt={0.5}>
                          <Button
                            data-cy="ticket-deescalate-button"
                            color="primary"
                            onClick={() => deescalate.mutate()}
                            size="small"
                            startIcon={
                              deescalate.isLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <TrendingDownIcon />
                              )
                            }
                            variant="contained"
                            disabled={deescalate.isLoading}
                          >
                            De-escalate
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </SplitButton>
                )}
            </Box>
          )
        }
      >
        <Box display="flex" width="100%" pb={1} justifyContent="space-between">
          <Button
            data-cy="ticket-back-button"
            startIcon={<ChevronLeftIcon />}
            variant="text"
            color="primary"
            component={Link}
            to={"/support/tickets?" + queryString.stringify(query)}
          >
            Back to all Tickets
          </Button>
          <Box display="flex" justifyContent="center">
            {/* {hasPermission(PermissionCodes.ticketsVisitsView) && ( */}
            <Viewers
              visits={[...(visits.data?.data ?? [])].reverse()}
              loading={visits.isLoading}
              maxShown={4}
            />
            {/* )} */}
            {(prevId || nextId) && (
              <Box margin="auto" pl={1}>
                <Button
                  data-cy="ticket-previous-button"
                  startIcon={<ArrowLeftIcon />}
                  variant="text"
                  color="primary"
                  onClick={() => history.push(`/support/tickets/${prevId}`)}
                  disabled={!prevId}
                >
                  Previous Ticket
                </Button>
                {" | "}
                <Button
                  data-cy="ticket-next-button"
                  endIcon={<ArrowRightIcon />}
                  variant="text"
                  color="primary"
                  onClick={() => history.push(`/support/tickets/${nextId}`)}
                  disabled={!nextId}
                >
                  Next Ticket
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {ticket && (
          <Paper elevation={4} style={{ width: "100%" }}>
            <Tabs indicatorColor="primary" textColor="primary" value={section}>
              <Tab
                data-cy="ticket-info-tab"
                label="Ticket Details"
                value="info"
                onClick={() => history.replace(`/support/tickets/${id}`)}
              />
              {ticket.s_l_a_calendar?.id &&
                hasPermission(PermissionCodes.slaCalendarView) && (
                  <Tab
                    data-cy="ticket-sla-tab"
                    label={
                      <>
                        {ticket.is_s_l_a_paused ? (
                          <>
                            <PauseIcon fontSize="inherit" /> SLA Details
                          </>
                        ) : ticket.is_in_s_l_a ? (
                          (ticket.active_resolution_commitment_percentage ??
                            0) > 51 ? (
                            <>
                              <TimerIcon style={{ color: amber[400] }} /> SLA
                              Details
                            </>
                          ) : (
                            <>
                              <DoneIcon /> SLA Details
                            </>
                          )
                        ) : (
                          <>
                            <CloseIcon fontSize="inherit" /> SLA Details
                          </>
                        )}
                      </>
                    }
                    value="sla"
                    className={
                      ticket.is_s_l_a_paused
                        ? classes.paused_sla
                        : ticket.is_in_s_l_a
                        ? classes.in_sla
                        : classes.out_of_sla
                    }
                    onClick={() =>
                      history.replace(`/support/tickets/${id}/sla`)
                    }
                  />
                )}
              {hasPermission(PermissionCodes.tasksViewAny) && (
                <Tab
                  data-cy="ticket-tasks-tab"
                  label="Tasks"
                  value="tasks"
                  onClick={() =>
                    history.replace(`/support/tickets/${id}/tasks`)
                  }
                />
              )}
              <Tab
                data-cy="ticket-associations-tab"
                label="Parent & Child"
                value="associations"
                onClick={() =>
                  history.replace(`/support/tickets/${id}/associations`)
                }
              />
              {hasPermission(PermissionCodes.ticketsHistory) && (
                <Tab
                  data-cy="ticket-history-tab"
                  label="History"
                  value="history"
                  onClick={() =>
                    history.replace(`/support/tickets/${id}/history`)
                  }
                />
              )}
              {hasPermission(PermissionCodes.ticketsTimersViewAny) && (
                <Tab
                  data-cy="ticket-time-tab"
                  label="Time"
                  value="time"
                  onClick={() => history.replace(`/support/tickets/${id}/time`)}
                />
              )}
            </Tabs>

            <Divider className={classes.divider} />

            <div className={classes.content}>
              <Info ticket={ticket} />

              <Divider className={classes.divider} />

              <Panel name="info">
                <Details ticket={ticket} />
                {ticket.id !== undefined && (
                  <Box pt={3}>
                    <Comments owner={`tickets/${ticket.id}/`} />
                  </Box>
                )}
              </Panel>
              {ticket.s_l_a_calendar?.id &&
                hasPermission(PermissionCodes.slaCalendarView) && (
                  <Panel name="sla">
                    <SLADetails ticket={ticket} />
                  </Panel>
                )}
              {hasPermission(PermissionCodes.tasksViewAny) && (
                <Panel name="tasks">
                  <Tasks ticket={ticket} />
                </Panel>
              )}
              <Panel name="associations">
                <Associations ticket={ticket} />
              </Panel>
              {hasPermission(PermissionCodes.ticketsHistory) && (
                <Panel name="history">
                  <Audits owner={`tickets/${ticket.id}/`} />
                </Panel>
              )}
              {hasPermission(PermissionCodes.ticketsTimersViewAny) && (
                <Panel name="time">
                  <Time ticket={ticket} />
                </Panel>
              )}
            </div>
          </Paper>
        )}
      </Main>
    </>
  );
}

export default withRouter(Component);
