import React from "react";
import { Box, makeStyles, Theme, createStyles } from "@material-ui/core";
import Panel from "./Panel";
import { Status } from "../../../types";
import { Query } from "../types";
import { useStatuses } from "../../../hooks/tickets/autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      position: "relative",
      "&::before": {
        content: '""',
        width: "16px",
        height: "100%",
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1), rgba(255,0,0,0))",
        position: "absolute",
        zIndex: 900,
      },
      "&::after": {
        content: '""',
        width: "16px",
        height: "100%",
        backgroundImage:
          "linear-gradient(to left, rgba(255,255,255,1), rgba(255,0,0,0))",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 900,
      },
    },
    box: {
      alignItems: "flex-start",
      display: "flex",
      padding: "0 16px",
      overflowX: "scroll",
      overflowY: "hidden",
    },
  })
);

type Props = {
  query: Partial<Query>;
};

function Component({ query }: Props) {
  const classes = useStyles();
  const statuses = useStatuses();
  const filteredStatuses = statuses.data?.data?.filter(
    (status: Status) =>
      query.is_open === undefined || status.is_open === Boolean(query.is_open)
  );

  return (
    <div className={classes.wrap}>
      <Box data-cy="tickets-kanban" className={classes.box}>
        {filteredStatuses?.map((status: Status, index: number) => (
          <Panel
            data-cy={`tickets-kanban-panel-${status.id}`}
            key={status.id}
            query={query}
            status={status}
            showDivider={index !== filteredStatuses.length - 1}
          />
        ))}
      </Box>
    </div>
  );
}

export default Component;
