import { get } from "./";
import { Query } from "../screens/Events/types";

export function paginate(query: Partial<Query> = { limit: 10, page: 1 }) {
  return get("events", query);
}

export function show(id?: number | null) {
  if (!id) return null;
  return get(`events/${id}`);
}
