import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { Query } from "../types";
import { ticketsReports } from "../../../helpers/constants";

type Props = {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
};

const useStyles = makeStyles((theme) => ({
  table: {
    "& th": {
      whiteSpace: "nowrap",
      width: "50%",
    },
    "& td": {
      whiteSpace: "nowrap",
    },
  },
}));

export default function Component({ query, setQuery }: Props) {
  const classes = useStyles();
  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: "Report Name",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => (
          <Button
            color="primary"
            size="small"
            component={Link}
            to={`/support/reports/${tableMeta.rowData[0]}`}
          >
            {value}
          </Button>
        ),
      },
    },

    {
      name: "description",
      label: "Description",
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    sort: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,

    // responsive: "standard",
    serverSide: true,

    rowsPerPage: query.limit,
    page: (query.page || 1) - 1,
    count: ticketsReports?.length,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    setTableProps: () => ({
      className: classes.table,
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="reports-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Reports</Typography>
          </Box>
        </Box>
      }
      data={ticketsReports}
      columns={columns}
      options={options}
    />
  );
}
