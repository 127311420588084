import React from "react";
import { UseQueryResult } from "react-query";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
  // Responsive
} from "mui-datatables";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { functions } from "../../../../helpers";
import { Query } from "../../types";
import { QueryData, SortDirection, Task } from "../../../../types";

type Props = {
  elevation?: number;
  embedded?: boolean;
  paginator: UseQueryResult<QueryData<Task>, Error>;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({
  elevation,
  embedded,
  paginator,
  query,
  setQuery,
}: Props) {
  // Check if "sort" is equal with the given "columnName"
  // and return the value of "order" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction : "none";

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = paginator.data?.data[tableMeta.rowIndex];

          if (row)
            return (
              <Button
                variant="text"
                color="primary"
                size="small"
                component={Link}
                to={`/support/tasks/${row.id}`}
              >
                #{value}
              </Button>
            );
        },
        sortDirection: getSortDirection("id"),
      },
    },
    {
      name: "name",
      label: "Subject",
      options: {
        sortDirection: getSortDirection("name"),
      },
    },
    {
      name: "status.name",
      label: "Status",
      options: {
        sortDirection: getSortDirection("status.name"),
      },
    },
    {
      name: "user.full_name",
      label: "Assigned To",
      options: {
        sortDirection: getSortDirection("user.full_name"),
      },
    },
    {
      name: "created_at",
      label: "Created",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return functions.formattedDate(value);
        },
        sortDirection: getSortDirection("created_at"),
      },
    },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        setCellHeaderProps: () => ({
          style: { width: 122 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return value ? functions.formattedDate(value) : "N/A";
        },
        sortDirection: getSortDirection("due_date"),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: elevation ?? 1,
    disableToolbarSelect: true,

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: paginator.data?.meta.total,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="tasks-table"
      title={
        embedded ? null : (
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <Typography variant="subtitle1">Tasks</Typography>
            </Box>
            {paginator.isLoading && <CircularProgress size={24} />}
          </Box>
        )
      }
      data={paginator.data?.data || []}
      columns={columns}
      options={options}
    />
  );
}
