import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableMeta,
} from "mui-datatables";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Query } from "../types";
import { SortDirection, User } from "../../../types";

type Props = {
  data: User[];
  loading: boolean;
  count: number;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({
  data,
  loading,
  count,
  query,
  setQuery,
}: Props) {
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.direction : "none";

  const columns: MUIDataTableColumn[] = [
    {
      name: "full_name",
      label: "Full Name",
      options: {
        sortDirection: getSortDirection("full_name"),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        sortDirection: getSortDirection("email"),
      },
    },
    {
      name: "phone",
      label: "Phone Number",
      options: {
        sortDirection: getSortDirection("phone"),
      },
    },
    {
      name: "teams",
      label: "Teams",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (teams: Array<any>, tableMeta: MUIDataTableMeta) => {
          return teams?.map((team) => team.name).join(", ");
        },
      },
    },
    {
      name: "customers",
      label: "Customers",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (
          customers: Array<any>,
          tableMeta: MUIDataTableMeta
        ) => {
          return customers?.map((customer) => customer.name).join(", ");
        },
      },
    },
    {
      name: "id",
      label: " ",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 10, paddingLeft: 40 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => (
          <Button
            data-cy="users-edit-button"
            color="primary"
            size="small"
            component={Link}
            to={`/support/admin/users/${value}/edit`}
          >
            Edit
          </Button>
        ),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "multiple",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: count,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        direction: direction === "ascending" ? "asc" : "desc",
      });
    },

    setTableProps: () => ({
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      data-cy="users-table"
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Users</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
}
