import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";

export default function FilterBox({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  showReset,
  disableApply,
  children,
  ...props
}: any) {
  const classes = useStyles();

  return (
    <div {...props}>
      <List disablePadding>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.filterActions}
        >
          {item?.name === "Bill Period" ? (
            <span>
              <Typography variant="caption" className={classes.filterTitle}>
                Filter by {item.name}
              </Typography>
              {item.MAX_COUNT && (
                <Typography component="p" variant="caption">
                  You can select up to {item.MAX_COUNT} bill/s
                </Typography>
              )}
            </span>
          ) : (
            <Typography variant="caption" className={classes.filterTitle}>
              Filter by {item.name}
            </Typography>
          )}
          <Button
            data-cy="reset-filter-button"
            size="small"
            onClick={() => resetSelection()}
            className={showReset ? classes.show : classes.hidden}
          >
            Reset
          </Button>
        </Grid>
        {children}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.filterAdd}
        >
          <Button
            data-cy="add-filter-button"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => addSelection()}
            disabled={disableApply ? disableApply : selection.length < 1}
          >
            Apply
          </Button>
          <Button
            data-cy="close-filter-button"
            size="small"
            onClick={() => close()}
          >
            Cancel
          </Button>
        </Grid>
      </List>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActions: {
      padding: theme.spacing(2),
    },
    filterAdd: {
      padding: theme.spacing(2),
    },
    filterTitle: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    hidden: {
      display: "none",
    },
    show: {
      display: "initial",
    },
  })
);
