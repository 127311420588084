import React from "react";
import { Box } from "@material-ui/core";
import TextFilter from "./TextFilter";
import { Query } from "../../types";
import AutocompleteFilterMultiple from "./AutocompleteFilterMultiple";
import { useQueryClient } from "react-query";
import { useCustomers, useTeams } from "../../../../hooks/autocomplete";

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
}

function Component({ query, setQuery }: Props) {
  const queryClient = useQueryClient();

  return (
    <Box display="flex">
      <Box pr={1}>
        <TextFilter
          filterName="Full Name"
          filterKey="full_names"
          queryPart={query.full_names || []}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1}>
        <TextFilter
          filterName="Email"
          filterKey="emails"
          queryPart={query.emails || []}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1}>
        <TextFilter
          filterName="Phone Number"
          filterKey="phones"
          queryPart={query.phones || []}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1}>
        <AutocompleteFilterMultiple
          filterName="Teams"
          filterKey="team_ids"
          queryFn={useTeams}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "teams",
            { ids: query.team_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
      </Box>
      <Box pr={1}>
        <AutocompleteFilterMultiple
          filterName="Customers"
          filterKey="customer_ids"
          queryFn={useCustomers}
          alreadySelected={queryClient.getQueryData([
            "autocomplete",
            "customers",
            { ids: query.customer_ids },
          ])}
          query={query}
          setQuery={setQuery}
        />
      </Box>
    </Box>
  );
}

export default Component;
