import React from "react";
import Typography from "@material-ui/core/Typography";
import { formattedDate } from "../../../../helpers/functions";
import { Task } from "../../../../types";

interface Props {
  task: Task;
}

export default function Component({ task }: Props) {
  return (
    <>
      <Typography paragraph variant="caption">
        Created {formattedDate(task.created_at)}
      </Typography>

      <Typography paragraph variant="h4">
        {task.name}
      </Typography>

      <Typography paragraph variant="body1">
        {task.description}
      </Typography>
    </>
  );
}
