import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormGroup, TextField } from "@material-ui/core";
import { StatusChip, LoaderButton } from "@akj-dev/design-system";
import { ModalAuthDialog } from "./ModalAuthDialog";
import { Login } from "@akj-dev/affinity-platform";
import { BrandedDocumentTitle } from "../BrandedDocumentTitle";
import config from "../../config";

interface LoginScreenProps {
  handleLogin: Login;
  loading: boolean;
  loginError: string;
  jwtError: string;
}

const useStyles = makeStyles((theme) => ({
  forgot: {
    alignSelf: "flex-end",
    marginBottom: theme.spacing(1),
  },
  login: {
    marginBottom: theme.spacing(2),
  },
}));

export const LoginScreen = ({
  handleLogin,
  loading,
  loginError,
  jwtError,
}: LoginScreenProps) => {
  const classes = useStyles();
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const doLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    handleLogin(state.username, state.password);
  };

  return (
    <ModalAuthDialog>
      <BrandedDocumentTitle title="login" />
      <form onSubmit={doLogin}>
        <FormGroup>
          <TextField
            label="Username"
            name="username"
            value={state.username}
            onChange={handleChange}
            // variant="outlined" // TODO: This errors - DS related?
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            // variant="outlined"
            margin="normal"
          />
          <Button
            className={classes.forgot}
            href={
              config.usersAppUrl[process.env.REACT_APP_STAGE] +
              "/user/forgotten_password"
            }
          >
            Forgot your password?
          </Button>
          <LoaderButton
            loading={loading}
            variant="contained"
            color="primary"
            className={classes.login}
            type="submit"
          >
            Log in
          </LoaderButton>
        </FormGroup>
      </form>

      {loginError && (
        <StatusChip type="error" title="Sign in error" message={loginError} />
      )}

      {jwtError && (
        // Not necessarily an auto logout, but there's only one error condition
        // returned AFAIK so this is the best we can do.
        <StatusChip
          type="info"
          title="You have been logged out"
          message="you have been automatically logged out"
        />
      )}
    </ModalAuthDialog>
  );
};
